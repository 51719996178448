import React, { useState } from 'react'

export default function Comments({ comments, showGoogle = false }) {
  const [modalOpen, setModalOpen] = useState(false)

  const onMouseOver = () => {
    setModalOpen(true)
  }

  const onMouseOut = () => {
    setModalOpen(false)
  }
  if (comments && comments.length > 0) {
    return (
      <div
        className='comments'
        onMouseOver={e => onMouseOver()}
        onMouseOut={e => onMouseOut()}
      >
        <i
          className='far fa-hand-paper mr-2'
          style={{
            color: '#FFE200',
          }}
        />
        {showGoogle && (
          <i className='fab fa-google' style={{ color: '#008DFF' }}></i>
        )}

        <div
          className='comments-pop'
          style={{
            visibility: modalOpen ? 'initial' : 'hidden',
          }}
        >
          {comments.map((comment, index) => {
            return (
              <div
                className='comments-pop-text'
                key={comment.id}
                style={{
                  border: index === 0 ? 'none' : null,
                }}
              >
                {comment.content}
              </div>
            )
          })}
        </div>
      </div>
    )
  } else if (showGoogle) {
    return (
      <div className='comments'>
        <i className='fab fa-google' style={{ color: '#008DFF' }}></i>
      </div>
    )
  }
  return <div>-</div>
}
