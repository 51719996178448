import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import ReactSelect from 'react-select'
import { BASIC_SELECT_STYLE, BASIC_SELECT_THEME } from '../../utils/constant'
import { filterFromObjectArray } from '../../utils/tools'
import { getOnboardingOptions } from '../../store/actions/onboarding'
const BrandsFilter = ({ onBrandChange, brandName }) => {
  const dispatch = useDispatch()
  const onboarding = useSelector(state => state.onboarding)
  const all_option = { value: '', label: 'All Brands' }
  const [options, setOptions] = useState([all_option])
  let { onboardingOptions, getOnboardingOptionsRequest } = onboarding
  const [selected, setSelected] = useState(all_option)

  useEffect(() => {
    const loadData = () => {
      dispatch(getOnboardingOptions({ type: 'brands' }))
    }
    loadData()
  }, [dispatch])

  useEffect(() => {
    if (brandName) {
      setSelected(filterFromObjectArray(options, brandName, 'value'))
    }
  }, [brandName, options])

  useEffect(() => {
    if (
      onboardingOptions &&
      onboardingOptions.brands &&
      !getOnboardingOptionsRequest
    ) {
      var arr = [{ value: '', label: 'All Brands' }]
      onboardingOptions.brands.forEach(brand => {
        arr.push({
          value: brand,
          label: brand,
        })
      })
      setOptions(arr)
    }
  }, [onboardingOptions, getOnboardingOptionsRequest])

  const DropdownIndicator = () => {
    return <i className='fas fa-caret-down multi-select-dropdown-indicator'></i>
  }

  return (
    <ReactSelect
      value={selected}
      name='brand_name'
      options={options}
      className='basic-single'
      classNamePrefix='select'
      onChange={value => onBrandChange(value)}
      components={{ DropdownIndicator }}
      theme={BASIC_SELECT_THEME}
      styles={BASIC_SELECT_STYLE}
    />
  )
}

export default BrandsFilter

BrandsFilter.propTypes = {
  onBrandChange: PropTypes.func.isRequired,
  brandName: PropTypes.string,
}
