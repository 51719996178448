import React, { useEffect, useState } from 'react'
import { DateRange } from 'react-date-range'
import { Button } from 'reactstrap'

const CustomDateRangePicker = ({ children, showPicker, submitDate }) => {
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())

  const selectedRange = e => {
    setStartDate(e.selection.startDate)
    setEndDate(e.selection.endDate)
  }

  return (
    <div className='position-relative'>
      {children}
      {showPicker && (
        <div className='position-absolute z-100 daterange-container'>
          <div className='date-range'>
            <DateRange
              moveRangeOnFirstSelection={false}
              onChange={selectedRange}
              ranges={[{ startDate, endDate, key: 'selection' }]}
              direction='horizontal'
              rangeColors={['#008DFF']}
            />
            <Button
              block
              color='primary'
              className='btn-shadow w-48'
              onClick={() => {
                submitDate(startDate, endDate)
              }}
            >
              Confirm
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default CustomDateRangePicker
