import axios from "axios";
import { toast } from "react-toastify";

export const GET_REFERRAL_LIST_REQUEST = "GET_REFERRAL_LIST_REQUEST";
export const GET_REFERRAL_LIST_SUCCESS = "GET_REFERRAL_LIST_SUCCESS";
export const GET_REFERRAL_LIST_FAIL = "GET_REFERRAL_LIST_FAIL";
export const getReferralList =
  ({ params = {}, refresh = true, page = 1, per_page = 15 }) =>
  (dispatch) => {
    dispatch({ type: GET_REFERRAL_LIST_REQUEST });
    axios
      .request({
        url: "/nc/referral/list",
        method: "get",
        params: {
          ...params,
          per_page: per_page,
          page: params.page ?? page,
        },
        cache: { ignoreCache: params ? params.refresh ?? refresh : refresh },
      })
      .then(({ data }) => {
        if (data.redirect) return;

        if (data.success) {
          dispatch({
            type: GET_REFERRAL_LIST_SUCCESS,
            data: data.data,
            msg: data.message,
            errors: data.errors,
          });
        } else {
          dispatch({
            type: GET_REFERRAL_LIST_FAIL,
            msg: data.message,
            errors: data.errors,
          });
          toast.warn(data.message);
        }
      });
  };

export const REFERRAL_LIST_CHANGE = "REFERRAL_LIST_CHANGE";
export const referralListChange = (list) => dispatch => {
  dispatch({ type: REFERRAL_LIST_CHANGE, list });
}

export const EDIT_REFERRAL_REQUEST = "EDIT_REFERRAL_REQUEST";
export const EDIT_REFERRAL_SUCCESS = "EDIT_REFERRAL_SUCCESS";
export const EDIT_REFERRAL_FAIL = "EDIT_REFERRAL_FAIL";
export const editReferral =
  ( signup_form, status ) =>
  async (dispatch) => {
    dispatch({ type: EDIT_REFERRAL_REQUEST });
    return axios
      .request({
        url: `nc/referral/edit/${signup_form}`,
        method: "post",
        params: { status }
      })
      .then(({ data }) => {
        if (data.redirect) return;

        if (data.success) {
          dispatch({
            type: EDIT_REFERRAL_SUCCESS,
            data: data.data,
            msg: data.message,
            errors: data.errors,
          });
        } else {
          dispatch({
            type: EDIT_REFERRAL_FAIL,
            msg: data.message,
            errors: data.errors,
          });
          toast.warn(data.message);
        }
        return data
      })
      .catch(err => {
        dispatch({ type: EDIT_REFERRAL_FAIL })
        return { success: false, data: [] }
      });
  };
  
  