import axios from 'axios'
import { toast } from 'react-toastify'

export const SUPPORT_GET_SUPPORTS_REQUEST = 'SUPPORT_GET_SUPPORTS_REQUEST'
export const SUPPORT_GET_SUPPORTS_SUCCESS = 'SUPPORT_GET_SUPPORTS_SUCCESS'
export const SUPPORT_GET_SUPPORTS_FAIL = 'SUPPORT_GET_SUPPORTS_FAIL'
export const getSupports = params => dispatch => {
  dispatch({ type: SUPPORT_GET_SUPPORTS_REQUEST })
  let {
    brand_name,
    progress,
    phone,
    page,
    ticket_status,
    refresh = false,
    count_order,
    project_lead_id,
  } = params
  // let get_params = {
  //   ticket_status: "help_needed",
  // };
  let get_params = {}
  if (brand_name && brand_name !== '')
    get_params = { ...get_params, brand_name }
  if (phone && phone !== '') get_params = { ...get_params, phone }
  if (page && page !== '') get_params = { ...get_params, page }
  if (ticket_status && ticket_status !== '')
    get_params = { ...get_params, ticket_status }
  if (progress) {
    get_params = { ...get_params, progress: progress }
  }
  if (count_order) {
    get_params = { ...get_params, count_order }
  }
  if (project_lead_id) {
    get_params = { ...get_params, project_lead_id }
  }
  axios
    .request({
      url: 'nc/restaurant/list',
      method: 'get',
      params: get_params,
      cache: { ignoreCache: refresh },
    })
    .then(({ data }) => {
      if (data.redirect) return

      if (data.success) {
        dispatch({
          type: SUPPORT_GET_SUPPORTS_SUCCESS,
          data: data.data,
          msg: data.message,
          errors: data.errors,
        })
      } else {
        dispatch({
          type: SUPPORT_GET_SUPPORTS_FAIL,
          msg: data.message,
          errors: data.errors,
        })
        toast.warn(data.message)
      }
    })
}

export const getOnboardingSupports = params => dispatch => {
  dispatch({ type: SUPPORT_GET_SUPPORTS_REQUEST })
  let {
    brand_name,
    phone,
    page,
    project_lead_id,
    refresh = false,
    progress,
  } = params
  let get_params = {}
  if (brand_name && brand_name !== '')
    get_params = { ...get_params, brand_name }
  if (phone && phone !== '') get_params = { ...get_params, phone }
  if (page && page !== '') get_params = { ...get_params, page }
  if (project_lead_id && project_lead_id !== '')
    get_params = { ...get_params, project_lead_id }
  if (progress) {
    get_params = { ...get_params, progress }
  }
  axios
    .request({
      url: 'nc/supports/onboardings',
      method: 'get',
      params: get_params,
      cache: { ignoreCache: refresh },
    })
    .then(({ data }) => {
      if (data.redirect) return

      if (data.success) {
        dispatch({
          type: SUPPORT_GET_SUPPORTS_SUCCESS,
          data: data.data,
          msg: data.message,
          errors: data.errors,
        })
      } else {
        dispatch({
          type: SUPPORT_GET_SUPPORTS_FAIL,
          msg: data.message,
          errors: data.errors,
        })
        toast.warn(data.message)
      }
    })
}

export const SUPPORT_GET_SUPPORT_REQUEST = 'SUPPORT_GET_SUPPORT_REQUEST'
export const SUPPORT_GET_SUPPORT_SUCCESS = 'SUPPORT_GET_SUPPORT_SUCCESS'
export const SUPPORT_GET_SUPPORT_FAIL = 'SUPPORT_GET_SUPPORT_FAIL'
export const getSupport =
  (params, refresh = false, page, per_page = 15) =>
  (dispatch, getState) => {
    dispatch({ type: SUPPORT_GET_SUPPORT_REQUEST })

    axios
      .request({
        url: 'nc/restaurant/show',
        method: 'get',
        params: {
          location_id: params ? params.location_id ?? '' : '',
        },
        cache: { ignoreCache: params ? params.refresh ?? refresh : refresh },
      })
      .then(({ data }) => {
        if (data.redirect) return

        if (data.success) {
          dispatch({
            type: SUPPORT_GET_SUPPORT_SUCCESS,
            data: data.data,
            msg: data.message,
            errors: data.errors,
          })
        } else {
          dispatch({
            type: SUPPORT_GET_SUPPORT_FAIL,
            msg: data.message,
            errors: data.errors,
          })
          toast.warn(data.message)
        }
      })
  }

export const postCancelAccount = (params) => async dispatch => {
  return axios.request({
    url: 'nc/sales/account/canceled',
    method: 'POST',
    params,
    cache: { ignoreCache: false },
  })
}

export const SUPPORT_UPLOAD_MENU_REQUEST = 'SUPPORT_UPLOAD_MENU_REQUEST'
export const SUPPORT_UPLOAD_MENU_SUCCESS = 'SUPPORT_UPLOAD_MENU_SUCCESS'
export const SUPPORT_UPLOAD_MENU_FAIL = 'SUPPORT_UPLOAD_MENU_FAIL'
export const uploadMenu =
  (params, refresh = false) =>
  (dispatch, getState) => {
    dispatch({ type: SUPPORT_UPLOAD_MENU_REQUEST })

    let formData = new FormData()

    formData.append('open_hours', JSON.stringify(params.open_hours) ?? '')

    formData.append('csv', params.csv)
    formData.append('menu_collection_name', params.menu_collection_name ?? '')
    formData.append('location_id', params.location_id ?? '')
    formData.append('type', params.type ?? '')
    formData.append('availability', params.availability ?? '')
    formData.append('reset_w_csv', 1)
    axios
      .request({
        url: 'nc/import',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        method: 'post',
        data: formData,
      })
      .then(({ data }) => {
        if (data.redirect) return
        if (data.success) {
          dispatch({
            type: SUPPORT_UPLOAD_MENU_SUCCESS,
            data: data.data,
            msg: data.message,
            errors: data.errors,
          })
        } else {
          dispatch({
            type: SUPPORT_UPLOAD_MENU_FAIL,
            msg: data.message,
            errors: data.errors,
          })
        }
      })
  }

export const getAccess =
  (params, refresh = false) =>
  async dispatch => {
    let formData = new FormData()

    formData.append('access_code', params.access_code ?? '')
    formData.append('location_id', params.location_id)
    return axios.request({
      url: 'nc/support/get_access',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      method: 'post',
      data: formData,
    })
  }

export const SUPPORT_GET_EXPORT_REQUEST = 'SUPPORT_GET_EXPORT_REQUEST'
export const SUPPORT_GET_EXPORT_SUCCESS = 'SUPPORT_GET_EXPORT_SUCCESS'
export const SUPPORT_GET_EXPORT_FAIL = ' SUPPORT_GET_EXPORT_FAIL'
export const getExport =
  (params, refresh = false) =>
  dispatch => {
    dispatch({ type: SUPPORT_GET_EXPORT_REQUEST })

    axios
      .request({
        url: 'nc/get_gsheet_link',
        method: 'get',
        params: {
          onboarding_id: params ? params.onboarding_id ?? '' : '',
        },
        cache: { ignoreCache: params ? params.refresh ?? refresh : refresh },
      })
      .then(({ data }) => {
        if (data.redirect) return

        if (data.success) {
          dispatch({
            type: SUPPORT_GET_EXPORT_SUCCESS,
            data: data.data,
            msg: data.message,
            errors: data.errors,
          })
        } else {
          dispatch({
            type: SUPPORT_GET_EXPORT_FAIL,
            msg: data.message,
            errors: data.errors,
          })
          toast.warn(data.message)
        }
      })
  }

export const getOnboarding = (onboarding_id, refresh) => async dispatch => {
  return axios.request({
    url: '/nc/support/onboarding/' + onboarding_id,
    method: 'GET',
    cache: { ignoreCache: refresh },
  })
}

export const getQuestionFile = (id, refresh) => async dispatch => {
  return axios.request({
    url: '/nc/support/onboarding/download/' + id,
    method: 'POST',
    cache: { ignoreCache: refresh },
    responseType: 'arraybuffer',
  })
}

export const updateOnboarding =
  (onboarding_id, params, refresh) => async dispatch => {
    return axios.request({
      url: '/nc/support/onboarding/' + onboarding_id,
      method: 'PATCH',
      params: params,
      cache: { ignoreCache: refresh },
    })
  }
export const noteCreate =
  (onboarding_id, content, refresh = true) =>
  async dispatch => {
    return axios.request({
      url: '/nc/support/onboarding/comment/create',
      method: 'POST',
      params: {
        onboarding_id,
        content,
      },
      cache: { ignoreCache: refresh },
    })
  }

export const noteEdit =
  (onboarding_comment_id, content, ticket_status, refresh = true) =>
  async dispatch => {
    return axios.request({
      url: '/nc/support/onboarding/comment/edit',
      method: 'POST',
      params: {
        onboarding_comment_id,
        content,
        ticket_status,
      },
      cache: { ignoreCache: refresh },
    })
  }

export const noteDelete =
  (onboarding_comment_id, refresh = true) =>
  async dispatch => {
    return axios.request({
      url: '/nc/support/onboarding/comment/delete',
      method: 'POST',
      params: {
        onboarding_comment_id,
      },
      cache: { ignoreCache: refresh },
    })
  }

export const onboardingUploadFiles =
  (params, refresh = true) =>
  async dispatch => {
    let formData = new FormData()
    formData.append('src', params.id)
    formData.append('type', params.type)
    formData.append('files[]', params.files[0])
    return axios.request({
      url: '/nc/support/onboarding/upload_files/' + params.id,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      method: 'post',
      data: formData,
      cache: { ignoreCache: refresh },
    })
  }

export const onboardingDeleteFiles =
  (params, refresh = true) =>
  async dispatch => {
    return axios.request({
      url: '/nc/support/onboarding/delete_file/' + params.id,
      method: 'POST',
      params: {},
      cache: { ignoreCache: refresh },
    })
  }
export const getLeaders = refresh => async dispatch => {
  return axios.request({
    url: 'nc/onboarding/options?type=project_leads',
    method: 'GET',
    cache: { ignoreCache: refresh },
  })
}

export const passwordVerify = (params, id, refresh) => async dispatch => {
  return axios.request({
    url: '/nc/support/onboarding/option/password_verify/' + id,
    method: 'PATCH',
    params: params,
    cache: { ignoreCache: refresh },
  })
}
export const getSupportOrders =
  (location_id, params, refresh = false) =>
  async dispatch => {
    return axios.request({
      url: 'nc/support/orders/' + location_id,
      method: 'GET',
      params: params,
      cache: { ignoreCache: params ? params.refresh ?? refresh : refresh },
    })
  }

export const getSupportOrder =
  (order_id, params = null, refresh = false) =>
  async dispatch => {
    return axios.request({
      url: 'nc/support/order/' + order_id,
      method: 'GET',
      params: params,
      cache: { ignoreCache: params ? params.refresh ?? refresh : refresh },
    })
  }

export const changeOrderStatus =
  (order_id, params = null, refresh = false) =>
  async dispatch => {
    return axios.request({
      url: 'nc/live_orders/order/' + order_id,
      method: 'PATCH',
      params: params,
      cache: { ignoreCache: params ? params.refresh ?? refresh : refresh },
    })
  }

export const SUPPORT_REMOVE_SUPPORT_DATA = 'SUPPORT_REMOVE_SUPPORT_DATA'
export const removeSupportData = () => dispatch => {
  dispatch({
    type: SUPPORT_REMOVE_SUPPORT_DATA,
  })
}

export const SUPPORT_SET_SIGNUP_FORM_INFO = 'SUPPORT_SET_SIGNUP_FORM_INFO'
export const setSignupFormInfo = info => dispatch => {
  dispatch({
    type: SUPPORT_SET_SIGNUP_FORM_INFO,
    info,
  })
}

export const getCuisineTypes =
  (refresh = true) =>
  async () => {
    return axios.request({
      url: '/nc/sales/cuisine/types',
      method: 'GET',
      cache: { ignoreCache: refresh },
    })
  }
