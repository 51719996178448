import React from 'react'
import { Label, Card } from 'reactstrap'
import { formatPhoneNumber, getRoleName } from '../../../../utils/digit'
import Table from '../../../../components/Table'

function AccountUser(props) {
  let { users } = props

  const data = users?.map(item => {
    return {
      first_name: item.first_name ?? '-',
      last_name: item.last_name ?? '-',
      phone: item.phone ?? '-',
      role: item.role ?? '-',
      email: item.email ?? '-',
      language: item.language ?? '-',
    }
  })

  const columns = [
    { key: 'first_name', name: 'First name' },
    { key: 'last_name', name: 'Last name' },
    { key: 'role', name: 'Access' },
    { key: 'email', name: 'Email' },
    { key: 'phone', name: 'Phone' },
    { key: 'language', name: 'Language' },
  ]

  const renderSupports = () => {
    if (!users || users.length === 0)
      return (
        <tr>
          <td colSpan='8' className='p-0 ponit-events-none'>
            <div className='text-white text-center text-cream-50'>No data</div>
          </td>
        </tr>
      )

    return users?.map((user, key) => {
      let {
        first_name,
        last_name,
        phone,
        role,
        email,
        has_mobile_app,
        language,
      } = user
      return (
        <tr key={key}>
          <td className='text-cream-100'>{first_name ?? ''}</td>
          <td className='text-cream-100'>{last_name ?? ''}</td>
          <td className='text-cream-100'>{role ? getRoleName(role) : '-'}</td>
          <td className='text-cream-100'>{email ?? ''}</td>
          <td className='text-cream-100'>
            {formatPhoneNumber(phone, true) ?? ''}
          </td>
          <td className='text-cream-100'>{language ?? ''}</td>
        </tr>
      )
    })
  }

  return (
    <>
      <Label>ACCOUNT USER</Label>
      <Card className='primary-table table-small'>
        {/* <Table responsive hover>
          <thead>
            <tr>
              <td colSpan="8" className="p-0 ponit-events-none">
                <div className="dashed-line"></div>
              </td>
            </tr>
            <tr>
              <th className="text-cream-100">First name</th>
              <th className="text-cream-100">Last name</th>
              <th className="text-cream-100">Access</th>
              <th className="text-cream-100">Email</th>
              <th className="text-cream-100">Phone</th>
              <th className="text-cream-100">Language</th>
            </tr>
          </thead>
          <tbody>
            {renderSupports()}
            <tr>
              <td colSpan="8" className="p-0 ponit-events-none"></td>
            </tr>
          </tbody>
        </Table> */}
        <div className='dashed-line'></div>
        <Table
          data={data}
          columns={columns}
          autoHeight
          tableHeaderTextUppercase={false}
        />
      </Card>
    </>
  )
}

export default AccountUser
