import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getExport } from '../../../../store/actions/support'
import { Button } from 'reactstrap'
import Import from './Import'
import Export from './Export'

function MenuWork(props) {
  let { supportId } = props
  const dispatch = useDispatch()
  const [workType, setWorkType] = useState('import')

  const getGsheetLink = () => {
    dispatch(getExport({ onboarding_id: supportId }))
  }

  return (
    <div>
      {' '}
      <div className='support-menu-work'>
        <div className='menu-work-two-btns'>
          {' '}
          <Button
            color='link'
            type='button'
            className={workType === 'import' ? 'selected' : ''}
            block
            onClick={e => setWorkType('import')}
          >
            IMPORT
          </Button>
          <Button
            color='link'
            type='button'
            className={workType === 'export' ? 'selected' : ''}
            block
            disabled={true}
            onClick={e => setWorkType('export')}
          >
            EXPORT
          </Button>
        </div>
        {workType === 'import' ? <Import {...props} /> : null}
        {workType === 'export' ? (
          <Export {...props} getGsheetLink={getGsheetLink} />
        ) : null}
      </div>
    </div>
  )
}
export default MenuWork
