import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import { Alert } from 'reactstrap'
import SeperateLine from './SeperateLine'

import {
  getOnboarding,
  updateOnboarding,
  onboardingUploadFiles,
  onboardingDeleteFiles,
  getQuestionFile,
} from '../../../../store/actions/support'
import AccountUser from './AccountUser'
import Preference from './Preference'
import ProgressChecklist from './ProgressChecklist'
import ProgressNotes from './ProgressNotes'
import { useLocation, useParams } from 'react-router'

function Onboarding(props) {
  const { supportData, profile } = props

  const dispatch = useDispatch()

  const [onboardingFormData, setOnboardingFormData] = useState({
    project_lead_id: '',
    complete_welcome_call: 0,
    complete_launch_call: 0,
    communication_notes: '',
    communication_notes_edit: false,
    website_preference: '',
    dns_configuration: 0,
    website_configuration: 0,
    domain_website_notes: '',
    domain_website_notes_edit: false,
    saved_information_notes: '',
    saved_information_notes_edit: false,
    menu_source: '',
    menu_faq: 0,
    menu_work_assigned: '',
    menu_work_sent_for_approval: 0,
    menu_work_approval: 0,
    menu_work_notes: '',
    menu_work_notes_edit: false,
    shipping_destination: '',
    shipped_out: 0,
    delivered: 0,
    tablet_notes: '',
    tablet_notes_edit: false,
    gmb_update_assistance: '',
    gmb_update_progress: 0,
    gmb_education: 0,
    gmb_notes: '',
    gmb_notes_edit: false,
    due_date: '',
    due_date_confirm: false,
    status: '',
  })

  const [getOnboardingRequest, setGetOnboardingRequest] = useState(false)
  const [getOnboardingError, setGetOnboardingError] = useState()
  const [updateOnboardingRequest, setUpdateOnboardingRequest] = useState(false)
  const [updateOnboardingError, setUpdateOnboardingError] = useState()
  const [updateOnboardingSuccess, setUpdateOnboardingSuccess] = useState(false)
  const [originOnboarding, setOriginOnboarding] = useState({
    website_preference: '',
    dns_configuration: 0,
    website_configuration: 0,
    domain_website_notes: '',
    domain_website_notes_edit: false,
    saved_information_notes: '',
    saved_information_notes_edit: false,
    menu_source: '',
    menu_faq: 0,
    menu_work_assigned: '',
    menu_work_sent_for_approval: 0,
    menu_work_approval: 0,
    menu_work_notes: '',
    menu_work_notes_edit: false,
    shipping_destination: '',
    shipped_out: 0,
    delivered: 0,
    tablet_notes: '',
    tablet_notes_edit: false,
    timezone: 'ct',
    gmb_update_assistance: '',
    gmb_update_progress: 0,
    gmb_education: 0,
    gmb_notes: '',
    gmb_notes_edit: false,
    due_date: '',
    due_date_confirm: false,
    status: '',
  })
  const [comments, setComments] = useState([])
  const [users, setUsers] = useState([])
  const [downloadAllLoading, setDownloadAllLoading] = useState(false)
  const search = useLocation().search ?? window.location.search
  const supportId = useParams().supportId
  let signupId = null
  if (supportId === 'create' && search) {
    signupId = new URLSearchParams(search).get('id')
  }
  const onboardingId = supportData?.signup_form?.onboarding?.id ?? signupId

  const loadData = data => {
    let formData = {
      ...data,
      communication_notes_edit: false,
      domain_website_notes_edit: false,
      menu_work_notes_edit: false,
      tablet_notes_edit: false,
      gmb_notes_edit: false,
      due_date_confirm: data ? (data.due_date ? true : false) : false,
    }
    setOnboardingFormData(formData)
    if (data.onboarding_comments) setComments(data.onboarding_comments)
    if (data.users) setUsers(data.users)
  }

  const getData = type => {
    if (type === 'frist') {
      setGetOnboardingRequest(true)
      setGetOnboardingError('')
      setUpdateOnboardingSuccess(false)
    }
    dispatch(getOnboarding(onboardingId, true))
      .then(({ data }) => {
        if (data.success) {
          if (type === 'frist') {
            setGetOnboardingRequest(false)
            setGetOnboardingError('')
          }
          loadData(data.data)
          setOriginOnboarding(data.data)
        } else {
          if (type === 'frist') {
            setGetOnboardingRequest(false)
            setGetOnboardingError(data.message)
          }
        }
      })
      .catch(error => {
        let resData = error.response
          ? error.response.data
          : { message: error.message }
        setGetOnboardingRequest(false)
        setGetOnboardingError(resData.message)
      })
  }

  const update = _params => {
    setUpdateOnboardingRequest(true)
    setUpdateOnboardingSuccess(false)

    if (onboardingId)
      dispatch(updateOnboarding(onboardingId, _params))
        .then(({ data }) => {
          if (data.success) {
            setUpdateOnboardingRequest(false)
            setUpdateOnboardingError('')
            setUpdateOnboardingSuccess(true)
          } else {
            setUpdateOnboardingRequest(false)
            setUpdateOnboardingError(data.message)
            setUpdateOnboardingSuccess(false)
          }
        })
        .catch(error => {
          let resData = error.response
            ? error.response.data
            : { message: error.message }
          setUpdateOnboardingRequest(false)
          setUpdateOnboardingError(resData.message)
          setUpdateOnboardingSuccess(false)
        })
  }

  const onOnboardingFormChange = (name, val, submit = true) => {
    setOnboardingFormData({ ...onboardingFormData, [name]: val })
    let unUpdateArr = [
      'communication_notes',
      'domain_website_notes',
      // "menu_work_notes",
      'tablet_notes',
      'gmb_notes',
      'due_date',
      // "saved_information_notes",
    ]
    let editArr = [
      'communication_notes_edit',
      'domain_website_notes_edit',
      'menu_work_notes_edit',
      'tablet_notes_edit',
      'gmb_notes_edit',
      'saved_information_notes_edit',
      'due_date_confirm',
    ]
    if (!unUpdateArr.includes(name) && submit) {
      let _params = { [name]: val }
      if (editArr.includes(name)) {
        let {
          communication_notes,
          domain_website_notes,
          menu_work_notes,
          tablet_notes,
          gmb_notes,
          due_date,
          saved_information_notes,
        } = onboardingFormData

        let shouldCall = !val
        switch (name) {
          case 'communication_notes_edit':
            _params = { communication_notes: communication_notes }
            break
          case 'domain_website_notes_edit':
            _params = { domain_website_notes: domain_website_notes }
            break
          case 'menu_work_notes_edit':
            _params = { menu_work_notes: menu_work_notes }
            break
          case 'tablet_notes_edit':
            _params = { tablet_notes: tablet_notes }
            break
          case 'gmb_notes_edit':
            _params = { gmb_notes: gmb_notes }
            break
          case 'saved_information_notes_edit':
            _params = { saved_information_notes: saved_information_notes }
            break
          case 'due_date_confirm':
            _params = {
              due_date: val
                ? due_date
                  ? moment(due_date).format('YYYY-MM-DD')
                  : ''
                : '',
            }
            shouldCall = true

            break
          default:
            _params = { [name]: val }
            break
        }
        if (shouldCall) update(_params)
      } else {
        update(_params)
      }
    }
  }

  const uploadFile = async (file, cb) => {
    const params = {
      id: onboardingId,
      files: [file],
      type: 'menu',
    }
    const { data } = await dispatch(onboardingUploadFiles(params))
    if (data.success) getData()
    cb && cb()
  }

  const deleteFile = async (id, cb) => {
    const params = {
      id,
      type: 'menu',
    }
    const { data } = await dispatch(onboardingDeleteFiles(params))
    if (data.success) getData()
    cb && cb()
  }

  const downloadFile = async (id, file_name) => {
    setDownloadAllLoading(true)
    const res = await dispatch(getQuestionFile(id))
    if (res.status === 200) {
      const { data } = res
      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = url
      link.setAttribute('download', file_name)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
    setDownloadAllLoading(false)
  }

  let {
    complete_welcome_call,
    website_configuration,
    menu_faq,
    menu_work_sent_for_approval,
    menu_work_approval,
    shipped_out,
    delivered,
    tablet_shipped_url,
    google_listing_updated,
    complete_launch_call,
  } = onboardingFormData

  let all_filled =
    complete_welcome_call &&
    menu_faq &&
    menu_work_sent_for_approval &&
    menu_work_approval &&
    shipped_out &&
    tablet_shipped_url &&
    delivered &&
    website_configuration &&
    google_listing_updated &&
    complete_launch_call

  useEffect(() => {
    if (onboardingId) {
      getData('frist')
    }
  }, [onboardingId])

  useEffect(() => {
    if (updateOnboardingSuccess) {
      getData('frist')
    }
  }, [updateOnboardingSuccess])

  return (
    <div className='support-onboarding'>
      {getOnboardingError ? (
        <Alert color='danger' className='mb-5 text-center'>
          {getOnboardingError}
        </Alert>
      ) : null}
      {updateOnboardingError ? (
        <Alert color='danger' className='mb-5 text-center'>
          {updateOnboardingError}
        </Alert>
      ) : null}
      <AccountUser users={users} />
      <Preference
        onboardingId={onboardingId}
        uploadFile={uploadFile}
        deleteFile={deleteFile}
        onboardingFormData={onboardingFormData}
        downloadAllLoading={downloadAllLoading}
        onOnboardingFormChange={onOnboardingFormChange}
        downloadFile={downloadFile}
      />
      <SeperateLine />
      <div className='onboarding-flex'>
        <ProgressChecklist
          profile={profile}
          all_filled={all_filled}
          onboardingFormData={onboardingFormData}
          onOnboardingFormChange={onOnboardingFormChange}
        />
        <ProgressNotes
          comments={comments}
          onboardingId={onboardingId}
          getData={getData}
        />
      </div>
    </div>
  )
}
export default Onboarding
