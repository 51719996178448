import moment from 'moment'
import { dayPluralize } from './dayPluralize'

/**
 *
 * @param {Date?} onboarded_at
 * @param {Date?} signup_paid_at
 * @returns
 */
export const getOnboardedDays = (onboarded_at, signup_paid_at) => {
  let onboardedDays = 0
  if (!signup_paid_at) return '-'
  if (onboarded_at) return moment(onboarded_at).format('ll')
  if (signup_paid_at) {
    onboardedDays =
      moment()
        .endOf('day')
        .diff(moment(signup_paid_at).startOf('day'), 'days') + 1
  }
  return dayPluralize(onboardedDays)
}
