import React from 'react'
import cn from 'classnames'

export default function OdRow({
  label,
  text,
  className = 'mb-2',
  valueClassName = 'text-cream-75',
}) {
  return (
    <div className={cn('d-flex justify-content-between w-100', className)}>
      <div className='label-text text-uppercase'>{label}</div>
      <div className={valueClassName}>{text}</div>
    </div>
  )
}
