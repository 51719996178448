import React from 'react'

import { Card, Form, Button, Alert } from 'reactstrap'
import cn from 'classnames'

import HeadContent from './HeadContent/index'
import LocationInfoContent from './LocationInfoContent'
import AccountContent from './AccountContent'
import BusinessHoursContent from './BusinessHoursContent'
import PlanChoice from './PlanChoice'
import VerificationContent from './VerificationContent'
import { currentStepMap2 } from '../../utils//constant'
import WelcomeCall from './WelcomeCall'

function Onboarding(props) {
  let {
    formData,
    cuisineTypes,
    days,
    status,
    getOnboardingError,
    getOnboardingRequest,
    editMode,
    profile,
    getSetupfeeError,
    setupfee,
    getSetupfee,
    onboardingOptions,
    getOnboardingOptionsRequest,
    isTab,
    prevApproval,
  } = props
  const { brand_id, need_welcome_call, welcome_note, requires_approval } =
    formData

  let brand_name = formData.brand_name
    ? formData.brand_name !== ''
      ? formData.brand_name
      : 'Onboarding'
    : 'Onboarding'

  // form status: new / draft / update / readonly
  let isNew = !editMode
  let isDraft = editMode && status === 'draft'
  let isWithdrawn = editMode && status === 'withdrawn'
  let isUpdatable =
    editMode &&
    status === 'signup_ready' &&
    (currentStepMap2[formData.current_step] < 5 ||
      !currentStepMap2[formData.current_step])
  let canUpdate = isNew || isDraft || isUpdatable

  let isMaster =
    profile &&
    profile.user &&
    (profile.user.role === 'master')
  let isPaid =
    formData && formData.payment_schedule && formData.payment_schedule.paid

  let canEditSignupfee = isUpdatable || (isMaster && !isPaid && !isWithdrawn)

  const canEditSource = canUpdate || isMaster

  let updateBtnShow =
    isUpdatable || (!isUpdatable && !canUpdate && canEditSignupfee)

  const submit = e => {
    if (canEditSignupfee && !canUpdate) {
      props.onSubmit(e, null)
    } else {
      props.onSubmit(e, editMode ? status : 'signup_ready')
    }
  }

  const ApprovalButtonRender = isNew => {
    return (
      <div className='approval-button'>
        <div className='approval-button-tips'>
          Approval is needed for selecting <span>existing brand</span> or{' '}
          <span>no welcome call</span>
        </div>
        <div className='approval-button-btn'>
          <Button
            color='primary'
            type='submit'
            className='btn-primary-approval'
            disabled={
              props.createOnboardingRequest || props.editOnboardingRequest
            }
          >
            {props.createOnboardingRequest || props.editOnboardingRequest ? (
              <i className='fad fa-spin fa-spinner mr-2'></i>
            ) : null}
            {isNew ? 'GET APPROVAL' : 'APPROVE'}
          </Button>
        </div>
      </div>
    )
  }

  const buttonChange = () => {
    if (!isMaster) {
      if (
        (brand_id && prevApproval.brand_id !== brand_id) ||
        (need_welcome_call === 0 &&
          need_welcome_call !== prevApproval.need_welcome_call) ||
        (need_welcome_call === 0 && welcome_note !== prevApproval.welcome_note)
      ) {
        return <>{ApprovalButtonRender(true)}</>
      }
    }
    return (
      <Button
        color='primary'
        type='submit'
        className='btn-primary-onboarding'
        style={{ marginTop: '38px' }}
        block
        disabled={props.editOnboardingRequest}
      >
        {props.editOnboardingRequest ? (
          <i className='fad fa-spin fa-spinner mr-2'></i>
        ) : null}
        UPDATE INFORMATION
      </Button>
    )
  }

  const isNewButtonRender = () => {
    if (requires_approval) {
      if (isMaster) {
        return (
          <>
            {ApprovalButtonRender()}
            <Button
              color='link'
              className='text-red bold btn-board-red-link'
              style={{ marginTop: '38px' }}
              type='button'
              block
              onClick={e => props.withdrawn()}
            >
              WITHDRAW
            </Button>
          </>
        )
      } else {
        return (
          <>
            <Button
              color='primary'
              type='submit'
              className='btn-primary-onboarding'
              style={{ marginTop: '38px' }}
              disabled
            >
              PENDING APPROVAL
            </Button>
            <Button
              color='primary'
              type='submit'
              className='btn-primary-onboarding'
              style={{ marginTop: '38px' }}
              block
              disabled={props.editOnboardingRequest}
            >
              {props.editOnboardingRequest ? (
                <i className='fad fa-spin fa-spinner mr-2'></i>
              ) : null}
              UPDATE INFORMATION
            </Button>
            <Button
              color='link'
              className='text-red bold btn-board-red-link'
              style={{ marginTop: '38px' }}
              type='button'
              block
              onClick={e => props.withdrawn()}
            >
              WITHDRAW
            </Button>
          </>
        )
      }
    } else {
      if (isMaster) {
        return (
          <>
            <Button
              color='primary'
              type='submit'
              className='btn-primary-onboarding'
              style={{ marginTop: '38px' }}
              disabled={props.createOnboardingRequest}
            >
              {props.createOnboardingRequest ? (
                <i className='fad fa-spin fa-spinner mr-2'></i>
              ) : null}
              INITIATE SIGNUP
            </Button>
            {status !== 'signup_ready' && (
              <Button
                color='light'
                type='button'
                size='lg'
                className='btn-primary-onboarding btn-rounded'
                disabled={props.createOnboardingRequest}
                onClick={e => props.onSubmit(e, 'draft')}
              >
                {props.createOnboardingRequest ? (
                  <i className='fad fa-spin fa-spinner mr-2'></i>
                ) : null}
                SAVE AS DRAFT
              </Button>
            )}
          </>
        )
      } else {
        return (
          <>
            {brand_id || need_welcome_call === 0 ? (
              ApprovalButtonRender(true)
            ) : (
              <Button
                color='primary'
                type='submit'
                className='btn-primary-onboarding'
                style={{ marginTop: '38px' }}
                disabled={props.createOnboardingRequest}
              >
                {props.createOnboardingRequest ? (
                  <i className='fad fa-spin fa-spinner mr-2'></i>
                ) : null}
                INITIATE SIGNUP
              </Button>
            )}
            {status !== 'signup_ready' && (
              <Button
                color='light'
                type='button'
                size='lg'
                className='btn-primary-onboarding btn-rounded'
                disabled={props.createOnboardingRequest}
                onClick={e => props.onSubmit(e, 'draft')}
              >
                {props.createOnboardingRequest ? (
                  <i className='fad fa-spin fa-spinner mr-2'></i>
                ) : null}
                SAVE AS DRAFT
              </Button>
            )}
          </>
        )
      }
    }
  }

  const isDraftButtonRender = () => {
    return (
      <>
        {!isMaster && (brand_id || need_welcome_call === 0) ? (
          ApprovalButtonRender(true)
        ) : (
          <Button
            color='primary'
            type='button'
            className='btn-primary-onboarding'
            style={{ marginTop: '38px' }}
            disabled={props.editOnboardingRequest}
            onClick={e => props.onSubmit(e, 'signup_ready')}
          >
            {props.editOnboardingRequest ? (
              <i className='fad fa-spin fa-spinner mr-2'></i>
            ) : null}
            INITIATE SIGNUP
          </Button>
        )}
        <Button
          color='light'
          type='button'
          className='btn-primary-onboarding btn-rounded'
          onClick={e => props.onSubmit(e, 'draft')}
          disabled={props.editOnboardingRequest}
        >
          {props.editOnboardingRequest ? (
            <i className='fad fa-spin fa-spinner mr-2'></i>
          ) : null}
          UPDATE DRAFT
        </Button>
        {/** to do: delete a draft onboarding */}
        <Button
          color='link'
          className='btn-primary-onboarding btn-board-red-link'
          type='button'
          onClick={e => props.deleteOnboardingDraft()}
        >
          DELETE DRAFT
        </Button>
      </>
    )
  }

  return (
    <Card className={cn(!isTab && 'board-card')}>
      {!isTab && (
        <button className='btn-board-close' onClick={e => props.goBack()}>
          <i className='fal fa-times f-22' />
        </button>
      )}
      <div className={cn(!isTab && 'board-content-onboarding')}>
        {!isTab && <h1>{brand_name}</h1>}
        {getOnboardingRequest ? (
          <div>
            <i className='fad fa-spinner fa-spin mr-2'></i>Loading...
          </div>
        ) : getOnboardingError ? (
          <Alert color='danger' className='mb-5 text-center'>
            {getOnboardingError}
          </Alert>
        ) : (
          <Form onSubmit={e => submit(e)}>
            <div className='w-100 board-content-secondary'>
              <HeadContent
                onChange={props.onFormChange}
                formData={formData}
                editMode={editMode}
                status={status}
                canUpdate={canUpdate}
                canEditSource={canEditSource}
                isMaster={isMaster}
                getSetupfeeError={getSetupfeeError}
                setupfee={setupfee}
                getSetupfee={getSetupfee}
                canEditSignupfee={canEditSignupfee}
                onboardingOptions={onboardingOptions}
                getOnboardingOptionsRequest={getOnboardingOptionsRequest}
              />
            </div>
            <div className='dashed-line'></div>
            <div className='w-100 board-content-secondary'>
              <div className='board-equal-row'>
                <div className='board-col-primary'>
                  <AccountContent
                    onChange={props.onFormChange}
                    formData={formData}
                    cuisineTypes={cuisineTypes}
                    editMode={editMode}
                    onRepPhoneKeyUp={props.onRepPhoneKeyUp}
                    onLocationPhoneKeyUp={props.onLocationPhoneKeyUp}
                    onPhoneKeyDown={props.onPhoneKeyDown}
                    brands={props.brands}
                    canUpdate={canUpdate}
                    profile={profile}
                  />
                  <PlanChoice
                    onChange={props.onFormChange}
                    formData={formData}
                    editMode={editMode}
                    canUpdate={canUpdate}
                    profile={profile}
                  />
                  <VerificationContent
                    onChange={props.onFormChange}
                    formData={formData}
                    editMode={editMode}
                    onBusinessPhoneKeyUp={props.onBusinessPhoneKeyUp}
                    onOwnerPhoneKeyUp={props.onOwnerPhoneKeyUp}
                    onOwnerDobKeyUp={props.onOwnerDobKeyUp}
                    onPhoneKeyDown={props.onPhoneKeyDown}
                    onBusinessEinKeyUp={props.onBusinessEinKeyUp}
                    canUpdate={canUpdate}
                    profile={profile}
                  />
                </div>

                <div className='board-col-primary'>
                  <WelcomeCall
                    onChange={props.onFormChange}
                    formData={formData}
                    canUpdate={canUpdate}
                  />
                  <LocationInfoContent
                    onChange={props.onFormChange}
                    formData={formData}
                    editMode={editMode}
                    onRepPhoneKeyUp={props.onRepPhoneKeyUp}
                    onLocationPhoneKeyUp={props.onLocationPhoneKeyUp}
                    onPhoneKeyDown={props.onPhoneKeyDown}
                    brands={props.brands}
                    canUpdate={canUpdate}
                    profile={profile}
                  />
                  <BusinessHoursContent
                    onChange={props.onFormChange}
                    formData={formData}
                    editMode={editMode}
                    days={days}
                    onHourChange={props.onHourChange}
                    setClosed={props.setClosed}
                    addHours={props.addHours}
                    removeHours={props.removeHours}
                    canUpdate={canUpdate}
                    profile={profile}
                  />
                </div>
              </div>
            </div>
            <div className='dashed-line'></div>
            {props.createOnboardingError ? (
              <Alert color='danger' className='mb-5 text-center '>
                {props.createOnboardingError}
              </Alert>
            ) : null}
            {props.editOnboardingError ? (
              <Alert color='danger' className='mb-5 text-center'>
                {props.editOnboardingError}
              </Alert>
            ) : null}
            {isNew && isNewButtonRender()}
            {isDraft && isDraftButtonRender()}
            {updateBtnShow && buttonChange()}
            {isUpdatable && (
              <Button
                color='link'
                className='text-red bold btn-board-red-link'
                style={{ marginTop: '38px' }}
                type='button'
                block
                onClick={e => props.withdrawn()}
              >
                WITHDRAW
              </Button>
            )}
          </Form>
        )}
      </div>
    </Card>
  )
}

export default Onboarding
