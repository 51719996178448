import React from 'react'
import ReferralTableRow from './ReferralTableRow'

const ReferralTableBody = ({
  referralList,
  editReward,
}) => {
  if (!referralList) return null
  if (!referralList.data) return null
  if (referralList.data.length === 0)
    return (
      <tr>
        <td colSpan='7' className='p-0 ponit-events-none'>
          <div className='text-white'>No data</div>
        </td>
      </tr>
    )
  return referralList.data.map((item) => (
    <ReferralTableRow
      key={item.id}
      referralItem={item}
      editReward={editReward}
    />
  ))
}

export default ReferralTableBody
