import React from 'react'
import Cookies from 'js-cookie'
import { Route, Redirect } from 'react-router-dom'
import DetailLayout from './DetailLayout'
import { TOKEN_KEY } from '../../utils/constant'

class DetailLayoutRoute extends React.Component {
  render() {
    const { component: Component, location, ...rest } = this.props
    let hasToken = Cookies.get(TOKEN_KEY)
    return (
      <Route
        {...rest}
        render={props =>
          hasToken ? (
            <DetailLayout {...props}>
              <Component {...props} />
            </DetailLayout>
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: location },
              }}
            />
          )
        }
      />
    )
  }
}

export default DetailLayoutRoute
