import axios from 'axios'
import { toast } from 'react-toastify'
import { replace } from 'connected-react-router'

export const AFFILIATE_GET_TEAM_AFFILIATES_REQUEST =
  'AFFILIATE_GET_TEAM_AFFILIATES_REQUEST'
export const AFFILIATE_GET_TEAM_AFFILIATES_SUCCESS =
  'AFFILIATE_GET_TEAM_AFFILIATES_SUCCESS'
export const AFFILIATE_GET_TEAM_AFFILIATES_FAIL =
  'AFFILIATE_GET_TEAM_AFFILIATES_FAIL'
export const getTeamAffiliates =
  (params, refresh = false, page, per_page = 15) =>
  dispatch => {
    dispatch({ type: AFFILIATE_GET_TEAM_AFFILIATES_REQUEST })

    axios
      .request({
        url: '/nc/team_affiliates',
        method: 'get',
        params: {
          team_ids: params ? params.team_ids : '',
          active: params ? params.active : '',
          per_page: per_page,
          page: params ? params.page : '',
        },
        cache: { ignoreCache: refresh },
      })
      .then(({ data }) => {
        if (data.redirect) return

        if (data.success) {
          dispatch({
            type: AFFILIATE_GET_TEAM_AFFILIATES_SUCCESS,
            data: data.data,
            msg: data.message,
            errors: data.errors,
          })
        } else {
          dispatch({
            type: AFFILIATE_GET_TEAM_AFFILIATES_FAIL,
            msg: data.message,
            errors: data.errors,
          })
          toast.warn(data.message)
        }
      })
  }

export const AFFILIATE_GET_AFFILIATES_REQUEST =
  'AFFILIATE_GET_AFFILIATES_REQUEST'
export const AFFILIATE_GET_AFFILIATES_SUCCESS =
  'AFFILIATE_GET_AFFILIATES_SUCCESS'
export const AFFILIATE_GET_AFFILIATES_FAIL = 'AFFILIATE_GET_AFFILIATES_FAIL'
export const getAffiliates =
  (params, refresh = false, page, per_page = 15) =>
  dispatch => {
    dispatch({ type: AFFILIATE_GET_AFFILIATES_REQUEST })

    axios
      .request({
        url: '/nc/affiliates',
        method: 'get',
        params: {
          team_id: params ? params.team_id : '',
          brand_name: params ? params.brand_name : '',
          types: params ? params.types : '',
          location_id: params ? params.location_id : '',
          per_page: params.per_page ?? per_page,
          page: params ? params.page : '',
        },
        cache: { ignoreCache: params.refresh ?? refresh },
      })
      .then(({ data }) => {
        if (data.redirect) return

        if (data.success) {
          dispatch({
            type: AFFILIATE_GET_AFFILIATES_SUCCESS,
            data: data.data,
            msg: data.message,
            errors: data.errors,
            selectedTeamId: params ? params.team_id : '',
          })
        } else {
          dispatch({
            type: AFFILIATE_GET_AFFILIATES_FAIL,
            msg: data.message,
            errors: data.errors,
          })
          toast.warn(data.message)
        }
      })
  }

export const AFFILIATE_CHANGE_FACADE_CLOSER_REQUEST =
  'AFFILIATE_CHANGE_FACADE_CLOSER_REQUEST'
export const AFFILIATE_CHANGE_FACADE_CLOSER_SUCCESS =
  'AFFILIATE_CHANGE_FACADE_CLOSER_SUCCESS'
export const AFFILIATE_CHANGE_FACADE_CLOSER_FAIL =
  'AFFILIATE_CHANGE_FACADE_CLOSER_FAIL'
export const changeFacadeCloser = params => dispatch => {
  dispatch({ type: AFFILIATE_CHANGE_FACADE_CLOSER_REQUEST })

  axios
    .request({
      url: '/nc/affiliate/change_closer',
      method: 'post',
      data: params,
    })
    .then(({ data }) => {
      if (data.redirect) return

      if (data.success) {
        dispatch({
          type: AFFILIATE_CHANGE_FACADE_CLOSER_SUCCESS,
          data: data.data,
          msg: data.message,
          errors: data.errors,
        })
        dispatch(
          getAffiliates({
            params: params.filterData,
            team_id: params.team_id,
            refresh: true,
          }),
        )
      } else {
        dispatch({
          type: AFFILIATE_CHANGE_FACADE_CLOSER_FAIL,
          msg: data.message,
          errors: data.errors,
        })
      }
    })
    .catch(err => {
      dispatch({ type: AFFILIATE_CHANGE_FACADE_CLOSER_FAIL })
    })
}

export const AFFILIATE_CLEAR_CLAIM_REQUEST = 'AFFILIATE_CLEAR_CLAIM_REQUEST'
export const clearClaim = () => dispatch => {
  dispatch({ type: AFFILIATE_CLEAR_CLAIM_REQUEST })
}

export const AFFILIATE_CLAIM_COMMISSION_REQUEST =
  'AFFILIATE_CLAIM_COMMISSION_REQUEST'
export const AFFILIATE_CLAIM_COMMISSION_SUCCESS =
  'AFFILIATE_CLAIM_COMMISSION_SUCCESS'
export const AFFILIATE_CLAIM_COMMISSION_FAIL = 'AFFILIATE_CLAIM_COMMISSION_FAIL'
export const claimCommission =
  ({ commission }) =>
  dispatch => {
    dispatch({ type: AFFILIATE_CLAIM_COMMISSION_REQUEST })
    axios
      .request({
        url: '/nc/affiliate/claim',
        method: 'POST',
        data: { commission },
      })
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: AFFILIATE_CLAIM_COMMISSION_SUCCESS,
            msg: data.message,
          })
        } else {
          dispatch({
            type: AFFILIATE_CLAIM_COMMISSION_FAIL,
            msg: data.message,
          })
        }
      })
  }
