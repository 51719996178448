import axios from 'axios'
import { MAIN_API_URL } from '../../utils/constant'

export const ADDR_AUTO_FILL_REQUEST = 'ADDR_AUTO_FILL_REQUEST'
export const ADDR_AUTO_FILL_SUCCESS = 'ADDR_AUTO_FILL_SUCCESS'
export const ADDR_AUTO_FILL_FAIL = 'ADDR_AUTO_FILL_FAIL'

export const autoFillAddress =
  ({ address_query }) =>
  dispatch => {
    dispatch({ type: 'ADDR_AUTO_FILL_REQUEST' })
    axios
      .request({
        url: MAIN_API_URL + '/cw/user/address/auto_fill',
        params: { address_query },
        method: 'get',
      })
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: 'ADDR_AUTO_FILL_SUCCESS', data: data.data })
        } else {
          dispatch({ type: 'ADDR_AUTO_FILL_FAIL', msg: data.message })
        }
      })
  }
