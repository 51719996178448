import {
  ONBOARDING_CREATE_ONBOARDING_REQUEST,
  ONBOARDING_CREATE_ONBOARDING_SUCCESS,
  ONBOARDING_CREATE_ONBOARDING_FAIL,
  ONBOARDING_EDIT_ONBOARDING_REQUEST,
  ONBOARDING_EDIT_ONBOARDING_SUCCESS,
  ONBOARDING_EDIT_ONBOARDING_FAIL,
  ONBOARDING_GET_OPTIONS_REQUEST,
  ONBOARDING_GET_OPTIONS_SUCCESS,
  ONBOARDING_GET_OPTIONS_FAIL,
  ONBOARDING_GET_ONBOARDINGS_REQUEST,
  ONBOARDING_GET_ONBOARDINGS_SUCCESS,
  ONBOARDING_GET_ONBOARDINGS_FAIL,
  ONBOARDING_GET_ONBOARDING_REQUEST,
  ONBOARDING_GET_ONBOARDING_SUCCESS,
  ONBOARDING_GET_ONBOARDING_FAIL,
  CLEAR_ONBOARDING_ERROR,
  ONBOARDING_CHANGE_STATUS_REQUEST,
  ONBOARDING_CHANGE_STATUS_SUCCESS,
  ONBOARDING_CHANGE_STATUS_FAIL,
  ONBOARDING_CLEAR_SELECTED_ONBOARDING_REQUEST,
  ONBOARDING_DELETE_DRAFT_REQUEST,
  ONBOARDING_DELETE_DRAFT_SUCCESS,
  ONBOARDING_DELETE_DRAFT_FAIL,
  ONBOARDING_GET_SETUPFEE_REQUEST,
  ONBOARDING_GET_SETUPFEE_SUCCESS,
  ONBOARDING_GET_SETUPFEE_FAIL,
  FROM_ONBOARDING_PAGE,
} from '../actions/onboarding'

const initialState = {
  createOnboardingRequest: false,
  createOnboardingError: '',
  editOnboardingRequest: false,
  editOnboardingError: '',
  getOnboardingOptionsRequest: false,
  getOnboardingOptionsRequestType: '',
  getOnboardingOptionsError: '',
  onboardingOptions: {
    status: [
      { value: 'placed', label: 'Placed' },
      { value: 'confirmed', label: 'Confirmed' },
      { value: 'ready', label: 'Ready' },
      { value: 'done', label: 'Done' },
      { value: 'canceled', label: 'Canceled' },
    ],
    types: [
      { value: 'pickup', label: 'Pickup' },
      { value: 'delivery', label: 'Delivery (in-house)' },
      { value: 'daas_delivery', label: 'Delivery (on-demand)' },
      { value: 'curbside', label: 'Curbside' },
      { value: 'dine-in', label: 'Dine-in' },
      { value: 'takeout', label: 'Takeout' },
    ],
  },
  getOnboardingsRequest: false,
  getOnboardingsError: false,
  onboardings: null,
  getOnboardingRequest: false,
  getOnboardingError: false,
  selectedOnboarding: null,
  changeOnboardingStatusRequest: false,
  changeOnboardingStatusError: null,
  fromOnboardingPageStatus: false,
}

const onboarding = (state = initialState, action) => {
  switch (action.type) {
    case ONBOARDING_CREATE_ONBOARDING_REQUEST:
      return {
        ...state,
        createOnboardingRequest: true,
        createOnboardingSuccess: null,
      }
    case ONBOARDING_CREATE_ONBOARDING_SUCCESS:
      return {
        ...state,
        createOnboardingRequest: false,
        createOnboardingError: null,
        createOnboardingSuccess: true,
      }
    case ONBOARDING_CREATE_ONBOARDING_FAIL:
      return {
        ...state,
        createOnboardingRequest: false,
        createOnboardingError: action.msg,
        createOnboardingSuccess: null,
      }

    case ONBOARDING_EDIT_ONBOARDING_REQUEST:
      return {
        ...state,
        editOnboardingRequest: true,
        editOnboardingSuccess: null,
      }
    case ONBOARDING_EDIT_ONBOARDING_SUCCESS:
      return {
        ...state,
        editOnboardingRequest: false,
        editOnboardingError: null,
        editOnboardingSuccess: true,
      }
    case ONBOARDING_EDIT_ONBOARDING_FAIL:
      return {
        ...state,
        editOnboardingRequest: false,
        editOnboardingError: action.msg,
        editOnboardingSuccess: null,
      }
    case ONBOARDING_GET_OPTIONS_REQUEST:
      return {
        ...state,
        getOnboardingOptionsRequest: true,
        getOnboardingOptionsRequestType: action.optionsType,
      }
    case ONBOARDING_GET_OPTIONS_SUCCESS:
      return {
        ...state,
        onboardingOptions: {
          ...state.onboardingOptions,
          [action.optionsType]: action.data,
        },
        getOnboardingOptionsRequest: false,
        getOnboardingOptionsError: null,
        getOnboardingOptionsRequestType: '',
      }
    case ONBOARDING_GET_OPTIONS_FAIL:
      return {
        ...state,
        getOnboardingOptionsRequest: false,
        getOnboardingOptionsError: action.msg,
        getOnboardingOptionsRequestType: '',
      }
    case ONBOARDING_GET_ONBOARDINGS_REQUEST:
      return {
        ...state,
        getOnboardingsRequest: true,
      }
    case ONBOARDING_GET_ONBOARDINGS_SUCCESS:
      return {
        ...state,
        onboardings: action.data,
        getOnboardingsRequest: false,
        getOnboardingsError: null,
      }
    case ONBOARDING_GET_ONBOARDINGS_FAIL:
      return {
        ...state,
        getOnboardingsRequest: false,
        getOnboardingsError: action.msg,
      }
    case ONBOARDING_GET_ONBOARDING_REQUEST:
      return {
        ...state,
        getOnboardingRequest: true,
        getOnboardingError: null,
      }
    case ONBOARDING_GET_ONBOARDING_SUCCESS:
      return {
        ...state,
        selectedOnboarding: action.data,
        getOnboardingRequest: false,
        getOnboardingError: null,
      }
    case ONBOARDING_GET_ONBOARDING_FAIL:
      return {
        ...state,
        getOnboardingRequest: false,
        getOnboardingError: action.msg,
      }
    case CLEAR_ONBOARDING_ERROR:
      return {
        ...state,
        editOnboardingRequest: false,
        createOnboardingRequest: false,
        editOnboardingError: null,
        createOnboardingError: null,
      }

    case ONBOARDING_CHANGE_STATUS_REQUEST:
      return {
        ...state,
        changeOnboardingStatusRequest: true,
      }
    case ONBOARDING_CHANGE_STATUS_SUCCESS:
      return {
        ...state,
        changeOnboardingStatusRequest: false,
        changeOnboardingStatusError: null,
      }
    case ONBOARDING_CHANGE_STATUS_FAIL:
      return {
        ...state,
        changeOnboardingStatusRequest: false,
        changeOnboardingStatusError: action.msg,
      }

    case ONBOARDING_CLEAR_SELECTED_ONBOARDING_REQUEST:
      return {
        ...state,
        selectedOnboarding: null,
        editOnboardingRequest: false,
        createOnboardingRequest: false,
        editOnboardingError: null,
        createOnboardingError: null,
      }
    case ONBOARDING_DELETE_DRAFT_REQUEST:
      return {
        ...state,
        deleteDraftRequest: true,
        deleteDraftErrorMsg: null,
      }
    case ONBOARDING_DELETE_DRAFT_SUCCESS:
      return {
        ...state,
        deleteDraftRequest: false,
        deleteDraftErrorMsg: null,
      }
    case ONBOARDING_DELETE_DRAFT_FAIL:
      return {
        ...state,
        deleteDraftRequest: false,
        deleteDraftErrorMsg: action.msg,
      }
    case ONBOARDING_GET_SETUPFEE_REQUEST:
      return {
        ...state,
        getSetupfeeRequest: true,
        getSetupfeeError: null,
      }
    case ONBOARDING_GET_SETUPFEE_SUCCESS:
      return {
        ...state,
        setupfee: action.data,
        getSetupfeeRequest: false,
        getSetupfeeError: null,
      }
    case ONBOARDING_GET_SETUPFEE_FAIL:
      return {
        ...state,
        setupfee: null,
        getSetupfeeRequest: false,
        getSetupfeeError: action.msg,
      }
    case FROM_ONBOARDING_PAGE:
      return {
        ...state,
        fromOnboardingPageStatus: action.msg,
      }
    default:
      return state
  }
}

export default onboarding
