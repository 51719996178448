import React from 'react'
import { Button, Row, Col } from 'reactstrap'

import moment from 'moment'

function AffiliateRightSide(props) {
  let { affiliates, claimCommissionRequest } = props
  if (!affiliates) return <div></div>
  let {
    available_amount,
    months,
    pending_amount,
    per_month,
    renew_training_days,
    signup_amount,
    training_schedules,
  } = affiliates

  const renderSchedules = () => {
    if (!training_schedules) return null
    if (training_schedules.length === 0) return null

    return training_schedules.map((schedule, key) => {
      let date = schedule.scheduled_date
        ? moment(schedule.scheduled_date).format('MMM DD, YYYY')
        : ''

      let days = schedule.distance_days
        ? schedule.distance_days > 1
          ? schedule.distance_days + ' days'
          : schedule.distance_days + ' day'
        : '-'
      return (
        <div className='info-row' key={`schedule-${key}`}>
          <div className='info-bar '>
            <span className='f-14 regular text-white my-auto'>{date}</span>
            <span className='f-14 regular text-regular my-auto'>{days}</span>
          </div>{' '}
        </div>
      )
    })
  }

  return (
    <div className='right-side-content'>
      <div className='right-side-content-section'>
        <label>YOUR COMMISSION</label>
        <div className='info-row'>
          {' '}
          <div className='info-bar '>
            <span className='f-14 regular text-white my-auto'>Pending</span>
            <span className='f-14 regular text-blue my-auto'>
              {' '}
              {'$' + (pending_amount || '')}
            </span>
          </div>
        </div>
        <div className='info-row'>
          {' '}
          <div className='info-bar '>
            <span className='f-14 regular text-white my-auto'>Available</span>
            <span className='f-14 regular text-green my-auto'>
              {'$' + (available_amount || '0')}
            </span>
          </div>
        </div>
        <div className='info-row'></div>

        <Button
          color='thirdary'
          className='w-100 btn-claim'
          onClick={e => props.claimCommission(available_amount || '')}
          disabled={props.claimCommissionRequest || !available_amount}
        >
          {props.claimCommissionRequest ? (
            <i className='fad fa-spinner fa-spin mr-2'></i>
          ) : null}
          CLAIM {' $' + (available_amount || '0')}
        </Button>
        {props.claimCommissionError ? (
          <div className='text-center text-red'>
            {props.claimCommissionError}
          </div>
        ) : null}
      </div>
      <div className='right-side-content-section'>
        <label>YOUR AFFILIATE STATUS</label>
        <Row form>
          <Col>
            {' '}
            <div className='info-bar height-72 p-0'>
              <span className='m-auto'>
                {' '}
                <div className='num-bold'>{signup_amount || '-'}</div>
                <div className='text-center f-12 regular text-regular m-0'>
                  signups
                </div>
              </span>
            </div>
          </Col>
          <Col>
            {' '}
            <div className='info-bar height-72 p-0'>
              {' '}
              <span className='m-auto'>
                {' '}
                <div className='num-bold'>{months || '-'}</div>
                <div className='text-center f-12 regular text-regular m-0'>
                  months
                </div>
              </span>
            </div>
          </Col>
          <Col>
            {' '}
            <div className='info-bar height-72 p-0'>
              {' '}
              <span className='m-auto'>
                {' '}
                <div className='num-bold'>{per_month || '-'}</div>
                <div className='text-center f-12 regular text-regular m-0'>
                  per month
                </div>
              </span>
            </div>
          </Col>
        </Row>
        <div className='info-row'>
          {' '}
          <div className='info-bar height-67'>
            {' '}
            <span className='m-auto'>
              {' '}
              <div className='text-center f-12 regular text-regular m-auto train'>
                Renew training within &nbsp;&nbsp;&nbsp;
                <b className='num-bold'> {renew_training_days || '-'} </b>{' '}
                &nbsp;&nbsp;&nbsp;days
              </div>
            </span>
          </div>
        </div>
      </div>
      <div className='right-side-content-section'>
        {' '}
        <label>UPCOMING TRAINING SESSION</label>
        {renderSchedules()}
        <div className='text-orange text-center f-12'>
          Email affiliate@rushable.io to register
        </div>
      </div>
    </div>
  )
}

export default AffiliateRightSide
