const ICON =
  'M24 12.0311C24 17.5078 16.6875 27.258 13.4812 31.281C12.7125 32.2397 11.2875 32.2397 10.5188 31.281C7.25625 27.258 0 17.5078 0 12.0311C0 5.38644 5.3725 0 12 0C18.625 0 24 5.38644 24 12.0311Z'

export default function Pin({ onClick, checked = false, onboarded }) {
  let fillColor = onboarded ? '#03C9A9' : '#008DFF'
  return (
    <svg
      width='24'
      height='32'
      viewBox='0 0 24 32'
      style={{
        cursor: 'pointer',
        fill: checked ? '#fff' : fillColor,
        stroke: 'none',
      }}
      onClick={onClick}
    >
      <path d={ICON} />
    </svg>
  )
}
