import React from 'react'
import { FormGroup, Col } from 'reactstrap'
import { BASIC_SELECT_STYLE, BASIC_SELECT_THEME } from '../../../utils/constant'
import ReactSelect from 'react-select'
import { filterFromObjectArray } from '../../../utils/tools'
import useUserTeamType from '../../../hooks/useUserTeamType'
import { allSources } from '../../../utils/common'

function SignupSourceFrom(props) {
  const teamType = useUserTeamType()
  let { formData, onChange, canEditSource } = props
  let { source } = formData

  let all_sources = allSources

  if (teamType === 'affiliate') {
    all_sources = [{ value: 'affiliate', label: 'Affiliate' }]
  }

  const onSelect = val => {
    onChange('source', val)
  }

  const DropdownIndicator = () => {
    return <i className='fas fa-caret-down multi-select-dropdown-indicator'></i>
  }

  const selectedValue = filterFromObjectArray(all_sources, source, 'value')

  return (
    <Col>
      <FormGroup>
        {selectedValue ? null : (
          <i className='fas fa-exclamation exclamation'></i>
        )}
        <ReactSelect
          name='signup_source'
          options={all_sources}
          placeholder={'Signup Source'}
          value={selectedValue}
          // menuIsOpen={true}
          className={
            'basic-single menu-z2' + (!canEditSource ? ' disabled' : '')
          }
          classNamePrefix='select'
          onChange={value => onSelect(value.value)}
          components={{ DropdownIndicator }}
          theme={BASIC_SELECT_THEME}
          styles={BASIC_SELECT_STYLE}
        />
      </FormGroup>
    </Col>
  )
}

export default SignupSourceFrom
