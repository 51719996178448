import './index.scss'
/**
 * 针对签约状态的自定义 select 筛选项
 * 接受数据类型 { value: 'no_progress', label: 'No Progress', color: '#FFE200' },
 * 即为 src/utils/common.js 里的 statusOptions
 * @param {*} props
 * @returns
 */
export default function SelectItemStatus(props) {
  const { innerProps, innerRef } = props
  const value = props.data?.value
  const isNoProgress = value === 'no_progress'
  return (
    <div ref={innerRef} {...innerProps} className='cutome-select-item-status'>
      <div
        className='item-status ml-4'
        style={{
          backgroundColor: isNoProgress ? 'transparent' : props.data.color,
          border: isNoProgress ? `1px solid ${props.data.color}` : 'none',
        }}
      ></div>
      <div className='item-label'>{props.data.label}</div>
    </div>
  )
}
