import { useState } from 'react'
import MapViewSearch from './MapViewSearch/index'
import MapViewResult from './MapViewResult/index'

export default function MapView(props) {
  const { address = {} } = props
  const [location, setLocation] = useState('')

  const isAddr = address.latitude && address.longitude && address.location_id

  return (
    <>
      {isAddr || location ? (
        <MapViewResult address={address} addressQuery={location} />
      ) : (
        <MapViewSearch onChange={e => setLocation(e)} />
      )}
    </>
  )
}
