import React from 'react'

const CreditTableHead = () => {
  return (
    <thead>
      <tr>
        <th className='fixed-width fixed-120'>STATUS</th>
        <th className='fixed-width fixed-120 text-right'>CREDIT $</th>
        <th className='text-right'>ORDER $</th>
        <th>REQUESTED BY</th>
        <th>REASON</th>
        <th>APPROVED/DENIED BY</th>
        <th>BRAND</th>
        <th>LOCATION</th>
        <th className='text-right'>ORDER ID</th>
        <th className='text-right'>ORDER DATE</th>
      </tr>
    </thead>
  )
}

export default CreditTableHead
