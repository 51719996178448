import React from 'react'
import { Label } from 'reactstrap'
import LogCard from './LogCard'
import LogHeader from './LogHeader'
import {
  RUSHABLE_FREE_PLAN_ID,
  RUSHABLE_MONTHLY_PLAN_ID,
  RUSHABLE_ANNUAL_PLAN_ID,
} from '../../../../utils/constant'
import { getFullName } from '../../../../utils/getFullName'

function DealClosingLog(props) {
  let { signup_form } = props

  let team = '-'
  let sourceStr = '-'
  let signupDeal = '-'
  let closerName = '-'
  let header = <LogHeader />

  if (signup_form) {
    let { onboarding, rushable_plan } = signup_form
    if (rushable_plan) {
      if (rushable_plan.id) {
        switch (rushable_plan.id.toString()) {
          case RUSHABLE_FREE_PLAN_ID:
            signupDeal = 'Free'
            break
          case RUSHABLE_MONTHLY_PLAN_ID:
            signupDeal = 'Monthly'
            break
          case RUSHABLE_ANNUAL_PLAN_ID:
            signupDeal = 'Annual'
            break
          default:
            break
        }
      }
    }
    if (onboarding) {
      let { crm_closer, source } = onboarding
      sourceStr = source ?? '-'
      if (crm_closer) {
        let { first_name, crm_team, last_name, profile_photo } = crm_closer
        closerName = getFullName(first_name, last_name)
        if (profile_photo) header = <LogHeader imgUrl={profile_photo} />
        if (crm_team) team = crm_team.name ?? '-'
      }
    }
  }
  return (
    <>
      <Label>DEAL CLOSING LOG</Label>
      <div className='log-row'>
        <div className='center-back-line'></div>
        <LogCard title={'Team'} subTitle={team} trailText={''} />
        <LogCard title={'Source'} subTitle={sourceStr} trailText={''} />
        <LogCard title={'Signup Deal'} subTitle={signupDeal} trailText={''} />
        <LogCard
          title={'Closer'}
          subTitle={closerName}
          trailText={''}
          large={true}
          trailChild={header}
        />
      </div>
    </>
  )
}

export default DealClosingLog
