import axios from 'axios'
import { toast } from 'react-toastify'

export const GET_LIVE_OERDERS_LIST_REQUEST = 'GET_LIVE_OERDERS_LIST_REQUEST'
export const GET_ORDERS_LIST = 'GET_ORDERS_LIST'
export const GET_ORDERS_LIST_FAIL = 'GET_ORDERS_LIST_FAIL'
export const GET_SPECIFIC_ORDER = 'GET_SPECIFIC_ORDER'
export const GET_SPECIFIC_ORDER_FAIL = 'GET_SPECIFIC_ORDER_FAIL'
export const GET_SPECIFIC_ORDER_REQUEST = 'GET_SPECIFIC_ORDER_REQUEST'
export const REQUEST_CHANGE_ORDER_STATUS = 'REQUEST_CHANGE_ORDER_STATUS'
export const CHANGE_STATUS_SUCCESS = 'CHANGE_STATUS_SUCCESS'
export const CHANGE_STATUS_FAILED = 'CHANGE_STATUS_FAILED'

export const getLiveOrders = params => dispatch => {
  dispatch({ type: GET_LIVE_OERDERS_LIST_REQUEST })
  axios
    .request({
      url: 'nc/live_orders/orders',
      method: 'GET',
      params: params,
      cache: { ignoreCache: true },
    })
    .then(({ data }) => {
      if (data.success) {
        dispatch({
          type: GET_ORDERS_LIST,
          data: data.data,
          msg: data.message,
        })
      } else {
        dispatch({
          type: GET_ORDERS_LIST_FAIL,
          msg: data.message,
          errors: data.errors,
        })
        toast.warn(data.message)
      }
    })
    .catch(err => {
      toast.warn(err.message)
      dispatch({ type: GET_ORDERS_LIST_FAIL })
    })
}

export const getSpecificOrder =
  (order_id, params = null, refresh = false) =>
  dispatch => {
    dispatch({ type: GET_SPECIFIC_ORDER_REQUEST })
    axios
      .request({
        url: `nc/live_orders/order/${order_id}`,
        method: 'get',
        params: {},
        cache: { ignoreCache: params ? params.refresh ?? refresh : refresh },
      })
      .then(({ data }) => {
        if (data.redirect) return
        if (data.success) {
          dispatch({
            type: GET_SPECIFIC_ORDER,
            data: data.data,
            msg: data.message,
          })
        } else {
          dispatch({
            type: GET_SPECIFIC_ORDER_FAIL,
            msg: data.message,
            errors: data.errors,
          })
          toast.warn(data.message)
        }
      })
      .catch(err => {
        toast.warn(err.message)
        dispatch({ type: GET_SPECIFIC_ORDER_FAIL })
      })
  }
