import React from 'react'
import { Row, Col, Input } from 'reactstrap'

export default function ModalConfirmation({
	disabled,
	creditConfirmation,
	setCreditConfirmation,
}) {
	return (
		<>
			<Row className='form-row'>
				<Col>
					<div className='cmi-title'>DD CONFIRMATION</div>
				</Col>
			</Row>
			<Row className='form-row'>
				<Col>
					<Input 
						placeholder={'Input the confirmation from DD'}
						disabled={disabled}
						value={creditConfirmation}
						onChange={e => setCreditConfirmation(e.target.value)}
					/>
				</Col>
			</Row>
		</>
	)
}