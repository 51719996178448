import React from 'react'
import { Card, Form, FormGroup, Input, Button, Alert } from 'reactstrap'

function LoginForm(props) {
  let { email, password } = props.data
  return (
    <Card className='form-card'>
      <div className='login-form'>
        <h1 className='text-center'>Rushable CRM</h1>
        {props.loginError ? (
          <Alert color='danger' className='mb-5 text-center'>
            {props.loginError}
          </Alert>
        ) : null}
        <Form onSubmit={props.onSubmit}>
          <FormGroup className='form-group-lg'>
            <Input
              type='email'
              name='email'
              placeholder='Email'
              className='form-control-lg'
              onChange={e => props.onInputChange('email', e.target.value)}
              value={email || ''}
              required
            />
          </FormGroup>
          <FormGroup className='form-group-lg'>
            <Input
              type='password'
              name='password'
              placeholder='Password'
              className='form-control-lg'
              onChange={e => props.onInputChange('password', e.target.value)}
              value={password || ''}
              required
            />
          </FormGroup>
          <Button
            type='submit'
            color='primary'
            className='btn-primary-login'
            block
            disabled={props.loginRequest}
          >
            {props.loginRequest ? (
              <i className='fad fa-spinner fa-spin mr-2'></i>
            ) : null}
            Login
          </Button>
        </Form>
      </div>
    </Card>
  )
}

export default LoginForm
