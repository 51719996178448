import React from 'react'
import { Row, Col, Card, Table } from 'reactstrap'
import ReactSelect from 'react-select'
import { capitalize } from '../../utils/tools'
import NinjaPagination from '../Common/NinjaPagination'
import { BASIC_SELECT_STYLE, BASIC_SELECT_THEME } from '../../utils/constant'
import { filterFromObjectArray } from '../../utils/tools'
import { DebounceInput } from 'react-debounce-input'
import MultiSelectPrimary from '../Common/MultiSelectPrimary'
import moment from 'moment'

function AffiliateContent(props) {
  let { affiliates, onboardingOptions, filterData } = props
  let { brand_name, location_id } = filterData
  if (!affiliates) return <div></div>

  const renderAffiliates = () => {
    if (!affiliates) return null
    let { affiliate_records } = affiliates
    if (!affiliate_records) return null
    if (!affiliate_records.data) return null
    if (affiliate_records.data.length === 0)
      return (
        <tr>
          <td colSpan='10' className='p-0 ponit-events-none'>
            <div className='text-white'>No data</div>
          </td>
        </tr>
      )

    return affiliate_records.data.map((affiliate, key) => {
      let {
        brand_name,
        location_name,
        location_id,
        commission,
        created_at,
        type,
        status,
      } = affiliate

      let date = created_at ? moment(created_at).format('MM/DD/YYYY') : ''
      let amount_color = ''

      if (status === 'invalid') {
        amount_color = 'text-red invalid-value'
      } else {
        if (status === 'pending') {
          amount_color = 'text-blue'
        } else if (status === 'available') {
          amount_color = 'text-green'
        }
      }

      let commission_value = commission
        ? type === 'claim'
          ? '- $' + commission
          : '$' + commission
        : '-'

      return (
        <tr key={key} className='ponit-events-none'>
          <td>{type === 'claim' ? '-' : brand_name ?? ''}</td>
          <td>{type === 'claim' ? '-' : location_name ?? ''}</td>
          <td>
            {type === 'claim' ? '-' : location_id ? '#' + location_id : ''}
          </td>
          <td>{type ? capitalize(type) : ''}</td>
          <td className={'text-right ' + amount_color + ' text-nowrap'}>
            {commission_value}
          </td>
          <td className='text-right'>{date}</td>
        </tr>
      )
    })
  }

  const renderBrandsFilter = () => {
    let all_options = [{ value: '', label: 'All Brands' }]
    if (props.getOnboardingOptionsRequestType === 'brands') {
      all_options = [{ value: '', label: 'Loading...' }]
    }
    if (onboardingOptions && onboardingOptions.brands)
      onboardingOptions.brands.map((brand, key) => {
        all_options.push({
          value: brand,
          label: brand,
        })
        return all_options
      })

    const DropdownIndicator = () => {
      return (
        <i className='fas fa-caret-down multi-select-dropdown-indicator'></i>
      )
    }

    return (
      <ReactSelect
        value={
          filterFromObjectArray(all_options, brand_name, 'value') ||
          filterFromObjectArray(all_options, '', 'value')
        }
        name='brand_name'
        options={all_options}
        // menuIsOpen={true}
        className='basic-single'
        classNamePrefix='select'
        onChange={value => props.onFilterChange('brand_name', value.value)}
        onMenuOpen={e => props.getOptions('brands')}
        components={{ DropdownIndicator }}
        theme={BASIC_SELECT_THEME}
        styles={BASIC_SELECT_STYLE}
      />
    )
  }

  const renderTypeFilter = () => {
    let all_types = [
      { value: 'all', label: 'All Types' },
      { value: 'signup', label: 'Signup' },
      { value: 'recurring', label: 'Recurring' },
      { value: 'claim', label: 'Claim' },
    ]

    return (
      <MultiSelectPrimary
        options={all_types}
        onFilterChange={props.onFilterChange}
        onFilterChangeType={'types'}
        selectText={'Type'}
      ></MultiSelectPrimary>
    )
  }

  const debounceLocationId = () => {
    return (
      <DebounceInput
        value={location_id || ''}
        placeholder={'Search by Location ID'}
        name='owner'
        className='debounce-input'
        debounceTimeout={500}
        onChange={e => props.onFilterChange('location_id', e.target.value)}
      />
    )
  }

  const renderPagination = () => {
    if (!affiliates) return null
    let { affiliate_records } = affiliates
    if (!affiliate_records) return null
    return (
      <NinjaPagination
        currentPage={affiliate_records.current_page}
        totalPages={affiliate_records.last_page}
        pageNeighbours={1}
        goToPage={page => props.goToPage(page)}
      />
    )
  }

  return (
    <div className='detail-content'>
      {' '}
      <div className='onboardings-filter'>
        <Row className='form-row'>
          <Col style={{ flexGrow: 'initial' }}>
            <div style={{ width: '240px' }}>{renderBrandsFilter()}</div>
          </Col>
          <Col style={{ flexGrow: 'initial' }}>
            <div className='debounce-row width-200'>{debounceLocationId()}</div>
          </Col>
          <Col style={{ flexGrow: 'initial' }}>
            <div style={{ width: '240px' }}>{renderTypeFilter()}</div>
          </Col>
        </Row>
      </div>
      <Card className='primary-table'>
        <Table responsive hover>
          <thead>
            <tr>
              <th className='onboarding-th-brand'>BRAND</th>
              <th className='onboarding-th-location'>LOCATION</th>
              <th>LOCATION ID</th>
              <th>TYPE</th>
              <th className='text-right'>AMOUNT</th>
              <th className='text-right'>DATE</th>
            </tr>
          </thead>
          <tbody>
            {props.getAffiliatesRequest ? (
              <tr>
                <td colSpan='10'>
                  <i className='fad fa-spinner fa-spin'></i>
                </td>
              </tr>
            ) : null}
            {renderAffiliates()}
            <tr>
              <td colSpan='10' className='p-0 ponit-events-none'></td>
            </tr>
          </tbody>
        </Table>
      </Card>
      <div className='dashed-line margin-top-10 margin-bottom-20'></div>
      {renderPagination()}
    </div>
  )
}

export default AffiliateContent
