import React from 'react'
import {
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap'
import { DebounceInput } from 'react-debounce-input'
import AddressInput from './AddressInput'

function LocationInfoContent(props) {
  let { formData, brands, profile } = props
  let {
    location_name,
    location_email,
    location_phone,
    loc_add_query,
    loc_add_line2,
    sales_tax,
  } = formData

  let rushable_team = profile
    ? profile.crm_team
      ? profile.crm_team.type === 'rushable'
        ? true
        : false
      : false
    : false

  return (
    <>
      <Row form>
        <Col>
          <Label>LOCATION INFO</Label>
        </Col>
      </Row>
      <Row form>
        <Col>
          <FormGroup>
            <Input
              type='text'
              name='location_name'
              placeholder={'Nickname of this location (e.g. Downtown)'}
              value={location_name || ''}
              onChange={e => props.onChange('location_name', e.target.value)}
              disabled={!props.canUpdate}
            />
            {rushable_team ? null : location_name ? null : (
              <i className='fas fa-exclamation exclamation'></i>
            )}
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col>
          <FormGroup>
            <AddressInput
              name='loc_add_query'
              classes='loc_add_query'
              value={loc_add_query || ''}
              placeholder='Address line 1'
              disabled={!props.canUpdate}
              onFormChange={value => props.onChange('loc_add_query', value)}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col>
          <FormGroup>
            <Input
              type='text'
              name='loc_add_line2'
              placeholder={'Address line 2'}
              value={loc_add_line2 || ''}
              onChange={e => props.onChange('loc_add_line2', e.target.value)}
              disabled={!props.canUpdate}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col>
          <FormGroup>
            <Input
              type='email'
              name='location_email'
              placeholder={'Restaurant email'}
              value={location_email || ''}
              onChange={e => props.onChange('location_email', e.target.value)}
              disabled={!props.canUpdate}
            />
            {rushable_team ? null : location_email ? null : (
              <i className='fas fa-exclamation exclamation'></i>
            )}
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col>
          <FormGroup>
            <Input
              type='text'
              name='location_phone'
              placeholder={'Restaurant phone'}
              onChange={e =>
                props.onChange(
                  'location_phone',
                  typeof e.target.value === 'string' &&
                    e.target.value.length >= 0
                    ? e.target.value.substr(0, 14)
                    : '',
                )
              }
              onKeyUp={props.onLocationPhoneKeyUp}
              onKeyDown={props.onPhoneKeyDown}
              value={location_phone || ''}
              disabled={!props.canUpdate}
            />
            {rushable_team ? null : location_phone ? null : (
              <i className='fas fa-exclamation exclamation'></i>
            )}
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Input
              type='text'
              inputMode='decimal'
              placeholder='Sales tax rate'
              name='sales_tax'
              autoComplete='off'
              value={sales_tax ?? ''}
              disabled={!props.canUpdate}
              onChange={e => props.onChange('sales_tax', e.target.value)}
            />
            {/* <InputGroupAddon addonType="prepend">%</InputGroupAddon> */}
          </FormGroup>
        </Col>
      </Row>
    </>
  )
}

export default LocationInfoContent
