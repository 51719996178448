import React from 'react'
import { Button } from 'reactstrap'

function BtnInForm(props) {
  let { onClick, text, select, disabled } = props
  return (
    <Button
      block
      color='in-form'
      className={(select ? 'select' : '') + (disabled ? ' disable' : '')}
      onClick={onClick ? e => onClick() : null}
      disabled={disabled}
    >
      {text ?? ''}
    </Button>
  )
}
export default BtnInForm
