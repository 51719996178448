import React from 'react'
import { connect } from 'react-redux'
import {
  createOnboarding,
  editOnboarding,
  getOnboardingOptions,
  getOnboardings,
  getOnboarding,
  clearOnboardingError,
  changeOnboardingStatus,
  deleteOnboardingDraft,
  getSetupfee,
} from '../../store/actions/onboarding'
import { searchBrands } from '../../store/actions/brands'
import { getPlans } from '../../store/actions/plans'
import Onboarding from '../../components/Onboarding'
import newid from '../../utils/newid'
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file
import {
  returnOpenHours,
  returnHoursInDays,
  filterFromObjectArray,
} from '../../utils/tools'
import {
  formatDigitOnly,
  onPhoneKeyDown,
  onPhoneKeyUp,
  onDobKeyUp,
  onEinKeyUp,
  formatPhoneNumber,
  formatEin,
} from '../../utils/digit'
import depositOptions from '../../utils/depositOptions'
import { setSignupFormInfo, getCuisineTypes } from '../../store/actions/support'
const format = 'HH:mm:00'

class OnboardingIndex extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      formData: {
        has_domain: 'null',
        has_website: 'null',
        gmb_accesss: null,
        business_type: 'company',
        rushable_plan_id: '102',
        source: '',
        need_welcome_call: 1,
        welcome_note: '',
        cuisine_type_ids: [],
      },
      prevApproval: {
        brand_id: null,
        need_welcome_call: null,
        welcome_note: null,
      },
      editMode: false,
      brands: [],
      modalOpen: false,
      cuisineTypes: [],
    }

    this.onSubmit = this.onSubmit.bind(this)
    this.onFormChange = this.onFormChange.bind(this)
    this.addHours = this.addHours.bind(this)
    this.removeHours = this.removeHours.bind(this)
    this.setClosed = this.setClosed.bind(this)
    this.onHourChange = this.onHourChange.bind(this)
    this.onRepPhoneKeyUp = this.onRepPhoneKeyUp.bind(this)
    this.onLocationPhoneKeyUp = this.onLocationPhoneKeyUp.bind(this)
    this.onBusinessPhoneKeyUp = this.onBusinessPhoneKeyUp.bind(this)
    this.onOwnerPhoneKeyUp = this.onOwnerPhoneKeyUp.bind(this)
    this.onOwnerDobKeyUp = this.onOwnerDobKeyUp.bind(this)
    this.onBusinessEinKeyUp = this.onBusinessEinKeyUp.bind(this)
    this.renderFormData = this.renderFormData.bind(this)
    this.goBack = this.goBack.bind(this)
    this.withdrawn = this.withdrawn.bind(this)
    this.deleteOnboardingDraft = this.deleteOnboardingDraft.bind(this)
    this.getSetupfee = this.getSetupfee.bind(this)
    this.changeOnboardingStatus = this.changeOnboardingStatus.bind(this)
    this.initialLoad = this.initialLoad.bind(this)
  }

  componentDidMount() {
    this.initialLoad()
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.match?.params?.signupId !==
      this.props.match?.params?.signupId ||
      prevProps.signupId !== this.props.signupId ||
      prevProps.location?.search !== this.props.location?.search
    ) {
      this.initialLoad()
    }
    if (prevProps.selectedOnboarding !== this.props.selectedOnboarding) {
      if (this.props.selectedOnboarding) {
        this.renderFormData()
      }
    }
    if (
      prevProps.createOnboardingSuccess !==
      this.props.createOnboardingSuccess ||
      prevProps.editOnboardingSuccess !== this.props.editOnboardingSuccess
    ) {
      if (
        this.props.createOnboardingSuccess ||
        this.props.editOnboardingSuccess
      ) {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        })
      }
    }
  }

  initialLoad() {
    this.setState({
      days: returnHoursInDays([]),
    })

    this.props.clearOnboardingError()

    this.props.getCuisineTypes().then(({ data }) => {
      if (data.success && data.data) {
        this.setState({
          cuisineTypes: data.data.map(item => {
            return {
              label: item.name,
              value: item.id,
            }
          }),
        })
      }
    })

    let signupId = this.props.signupId
      ? this.props.signupId
      : this.props.match.params.signupId
    if (signupId === 'create') {
      const search = this.props.location?.search ?? window.location.search
      if (search) {
        const actualSignupId = new URLSearchParams(search).get('id')
        if (actualSignupId) {
          this.setState({ editMode: true })
          this.props.getOnboarding(actualSignupId, true)
          return
        }
      }
    }

    if (signupId !== 'create') {
      this.setState({ editMode: true })
      this.props.getOnboarding(this.props.onboardingId, true)
      return
    }

    this.setState({ editMode: false })
    this.renderBasicFormData()
  }

  /**
   * To Submit Onboarding
   * create/save as draft/update
   * @param {Object} e
   * @param {string} onboardingStatus
   */
  onSubmit(e, onboardingStatus) {
    e.preventDefault()
    const { user } = this.props.profile ? this.props.profile : {}
    let { formData, days } = this.state
    let {
      loc_add_query,
      loc_add_line2,
      biz_add_line1,
      biz_add_line2,
      biz_add_city,
      biz_add_state,
      biz_add_zipcode,
      owner_add_line1,
      owner_add_line2,
      owner_add_city,
      owner_add_state,
      owner_add_zipcode,
      coupon_code,
      requires_approval,
      brand_id,
      status,
    } = formData
    let location_hours = returnOpenHours(days)
    let business_address_query = biz_add_line1
      ? biz_add_line1 +
      ', ' +
      biz_add_city +
      ' ' +
      biz_add_state +
      ' ' +
      biz_add_zipcode
      : null
    let owner_address_query = owner_add_line1
      ? owner_add_line1 +
      ', ' +
      owner_add_city +
      ' ' +
      owner_add_state +
      ' ' +
      owner_add_zipcode
      : null

    let params = {
      ...formData,
      location_hours: location_hours,
      status: onboardingStatus,
      rep_phone: formatDigitOnly(formData.rep_phone ?? ''),
      location_phone: formatDigitOnly(formData.location_phone ?? ''),
      business_phone: formatDigitOnly(formData.business_phone ?? ''),
      owner_phone: formatDigitOnly(formData.owner_phone ?? ''),
      business_ein: formatDigitOnly(formData.business_ein ?? ''),
      coupon_code: coupon_code,
      location_address_query: loc_add_query,
      location_address_2: loc_add_line2,
      business_address_query: business_address_query,
      business_address_2: biz_add_line2,
      owner_address_query: owner_address_query,
      owner_address_2: owner_add_line2,
      onboarding_id:
        this.state.editMode || (!this.state.editMode && requires_approval)
          ? this.props.selectedOnboarding.id
          : '',
    }

    if (brand_id) delete params.brand_name
    if (
      this.state.editMode ||
      (!this.state.editMode && requires_approval) ||
      (!this.state.editMode &&
        onboardingStatus === 'signup_ready' &&
        status !== 'new')
    ) {
      delete params.requires_approval
      this.props.editOnboarding(params)
    } else {
      this.props.createOnboarding(params)
    }
  }

  renderBasicFormData() {
    const { crm_team } = this.props.profile ? this.props.profile : {}
    let teamType = crm_team ? crm_team.type : ''
    let presetSource = teamType === 'affiliate' ? 'affiliate' : ''
    this.setState({
      days: returnHoursInDays([]),
      formData: {
        has_domain: 'null',
        has_website: 'null',
        gmb_accesss: null,
        business_type: 'company',
        rushable_plan_id: '102',
        signup_fee: 199,
        doordash_dispatch_fee: 1.5,
        send_tablet: true,
        language: 'english',
        coupon_code: '',
        source: presetSource,
        need_welcome_call: 1,
        welcome_note: '',
        cuisine_type_ids: [],
        status: 'new',
      },
    })

    this.getSetupfee()
  }

  renderFormData() {
    let { selectedOnboarding } = this.props
    let { signup_form } = selectedOnboarding
    let {
      open_hours_collection,
      crm_representative,
      location_address,
      business_address,
      owner_address,
      has_domain,
      has_website,
      location_phone,
      business_phone,
      owner_phone,
      business_ein,
      deposit,
      coupon_code,
      payment_schedule,
      source,
      referrer_id,
      doordash_dispatch_fee,
      brand_name,
      location_name,
      brand_id,
      need_welcome_call,
      welcome_note,
    } = signup_form

    this.props.setSignupFormInfo({ brand_name, location_name })

    // default deposit & send_tablet value
    deposit = deposit >= 0 ? deposit : 199
    let selectedDepositType = filterFromObjectArray(
      depositOptions(false),
      deposit,
      'deposit',
    )
    let send_tablet = selectedDepositType
      ? selectedDepositType.send_tablet
      : null

    let loc_add_data = {}
    let biz_add_data = {}
    let owner_add_data = {}

    // location address fields
    if (location_address) {
      loc_add_data = {
        loc_add_query:
          location_address.line_1 +
          ' ' +
          location_address.city +
          ' ' +
          location_address.state +
          ' ' +
          location_address.zipcode,
        loc_add_line2: location_address.line_2,
      }
    }

    // business address fields
    if (business_address) {
      biz_add_data = {
        biz_add_line1: business_address.line_1,
        biz_add_line2: business_address.line_2,
        biz_add_city: business_address.city,
        biz_add_state: business_address.state,
        biz_add_zipcode: business_address.zipcode,
      }
    }

    // owner address fields
    if (owner_address) {
      owner_add_data = {
        owner_add_line1: owner_address.line_1,
        owner_add_line2: owner_address.line_2,
        owner_add_city: owner_address.city,
        owner_add_state: owner_address.state,
        owner_add_zipcode: owner_address.zipcode,
      }
    }

    // day hours fields
    let days = returnHoursInDays([])

    if (open_hours_collection) {
      if (open_hours_collection.open_hours)
        days = returnHoursInDays(open_hours_collection.open_hours)
    }

    var newDaysMap = JSON.parse(JSON.stringify(days))

    // map form data
    let formData = {
      ...signup_form,
      deposit,
      send_tablet,
      rep_first_name: crm_representative?.first_name ?? null,
      rep_last_name: crm_representative?.last_name ?? null,
      rep_email: crm_representative?.email ?? null,
      rep_phone: crm_representative?.phone
        ? formatPhoneNumber(crm_representative.phone)
        : null,
      primary_language: crm_representative?.primary_language ?? null,
      has_domain: String(has_domain),
      has_website: String(has_website),
      ...loc_add_data,
      ...biz_add_data,
      ...owner_add_data,
      //format phones
      location_phone: location_phone ? formatPhoneNumber(location_phone) : null,
      business_phone: business_phone ? formatPhoneNumber(business_phone) : null,
      owner_phone: owner_phone ? formatPhoneNumber(owner_phone) : null,
      business_ein: business_ein ? formatEin(business_ein) : null,
      coupon_code,
      payment_schedule,
      signup_fee:
        payment_schedule && payment_schedule.signup_fee
          ? payment_schedule.signup_fee
          : '',
      source,
      referrer_id,
      doordash_dispatch_fee,
      need_welcome_call: need_welcome_call ?? 1,
      welcome_note: welcome_note ?? '',
    }

    this.getSetupfee()

    // set state
    this.setState({
      formData,
      days: newDaysMap,
      status: selectedOnboarding.status,
      prevApproval: {
        brand_id,
        need_welcome_call,
        welcome_note,
      },
    })
    const { requires_approval } = signup_form
    if (requires_approval) {
      this.setState({ editMode: false })
    } else if (selectedOnboarding.status) {
      this.setState({ editMode: true })
    }
  }

  onFormChange(name, val) {
    switch (name) {
      case 'offer-type':
        this.setState({
          formData: {
            ...this.state.formData,
            send_tablet: val.send_tablet,
            deposit: val.deposit,
          },
        })
        break
      case 'connect-method':
        this.setState({
          formData: {
            ...this.state.formData,
            has_website: val.has_website,
            has_domain: val.has_domain,
          },
        })
        break
      case 'brand_name':
        this.setState({
          formData: {
            ...this.state.formData,
            [name]: val,
          },
        })
        this.props.setSignupFormInfo({
          brand_name: val,
          location_name: this.state.formData.location_name,
        })
        // if (val) this.props.searchBrands(val);
        break
      case 'location_name':
        this.setState({
          formData: {
            ...this.state.formData,
            [name]: val,
          },
        })
        this.props.setSignupFormInfo({
          brand_name: this.state.formData.brand_name,
          location_name: val,
        })
        break
      default:
        this.setState({
          formData: {
            ...this.state.formData,
            [name]: val,
          },
        })
        break
    }
  }

  getSetupfee(code) {
    let { coupon_code } = this.state.formData
    let params = { coupon_code: code ?? coupon_code }
    this.props.getSetupfee(params)
  }

  setClosed(panel, day) {
    panel.close()
    let { days } = this.state
    this.setState({
      days: {
        ...days,
        [day]: [],
      },
    })
  }

  addHours(day) {
    let { days } = this.state
    let item = { id: newid('null'), day: day, from: '', to: '' }
    this.setState({
      days: {
        ...days,
        [day]: [...days[day], item],
      },
    })
  }

  removeHours(day, index) {
    this.setState({
      days: {
        ...this.state.days,
        [day]: this.state.days[day].filter((_, i) => i !== index),
      },
    })
  }

  onHourChange(index, day, name, value) {
    let dayHours = this.state.days[day]
    if (dayHours[index]) {
      dayHours[index][name] = value && value.format(format)
    } else {
      dayHours[index] = { id: newid('null'), day: day, from: '', to: '' }
      dayHours[index][name] = value && value.format(format)
    }

    this.setState({
      days: {
        ...this.state.days,
        [day]: dayHours,
      },
    })
  }

  onRepPhoneKeyUp(e) {
    var ph = onPhoneKeyUp(e, e.target.value)
    let formData = {
      ...this.state.formData,
      rep_phone: ph,
    }
    this.setState({ formData })
  }
  onLocationPhoneKeyUp(e) {
    var ph = onPhoneKeyUp(e, e.target.value)
    let formData = {
      ...this.state.formData,
      location_phone: ph,
    }
    this.setState({ formData })
  }

  onBusinessPhoneKeyUp(e) {
    var ph = onPhoneKeyUp(e, e.target.value)
    let formData = {
      ...this.state.formData,
      business_phone: ph,
    }
    this.setState({ formData })
  }

  onOwnerPhoneKeyUp(e) {
    var ph = onPhoneKeyUp(e, e.target.value)
    let formData = {
      ...this.state.formData,
      owner_phone: ph,
    }
    this.setState({ formData })
  }

  onOwnerDobKeyUp(e) {
    var dob = onDobKeyUp(e, e.target.value)
    let formData = {
      ...this.state.formData,
      owner_dob: dob,
    }
    this.setState({ formData })
  }

  onBusinessEinKeyUp(e) {
    var ein = onEinKeyUp(e, e.target.value)
    let formData = {
      ...this.state.formData,
      business_ein: ein,
    }
    this.setState({ formData })
  }

  goBack() {
    if (this.props.location.state) {
      let { fromPath } = this.props.location.state
      fromPath = fromPath ? fromPath : '/signups'
      this.props.history.push({
        pathname: fromPath,
        state: this.props.location.state,
      })
    } else {
      this.props.history.push({
        pathname: '/signups',
      })
    }
    this.props.clearOnboardingError()
  }

  withdrawn() {
    this.changeOnboardingStatus('withdrawn')
  }

  changeOnboardingStatus(status) {
    let onboarding_id = this.props.selectedOnboarding.id ?? ''
    this.props.changeOnboardingStatus({
      onboarding_id,
      status: status,
    })
  }

  deleteOnboardingDraft() {
    let onboarding_id = this.props.selectedOnboarding.id ?? ''
    this.props.deleteOnboardingDraft({
      onboarding_id,
    })
  }

  render() {
    return (
      <Onboarding
        isTab={this.props.isTab}
        editMode={this.state.editMode}
        onFormChange={this.onFormChange}
        onSubmit={this.onSubmit}
        formData={this.state.formData}
        prevApproval={this.state.prevApproval}
        cuisineTypes={this.state.cuisineTypes}
        days={this.state.days}
        onHourChange={this.onHourChange}
        setClosed={this.setClosed}
        addHours={this.addHours}
        removeHours={this.removeHours}
        onRepPhoneKeyUp={this.onRepPhoneKeyUp}
        onLocationPhoneKeyUp={this.onLocationPhoneKeyUp}
        onBusinessPhoneKeyUp={this.onBusinessPhoneKeyUp}
        onOwnerPhoneKeyUp={this.onOwnerPhoneKeyUp}
        onOwnerDobKeyUp={this.onOwnerDobKeyUp}
        onPhoneKeyDown={onPhoneKeyDown}
        onBusinessEinKeyUp={this.onBusinessEinKeyUp}
        brands={this.state.brands}
        createOnboardingError={this.props.createOnboardingError}
        editOnboardingError={this.props.editOnboardingError}
        createOnboardingRequest={this.props.createOnboardingRequest}
        editOnboardingRequest={this.props.editOnboardingRequest}
        getSetupfeeRequest={this.props.getSetupfeeRequest}
        getSetupfeeError={this.props.getSetupfeeError}
        setupfee={this.props.setupfee}
        getSetupfee={this.getSetupfee}
        getOnboardingError={this.props.getOnboardingError}
        getOnboardingRequest={this.props.getOnboardingRequest}
        changeOnboardingStatus={this.changeOnboardingStatus}
        changeOnboardingStatusRequest={this.props.changeOnboardingStatusRequest}
        changeOnboardingStatusError={this.props.changeOnboardingStatusError}
        createOnboardingSuccess={this.props.createOnboardingSuccess}
        editOnboardingSuccess={this.props.editOnboardingSuccess}
        withdrawn={this.withdrawn}
        deleteOnboardingDraft={this.deleteOnboardingDraft}
        status={this.state.status}
        goBack={this.goBack}
        profile={this.props.profile}
        onboardingOptions={this.props.onboardingOptions}
        getOnboardingOptionsRequest={this.props.getOnboardingOptionsRequest}
      ></Onboarding>
    )
  }
}

const mapStateToProps = state => {
  let {
    selectedOnboarding,
    getOnboardingRequest,
    getOnboardingError,
    createOnboardingRequest,
    editOnboardingRequest,
    createOnboardingError,
    editOnboardingError,
    changeOnboardingStatusRequest,
    changeOnboardingStatusError,
    createOnboardingSuccess,
    editOnboardingSuccess,
    getSetupfeeRequest,
    getSetupfeeError,
    setupfee,
    onboardingOptions,
    getOnboardingOptionsRequest,
  } = state.onboarding
  return {
    auth: state.auth,
    profile: state.auth.profile,
    brands: state.brands,
    onboarding: state.onboarding.data,
    selectedOnboarding,
    getOnboardingRequest,
    getOnboardingError,
    createOnboardingRequest,
    editOnboardingRequest,
    createOnboardingError,
    editOnboardingError,
    changeOnboardingStatusRequest,
    changeOnboardingStatusError,
    createOnboardingSuccess,
    editOnboardingSuccess,
    getSetupfeeRequest,
    getSetupfeeError,
    setupfee,
    onboardingOptions,
    getOnboardingOptionsRequest,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    createOnboarding: params => dispatch(createOnboarding(params)),
    editOnboarding: params => dispatch(editOnboarding(params)),
    clearOnboardingError: params => dispatch(clearOnboardingError(params)),
    changeOnboardingStatus: params => dispatch(changeOnboardingStatus(params)),
    deleteOnboardingDraft: params => dispatch(deleteOnboardingDraft(params)),
    getOnboardingOptions: params => dispatch(getOnboardingOptions(params)),
    getOnboardings: params => dispatch(getOnboardings(params)),
    getOnboarding: params => dispatch(getOnboarding(params)),
    searchBrands: params => dispatch(searchBrands(params)),
    getPlans: params => dispatch(getPlans(params)),
    getSetupfee: params => dispatch(getSetupfee(params)),
    setSignupFormInfo: params => dispatch(setSignupFormInfo(params)),
    getCuisineTypes: params => dispatch(getCuisineTypes(params)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(OnboardingIndex)
