import React, { useState } from 'react'
import { FormGroup, Label, Input, Button, Row, Col } from 'reactstrap'

function LocationInfo(props) {
  let { name, address } = props

  if (!address) address = {}
  if (!name) name = ''
  let { line_1, line_2, state, city, zipcode } = address
  return (
    <div className='basic-info-section'>
      <Label>LOCATION INFO</Label>

      <Row form>
        <Col>
          <FormGroup>
            <Input
              type='text'
              name='email'
              placeholder={'Location Name'}
              value={name ?? ''}
              disabled={true}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col>
          <FormGroup>
            <Input
              type='text'
              name='email'
              placeholder={'Address Line 1'}
              value={line_1 ?? ''}
              disabled={true}
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Input
              type='text'
              name='email'
              placeholder={'Address Line 2'}
              value={line_2 ?? ''}
              disabled={true}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col md={6}>
          <FormGroup>
            <Input
              type='text'
              name='email'
              placeholder={'City'}
              value={city ?? ''}
              disabled={true}
            />
          </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup>
            <Input
              type='text'
              name='email'
              placeholder={'State'}
              value={state ?? ''}
              disabled={true}
            />
          </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup>
            <Input
              type='text'
              name='email'
              placeholder={'Zipcode'}
              value={zipcode ?? ''}
              disabled={true}
            />
          </FormGroup>
        </Col>
      </Row>
    </div>
  )
}

export default LocationInfo
