import React from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { ToastContainer } from 'react-toastify'

class AuthLayout extends React.Component {
  componentDidMount() {}

  componentDidUpdate(prevProps) {}

  render() {
    return (
      <div className='auth-layout'>
        <ToastContainer
          autoClose={2 * 1000}
          position={toast.POSITION.TOP_CENTER}
        />
        <div className='auth-main'>{this.props.children}</div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {}
}
const mapDispatchToProps = dispatch => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthLayout)
