import {
  CHANGE_STATUS_FAILED,
  CHANGE_STATUS_SUCCESS,
  GET_ORDERS_LIST,
  GET_ORDERS_LIST_FAIL,
  GET_SPECIFIC_ORDER,
  GET_SPECIFIC_ORDER_FAIL,
  GET_SPECIFIC_ORDER_REQUEST,
  REQUEST_CHANGE_ORDER_STATUS,
  GET_LIVE_OERDERS_LIST_REQUEST,
} from '../actions/orders'

const initialState = {
  get_live_orders_list_request: false,
  get_orders_list_failed: false,
  orders: [],
  total_num: 0,
  total_page: 0,
  selected_order: {},
  get_specific_order_failed: false,
  get_specific_order_request: false,
  request_order_status_change: false,
  change_order_failed: false,
}

const orders = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIVE_OERDERS_LIST_REQUEST:
      return {
        ...state,
        get_live_orders_list_request: true,
      }
    case GET_ORDERS_LIST:
      return {
        ...state,
        total_num: action.data.total,
        total_page: action.data.last_page,
        orders: action.data.data,
        get_live_orders_list_request: false,
      }

    case GET_ORDERS_LIST_FAIL:
      return {
        ...state,
        get_orders_list_failed: true,
        get_live_orders_list_request: false,
      }

    case GET_SPECIFIC_ORDER:
      return {
        ...state,
        selected_order: action.data,
        get_specific_order_request: false,
      }

    case GET_SPECIFIC_ORDER_FAIL:
      return {
        ...state,
        selected_order: {},
        get_specific_order_failed: true,
        get_specific_order_request: false,
      }

    case GET_SPECIFIC_ORDER_REQUEST:
      return {
        ...state,
        get_specific_order_request: true,
      }

    case CHANGE_STATUS_SUCCESS:
      return {
        ...state,
        change_order_failed: false,
      }

    case CHANGE_STATUS_FAILED:
      return {
        ...state,
        change_order_failed: true,
      }

    case REQUEST_CHANGE_ORDER_STATUS:
      return {
        ...state,
      }

    default:
      return state
  }
}

export default orders
