import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import moment from 'moment-timezone'

import pusher from '../../utils/pusher'
import FilterBar from './Filterbar'
import Table from '../../components/Table'
import BadgeText from '../../components/BadgeText'
import { getLiveOrders } from '../../store/actions/orders'
import { openModal } from '../../store/actions/ui'
import { getReference, getTypeName } from '../../utils/order'
import { DB_TIMEZONE } from '../../utils/constant'
import Pagination from '../../components/Support/SupportContent/Orders/OrdersTable/Pagination'
// import LiveOrderDetail from './LiveOrderDetail'
import { currencyFormatter } from '../../utils/digit'

const currencyOptions = {
  significantDigits: 2,
  thousandsSeparator: ',',
  decimalSeparator: '.',
  symbol: '$',
}

const columns = [
  {
    key: 'order_id',
    name: 'Order ID',
    custom: value => <div>#{value}</div>,
  },
  {
    key: 'status',
    name: 'Status',
    custom: value => {
      if (value === 'placed') {
        value = 'new'
      } else if (value === 'done') {
        value = 'completed'
      } else if (value === 'confirmed') {
        value = 'accepted'
      }
      return (
        <div>
          <BadgeText label={value} />
        </div>
      )
    },
  },
  {
    key: 'total',
    name: 'Total',
    className: 'text-right',
    custom: value => <span>{currencyFormatter(value, currencyOptions)}</span>,
  },
  {
    key: 'brand',
    name: 'Brand',
  },
  {
    key: 'location',
    name: 'Location',
  },
  {
    key: 'type',
    name: 'Type',
  },
  {
    key: 'reference',
    name: 'Reference',
    custom: value => <div>{value}</div>,
  },
  {
    key: 'submit',
    name: 'Submit',
  },
  {
    key: 'request',
    name: 'Request',
  },
  {
    key: 'refund',
    name: 'Refund',
    custom: value => {
      let result = ''
      if (value === 0) {
        result = 'Full'
      } else if (value === '-') {
        result = '-'
      } else result = 'Partial'
      return <div>{result}</div>
    },
  },
]

const LiveOrder = () => {
  const dispatch = useDispatch()
  const channel = pusher.subscribe('order')
  const orders = useSelector(state => state.orders)
  const history = useHistory()
  const locationState = history.location.state

  const [filters, setFilters] = useState(
    locationState
      ? locationState
      : {
        begin_time: '',
        end_time: '',
        page: 1,
        types: '',
        status: '',
        brands: '',
      },
  )

  useEffect(() => {
    //Handler for the pusher event
    channel.bind('order_updated', () => {
      fetchWithUpdate()
    })

    channel.bind('order_created', data => {
      fetchWithUpdate()
    })

    return () => {
      channel.unbind('order_updated')
      channel.unbind('order_created')
    }
  }, [])

  const converStateToParams = f_p => {
    let filterParams = f_p ? f_p : filters
    let params = {
      brand_name: filterParams.brands,
      begin_time: filterParams.begin_time,
      end_time: filterParams.end_time,
      page: filterParams.page,
    }
    if (filterParams.status) {
      params['statuses[0]'] = filterParams.status
    }
    if (filterParams.types) {
      params['types[0]'] = filterParams.types
    }
    return params
  }

  const fetchWithUpdate = () => {
    let t_p = localStorage.getItem('live_order_filters')
      ? JSON.parse(localStorage.getItem('live_order_filters'))
      : null
    fetchLiveOrdersList(t_p)
  }

  const temp_data = orders?.orders?.map(order => {
    const localTimezone = order.location?.address?.timezone ?? DB_TIMEZONE
    return {
      order_id: order.id,
      status: order.status,
      total: order.customer_total,
      brand: order.location?.brand?.name,
      location: order.location?.name,
      type: getTypeName(order.type),
      reference: getReference(order.type, order.daas_delivery),
      submit: `${moment.tz(order.created_at, DB_TIMEZONE)
        .tz(localTimezone)
        .format('MMM D hh:mm A z')}`,
      request: `${moment.tz(order.request_at, DB_TIMEZONE)
        .tz(localTimezone)
        .format('MMM D hh:mm A z')}`,
      refund: order.refund ? order.customer_total - order.refund?.amount : '-',
    }
  })

  useEffect(() => { }, [filters])

  const filterOptionChange = (type, value) => {
    setFilters({ ...filters, [type]: value, page: 1 })
  }

  const submitDateRange = (startDate, endDate) => {
    const t_filters = {
      ...filters,
      begin_time: moment(startDate)
        .startOf('day')
        .add(3, 'hours')
        .format('YYYY-MM-DD HH:mm:ss'),
      end_time: moment(endDate)
        .endOf('day')
        .add(3, 'hours')
        .format('YYYY-MM-DD HH:mm:ss'),
      page: 1,
    }
    setFilters(t_filters)
  }

  useEffect(() => {
    fetchLiveOrdersList()
  }, [filters])

  //If function has param, get fetch with that param or IF not, fetch with state params
  const fetchLiveOrdersList = f_param => {
    let param = f_param ? { ...f_param } : { ...filters }
    localStorage.setItem('live_order_filters', JSON.stringify(param))
    dispatch(getLiveOrders(converStateToParams(param), false))
  }

  const toLiveOrderDetailPage = row => {
    dispatch(openModal())
    const location = {
      pathname: `/liveorders/${row.order_id}`,
      state: filters,
    }
    history.push(location)
  }

  return (
    <div className='liveorder-page'>
      {orders.get_live_orders_list_request ? (
        <div className='loadingToast'>
          <i className='fad fa-spinner fa-spin'></i>
        </div>
      ) : null}
      <FilterBar
        filters={filters}
        submitDateRange={(startDate, endDate) => {
          submitDateRange(startDate, endDate)
        }}
        onChangeHandle={(type, value) => {
          filterOptionChange(type, value)
        }}
      />
      <div>
        <Table
          columns={columns}
          handleRowClicked={row => {
            toLiveOrderDetailPage(row)
          }}
          data={temp_data}
          trClassName='py-39px'
        />
        <div className='custom-border-bottom-dashed'></div>
        <Pagination
          orders={{ current_page: filters.page, last_page: orders.total_page }}
          goToPage={page => {
            setFilters({ ...filters, page })
          }}
        />
      </div>
    </div>
  )
}

export default LiveOrder
