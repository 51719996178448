import React, { useState } from 'react'
import { Input } from 'reactstrap'
import moment from 'moment'
import { DOMAIN } from '../../../../utils/constant'

export default function ProgressReview({
  comment,
  noteUpdate,
  handleNoteDelete,
  isClose,
}) {
  const { content, ticket_status, id, updated_at, author } = comment
  const { profile_photo, first_name, last_name } = author
  const [editStatus, setEditStatus] = useState(false)
  const [value, setValue] = useState(content)
  const [editLoading, setEditLoading] = useState(false)
  const [doneLoading, setDoneLoading] = useState(false)
  const [helpLoading, setHelpLoading] = useState(false)
  const [deleteLaoding, setDeleteLoading] = useState(false)
  const handleChangeEdit = () => {
    if (!editStatus) {
      setValue(content)
    }
    setEditStatus(!editStatus)
  }
  const handleUpdate = type => {
    if (type === 'edit') {
      setEditLoading(true)
      noteUpdate({
        id,
        content: value,
        cb: () => {
          setEditLoading(false)
          setEditStatus(false)
        },
      })
    }
    if (type === 'done') {
      setDoneLoading(true)
      noteUpdate({
        id,
        status: ticket_status === 'done' ? '' : 'done',
        cb: () => {
          setDoneLoading(false)
        },
      })
    }
    if (type === 'help') {
      setHelpLoading(true)
      noteUpdate({
        id,
        status: ticket_status === 'help_needed' ? '' : 'help_needed',
        cb: () => {
          setHelpLoading(false)
        },
      })
    }
  }
  const handleDelete = () => {
    setDeleteLoading(true)
    handleNoteDelete({
      id,
      cb: () => {
        setDeleteLoading(false)
      },
    })
  }

  return (
    <div className='progress-review'>
      {ticket_status === 'done' ? (
        <div className='pr-close'>
          <div className='pr-close-title'>{content}</div>
          <div className='pr-close-icon' onClick={() => handleUpdate('done')}>
            {doneLoading ? (
              <i className='far fa-spinner fa-spin' />
            ) : (
              <i className='far fa-check-square'></i>
            )}
          </div>
        </div>
      ) : (
        <div className='pr-container'>
          <div className='pr-container-header'>
            <div className='pr-header-left'>
              {profile_photo ? (
                <img className='header-left-img' src={DOMAIN + profile_photo} />
              ) : (
                <i className='fas fa-user-circle header-left-icon'></i>
              )}

              <span>
                {first_name} {last_name}
              </span>
            </div>
            <div className='pr-header-right'>
              <span className='header-right-time'>
                {moment(updated_at).format('MMM D, HH:mm a')}
              </span>
              <span
                className='header-right-icon'
                onClick={() => handleUpdate('help')}
              >
                {helpLoading ? (
                  <i className='far fa-spinner fa-spin' />
                ) : (
                  <i
                    className='far fa-hand-paper'
                    style={{
                      color: ticket_status === 'help_needed' ? '#FFE200' : '',
                    }}
                  />
                )}
              </span>
              <span
                className='header-right-icon'
                onClick={() => handleUpdate('done')}
              >
                {doneLoading ? (
                  <i className='far fa-spinner fa-spin' />
                ) : (
                  <i className='far fa-check-square'></i>
                )}
              </span>
              <span className='header-right-icon' onClick={handleChangeEdit}>
                <i
                  className='far fa-pen'
                  style={{
                    color: editStatus ? '#e9e9e9' : 'rgba(233, 233, 233, 0.25)',
                  }}
                ></i>
              </span>
              <span className='header-right-icon' onClick={handleDelete}>
                {deleteLaoding ? (
                  <i className='far fa-spinner fa-spin' />
                ) : (
                  <i className='far fa-trash-alt'></i>
                )}
              </span>
            </div>
          </div>
          <div className='pr-container-info'>
            {editStatus ? (
              <div className='pr-info'>
                <Input
                  value={value}
                  className='pr-info-input'
                  type='textarea'
                  placeholder='Input notes here'
                  onChange={e => setValue(e.target.value)}
                />
                <div
                  className='pr-info-button'
                  onClick={() => handleUpdate('edit')}
                >
                  {editLoading ? (
                    <i className='far fa-spinner fa-spin' />
                  ) : (
                    <i className='fas fa-long-arrow-down'></i>
                  )}
                </div>
              </div>
            ) : (
              <div
                className='pr-info-text'
                style={{
                  color: ticket_status === 'help_needed' ? '#FFE200' : '',
                }}
              >
                {content}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}
