import moment from 'moment'
/**
 * Count Days from sign up time
 * @param {Date} compareTime
 * @param {Date} signupAt
 */
export const getDaysFromSignupAt = (compareTime, signupAt) => {
  let days = 0
  if (!signupAt) return '-'
  if (!compareTime) return '-'
  days =
    moment(signupAt)
      .endOf('day')
      .diff(moment(compareTime).startOf('day'), 'days') + 1
  return days
}
