import {
  REFERRER_GET_REFERRERS_FAIL,
  REFERRER_GET_REFERRERS_REQUEST,
  REFERRER_GET_REFERRERS_SUCCESS,
} from '../actions/referrer'

const initialState = {
  referrers: [],
}

const referrer = (state = initialState, action) => {
  switch (action.type) {
    // plans
    case REFERRER_GET_REFERRERS_REQUEST:
      return {
        ...state,
        getReferrersRequest: true,
      }
    case REFERRER_GET_REFERRERS_SUCCESS:
      return {
        ...state,
        getReferrersRequest: false,
        referrers: action.data,
      }
    case REFERRER_GET_REFERRERS_FAIL:
      return {
        ...state,
        getReferrersRequest: false,
      }

    default:
      return state
  }
}

export default referrer
