import React, { useEffect, useState } from 'react'
import { Row, Col } from 'reactstrap'
import ReactSelect, { components } from 'react-select'
import NinjaPagination from '../Common/NinjaPagination'
import PhoneControl from './PhoneControl'
import ClientTable from '../ClientTable/ClientTable'
import BrandsFilter from '../BrandsFilter/BrandsFilter'
import ProjectLeadsFilter from '../ProjectLeadsFilter/ProjectLeadFilter'
import { BASIC_SELECT_STYLE, BASIC_SELECT_THEME } from '../../utils/constant'
import { filterFromObjectArray } from '../../utils/tools'
import DropdownIndicator from '../Common/DropdownIndicator'
// import { statusOptions } from "../../utils/common";

const statusOptions = [
  { value: '', label: 'Showing All', color: '#e9e9e9' },
  { value: 'onboarding', label: 'Onboarding', color: '#008DFF' },
]

function Supports(props) {
  let { filterData, supports, onFilterChange, mode } = props
  let {
    brand_name,
    phone,
    project_lead_id,
    progress,
    ticket_status,
    count_order,
  } = filterData
  const allOptions = [
    { value: '', label: 'All Onboarding' },
    { value: 'help_needed', label: 'Needs Attention' },
  ]
  const handleAttentionChange = value => {
    onFilterChange('ticket_status', value)
  }
  return (
    <div className='onboardings-content'>
      <div className='onboardings-filter'>
        <Row className='form-row'>
          <Col style={{ flexGrow: 'initial' }}>
            <div style={{ width: '192px' }}>
              <ReactSelect
                value={filterFromObjectArray(statusOptions, progress || "", 'value')}
                name='status'
                options={statusOptions}
                isSearchable={false}
                className='basic-single'
                classNamePrefix='select'
                onChange={value => {
                  onFilterChange('progress', value.value)
                }}
                components={{
                  Control: ({ children, ...rest }) => (
                    <components.Control {...rest}>
                      <div
                        className='rectangle-banner ml-4'
                        style={{
                          backgroundColor:
                            filterFromObjectArray(
                              statusOptions,
                              progress || "",
                              'value',
                            )?.color ?? 'white',
                        }}
                      ></div>
                      {children}
                    </components.Control>
                  ),
                  DropdownIndicator,
                }}
                theme={BASIC_SELECT_THEME}
                styles={{
                  ...BASIC_SELECT_STYLE,
                  control: (styles) => ({
                    ...styles,
                    boxShadow: 'none',
                    minHeight: '36px',
                    height: '36px',
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    borderColor: '#E9E9E9 !important'
                  }),
                  menu: (styles) => {
                    return {
                      ...styles,
                      backgroundColor: '#000016',
                      marginTop: -1,
                      borderTopLeftRadius: 0,
                      borderTopRightRadius: 0,
                      borderLeft: '2px solid #E9E9E9',
                      borderRight: '2px solid #E9E9E9',
                      borderBottom: '2px solid #E9E9E9',
                    }
                  },
                  valueContainer: (styles) => ({
                    ...styles,
                    padding: '2px 16px',
                    fontWeight: 700
                  }),
                }}
              />
            </div>
          </Col>
          <Col style={{ flexGrow: 'initial' }}>
            <div style={{ width: '240px' }}>
              <BrandsFilter
                onBrandChange={e => onFilterChange('brand_name', e.value)}
                brandName={brand_name}
              />
            </div>
          </Col>
          <PhoneControl
            onChange={value => onFilterChange('phone', value)}
            value={phone}
          />
          {progress === 'onboarding' ? (
            <Col style={{ flexGrow: 'initial' }}>
              <div style={{ width: '240px' }}>
                <ProjectLeadsFilter
                  onChange={e => onFilterChange('project_lead_id', e.value)}
                  value={project_lead_id}
                />
              </div>
            </Col>
          ) : null}
          {progress === 'onboarding' ? (
            <Col style={{ flexGrow: 'initial' }}>
              <div style={{ width: '240px' }}>
                <ReactSelect
                  value={filterFromObjectArray(
                    allOptions,
                    ticket_status,
                    'value',
                  )}
                  options={allOptions}
                  className='basic-single'
                  classNamePrefix='select'
                  onChange={({ value }) => handleAttentionChange(value)}
                  components={{ DropdownIndicator }}
                  theme={BASIC_SELECT_THEME}
                  styles={BASIC_SELECT_STYLE}
                />
              </div>
            </Col>
          ) : null}
        </Row>
      </div>
      <ClientTable
        mode={mode}
        isLoading={props.getSupportsRequest}
        supports={props.supports}
        goToSupport={props.goToSupport}
        progress={progress}
        onFilterChange={onFilterChange}
        count_order={count_order}
      />
      <div className='dashed-line margin-top-10 margin-bottom-20'></div>
      {supports ? (
        <NinjaPagination
          currentPage={supports.current_page}
          totalPages={supports.last_page}
          pageNeighbours={1}
          goToPage={page => props.goToPage(page)}
        />
      ) : null}
    </div>
  )
}

export default Supports
