import React, { useState, useEffect } from 'react'
import OnboardingIndex from '../../../../pages/onboarding/index'

export default function SignupForm({ supportData, location }) {
  const [signupId, setSignupId] = useState('create')
  const onboardingId = supportData?.signup_form?.onboarding?.id ?? ''

  useEffect(() => {
    setSignupId(supportData?.signup_form?.id ?? 'create')
  }, [supportData])

  const toggleOnboardingBoard = () => {}
  return (
    <div className='signup_form w-944px'>
      <OnboardingIndex
        location={location}
        signupId={signupId}
        onboardingId={onboardingId}
        toggleBoard={toggleOnboardingBoard}
        type={signupId ? 'create' : 'edit'}
        isTab={true}
      />
    </div>
  )
}
