import React from 'react'
import { Card, Table } from 'reactstrap'
import ClientTableBody from './ClientTableBody'
import ClientTableHead from './ClientTableHead'

const ClientTable = ({
  isLoading,
  supports,
  goToSupport,
  mode,
  progress,
  onFilterChange,
  count_order,
}) => {
  return (
    <Card className='primary-table support-table'>
      <Table responsive hover>
        <ClientTableHead
          mode={mode}
          progress={progress}
          onFilterChange={onFilterChange}
          count_order={count_order}
        />
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan='12'>
                <i className='fad fa-spinner fa-spin mr-2'></i> Loading
              </td>
            </tr>
          ) : null}
          <ClientTableBody
            isLoading={true}
            supports={supports}
            goToSupport={goToSupport}
            mode={mode}
            progress={progress}
          />
          <tr>
            <td colSpan='12' className='p-0 ponit-events-none'></td>
          </tr>
        </tbody>
      </Table>
    </Card>
  )
}

export default ClientTable
