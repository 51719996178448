import React from 'react'
import { FormGroup, Label, Button, Row, Col } from 'reactstrap'
import classnames from 'classnames'
import { formatCurrency } from '../../utils/digit'

function PlanChoice(props) {
  let { formData } = props
  let { rushable_plan_id, signup_fee } = formData
  let basicSignupFeeStr =
    signup_fee && signup_fee !== 0 && signup_fee !== '0'
      ? '$' + formatCurrency(signup_fee, 0) + ' +  '
      : null

  return (
    <>
      <Row form>
        <Col>
          <Label>PLAN PRE-SELECT</Label>
        </Col>
      </Row>
      <Row form>
        <Col>
          <FormGroup>
            <Button
              type='button'
              color='plan'
              className={classnames('select-form-primary', {
                selected: rushable_plan_id.toString() === '102',
                onboarded: !props.canUpdate,
              })}
              onClick={e => props.onChange('rushable_plan_id', '102')}
            >
              <div className='plan-name d-inline-flex'>
                <span className='select-form-primary-dot green'></span>
                <span className='name'>Monthly Plan</span>
              </div>
              <div className='plan-price d-inline-flex'>
                <span className='price'>
                  {basicSignupFeeStr}
                  {'$119/mo'}
                </span>
              </div>
            </Button>
            {rushable_plan_id ? null : (
              <i className='fas fa-exclamation exclamation'></i>
            )}
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col>
          <FormGroup>
            <Button
              type='button'
              color='plan'
              className={classnames('select-form-primary', {
                selected: rushable_plan_id.toString() === '107',
                onboarded: !props.canUpdate,
              })}
              onClick={e => props.onChange('rushable_plan_id', '107')}
            >
              <div className='plan-name d-inline-flex'>
                <span className='select-form-primary-dot blue'></span>
                <span className='name'>Annual Plan</span>
              </div>
              <div className='plan-price d-inline-flex'>
                <span className='price'>
                  {basicSignupFeeStr}
                  {'$1188/yr'}
                </span>
              </div>
            </Button>
            {rushable_plan_id ? null : (
              <i className='fas fa-exclamation exclamation'></i>
            )}
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col>
          <FormGroup>
            <Button
              type='button'
              color='plan'
              className={classnames('select-form-primary', {
                selected: rushable_plan_id.toString() === '108',
                onboarded: !props.canUpdate,
              })}
              onClick={e => props.onChange('rushable_plan_id', '108')}
            >
              <div className='plan-name d-inline-flex'>
                <span className='select-form-primary-dot orange'></span>
                <span className='name'>Free Plan</span>
              </div>
              <div className='plan-price d-inline-flex'>
                <span className='price'>
                  {basicSignupFeeStr}
                  {'$0/mo'}
                </span>
              </div>
            </Button>
            {rushable_plan_id ? null : (
              <i className='fas fa-exclamation exclamation'></i>
            )}
          </FormGroup>
        </Col>
      </Row>
    </>
  )
}

export default PlanChoice
