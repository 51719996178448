import { capitalize } from './tools'

const getDaasType = daas_type => {
  var before = ''
  switch (daas_type) {
    case 'doordash':
      before = 'DD-'
      break
    default:
      break
  }
  return before
}

const getReference = (type, daas_delivery) => {
  let str = '-'
  if (type === 'delivery') str = 'In-house'
  if (type === 'daas_delivery') {
    if (!daas_delivery) str = 'On-demand'
    if (daas_delivery)
      if (daas_delivery.type)
        str = getDaasType(daas_delivery.type) + daas_delivery.external_id ?? ''
  }
  return str
}

const getTypeName = type => {
  var name = ''
  switch (type) {
    case 'pickup':
    case 'curbside':
    case 'dine-in':
    case 'takeout':
      name = type.charAt(0).toUpperCase() + type.slice(1)
      break
    case 'daas_delivery':
      name = 'Delivery '
      break
    case 'delivery':
      name = 'Delivery'
      break
    default:
      break
  }
  return name
}

const getStatusName = status => {
  switch (status) {
    case 'placed':
      return 'New'
    case 'done':
      return 'Completed'
    case 'confirmed':
      return 'Accepted'
    default:
      return capitalize(status)
  }
}

const renderStatusColor = status => {
  let color = ''
  switch (status) {
    case 'Placed':
    case 'New':
      color = 'text-yellow'
      break
    case 'Accepted':
      color = 'text-orange'
      break
    case 'Canceled':
      color = 'text-red'
      break
    case 'Ready':
      color = 'text-blue'
      break
    case 'Completed':
      color = 'text-green'
      break
    default:
      color = ''
  }
  return color
}

export { getReference, getTypeName, getStatusName, renderStatusColor }
