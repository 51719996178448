import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'
import Map, { Marker, NavigationControl, ScaleControl } from 'react-map-gl'
import mapboxgl from 'mapbox-gl'
import Pin from './pin'
import GeocoderControl from './geocoder-control'
import { MAPBOX_ACCESSTOKEN } from '../../../../utils/constant'

mapboxgl.workerClass =
  // eslint-disable-next-line import/no-webpack-loader-syntax
  require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default

const initViewState = {
  longitude: '',
  latitude: '',
  zoom: 10,
}

function App(props) {
  const { mapPointList = [], center = {}, loading } = props
  const [cityList, setCityList] = useState([])
  const [viewState, setViewState] = useState(initViewState)
  const mapRef = useRef(null)

  useEffect(() => {
    const copyList = [...mapPointList]
    copyList.forEach(v => (v.checked = false))
    if (props.center.brandId) {
      const centerIndex = mapPointList.findIndex(
        v => v.brandId === center.brandId,
      )
      const centerPoint = copyList[centerIndex]
      copyList[centerIndex].checked = true
      setViewState({
        ...initViewState,
        ...centerPoint,
      })
      setCityList(copyList)
    } else {
      const centerPoint = {
        ...initViewState,
        ...center,
        checked: true,
      }
      setCityList([...copyList, centerPoint])
      setViewState(centerPoint)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapPointList.length, center.brandId, center.latitude, center.longitude])

  const handleClickPin = (item, index, ary) => {
    if (item.brandId === viewState.brandId) return
    const filterList = ary.filter(v => !!v.brandId)
    filterList.forEach(v => (v.checked = false))
    filterList[index].checked = true
    setCityList(filterList)
    props.onChange(filterList[index])
  }
  return (
    <>
      <Button
        className='search-area font-bold btn btn-menu f-12'
        onClick={() => {
          if (loading) return
          const center = mapRef.current?.getCenter()
          props.onClickSearchArea(center)
        }}
      >
        {loading && <i className='fad fa-spinner fa-spin mr-2' />}
        SEARCH THIS AREA
      </Button>
      <Map
        ref={mapRef}
        {...viewState}
        onMove={evt => setViewState(evt.viewState)}
        mapStyle='mapbox://styles/mapbox/dark-v9'
        mapboxAccessToken={MAPBOX_ACCESSTOKEN}
      >
        <GeocoderControl
          mapboxAccessToken={MAPBOX_ACCESSTOKEN}
          position='top-right'
          collapsed={true}
          onChange={point => {
            props.onClickSearchArea({ lng: point[0], lat: point[1] })
          }}
        />
        <NavigationControl position='top-right' />
        <ScaleControl />
        {cityList.map((item, index, ary) => {
          return (
            <Marker
              key={`marker-${item.brandId}`}
              longitude={item.longitude}
              latitude={item.latitude}
              anchor='bottom'
              style={{ zIndex: item.checked ? 999 : 1 }}
            >
              <Pin
                checked={item.checked}
                onboarded={item.onboarded}
                onClick={() => handleClickPin(item, index, ary)}
              />
            </Marker>
          )
        })}
      </Map>
    </>
  )
}

App.propTypes = {
  mapPointList: PropTypes.array.isRequired,
  center: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onClickSearchArea: PropTypes.func.isRequired,
}
App.defaultProps = {}

export default React.memo(App)
