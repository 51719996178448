import React from 'react'
import Supports from '../../components/Supports'
import { connect } from 'react-redux'
import { getSupports } from '../../store/actions/support'
import { fromOnboardingPage } from '../../store/actions/onboarding'
const SUPPORT_MODE = 'support'
class SupportsIndex extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      filterData: {
        brand_name: '',
        phone: '',
        progress: '',
        page: 1,
        ticket_status: '',
      },
      openOnboarding: false,
    }
    this.onFilterChange = this.onFilterChange.bind(this)
    this.goToSupport = this.goToSupport.bind(this)
    this.toggleOnboardingBoard = this.toggleOnboardingBoard.bind(this)
    this.goToPage = this.goToPage.bind(this)
  }

  componentDidMount() {
    let params = {}
    if (this.props.location.state) {
      let { prevFilterData } = this.props.location.state

      params = {
        ...prevFilterData,
      }

      this.setState({
        filterData: prevFilterData,
      })
    } else {
      params = { ...this.state.filterData }
    }
    if (this.props.fromOnboardingPageStatus) {
      params.refresh = true
    }
    this.props.getSupports(params)
    this.props.fromOnboardingPage(false)
    let supports = this.props.supports

    if (supports) {
      this.setState({
        supports,
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.supports !== this.props.supports) {
      this.setState({
        supports: this.props.supports,
      })
    }
  }

  onFilterChange(name, val) {
    let filterData
    if (name === 'count_order') {
      filterData = {
        ...this.state.filterData,
        [name]:
          this.state.filterData['count_order'] &&
            this.state.filterData['count_order'] === 'ASC'
            ? 'DESC'
            : this.state.filterData['count_order'] &&
              this.state.filterData['count_order'] === 'DESC'
              ? ''
              : 'ASC',
      }
    } else {
      filterData = { ...this.state.filterData, [name]: val }
    }

    filterData = {
      ...filterData,
      page: name === 'count_order' ? filterData.page : 1
    }
    if (filterData.progress === 'onboarding') {
      filterData['count_order'] = ''
    }

    this.setState({
      filterData: {
        ...filterData,
      },
    })

    this.props.getSupports(filterData)
  }

  toggleOnboardingBoard(type) {
    this.setState({
      openOnboarding: !this.state.openOnboarding,
    })
  }

  goToSupport(id, status) {
    let pathname = `/restaurants/${id}`
    this.props.history.push({
      pathname: pathname,
      state: {
        prevFilterData: this.state.filterData,
        fromPath: '/restaurants',
      },
    })
  }

  goToPage(page) {
    let filterData = {
      ...this.state.filterData,
      page,
    }
    this.setState({
      filterData,
    })
    this.props.getSupports(filterData)
  }

  render() {
    return (
      <Supports
        onFilterChange={this.onFilterChange}
        filterData={this.state.filterData}
        getSupportsRequest={this.props.getSupportsRequest}
        supports={this.state.supports}
        goToPage={this.goToPage}
        goToSupport={this.goToSupport}
        mode={SUPPORT_MODE}
      />
    )
  }
}

const mapStateToProps = state => {
  const { fromOnboardingPageStatus } = state.onboarding
  return {
    auth: state.auth,
    profile: state.auth.profile,

    supports: state.support.supports,
    getSupportsRequest: state.onboarding.getSupportsRequest,
    fromOnboardingPageStatus,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getSupports: params => dispatch(getSupports(params)),
    fromOnboardingPage: params => dispatch(fromOnboardingPage(params)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(SupportsIndex)
