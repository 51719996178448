import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import ReferralTablePlan from './ReferralTablePlan'
import UserAvatar from '../UserAvatar/UserAvatar'
import { getFullName } from '../../utils/getFullName'
import { getOnboardedDays } from '../../utils/getOnboardedDays'
import { currencyFormatter } from '../../utils/digit'

const ReferralTableRow = ({ referralItem, editReward }) => {
	const history = useHistory()
	const { referral_info, id, referrer, brand_name, location_name, rushable_plan, onboarding, order_count, order_amount, rewardLoading, location_id, paid_at } = referralItem
	const { bonus, status } = referral_info
	const { name, email } = referrer
	const { crm_closer, done_at } = onboarding
	const closerName = crm_closer
		? getFullName(crm_closer.first_name, crm_closer.last_name)
		: '-'

	const closerAvatar = crm_closer ? crm_closer.profile_photo : null
	const onboard_days = getOnboardedDays(done_at, paid_at)

	const goDetail = () => {
		const currentPath = `/referral`
		const location = {
			pathname: location_id
				? `/restaurants/${location_id}`
				: `/restaurants/create/signup-form`,
			state: {
				fromPath: currentPath,
			},
		}
		history.push(location)
	}

	const days_30 =
		order_count || order_amount
			? `${order_count ?? '-'} / ${order_amount ? currencyFormatter(order_amount, { significantDigits: 0 }) : '-'
			}`
			: '-'

	return (
		<tr onClick={goDetail}>
			<td
				className='fixed-width fixed-120 referral-select'
				onClick={(e) => {
					e.stopPropagation()
					editReward(id, status === 'sent' ? 'qualified' : 'sent')
				}}
			>
				{rewardLoading ? (
					<i className='fad fa-spinner fa-spin mr-2' />
				) : (
					status === 'sent' ? (
						<i className="far fa-check-square" />
					) : (
						<i className="far fa-square" />
					)
				)}
			</td>
			<td className='fixed-width fixed-120'>{bonus ? `$${Math.round(Number(bonus))}` : '-'}</td>
			<td className='mw-120px text-elliosis'>{name ?? '-'}</td>
			<td className='mw-220px text-elliosis'>
				{email ?? '-'}
			</td>
			<td className='mw-160px text-elliosis'>{brand_name ?? '-'}</td>
			<td className='mw-160px text-elliosis'>{location_name ?? '-'}</td>
			<td className='table-plan-td'>
				<ReferralTablePlan plan={rushable_plan} />
			</td>
			<td className='table-closer-td'>
				<UserAvatar imgUrl={closerAvatar} name={closerName} />
			</td>
			<td>{days_30}</td>
			<td className='text-right text-nowrap'>
				{/* {paid_at ? moment(paid_at).format('MMM DD, YYYY') : '-'} */}
				{onboard_days}
			</td>
		</tr>
	)
}

export default ReferralTableRow
