import React from 'react'

import { BASIC_SELECT_STYLE, BASIC_SELECT_THEME } from '../../utils/constant'
import ReactSelect from 'react-select'

class MultiSelectPrimary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      menu_is_open: false,
      selected_values: [],
      selected_all: false,
    }
    this.onSelected = this.onSelected.bind(this)
    this.toggleMenuOpen = this.toggleMenuOpen.bind(this)
  }

  componentDidMount() {
    if (this.props.options) {
      this.setState({
        options: this.props.options,
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.options !== prevProps.options) {
      this.setState({
        options: this.props.options,
      })
    }
  }

  onSelected(values) {
    let selected_values = []
    let filter_values = []
    let has_all = false
    values.forEach(val => {
      if (val.value === 'all' || val.value === '') {
        has_all = true
      }
    })
    if (!this.state.selected_all && has_all) {
      this.setState({
        selected_all: true,
      })
      this.props.options.forEach(val => {
        if (val.value === 'all' || val.value === '') {
          selected_values.push({ value: val.value, label: val.label })
        }
        if (val.value !== 'all' && val.value !== '') {
          filter_values.push(val.value)
        }
      })
    } else if (this.state.selected_all && !has_all) {
      this.setState({
        selected_all: false,
      })
      values.forEach(val => {
        if (val.value !== 'all' && val.value !== '') {
          selected_values.push({ value: val.value, label: val.label })
          filter_values.push(val.value)
        }
      })
    } else {
      values.forEach(val => {
        if (val.value !== 'all' && val.value !== '') {
          selected_values.push({ value: val.value, label: val.label })
          filter_values.push(val.value)
        }
      })
    }

    this.setState({
      selected_values: selected_values,
    })
    this.props.onFilterChange(this.props.onFilterChangeType, filter_values)
  }

  toggleMenuOpen(type) {
    if (type) {
      if (this.props.onMenuOpen) {
        this.props.onMenuOpen(this.props.onMenuopenType)
      }
    }
    this.setState({
      menu_is_open: type,
    })
  }

  render() {
    let { selected_values, selected_all, menu_is_open } = this.state
    let show_text = selected_all
      ? 'All ' + this.props.selectText
      : selected_values.length === 0
      ? 'All ' + this.props.selectText
      : selected_values.length === 1
      ? selected_values[0].label
      : this.props.selectText + ': ' + selected_values.length
    return (
      <div className='multi-select-primary' id='multi-pri'>
        <div
          className={
            'multi-select-showtext ' + (menu_is_open ? 'menu_is_open' : '')
          }
        >
          <span className='multi-select-showtext-content'>{show_text}</span>
        </div>

        <ReactSelect
          value={this.state.selected_values}
          isMulti
          name='location_ids'
          options={this.props.options}
          onMenuOpen={e => this.toggleMenuOpen(true)}
          onMenuClose={e => this.toggleMenuOpen(false)}
          hideSelectedOptions={false}
          closeMenuOnSelect={false}
          className={'basic-multi-select checkbox  h-auto'}
          classNamePrefix='select'
          onChange={values => this.onSelected(values)}
          theme={BASIC_SELECT_THEME}
          styles={BASIC_SELECT_STYLE}
        />
      </div>
    )
  }
}

export default MultiSelectPrimary
