import React from 'react'
import Onboardings from '../../components/Onboardings'
import { connect } from 'react-redux'
import {
  getOnboardingOptions,
  getOnboardings,
  getOnboarding,
  clearOnboardingError,
} from '../../store/actions/onboarding'
import OnboardingIndex from '../onboarding/index'

// mapView 状态下
class OnboardingsIndex extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      filterData: {
        brand_name: '',
        brand_map_view_name: '',
        owner_name: '',
        team_id: '',
        closer_id: '',
        progress: '',
        location_name: '',
      },
      openOnboarding: false,
      team_id: '',
    }
    this.getOptions = this.getOptions.bind(this)
    this.ownerChange = this.ownerChange.bind(this)
    this.onFilterChange = this.onFilterChange.bind(this)
    this.seeOnboardingDetail = this.seeOnboardingDetail.bind(this)
    this.toggleOnboardingBoard = this.toggleOnboardingBoard.bind(this)
    this.goToPage = this.goToPage.bind(this)
    this.onTeamsChange = this.onTeamsChange.bind(this)
  }

  componentDidMount() {
    let { profile } = this.props
    const isMaster =
      profile?.crm_team?.type === 'rushable' &&
      profile?.crm_role?.name === 'master'
    if (!isMaster && profile?.id) {
      this.setState({
        filterData: {
          ...this.state.filterData,
          closer_id: profile.id,
        },
      })
    }
    //hold filter when going back from detail page
    if (this.props.location.state) {
      let { prevFilterData, prevTeamId, prevOptionsType } =
        this.props.location.state
      let filterData = {
        ...prevFilterData,
      }

      this.setState({
        filterData: prevFilterData,
        options_type: prevOptionsType,
        team_id: prevTeamId,
      })
      let params = { ...filterData }
      this.props.getOnboardings({ params })
      this.props.getOnboardingOptions({
        type: prevOptionsType,
        team_id: prevTeamId,
      })
    }

    this.getOptions('closers')

    let onboardings = this.props.onboardings
    if (onboardings) {
      this.setState({
        onboardings: onboardings,
      })
    }
  }

  componentDidUpdate(prevProps) {
    let { onboardingOptions, profile } = this.props
    if (
      prevProps.onboardingOptions !== onboardingOptions ||
      (onboardingOptions && !this.state.onboardingOptions)
    ) {
      this.setState({
        onboardingOptions: onboardingOptions,
      })
    }
    if (prevProps.onboardings !== this.props.onboardings) {
      this.setState({
        onboardings: this.props.onboardings,
      })
    }

    if (this.state.openOnboarding && this.props.getOnboardingsRequest) {
      this.toggleOnboardingBoard()
    }

    if (prevProps.profile !== profile) {
      const isMaster =
        profile.crm_team.type === 'rushable' &&
        profile.crm_role.name == 'master'
      this.setState(
        {
          filterData: {
            ...this.state.filterData,
            closer_id: !isMaster ? profile.id : '',
          },
        },
        () => {
          let params = { ...this.state.filterData }
          this.props.getOnboardings({ params, page: 1 })
        },
      )
    }
  }

  getOptions(type) {
    let team_id = this.state.team_id
    this.setState({
      options_type: type,
    })
    if (!this.props.onboardingOptions[type]) {
      this.props.getOnboardingOptions({ type, team_id })
    }
  }
  ownerChange(name, val) {
    this.setState({
      filterData: {
        ...this.state.filterData,
        owner_name: val,
      },
    })
    this.props.getOnboardings({ params: this.state.filterData })
  }

  onFilterChange(name, val) {
    let filterData
    if (name === 'count_order') {
      filterData = {
        ...this.state.filterData,
        [name]:
          this.state.filterData['count_order'] &&
          this.state.filterData['count_order'] === 'ASC'
            ? 'DESC'
            : this.state.filterData['count_order'] &&
              this.state.filterData['count_order'] === 'DESC'
            ? ''
            : 'ASC',
      }
    } else {
      filterData = { ...this.state.filterData, [name]: val }
    }
    filterData = {
      ...filterData,
      page: name === 'count_order' ? filterData.page : 1,
    }
    if (filterData.progress !== 'launched') {
      filterData['count_order'] = ''
    }

    this.setState({
      filterData: {
        ...filterData,
      },
    })
    if (filterData.progress !== 'map_view') {
      this.props.getOnboardings({ params: filterData })
    }
  }

  onTeamsChange(name, val, id) {
    let filterData = {
      ...this.state.filterData,
      [name]: val,
      team_id: id,
    }
    this.setState({
      filterData,
    })
    this.props.getOnboardings({ params: filterData })
  }

  toggleOnboardingBoard(type) {
    this.setState({
      openOnboarding: !this.state.openOnboarding,
    })
    // this.props.clearOnboardingError();
  }

  seeOnboardingDetail(id, location_id, status) {
    // this.props.getOnboarding({ onboarding_id: id, refresh: true });
    let pathname = location_id
      ? `/restaurants/${location_id}`
      : `/restaurants/create`
    let search = location_id ? null : `?id=${id}`
    this.props.history.push({
      pathname,
      search,
      state: {
        prevFilterData: this.state.filterData,
        prevTeamId: this.state.team_id,
        prevOptionsType: this.state.options_type,
        fromPath: '/signups',
      },
    })
  }

  goToPage(page) {
    this.setState(
      {
        filterData: {
          ...this.state.filterData,
          page: page,
        },
      },
      () => {
        this.props.getOnboardings({ params: this.state.filterData })
      },
    )
  }

  render() {
    return (
      <>
        {this.state.openOnboarding ? (
          <OnboardingIndex
            toggleBoard={this.toggleOnboardingBoard}
            type={'edit'}
          />
        ) : null}

        <Onboardings
          getOptions={this.getOptions}
          ownerChange={this.ownerChange}
          onFilterChange={this.onFilterChange}
          filterData={this.state.filterData}
          onboardingOptions={this.state.onboardingOptions}
          getOnboardingOptionsRequest={this.props.getOnboardingOptionsRequest}
          getOnboardingOptionsRequestType={
            this.props.getOnboardingOptionsRequestType
          }
          getOnboardingsRequest={this.props.getOnboardingsRequest}
          onboardings={this.state.onboardings}
          options_type={this.state.options_type}
          toggleBoard={this.toggleOnboardingBoard}
          seeOnboardingDetail={this.seeOnboardingDetail}
          goToPage={this.goToPage}
          onTeamsChange={this.onTeamsChange}
        ></Onboardings>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    profile: state.auth.profile,
    onboarding: state.onboarding,
    onboardingOptions: state.onboarding.onboardingOptions,
    onboardings: state.onboarding.onboardings,
    getOnboardingOptionsRequest: state.onboarding.getOnboardingOptionsRequest,
    getOnboardingsRequest: state.onboarding.getOnboardingsRequest,
    getOnboardingOptionsRequestType:
      state.onboarding.getOnboardingOptionsRequestType,
    selectedOnboarding: state.onboarding.selectedOnboarding,
    getOnboardingRequest: state.onboarding.getOnboardingRequest,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getOnboardingOptions: params => dispatch(getOnboardingOptions(params)),
    getOnboardings: params => dispatch(getOnboardings(params)),
    getOnboarding: params => dispatch(getOnboarding(params)),
    clearOnboardingError: () => dispatch(clearOnboardingError()),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(OnboardingsIndex)
