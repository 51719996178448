import React, { useEffect } from 'react'

import ReactSelect, { components } from 'react-select'
import NinjaPagination from '../Common/NinjaPagination'
import { BASIC_SELECT_STYLE, BASIC_SELECT_THEME } from '../../utils/constant'
import { filterFromObjectArray } from '../../utils/tools'
import SignupsTable from '../SignupsTable/SignupsTable'
import MapView from './MapView'
import { SelectItemStatus } from '../Select/index'
import { statusOptions } from '../../utils/common'

const DropdownIndicator = () => {
  return <i className='fas fa-caret-down multi-select-dropdown-indicator'></i>
}

// location 列表
let locationOptions = {}
// 当前地址信息
let locationAddress = {}

function Onboardings(props) {
  let { onboardingOptions, filterData, onboardings } = props
  let {
    brand_name,
    brand_map_view_name,
    team_name,
    location_name,
    closer_id,
    progress,
    count_order,
  } = filterData || {}
  // progress = 'map_view'
  useEffect(() => {
    return () => {
      locationOptions = {}
      locationAddress = {}
    }
  }, [])
  const renderPagination = () => {
    if (!onboardings) return null

    return (
      <NinjaPagination
        currentPage={onboardings.current_page}
        totalPages={onboardings.last_page}
        pageNeighbours={1}
        goToPage={page => props.goToPage(page)}
      />
    )
  }

  const renderTeamsFilter = () => {
    let all_options = [{ value: '', label: 'All Teams', id: '' }]

    if (
      props.getOnboardingOptionsRequest &&
      props.getOnboardingOptionsRequestType === 'teams'
    ) {
      all_options = [{ value: '', label: 'Loading...', id: '' }]
    }
    if (onboardingOptions && onboardingOptions.teams)
      onboardingOptions.teams.forEach((team, key) => {
        all_options.push({
          value: team.name,
          label: team.name,
          id: team.id,
        })
        return all_options
      })

    return (
      <ReactSelect
        value={filterFromObjectArray(all_options, team_name || '', 'value')}
        name='team_name'
        options={all_options}
        className='basic-single'
        classNamePrefix='select'
        onChange={value =>
          props.onTeamsChange('team_name', value.value, value.id)
        }
        onMenuOpen={e => props.getOptions('teams')}
        components={{ DropdownIndicator }}
        theme={BASIC_SELECT_THEME}
        styles={BASIC_SELECT_STYLE}
      />
    )
  }

  // brand Filter
  const renderBrandsFilter = () => {
    let all_options = [{ value: '', label: 'All Brands' }]
    if (
      props.getOnboardingOptionsRequest &&
      props.getOnboardingOptionsRequestType === 'brands'
    ) {
      all_options = [{ value: '', label: 'Loading...' }]
    }
    if (onboardingOptions && onboardingOptions.brands)
      onboardingOptions.brands.map((brand, key) => {
        all_options.push({
          value: brand,
          label: brand,
        })
        return all_options
      })

    return (
      <ReactSelect
        value={filterFromObjectArray(all_options, brand_name || '', 'value')}
        name='brand_name'
        options={all_options}
        // menuIsOpen={true}
        className='basic-single'
        classNamePrefix='select'
        onChange={value => props.onFilterChange('brand_name', value.value)}
        onMenuOpen={e => props.getOptions('brands')}
        components={{ DropdownIndicator }}
        theme={BASIC_SELECT_THEME}
        styles={BASIC_SELECT_STYLE}
      />
    )
  }

  // mapView brand Filter
  const renderMapViewBrandsFilter = () => {
    let all_options = [{ value: '', label: 'All Restaurant' }]

    if (
      props.getOnboardingOptionsRequest &&
      props.getOnboardingOptionsRequestType === 'brands_map_view'
    ) {
      all_options = [{ value: '', label: 'Loading...' }]
    }

    if (onboardingOptions?.brands_map_view?.length) {
      onboardingOptions?.brands_map_view?.forEach(item => {
        const obj = {
          value: item.name,
          label: item.name,
          locations: item.locations,
        }
        all_options.push(obj)
      })
    }

    return (
      <ReactSelect
        value={filterFromObjectArray(
          all_options,
          brand_map_view_name || '',
          'value',
        )}
        name='brand_map_view_name'
        options={all_options}
        className='basic-single'
        classNamePrefix='select'
        onChange={value => {
          locationAddress = {}
          locationOptions = value
          props.onFilterChange('brand_map_view_name', value.value)
        }}
        onMenuOpen={e => {
          props.getOptions('brands_map_view')
        }}
        components={{ DropdownIndicator }}
        theme={BASIC_SELECT_THEME}
        styles={BASIC_SELECT_STYLE}
      />
    )
  }

  // 地区选择filter
  const renderLocationFilter = () => {
    const brands = onboardingOptions?.brands_map_view || []
    if (!brands.length || !brand_map_view_name) return null
    const options = locationOptions?.locations?.map(item => ({
      label: item.name,
      value: item.id,
      address: item.address,
    }))
    return (
      <ReactSelect
        value={filterFromObjectArray(options, location_name || '', 'value')}
        name='location_name'
        options={options}
        placeholder='select location'
        className='basic-single'
        classNamePrefix='select'
        onChange={item => {
          const address = item.address || {}
          locationAddress = { ...address, location_id: item.value }
          props.onFilterChange('location_name', item.value)
        }}
        onMenuOpen={e => {
          // props.getOptions('brands')
        }}
        components={{ DropdownIndicator }}
        theme={BASIC_SELECT_THEME}
        styles={BASIC_SELECT_STYLE}
      />
    )
  }

  const renderClosersFilter = () => {
    let all_options = [{ value: '', label: 'All Closers' }]
    if (
      props.getOnboardingOptionsRequest &&
      props.getOnboardingOptionsRequestType === 'closers'
    ) {
      all_options = [{ value: '', label: 'Loading...' }]
    }
    if (onboardingOptions && onboardingOptions.closers)
      onboardingOptions.closers.map((closer, key) => {
        all_options.push({
          value: closer.id,
          label: closer.first_name + ' ' + closer.last_name,
        })
        return all_options
      })
    return (
      <ReactSelect
        value={filterFromObjectArray(all_options, closer_id || '', 'value')}
        name='closer_id'
        options={all_options}
        // menuIsOpen={true}
        className='basic-single'
        classNamePrefix='select'
        onChange={value => props.onFilterChange('closer_id', value.value)}
        onMenuOpen={e => props.getOptions('closers')}
        components={{ DropdownIndicator }}
        theme={BASIC_SELECT_THEME}
        styles={BASIC_SELECT_STYLE}
      />
    )
  }

  const renderStatusFilter = () => {
    let all_statuses = statusOptions
    return (
      <ReactSelect
        value={filterFromObjectArray(all_statuses, progress || '', 'value')}
        name='progress'
        options={all_statuses}
        className='basic-single'
        isSearchable={false}
        classNamePrefix='select'
        onChange={value => {
          props.onFilterChange('progress', value.value)
        }}
        components={{
          Control: ({ children, ...rest }) => {
            const item = rest.getValue()?.[0] || {}
            const isNoProgress = item.value === 'no_progress'
            return (
              <components.Control {...rest}>
                <div
                  className='rectangle-banner ml-4'
                  style={{
                    backgroundColor: isNoProgress ? 'transparent' : item.color,
                    border: isNoProgress ? `1px solid ${item.color}` : 'none',
                  }}
                ></div>
                {children}
              </components.Control>
            )
          },
          DropdownIndicator,
          Option: props => <SelectItemStatus {...props} />,
        }}
        theme={BASIC_SELECT_THEME}
        styles={{
          ...BASIC_SELECT_STYLE,
          control: styles => ({
            ...styles,
            boxShadow: 'none',
            minHeight: '36px',
            height: '36px',
            borderWidth: '2px',
            borderStyle: 'solid',
            borderColor: '#E9E9E9 !important',
          }),
          menu: styles => {
            return {
              ...styles,
              backgroundColor: '#000016',
              marginTop: -1,
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              borderLeft: '2px solid #E9E9E9',
              borderRight: '2px solid #E9E9E9',
              borderBottom: '2px solid #E9E9E9',
            }
          },
          valueContainer: styles => ({
            ...styles,
            padding: '2px 16px',
            fontWeight: 700,
          }),
        }}
      />
    )
  }
  return (
    <div className='onboardings-content'>
      <div className='onboardings-filter'>
        <div className='d-flex'>
          <div style={{ width: '192px' }} className='mr-2'>
            {renderStatusFilter()}
          </div>
          <div style={{ width: '240px' }} className='mr-2'>
            {progress === 'map_view'
              ? renderMapViewBrandsFilter()
              : renderBrandsFilter()}
          </div>

          {progress === 'map_view' && (
            <div style={{ width: '240px' }} className='mr-2'>
              {renderLocationFilter()}
            </div>
          )}
          {progress !== 'map_view' && (
            <>
              <div style={{ width: '240px' }} className='mr-2'>
                {renderTeamsFilter()}
              </div>
              <div style={{ width: '240px' }}>{renderClosersFilter()}</div>
            </>
          )}
        </div>
      </div>
      {progress === 'map_view' ? (
        <MapView address={locationAddress}></MapView>
      ) : (
        <>
          <SignupsTable
            signups={onboardings}
            seeDetail={props.seeOnboardingDetail}
            progress={progress}
            onFilterChange={props.onFilterChange}
            count_order={count_order}
          />
          <div className='dashed-line margin-top-10 margin-bottom-20'></div>
          {renderPagination()}
        </>
      )}
    </div>
  )
}

export default Onboardings
