import { FormGroup, Button, Row, Col, Label } from 'reactstrap'
import { toast } from 'react-toastify'
import Clipboard from 'clipboard'
import { formatDigitOnly } from '../../utils/digit'

// 为实现样式必须写在行内
const emailWrapStyle = {
  width: '496px',
  padding: '0 32px',
  backgroundColor: '#fff',
  borderRadius: '8px',
}

const nameStyle = {
  fontFamily: 'Roboto',
  fontWeight: 'bold',
  fontSize: '18px',
  lineHeight: '21px',
  color: '#222',
}

const positionStyle = {
  fontSize: '14px',
  lineHeight: '16px',
  color: '#888',
  marginTop: '2px',
}

const scheduleBtnStyle = {
  display: 'block',
  width: '151px',
  height: '30px',
  marginTop: '8px',
  background: '#00a5eb',
  borderRadius: '16px',
  fontSize: '12px',
  color: '#ffffff',
  lineHeight: '30px',
  textAlign: 'center',
  textDecoration: 'none',
  fontWeight: 'bold',
}

const iconStyle = {
  marginLeft: '10px',
  fontSize: '14px',
  textDecoration: 'none',
  color: '#222',
}

const iconDivStyle = {
  lineHeight: '16px',
  color: '#222',
}

const getHTMLStr = body => {
  return `<html><head><style>@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap);* { margin: 0; padding: 0; }</style></head><body>${body}</body></html>`
}

export default function MailCopy(props) {
  const { formData, phone, email } = props
  const handleCopy = type => {
    if (type === 'html') {
      new Clipboard('.copy-btn', {
        text: function (trigger) {
          const mailDom = document.querySelector('.profile-email-signature')
          const mailContent = getHTMLStr(mailDom.innerHTML)
          return mailContent
        },
      })
    } else {
      new Clipboard('.copy-btn', {
        text: function (trigger) {
          const mailDom = document.querySelector('.profile-email-signature')
          return mailDom
        },
      })
    }
    toast.success('Copy Success!')
  }

  const getMailComps = () => {
    return (
      <table
        width='100%'
        style={{
          height: '253px',
          fontFamily: 'Roboto',
          background: '#FFFFFF',
          color: '#222',
        }}
        cellPadding='0'
        cellSpacing='0'
      >
        <tbody>
          <tr>
            <td>
              <div style={nameStyle}>
                {formData.first_name} {formData.last_name}
              </div>
              <div style={positionStyle}>{formData.position}</div>
              {!!formData.call_link?.length && (
                <a
                  className='schedule-btn'
                  style={scheduleBtnStyle}
                  href={formData.call_link}
                  target='_blank'
                  rel='noreferrer'
                >
                  SCHEDULE A CALL
                </a>
              )}
              <div style={{ marginTop: '16px', ...iconDivStyle }}>
                <img
                  alt=''
                  src='https://public.rushable.io/icon-phone.png'
                  style={{ width: '16px', verticalAlign: 'middle' }}
                />
                <a style={iconStyle} href={`tel:${formatDigitOnly(phone)}`}>
                  {phone}
                </a>
              </div>
              <div style={{ margin: '8px 0', ...iconDivStyle }}>
                <img
                  alt=''
                  src='https://public.rushable.io/icon-email.png'
                  style={{ width: '16px', verticalAlign: 'middle' }}
                />
                <a style={iconStyle} href={`mailto:${email}`}>
                  {email}
                </a>
              </div>
              <div style={iconDivStyle}>
                <img
                  alt=''
                  src='https://public.rushable.io/icon-link.png'
                  style={{ width: '16px', verticalAlign: 'middle' }}
                />
                <a
                  style={iconStyle}
                  href='https://rushable.io'
                  target='_blank'
                  rel='noreferrer'
                >
                  rushable.io
                </a>
              </div>
              <div style={{ marginTop: '18px' }}>
                <img
                  src='https://public.rushable.io/Rushable-Logo.png'
                  style={{ width: '132px' }}
                  alt=''
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    )
  }
  return (
    <>
      <hr className='cross-line' />
      <Row form>
        <Col>
          <FormGroup>
            <Label>EMAIL SIGNATURE</Label>
            <div
              id='profile-email-signature'
              className='profile-email-signature'
              style={emailWrapStyle}
            >
              {getMailComps()}
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row form className='copy-wrap'>
        <Button className='copy-btn' onClick={() => handleCopy('txt')}>
          DIRECT COPY
        </Button>
        <Button className='copy-btn' onClick={() => handleCopy('html')}>
          HTML COPY
        </Button>
      </Row>
      <hr className='cross-line' />
    </>
  )
}
