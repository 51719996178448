import ReactModal from 'react-modal'
import { CircleClose } from '../Icons'

const Modal = ({ isOpen, handleClose, children, className }) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={handleClose}
      className='position-relative'
    >
      <CircleClose
        className='position-sticky close-button'
        handleClick={handleClose}
      />
      <div className='modal-content-container'>{children}</div>
    </ReactModal>
  )
}

if (typeof window !== 'undefined') {
  ReactModal.setAppElement('body')
}

export default Modal
