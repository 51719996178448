import React, { useState } from 'react'
import { FormGroup, Label, Input, Row, Col } from 'reactstrap'
import {
  onPhoneKeyDown,
  onNumericInputKeydown,
  formatCurrency,
} from '../../../utils/digit'
import moment from 'moment'
import ReactSelect from 'react-select'

import { filterFromObjectArray } from '../../../utils/tools'
import languageOptions from '../../../utils/languageOptions'
import DropdownIndicator from '../../Common/DropdownIndicator'
import { BASIC_SELECT_STYLE, BASIC_SELECT_THEME, currentStepMap2 } from '../../../utils/constant'

function SignupSettingForm(props) {
  const [priceStatus, setPriceStatus] = useState(false)
  const { formData, onChange, canEditSignupfee, canUpdate, status, isMaster } =
    props
  let { signup_fee, doordash_dispatch_fee, payment_schedule, language, current_step } =
    formData
  let signupFeeStr = signup_fee ? '$' + formatCurrency(signup_fee, 0) : ''
  let typeColor = 'text-orange'
  let typeStr = 'Scheduled'
  let timeStr = '90 days after signup'
  let dispatchFeeStr = priceStatus ? String(doordash_dispatch_fee) : `$${formatCurrency(doordash_dispatch_fee)}`


  if (payment_schedule) {
    let { signup_at, paid, delay, active } = payment_schedule
    if (delay === 0 || delay) timeStr = delay + ' days after signup'
    if (signup_at) {
      timeStr = moment(signup_at)
        .add(delay ? parseInt(delay) : 0, 'days')
        .format('MM/DD/YYYY')
      if (paid) {
        typeColor = 'text-green'
        typeStr = 'Charged'
      }
    }
    if (active === 0) typeStr = 'Canceled'
  }
  if (
    !signup_fee ||
    signup_fee === 0 ||
    signup_fee === '0' ||
    signup_fee === '0.00'
  ) {
    typeColor = 'text-green'
    typeStr = 'Void'
    timeStr = 'N/A'
  }
  const onInputChange = e => {
    let val = ''
    val = e.target.value.replace('$', '')
    onChange(e.target.name, val)
  }

  const onMouseOut = e => {
    if (e.target.name === 'signup_fee') {
      if (!signup_fee) onChange('signup_fee', 0)
    }
    if (e.target.name === 'doordash_dispatch_fee') {
      if (!doordash_dispatch_fee) onChange('doordash_dispatch_fee', 0)
    }
  }

	const handleSelect = (price) => {
    onChange('doordash_dispatch_fee',  price)
    setPriceStatus(true)
	}

  const handleSelectFocus = (price) => {
    setPriceStatus(true)
		if (price[0] === '$' && Number(price.substr(1)) > 0) {
      onChange('doordash_dispatch_fee', Number(price.substr(1)))
			return
		}
    onChange('doordash_dispatch_fee', '0.00')
	}

  const handleSelectBlur = (price) => {
    setPriceStatus(false)
		if (price && Number(price) > 0) {
      onChange('doordash_dispatch_fee', `${formatCurrency(price)}`)
			return
		}
    onChange('doordash_dispatch_fee', '0.00')
	}

  const renderLang = () => {
    let selectedLang = language
      ? filterFromObjectArray(languageOptions, language, 'value')
      : ''

    return (
      <ReactSelect
        value={selectedLang}
        name='selected_lang'
        options={languageOptions}
        placeholder={'Select one'}
        className={'basic-single' + (!canUpdate ? ' disabled' : '')}
        classNamePrefix='select'
        onChange={e => onChange('language', e.value)}
        components={{ DropdownIndicator }}
        theme={BASIC_SELECT_THEME}
        styles={BASIC_SELECT_STYLE}
        required
      />
    )
  }

  return (
    <div className='board-col-primary'>
      <Row form>
        <Col>
          <Label>SIGNUP SETTING</Label>
        </Col>
      </Row>
      <Row form>
        <Col md={3}>
          <FormGroup>
            <Input
              type='text'
              name='signup_fee'
              placeholder={''}
              onKeyDown={onPhoneKeyDown}
              value={signupFeeStr}
              onChange={e => onInputChange(e)}
              onBlurCapture={e => onMouseOut(e)}
              disabled={!canEditSignupfee}
            />
          </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup>
            <Input
              type='text'
              name='scheduled'
              className={typeColor}
              placeholder={'Coupon code'}
              value={typeStr}
              disabled={true}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Input
              type='text'
              name='after signup'
              placeholder={''}
              value={timeStr}
              disabled={true}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col md={3}>
          <FormGroup>
            <Input
              type='text'
              name='doordash_dispatch_fee'
              placeholder={''}
              onKeyDown={onNumericInputKeydown}
              value={dispatchFeeStr}
              onChange={e => handleSelect(e.target.value)}
              onFocus={e => handleSelectFocus(e.target.value)}
              onBlur={e => handleSelectBlur(e.target.value)}
              disabled={!isMaster} // same permission as signup fee
            />
          </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup>
            <Input
              type='text'
              name='dispatchFee'
              placeholder={'Dispatch'}
              value={'Dispatch'}
              disabled={true}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>{renderLang()}</FormGroup>
        </Col>
      </Row>
    </div>
  )
}

export default SignupSettingForm
