import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getAccess } from '../../store/actions/support'
import GoBackBtn from './SupportHeader/GoBackBtn'
import HeaderCenter from './SupportHeader/HeaderCenter'
import RequireAccessBtn from './SupportHeader/RequireAccessBtn'
import SupportSidebar from './SupportSidebar/index'
import SupportCancel from './SupportCancel'
import { DASHBOARD_ADMIN_URL } from '../../utils/constant'
import { useLocation, useParams } from 'react-router'

function Support(props) {
  let {
    supportData = {},
    getSupportRequest,
    children,
    goToPath,
    activeLink,
    location,
  } = props
  let access_time = '-'
  const dispatch = useDispatch()
  const [getAccessRequest, setGetAccessRequest] = useState(false)
  const [getAccessError, setGetAccessError] = useState('')
  const search = useLocation().search ?? window.location.search
  const { supportId } = useParams()
  const [signupId, setSignupId] = useState(null)

  const signup_form = supportData?.signup_form ?? {}
  const { onboarding } = signup_form

  useEffect(() => {
    if (supportId === 'create' && search) {
      setSignupId(new URLSearchParams(search).get('id'))
    }
  }, [search, supportId])

  const redirectToStoreDashboard = () => {
    let params = {
      location_id: supportId,
      access_code: 1234,
      refresh: true,
    }

    setGetAccessRequest(true)
    setGetAccessError('')
    dispatch(getAccess(params))
      .then(({ data }) => {
        if (data.success) {
          setGetAccessRequest(false)
          // open window
          const { brand_id, location_id } = supportData
          window.open(
            `${DASHBOARD_ADMIN_URL}/brand/${brand_id}/location/${location_id}`,
          )
        } else {
          setGetAccessRequest(false)
          setGetAccessError(data.message)
        }
      })
      .catch(error => {
        let resData = error.response
          ? error.response.data
          : { message: error.message }
        setGetAccessRequest(false)
        setGetAccessError(resData.message)
      })
  }

  if (getSupportRequest) {
    return (
      <div className='support-container'>
        <div className='w-100 text-center'>
          {' '}
          <i className='fad fa-spinner fa-spin mr-2'></i>Loading...
        </div>
      </div>
    )
  }
  return (
    <div className='support-container'>
      <div className='support-header'>
        <div className='support-header-leader'>
          <GoBackBtn goBack={props.goBack} />
        </div>
        <div className='support-header-center'>
          <HeaderCenter supportData={supportData} access_time={access_time} />
        </div>
        <div className='support-header-trail'>
          {supportData?.location_id ? (
            <RequireAccessBtn
              requesting={getAccessRequest}
              redirectToStoreDashboard={redirectToStoreDashboard}
            />
          ) : null}
          {getAccessError ? (
            <div className='text-danger f-12 text-center'>{getAccessError}</div>
          ) : null}
        </div>
      </div>
      <div className='support-body'>
        <div className='support-sidebar'>
          <SupportSidebar
            location={location}
            supportData={supportData}
            goToPath={goToPath}
            activeLink={activeLink}
            supportId={supportId}
            signupId={signupId}
          />
          {activeLink.includes(`${supportId}/basic-info`) && 
            (onboarding?.status === 'signup_done' 
              || 
            (onboarding?.status === 'canceled' && onboarding?.onboarding_cancellation)) ? (
            <SupportCancel onboarding={onboarding} />
          ) : null}
        </div>
        <div className='support-content'>{children}</div>
      </div>
    </div>
  )
}

export default Support
