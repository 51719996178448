import React from 'react'
import {
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap'
import classnames from 'classnames'
import SingleDocUploader from '../../../Common/SingleDocUploader'
import OpenHoursForm from '../../../OpenHours/OpenHoursForm'

function Import(props) {
  let {
    onMenuUpload,
    fileCount,
    menuData,
    modifier_file_name,
    uploadMenuRequest,
  } = props

  const renderMenuUploads = () => {
    return menuData.map((item, key) => {
      if (
        (item.count === fileCount && !onMenuUpload) ||
        item.count === 0 ||
        !item.count
      ) {
        return null
      }

      return (
        <div key={`upload-zone-${key}`}>
          {' '}
          <div className='dashed-line' key={`dashline-${key}`}></div>
          <div className='support-upload' key={`upload-${key}`}>
            <div className='support-upload-left'>
              <span className='head'>{item.count ?? ''}</span>
            </div>
            <div
              className={classnames('support-upload-right', {
                inactive: fileCount > item.count,
              })}
            >
              <Input
                type='text'
                name='modifiers-title'
                className={classnames('form-control', {
                  inactive: fileCount > item.count,
                })}
                value={item.menu_collection_name ?? ''}
                onChange={e =>
                  props.onFormChange(
                    'menu_collection_name',
                    e.target.value,
                    item.count,
                  )
                }
                disabled={fileCount > item.count}
                required
              />
              <SingleDocUploader
                onChange={props.onFormChange}
                placeHolderText={'select menu csv file'}
                count={item.count}
                disabled={fileCount > item.count}
                fileName={item.csv ? item.csv.name ?? '' : ''}
              />

              <div className='two-btns-row mt-0'>
                <Button
                  type='button'
                  color='plan'
                  className={classnames(
                    'select-form-primary d-inline two-btns-btn ',
                    {
                      selected: item.availability === 'same_as_store',
                      inactive: fileCount > item.count,
                    },
                  )}
                  onClick={e =>
                    props.toggleAvailability('same_as_store', item.count)
                  }
                >
                  <span className='name'>Same as Store Hours</span>
                </Button>
                <Button
                  type='button'
                  color='plan'
                  className={classnames(
                    'select-form-primary d-inline two-btns-btn ',
                    {
                      selected: item.availability === 'customize',
                      inactive: fileCount > item.count,
                    },
                  )}
                  onClick={e =>
                    props.toggleAvailability('customize', item.count)
                  }
                >
                  <span className='name'>Custom Schedule</span>
                </Button>
              </div>

              {item.availability === 'same_as_store' ? null : (
                <div className='upload-openhours'>
                  {' '}
                  <OpenHoursForm
                    days={item.days}
                    addHours={props.addHours}
                    onHourChange={props.onHourChange}
                    setClosed={props.setClosed}
                    removeHours={props.removeHours}
                    disabled={fileCount > item.count}
                    hiddenAlert={true}
                    count={item.count}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )
    })
  }
  return (
    <>
      {' '}
      <div className='dashed-line'></div>
      <div className='support-upload'>
        <div className='support-upload-left'>
          <span className='head'>M</span>
        </div>
        <div className='support-upload-right'>
          <Input
            type='text'
            name='modifiers-title'
            className='form-control'
            disabled={true}
            value={'Modifiers'}
            required
          />
          <SingleDocUploader
            onChange={props.onFormChange}
            placeHolderText={'select modifier csv file'}
            count={fileCount}
            disabled={fileCount > 0}
            fileName={modifier_file_name ?? ''}
          />
        </div>
      </div>
      {/* modifier not upload? */}
      {fileCount < 1 ? (
        <>
          {' '}
          <div className='dashed-line'></div>
          <div className='support-upload px-0 text-center'>
            <label>
              Modifier upload needed prior to create new Menu Collection
            </label>
          </div>
        </>
      ) : null}
      {/* render menu upload */}
      {renderMenuUploads()}
      {/* +menu upload */}
      <div className='dashed-line'></div>
      {!onMenuUpload ? (
        <div className='support-upload px-0'>
          <Button
            color='link'
            className='w-100 bold'
            onClick={e => props.toggleOnUpload(true)}
          >
            + New Menu Collection
          </Button>
        </div>
      ) : (
        <div className='support-upload px-0'>
          <Button
            color='primary'
            type='submit'
            className='btn-primary-upload'
            block
            disabled={uploadMenuRequest}
            onClick={e => props.uploadMenu()}
          >
            IMPORT NOW
          </Button>
        </div>
      )}
      <Modal
        isOpen={props.openErrorDialog}
        toggle={props.toggleErrorDialog}
        className='sort-modal'
        centered={true}
      >
        <ModalHeader toggle={props.toggle} className='text-red'>
          OOPS, ERROR FOUND
        </ModalHeader>
        <ModalBody>
          {' '}
          <div className='warning-info danger'>{props.errorMsg}</div>
        </ModalBody>
        <ModalFooter>
          {' '}
          <Button
            color='thirdary '
            className='btn-thirdary-upload w-100'
            block
            onClick={e => props.toggleErrorDialog(false)}
          >
            OKAY, I WILL FIX IT
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}
export default Import
