export const depositOptions = (rushableTeam = false) => {
  const options = [
    {
      send_tablet: true,
      signup_fee: 199,
      value: 199,
      label: '$199 with Tablet',
    },
    {
      send_tablet: false,
      signup_fee: 99,
      value: 99,
      label: '$99 and waive tablet',
    },
  ]

  if (rushableTeam) {
    options.push({ send_tablet: false, signup_fee: 0, value: 0, label: '$0' })
  }
  return options
}

export default depositOptions
