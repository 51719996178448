import React from 'react'
import classnames from 'classnames'

const StatusPopUp = props => {
  const { nowStatus } = props

  let { open, signup_pop_arr, onboard_pop_arr } = props

  let render_arr =
    nowStatus === 'draft' || nowStatus === 'signup_up'
      ? signup_pop_arr
      : onboard_pop_arr

  return (
    <div
      className='status-popup'
      style={{ visibility: open ? 'visible' : 'hidden' }}
    >
      <div className='status-popup-body'>
        {render_arr.map((item, key) => {
          return (
            <div className='status-popup-line' key={key}>
              <div className='popup-line-step'>
                <span
                  className={classnames(
                    'popup-line-text ' +
                      (item.checked ? item.checked_color ?? 'text-white' : ''),
                    {},
                  )}
                >
                  {item.text}
                </span>

                {item.sub_text ? (
                  <span
                    className={
                      'popup-line-subtext ' +
                      (item.sub_text_color ? item.sub_text_color : '')
                    }
                  >
                    {' '}
                    {item.sub_text}
                  </span>
                ) : null}
              </div>
              <span
                className={classnames(
                  'popup-line-check ' +
                    (item.checked ? item.checked_color : ''),
                  {
                    checked: item.checked,
                  },
                )}
              >
                {' '}
                <i className='far fa-check'></i>
              </span>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default StatusPopUp
StatusPopUp.propTypes = {}
