import React from 'react'
import { FormGroup, Input, Row, Col } from 'reactstrap'
import {
  BASIC_SELECT_STYLE,
  BASIC_SELECT_THEME,
} from '../../../../utils/constant'
import ReactSelect from 'react-select'
import DropdownIndicator from '../../../Common/DropdownIndicator'
import { DOMAIN } from '../../../../utils/constant'

function OnboardingSelectRow(props) {
  let {
    title,
    options,
    onChange,
    value,
    changeName,
    onMenuOpen,
    disabled,
    isSuccess,
    withHeader,
    imgUrl,
    leftCn,
  } = props
  const renderSelect = () => {
    return (
      <ReactSelect
        value={value || ''}
        options={options}
        placeholder={'Select one'}
        className={
          'basic-single ' +
          (isSuccess ? 'basic-single-success ' : ' ') +
          (withHeader ? 'with-header ' : '')
        }
        classNamePrefix='select'
        onChange={value => onChange(changeName, value.value)}
        isDisabled={disabled}
        onMenuOpen={e => (onMenuOpen ? onMenuOpen() : null)}
        components={{ DropdownIndicator }}
        theme={BASIC_SELECT_THEME}
        styles={BASIC_SELECT_STYLE}
        required
      />
    )
  }
  return (
    <Row form>
      <Col className={leftCn}>
        <FormGroup>
          <Input
            type='text'
            name='select-title'
            value={title ?? ''}
            disabled={true}
          />
        </FormGroup>
      </Col>
      <Col>
        <FormGroup>
          {' '}
          <div className='select-with-header'>
            {renderSelect()}{' '}
            {withHeader ? (
              <span className='header'>
                {imgUrl ? (
                  <img
                    alt=''
                    src={DOMAIN + imgUrl}
                    className='head-sculpture'
                  />
                ) : (
                  <i className='fas fa-user-circle'></i>
                )}
              </span>
            ) : null}
          </div>
        </FormGroup>
      </Col>
    </Row>
  )
}
export default OnboardingSelectRow
