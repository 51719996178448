import React, { useState, useEffect } from 'react'
import cn from 'classnames'

const Table = ({
  columns,
  data,
  handleRowClicked,
  isBoarded = false,
  autoHeight = false,
  tableFooter,
  className,
  tableHeaderTextUppercase = true,
  availableShow = true,
}) => {
  const [sortIndex, setSortIndex] = useState(0)
  const [sortOrder, setSortOrder] = useState(0)
  const [tableData, setTableData] = useState([])
  useEffect(() => {
    setTableData(data)
  }, [data])
  useEffect(() => {
    if (sortOrder == 0) {
      return
    }
    setTableData(
      data
        .map(each => each)
        .sort(
          (a, b) =>
            sortOrder *
            (a[columns[sortIndex].key] < b[columns[sortIndex].key] ? 1 : -1),
        ),
    )
  }, [sortIndex, sortOrder])

  const setSort = index => {
    if (index === sortIndex) {
      setSortOrder(((sortOrder + 2) % 3) - 1)
    } else {
      setSortIndex(index)
      setSortOrder(1)
    }
  }

  return (
    <div
      style={{ minHeight: autoHeight && 'auto' }}
      className={cn(
        'overflow-y-auto support-table primary-table',
        tableFooter,
        className,
      )}
    >
      <div
        style={{ minHeight: autoHeight && 'auto' }}
        className={cn('table-responsive', isBoarded && 'border-table')}
      >
        <table className='w-100 table table-hover'>
          <thead>
            <tr>
              {columns.map((column, index) => (
                <th key={index}>
                  <div
                    className={cn(
                      tableHeaderTextUppercase && 'text-uppercase',
                      column.className,
                    )}
                    onClick={() => column.sortable && setSort(index)}
                  >
                    {column.name}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {columns.map((column, index) => (
                  <td
                    key={index}
                    className={column.className}
                    onClick={() => {
                      handleRowClicked(row)
                    }}
                  >
                    {column.custom
                      ? column.custom(
                          row[column.key],
                          Object.assign({ id: rowIndex }, row),
                        )
                      : row[column.key]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        {tableData.length === 0 && availableShow && (
          <div
            style={{ marginTop: '280px' }}
            className='text-center text-24px text-gray-70'
          >
            No Data is available
          </div>
        )}
        <div>{tableFooter}</div>
      </div>
    </div>
  )
}

export default Table
