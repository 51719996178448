import React from 'react'
import { Card, Table } from 'reactstrap'
import SignupsTableBody from './SignupsTableBody'
import SignupsTableHead from './SignupsTableHead'

const SignupsTable = ({
  isLoading,
  signups,
  seeDetail,
  mode,
  progress,
  onFilterChange,
  count_order,
}) => {
  const isShowAttentionColumn = progress === 'onboarding' ? true : false

  return (
    <Card className='primary-table support-table'>
      <Table responsive hover>
        <SignupsTableHead
          mode={mode}
          progress={progress}
          isShowAttentionColumn={isShowAttentionColumn}
          onFilterChange={onFilterChange}
          count_order={count_order}
        />
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan='12'>
                <i className='fad fa-spinner fa-spin mr-2'></i> Loading
              </td>
            </tr>
          ) : null}
          <SignupsTableBody
            isShowAttentionColumn={isShowAttentionColumn}
            isLoading={true}
            progress={progress}
            signups={signups}
            seeDetail={seeDetail}
            mode={mode}
          />
          <tr>
            <td colSpan='12' className='p-0 ponit-events-none'></td>
          </tr>
        </tbody>
      </Table>
    </Card>
  )
}

export default SignupsTable
