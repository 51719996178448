import React, { useState } from 'react'
import moment from 'moment'
import StatusBar from './StatusBar'
import StatusPopUp from './StatusPopUp'
import { getPlanStatus } from '../../utils/digit'
import { currentStepMap1, currentStepMap2 } from '../../utils/constant'

const StatusTd = props => {
  const [modalOpen, setModalOpen] = useState(false)

  const onMouseOver = () => {
    setModalOpen(true)
  }

  const onMouseOut = () => {
    setModalOpen(false)
  }

  let { signup_form = {}, onboarding = {} } = props
  const { menu_link, onboarding_menus, status } = onboarding
  const MenuSubmitted =
    menu_link ||
    (onboarding_menus && onboarding_menus.length && onboarding_menus.length > 0)

  const nowStatusFn = () => {
    const { location } = signup_form
    const onboarding_done_at = onboarding?.done_at
    if (location && onboarding_done_at) {
      const { stripe_subscription_ends_at = '' } = location
      if (!stripe_subscription_ends_at) return 'live'
      if (moment().isBefore(stripe_subscription_ends_at)) return 'canceling'
      if (moment().isAfter(stripe_subscription_ends_at)) return 'canceled'
    }
    if (status === 'draft') return 'draft'
    if (status === 'signup_ready') return 'signup_up'
    if (status === 'signup_done') return 'onboarding'
    if (status === 'canceled') return 'canceled'
  }
  const doneColorRender = () => {
    const { location = {} } = signup_form
    if (status === 'canceled') {
      return 'red'
    }
    if (location) {
      const { stripe_subscription_ends_at = '' } = location
      if (!stripe_subscription_ends_at) {
        return 'green'
      }
      if (moment().isBefore(stripe_subscription_ends_at)) {
        return 'yellow'
      }
      if (moment().isAfter(stripe_subscription_ends_at)) {
        return 'red'
      }
    }
  }
  let current_step = signup_form ? signup_form.current_step ?? 0 : 0
  // let current_step = 'completed'
  let plan_name = getPlanStatus(
    signup_form && signup_form.rushable_plan_id
      ? signup_form.rushable_plan_id
      : '',
    'name',
    true,
  )
  let verifcationCheck =
    signup_form &&
      signup_form.crm_representative &&
      signup_form.crm_representative.phone_verified_at !== null
      ? true
      : false
  const { hello_process_id } = signup_form
  const currentStepMap =
    hello_process_id === 2 ? currentStepMap2 : currentStepMap1
  let completeWelcomeCall = false
  let menuWorkassigned = false
  let menuWorkSentForApproval = false
  let menuWorkApproval = false
  let shippedOut = false
  let deliveredCheck = false
  let websiteConfiguration = false

  let gglUpdated = false
  let statusCheck = false
  let isCanceled = false

  if (onboarding) {
    let {
      complete_welcome_call,
      menu_faq,
      menu_work_sent_for_approval,
      menu_work_approval,
      shipped_out,
      delivered,
      website_configuration,
      google_listing_updated,
      status,
    } = onboarding
    isCanceled = status ? (status === 'canceled' ? true : false) : false
    statusCheck = status ? (status === 'done' ? true : false) : false
    completeWelcomeCall = (statusCheck || complete_welcome_call) ?? false
    menuWorkassigned = (statusCheck || menu_faq) ?? false
    menuWorkSentForApproval =
      (statusCheck || menu_work_sent_for_approval) ?? false
    menuWorkApproval = (statusCheck || menu_work_approval) ?? false
    shippedOut = (statusCheck || shipped_out) ?? false
    deliveredCheck = (statusCheck || delivered) ?? false
    websiteConfiguration = (statusCheck || website_configuration) ?? false

    gglUpdated = (statusCheck || google_listing_updated) ?? false
  }

  let status_arr = [
    {
      top_filled: verifcationCheck,
      bottom_filled: currentStepMap[current_step] > 1,
      filled_color: null,
    },
    {
      top_filled: currentStepMap[current_step] > 2,
      bottom_filled: currentStepMap[current_step] > 3,
      filled_color: null,
    },
    {
      top_filled: currentStepMap[current_step] > 4,
      bottom_filled: !!MenuSubmitted,
      filled_color: null,
    },
    {
      top_filled: currentStepMap[current_step] > 5,
      bottom_filled: null,
      filled_color: 'orange',
    },
    {
      top_filled:
        hello_process_id === 1
          ? currentStepMap[current_step] > 7
          : currentStepMap[current_step] > 6,
      bottom_filled:
        hello_process_id === 1
          ? currentStepMap[current_step] > 10
          : currentStepMap[current_step] > 9,
      filled_color: null,
    },
    {
      top_filled: currentStepMap[current_step] > 10,
      bottom_filled: null,
      filled_color: currentStepMap[current_step] === 11 ? 'purple' : 'red',
    },
    {
      top_filled: completeWelcomeCall,
      bottom_filled: menuWorkassigned,
      filled_color: null,
    },
    {
      top_filled: menuWorkSentForApproval,
      bottom_filled: menuWorkApproval,
      filled_color: null,
    },
    {
      top_filled: shippedOut,
      bottom_filled: deliveredCheck,
      filled_color: null,
    },
    {
      top_filled: websiteConfiguration,
      bottom_filled: gglUpdated,
      filled_color: null,
    },
    {
      top_filled: statusCheck || isCanceled,
      bottom_filled: null,
      filled_color: doneColorRender(),
    },
  ]

  let signup_pop_arr = [
    {
      checked: verifcationCheck,
      text: 'User account created',
      checked_color: null,
    },
    {
      checked: currentStepMap[current_step] > 1,
      text: 'Location information confirmed',
      checked_color: null,
    },
    {
      checked: currentStepMap[current_step] > 2,
      text: 'Payment processing acknowledged',
      checked_color: null,
    },
    {
      checked: currentStepMap[current_step] > 3,
      text: 'Privacy and Terms display approved',
      checked_color: null,
    },
    {
      checked: currentStepMap[current_step] > 4,
      text: 'Preference Q&A answered',
      checked_color: null,
    },
    {
      checked: !!MenuSubmitted,
      text: 'Menu submitted',
      checked_color: null,
    },
    {
      checked: currentStepMap[current_step] > 5,
      text: 'Subscription confirmed',
      sub_text: currentStepMap[current_step] > 5 ? plan_name : '',
      checked_color: 'orange',
      sub_text_color: '',
    },
    {
      checked:
        hello_process_id === 1
          ? currentStepMap[current_step] > 7
          : currentStepMap[current_step] > 6,
      text: 'Bank account for payout provided',
      checked_color: null,
    },
    {
      checked:
        hello_process_id === 1
          ? currentStepMap[current_step] > 10
          : currentStepMap[current_step] > 9,
      text: 'Business verification completed',
      checked_color: null,
    },
    {
      checked: currentStepMap[current_step] > 10,
      text: 'Signup Done',
      sub_text:
        current_step === 9
          ? 'Failed'
          : current_step === 10
            ? 'Verified'
            : current_step === 8
              ? 'Pending'
              : null,
      checked_color: 'purple',
      sub_text_color: current_step === 11 ? 'red' : '',
    },
  ]

  let onboard_pop_arr = [
    {
      checked: completeWelcomeCall,
      text: 'Welcome call completed',
      checked_color: null,
    },
    {
      checked: menuWorkassigned,
      text: 'Menu Q&A',
      checked_color: null,
    },
    {
      checked: menuWorkSentForApproval,
      text: 'Menu work sent for approval',
      checked_color: null,
    },
    {
      checked: menuWorkApproval,
      text: 'Menu work approved',
      checked_color: null,
    },
    { checked: shippedOut, text: 'Tablet shipped', checked_color: null },
    {
      checked: deliveredCheck,
      text: 'Tablet delivered',
      checked_color: null,
    },
    {
      checked: websiteConfiguration,
      text: 'Website & domain configured',
      checked_color: null,
    },
    {
      checked: gglUpdated,
      text: 'Google listing updated',
      checked_color: null,
    },

    {
      checked: statusCheck || isCanceled,
      text: 'Onboarded',
      checked_color: doneColorRender(),
    },
  ]
  return (
    <div
      className='table-status-td-content'
      onMouseOver={e => onMouseOver()}
      onMouseOut={e => onMouseOut()}
    >
      <StatusBar status_arr={status_arr} nowStatus={nowStatusFn()} />
      <StatusPopUp
        open={modalOpen}
        signup_pop_arr={signup_pop_arr}
        onboard_pop_arr={onboard_pop_arr}
        nowStatus={nowStatusFn()}
      />
    </div>
  )
}

export default StatusTd
StatusTd.propTypes = {}
