import React, { useEffect, useState } from 'react'
import { Button } from 'reactstrap'
import { useParams } from 'react-router-dom'
import moment from 'moment'

import { getLeaderDetail } from '../../store/actions/leaderboard'

import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { DefaultAvatar, Calendar } from '../../components/Icons'
import LeftSideBar from './detail/LeftSideBar'
import RightSideBar from './detail/RightSideBar'
import { DOMAIN } from '../../utils/constant'
import Card from '../../components/Card'

import Loading from '../../components/Loading'

const LeaderboardDetail = () => {
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [type, setType] = useState('month')
  const [date, setDate] = useState(moment())
  const [displayDateString, setDisplayDateString] = useState('')
  const { leaderId } = useParams()
  const dispatch = useDispatch()
  const leaderboard = useSelector(state => state.leaderboard)
  const history = useHistory()
  const leaderboard_detail = leaderboard.leaderboard_detail
  const canceledOnboardingShow = leaderboard_detail?.canceled_onboarding?.data.length > 0
  const locationState = history.location.state

  const getDateString = (p_type, p_date) => {
    return p_type === 'year'
      ? p_date.format('YYYY')
      : p_type === 'month'
      ? `${p_date.format('MMMM, YYYY')}`
      : `Q${p_date.quarter()}, ${p_date.year()}`
  }

  useEffect(() => {
    let start_date = moment().startOf('month').startOf('day')
    let end_date = moment().endOf('month').startOf('day')
    setDisplayDateString(getDateString('month', moment()))
    if (locationState?.date) {
      start_date = moment(locationState?.date).startOf(locationState?.type)
      end_date = moment(locationState?.date).endOf(locationState?.type)
      setDisplayDateString(
        getDateString(locationState?.type, moment(locationState?.date)),
      )
      setType(locationState?.type)
      setDate(locationState?.date)
    }

    setStartDate(start_date)
    setEndDate(end_date)
    fetchLeaderboardDetail(start_date, end_date)
  }, [locationState])

  const fetchLeaderboardDetail = (param0, param1, page = 1, type) => {
    let params = {
      begin_time: param0.format('YYYY-MM-DD HH:mm:ss'),
      end_time: param1.format('YYYY-MM-DD HH:mm:ss'),
    }
    if (type === 'onboarding') params.page = page
    if (type === 'canceled_onboarding') params.canceled_page = page
    dispatch(getLeaderDetail(leaderId, params))
  }

  const pageHandler = (no, type) => {
    fetchLeaderboardDetail(startDate, endDate, no, type)
  }

  const seeSignupDetail = onboarding => {
    if (!onboarding) return
    const { location_id = '' } = onboarding?.status
    const currentPath = `/leaderboard/${leaderId}`
    const location = {
      pathname: location_id
        ? `/restaurants/${location_id}`
        : `/restaurants/create/signup-form`,
      state: {
        fromPath: currentPath,
        type,
        date,
      },
    }
    history.push(location)
  }

  const goBack = () => {
    const location = {
      pathname: '/leaderboard',
      state: {
        ...locationState,
      },
    }
    history.push(location)
  }

  return (
    <div className='container py-24px'>
      {Object.keys(leaderboard_detail).length > 0 ? (
        <div>
          <div className='header-grid'>
            <Button
              outline
              className='go-back-btn'
              onClick={goBack}
            >
              GO BACK
            </Button>
            <div className='d-flex align-items-center justify-content-center'>
              <img
                alt=''
                className='avatar-image mr-15px'
                src={DOMAIN + leaderboard_detail?.crm_user?.profile_photo}
              />
              <span className='text-32px font-weight-bold'>
                {leaderboard_detail?.crm_user?.user?.first_name}{' '}
                {leaderboard_detail?.crm_user?.user?.last_name}
              </span>
            </div>
            <div className='d-flex align-items-center justify-content-end'>
              <Card
                label={leaderboard_detail?.crm_user?.crm_role?.display_name}
                className='mr-2'
              >
                <DefaultAvatar />
              </Card>
              {displayDateString && (
                <Card label={displayDateString} className='mr-2'>
                  <Calendar />
                </Card>
              )}
            </div>
          </div>
          {leaderboard.get_leaderboard_detail_request ? (
            <Loading />
          ) : (
            <div className='d-flex pt-30px mt-28px'>
              <div className='w-360px mr-6'>
                <LeftSideBar
                  canceledOnboardingShow={canceledOnboardingShow}
                  totalSignup={leaderboard_detail?.total_signup_bonus}
                  touchPoint={leaderboard_detail?.touch_point}
                  tierBonusCorrection={leaderboard_detail?.tier_bonus_correction}
                />
              </div>
              <div className='w-100'>
                <RightSideBar
                  canceledOnboardingShow={canceledOnboardingShow}
                  onboarding={leaderboard_detail?.onboarding}
                  canceledOnboarding={leaderboard_detail?.canceled_onboarding}
                  pageHandler={pageHandler}
                  seeSignupDetail={seeSignupDetail}
                />
              </div>
            </div>
          )}
        </div>
      ) : null}
    </div>
  )
}

export default LeaderboardDetail
