import React from 'react'
import cn from 'classnames'
import { currencyFormatter } from '../../../utils/digit'
const currencyOptions = {
  significantDigits: 0,
  thousandsSeparator: ',',
  decimalSeparator: '.',
  symbol: '$',
}
/**
 * Helper method for creating a range of numbers
 * range(1, 5) => [1, 2, 3, 4, 5]
 */
const range = (from, to, step = 1) => {
  let i = from
  const range = []

  while (i <= to) {
    range.push(i)
    i += step
  }

  return range
}
const fetchNumbers = (totalNums, currentIndex, neighbours, totalBlocks) => {
  if (totalNums > totalBlocks) {
    const startPage = Math.max(0, currentIndex - neighbours)
    let newPage = startPage
    let list = [startPage]
    while (newPage >= 0 && list.length < totalBlocks) {
      list.push(newPage + 1)
      newPage += 1
    }

    return [...list]
  }

  return range(0, totalNums)
}
const TouchPointTable = ({ className, touchPoint }) => {
  const signupAttribute = touchPoint?.signup_amount
  const total =
    parseInt(touchPoint?.data[0]?.email_quantity) +
    parseInt(touchPoint?.data[0]?.call_quantity) +
    parseInt(touchPoint?.data[0]?.text_quantity) +
    parseInt(touchPoint?.data[0]?.social_quantity) +
    parseInt(touchPoint?.data[0]?.demo_quantity)
  let tierReachedIndex = 0
  let percent = '0'
  let showBlocks = []
  Object.keys(touchPoint?.tier_fee_list).forEach((item, index) => {
    const currentAttribute = parseInt(item)
    const nextAttribute = parseInt(
      Object.keys(touchPoint?.tier_fee_list)[index + 1],
    )
    const lastAttribute = parseInt(
      Object.keys(touchPoint?.tier_fee_list)[
        Object.keys(touchPoint?.tier_fee_list).length - 1
      ],
    )
    const isPutLastPeriod =
      signupAttribute >= lastAttribute && lastAttribute === nextAttribute
    const isShowFlag =
      (signupAttribute >= currentAttribute &&
        nextAttribute > signupAttribute) ||
      isPutLastPeriod
    if (isShowFlag) {
      tierReachedIndex = index
      percent = !isPutLastPeriod
        ? `calc(-${
            ((signupAttribute - currentAttribute) * 100) /
              (nextAttribute - currentAttribute) +
            0
          }% + 14px)`
        : '-100%'
      showBlocks = fetchNumbers(
        Object.keys(touchPoint?.tier_fee_list).length,
        tierReachedIndex,
        2,
        5,
      )
    }
  })

  return (
    <div
      className={cn('overflow-y-auto support-table primary-table', className)}
      style={{ minHeight: 'auto', marginTop: 32}}
    >
      <div
        className='table-responsive border-table'
        style={{ minHeight: 'auto' }}
      >
        <table className='w-100 table'>
          <thead>
            <tr>
              <th className='w-80 pr-5'>TPS</th>
              <th className='w-80 text-right pr-5'>COUNT</th>
              <th>QTY</th>
              <th className='text-right'>TIER $</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Email</td>
              <td className='text-right pr-5'>
                {touchPoint?.data[0]?.email_quantity}
              </td>
              <td style={{ padding: 0 }} colSpan={2} rowSpan={5}>
                <div className='h-230px d-flex justify-content-lg-around'>
                  <div className='spread-block my-2 position-relative'>
                    {Object.keys(touchPoint?.tier_fee_list).map(
                      (item, index) => {
                        return showBlocks.indexOf(index) > -1 ? (
                          <div
                            key={index}
                            className=' h-100 pl-3 custom-center position-relative tier-block'
                          >
                            <div
                              className={cn('tier-line', {
                                muted: tierReachedIndex < index,
                              })}
                            ></div>
                            <div
                              className={cn(
                                'rounded position-absolute',
                                { 'circle-p': tierReachedIndex >= index },
                                { 'circle-muted': tierReachedIndex < index },
                              )}
                            ></div>
                            {tierReachedIndex === index ? (
                              <div
                                style={{ bottom: percent }}
                                className='flag-rect rounded position-absolute'
                              >
                                {signupAttribute}
                              </div>
                            ) : null}
                            <span
                              className={cn({
                                'text-cream-25': index !== tierReachedIndex,
                              })}
                            >
                              {item}
                            </span>
                          </div>
                        ) : null
                      },
                    )}
                  </div>
                  <div className='spread-block my-2'>
                    {Object.keys(touchPoint?.tier_fee_list).map(
                      (item, index) => {
                        return showBlocks.indexOf(index) > -1 ? (
                          <div
                            key={index}
                            className={cn(
                              'h-100 pl-3 position-relative custom-center',
                              { 'text-cream-25': index !== tierReachedIndex },
                            )}
                          >
                            <span>
                              {touchPoint?.tier_fee_list[item]
                                ? currencyFormatter(
                                    touchPoint?.tier_fee_list[item],
                                    currencyOptions,
                                  )
                                : '-'}
                            </span>
                          </div>
                        ) : null
                      },
                    )}
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>Call</td>
              <td className='text-right pr-5'>
                {touchPoint?.data[0]?.call_quantity}
              </td>
            </tr>
            <tr>
              <td>Text</td>
              <td className='text-right pr-5'>
                {touchPoint?.data[0]?.text_quantity}
              </td>
            </tr>
            <tr>
              <td>Social</td>
              <td className='text-right pr-5'>
                {touchPoint?.data[0]?.social_quantity}
              </td>
            </tr>
            <tr>
              <td>Demo</td>
              <td className='text-right pr-5'>
                {touchPoint?.data[0]?.demo_quantity}
              </td>
            </tr>
          </tbody>
        </table>
        <div>
          <div className='d-flex flex-start table-top-footer-padding custom-border-top text-14px line-height-1'>
            <div className="text-14px text-cream-75" style={{ width: 49 }}>Total</div>
            <div className='text-right text-14px text-cream-75' style={{ width: 70 }}>
              {total ? total : 0}
            </div>
            {/* <div>
              {total < limitation || !total ? <span>❌ Unqualified</span> : <span>✅ Qualified</span>}

            </div> */}
          </div>
          <div className='custom-between table-footer-padding custom-border-top'>
            <div className='text-14px'>🙌 Tier Bonus Reached</div>
            <div className='text-24px text-green font-weight-bold'>
              {touchPoint?.tier_bonus_reached_value
                ? `+ ${currencyFormatter(
                    touchPoint?.tier_bonus_reached_value,
                    currencyOptions,
                  )}`
                : '-'}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TouchPointTable
