import React from 'react'
import classnames from 'classnames'

function FromWithIcon(props) {
  let { selected, icon, text } = props
  return (
    <div
      className={classnames('form-group-content-primary form-with-icon', {
        selected: selected ?? false,
      })}
    >
      <span className='icon-box'>{icon}</span>
      <span>{text}</span>
    </div>
  )
}

export default FromWithIcon
