import React from 'react'
import { Row, Col } from 'reactstrap'
import ReactSelect from 'react-select'

import DropdownIndicator from '../../Common/DropdownIndicator'
import { filterFromObjectArray } from '../../../utils/tools'
import { BASIC_SELECT_STYLE, BASIC_SELECT_THEME } from '../../../utils/constant'

const reasonOptions = [{
	value: 'other',
	label: 'Other',
}, {
	value: 'odd',
	label: 'ODD Error'
}]

const assoOrderOptions = [{
	value: 'yes',
	label: 'Yes',
}, {
	value: 'no',
	label: 'No'
}]

export default function ModalReason({
	disabled,
	reason,
	assoOrder,
	setReason,
	setAssoOrder
}) {
	return (
		<>
			<Row className='form-row'>
				<Col>
					<div className={`cmi-title ${reason === 'other' ? 'cmi-title-w156' : ''}`}>CREDIT REASON</div>
				</Col>
				{reason === 'other' ? (
					<Col>
						<div className='cmi-title cmi-title-w156 cmi-ml-5'>ASSO. ORDER</div>
					</Col>
				) : null}
			</Row>
			<Row className='form-row'>
				<Col>
					<div>
						<ReactSelect
							value={filterFromObjectArray(reasonOptions, reason || '', 'value')}
							name='reason'
							placeholder={'Select'}
							options={reasonOptions}
							isDisabled={disabled}
							className={`basic-single ${reason === 'other' ? 'cmi-title-w156' : ''}`}
							classNamePrefix='select'
							onChange={({ value }) => {
								setReason(value)
							}}
							components={{ DropdownIndicator }}
							theme={BASIC_SELECT_THEME}
							styles={BASIC_SELECT_STYLE}
						/>
					</div>
				</Col>
				{reason === 'other' ? (
					<Col>
						<div>
							<ReactSelect
								value={filterFromObjectArray(assoOrderOptions, assoOrder || '', 'value')}
								name='assoOrder'
								placeholder={'Select'}
								options={assoOrderOptions}
								isDisabled={disabled}
								className='basic-single cmi-title-w156 cmi-ml-5'
								classNamePrefix='select'
								onChange={({ value }) => {
									setAssoOrder(value)
								}}
								components={{ DropdownIndicator }}
								theme={BASIC_SELECT_THEME}
								styles={BASIC_SELECT_STYLE}
							/>
						</div>
					</Col>
				) : null}
			</Row>
		</>
	)
}