import axios from "axios";
import { toast } from "react-toastify";

export const GET_CREDIT_LIST_REQUEST = "GET_CREDIT_LIST_REQUEST";
export const GET_CREDIT_LIST_SUCCESS = "GET_CREDIT_LIST_SUCCESS";
export const GET_CREDIT_LIST_FAIL = "GET_CREDIT_LIST_FAIL";
export const getCreditList =
  ({ params = {}, refresh = true, page = 1, per_page = 15 }) =>
  (dispatch) => {
    dispatch({ type: GET_CREDIT_LIST_REQUEST });
    axios
      .request({
        url: "/nc/credit/list",
        method: "get",
        params: {
          ...params,
          per_page: per_page,
          page: params.page ?? page,
        },
        cache: { ignoreCache: params ? params.refresh ?? refresh : refresh },
      })
      .then(({ data }) => {
        if (data.redirect) return;

        if (data.success) {
          dispatch({
            type: GET_CREDIT_LIST_SUCCESS,
            data: data.data,
            msg: data.message,
            errors: data.errors,
          });
          dispatch(updateCreditList(false))
        } else {
          dispatch({
            type: GET_CREDIT_LIST_FAIL,
            msg: data.message,
            errors: data.errors,
          });
          toast.warn(data.message);
        }
      });
  };

export const GET_CREDIT_REQUEST = "GET_CREDIT_REQUEST";
export const GET_CREDIT_SUCCESS = "GET_CREDIT_SUCCESS";
export const GET_CREDIT_FAIL = "GET_CREDIT_FAIL";
export const getCredit =
  (id) =>
  (dispatch) => {
    dispatch({ type: GET_CREDIT_REQUEST });
    axios
      .request({
        url: "/nc/credit/credit",
        method: "get",
        params: {
          credit_id: id,
        },
        cache: { ignoreCache: true }
      })
      .then(({ data }) => {
        if (data.redirect) return;

        if (data.success) {
          dispatch({
            type: GET_CREDIT_SUCCESS,
            data: data.data,
            msg: data.message,
            errors: data.errors,
          });
        } else {
          dispatch({
            type: GET_CREDIT_FAIL,
            msg: data.message,
            errors: data.errors,
          });
          toast.warn(data.message);
        }
      });
  };

export const GET_CREDIT_BRANDS_REQUEST = 'GET_CREDIT_BRANDS_REQUEST'
export const GET_CREDIT_BRANDS_SUCCESS = 'GET_CREDIT_BRANDS_SUCCESS'
export const GET_CREDIT_BRANDS_FAIL = 'GET_CREDIT_BRANDS_FAIL'
export const getCreditBrands =
  (params, refresh = false) =>
  async dispatch => {
    dispatch({
      type: GET_CREDIT_BRANDS_REQUEST,
    })
    return axios
      .request({
        url: '/nc/credit/brands',
        method: 'get',
        params: {
          brand_name: params.name,
          type: params.type,
        },
        cache: { ignoreCache: refresh },
      })
      .then(({ data }) => {
        if (data.redirect) return

        if (data.success) {
          dispatch({
            type: GET_CREDIT_BRANDS_SUCCESS,
            data: data.data,
          })
        } else {
          dispatch({
            type: GET_CREDIT_BRANDS_FAIL,
            msg: data.message,
            errors: data.errors,
          })
          toast.warn(data.message);
        }
        return data
      })
      .catch(error => {
        dispatch({ type: GET_CREDIT_BRANDS_FAIL })
        let resData = error.response
          ? error.response.data
          : { message: error.message }
          toast.warn(resData.message);
      })
  }

export const GET_CREDIT_LOCATIONS_REQUEST = 'GET_CREDIT_LOCATIONS_REQUEST'
export const GET_CREDIT_LOCATIONS_SUCCESS = 'GET_CREDIT_LOCATIONS_SUCCESS'
export const GET_CREDIT_LOCATIONS_FAIL = 'GET_CREDIT_LOCATIONS_FAIL'
export const getCreditLocations = (params, refresh = false) =>
async dispatch => {
  dispatch({
    type: GET_CREDIT_LOCATIONS_REQUEST,
  })
  return axios
    .request({
      url: '/nc/credit/locations',
      method: 'get',
      params: {
        brand_id: params.id,
      },
      cache: { ignoreCache: refresh },
    })
    .then(({ data }) => {
      if (data.redirect) return

      if (data.success) {
        dispatch({
          type: GET_CREDIT_LOCATIONS_SUCCESS,
          data: data.data,
        })
      } else {
        dispatch({
          type: GET_CREDIT_LOCATIONS_FAIL,
          msg: data.message,
          errors: data.errors,
        })
        toast.warn(data.message);
      }
      return data
    })
    .catch(err => {
      dispatch({ type: GET_CREDIT_LOCATIONS_FAIL })
    })
}

export const GET_CREDIT_ORDER_REQUEST = 'GET_CREDIT_ORDER_REQUEST'
export const GET_CREDIT_ORDER_SUCCESS = 'GET_CREDIT_ORDER_SUCCESS'
export const GET_CREDIT_ORDER_FAIL = 'GET_CREDIT_ORDER_FAIL'
export const getCreditOrder = (params, refresh = false) =>
async dispatch => {
  dispatch({
    type: GET_CREDIT_ORDER_REQUEST,
  })
  return axios
    .request({
      url: '/nc/credit/order',
      method: 'get',
      params: {
        order_id: params.orderId,
        location_id: params.locationId,
        type: params.type,
      },
      cache: { ignoreCache: refresh },
    })
    .then(({ data }) => {
      if (data.redirect) return

      if (data.success) {
        dispatch({
          type: GET_CREDIT_ORDER_SUCCESS,
          data: data.data,
        })
      } else {
        dispatch({
          type: GET_CREDIT_ORDER_FAIL,
          msg: data.message,
          errors: data.errors,
        })
        toast.warn(data.message);
      }
      return data
    })
    .catch(err => {
      dispatch({ type: GET_CREDIT_ORDER_FAIL })
    })
}

export const CREATE_CREDIT_ORDER_REQUEST = 'CREATE_CREDIT_ORDER_REQUEST'
export const CREATE_CREDIT_ORDER_SUCCESS = 'CREATE_CREDIT_ORDER_SUCCESS'
export const CREATE_CREDIT_ORDER_FAIL = 'CREATE_CREDIT_ORDER_FAIL'
export const createCreditOrder = (params) =>
async dispatch => {
  dispatch({
    type: CREATE_CREDIT_ORDER_REQUEST,
  })
  return axios
    .request({
      url: '/nc/credit/create',
      method: 'post',
      data: params
    })
    .then(({ data }) => {
      if (data.redirect) return

      if (data.success) {
        dispatch({
          type: CREATE_CREDIT_ORDER_SUCCESS,
        })
        dispatch(updateCreditList(true))
        dispatch(handleCreditModal({
          open: false
        }))
      } else {
        dispatch({
          type: CREATE_CREDIT_ORDER_FAIL,
        })
        toast.warn(data.message);
      }
      return data
    })
    .catch(err => {
      dispatch({ type: CREATE_CREDIT_ORDER_FAIL })
    })
}

export const APPROVE_CREDIT_ORDER_REQUEST = 'APPROVE_CREDIT_ORDER_REQUEST'
export const APPROVE_CREDIT_ORDER_SUCCESS = 'APPROVE_CREDIT_ORDER_SUCCESS'
export const APPROVE_CREDIT_ORDER_FAIL = 'APPROVE_CREDIT_ORDER_FAIL'
export const approveCreditOrder = (params) =>
async dispatch => {
  dispatch({
    type: APPROVE_CREDIT_ORDER_REQUEST,
    status: params.status,
  })
  return axios
    .request({
      url: '/nc/credit/approve',
      method: 'post',
      data: params
    })
    .then(({ data }) => {
      if (data.redirect) return

      if (data.success) {
        dispatch({
          type: APPROVE_CREDIT_ORDER_SUCCESS,
        })
        dispatch(updateCreditList(true))
        dispatch(handleCreditModal({
          open: false
        }))
      } else {
        dispatch({
          type: APPROVE_CREDIT_ORDER_FAIL,
        })
        toast.warn(data.message);
      }
      return data
    })
    .catch(err => {
      dispatch({ type: APPROVE_CREDIT_ORDER_FAIL })
    })
}

export const HANDLE_CREDIT_MODAL = "HANDLE_CREDIT_MODAL"
export const handleCreditModal = ({open, creditType}) => (dispatch) => {
  dispatch({ type: HANDLE_CREDIT_MODAL, open, creditType });
}

export const UPDATECREDITLIST = 'UPDATECREDITLIST'
export const updateCreditList = need => dispatch => {
  dispatch({ type: UPDATECREDITLIST, need})
}
