import axios from 'axios'

export const REFERRER_GET_REFERRERS_REQUEST = 'REFERRER_GET_REFERRERS_REQUEST'
export const REFERRER_GET_REFERRERS_SUCCESS = 'REFERRER_GET_REFERRERS_SUCCESS'
export const REFERRER_GET_REFERRERS_FAIL = 'REFERRER_GET_REFERRERS_FAIL'
export const getReferrers =
  (params, refresh = true) =>
  async dispatch => {
    dispatch({ type: REFERRER_GET_REFERRERS_REQUEST })
    return axios
      .request({
        url: '/nc/sales/referrers',
        method: 'get',
        cache: { ignoreCache: refresh },
        params,
      })
      .then(({ data }) => {
        if (data.redirect) return

        if (data.success) {
          dispatch({
            type: REFERRER_GET_REFERRERS_SUCCESS,
            data: data.data,
          })
        } else {
          dispatch({ type: REFERRER_GET_REFERRERS_FAIL })
        }

        return data
      })
  }
