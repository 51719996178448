import React, { useState, useEffect, useRef } from 'react'
import classnames from 'classnames'
import moment from 'moment'
import axios from 'axios'
import Map from './Map'
import './index.scss'

export default function MapViewResult(props) {
  const { address = {}, addressQuery = '' } = props
  const [loading, setLoading] = useState(false)
  const [brandList, setBrandList] = useState([])
  const [mapPointList, setMapPointList] = useState([])
  const [curItem, setCurItem] = useState({})
  const mapRef = useRef()
  const centerRef = useRef({})

  useEffect(() => {
    // 将中心点置空
    centerRef.current = {}
    // 顶部搜索框选中地点跳进来
    if (address.location_id) {
      getAddrList(address.location_id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address.location_id])

  useEffect(() => {
    centerRef.current = {}
    // 地点模糊搜索跳进来
    getAddrList(undefined, addressQuery)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressQuery])

  // 搜索附近brand
  const getAddrList = (location_id, query) => {
    let params = {}
    if (location_id) {
      params = {
        location_id: location_id,
        latitude: address.latitude,
        longitude: address.longitude,
      }
    }
    if (query) {
      params = {
        address_query: query,
      }
    }
    // 点击searchArea
    if (centerRef.current?.latitude) {
      setLoading(true)
      params = {
        latitude: centerRef.current.latitude,
        longitude: centerRef.current.longitude,
      }
    }
    axios
      .request({
        url: '/nc/sales/map_view/signups',
        method: 'get',
        params,
        cache: { ignoreCache: true },
      })
      .then(res => {
        const data = res.data?.data?.list || []
        const select = res.data?.data?.select || {}
        normalizeData(data, select)
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
        console.log(err)
      })
  }

  // 规整数据
  const normalizeData = (data, select) => {
    const list = data.map(item => {
      const done_at = item?.onboarding?.done_at
      const diffDay = done_at ? moment().diff(done_at, 'days') : undefined
      const obj = {
        brandId: item.id,
        location_id: item.location_id,
        order_amount: item.order_amount?.toFixed(0) || 0,
        order_count: item.order_count?.toFixed(0) || 0,
        diffDay: diffDay < 30 ? diffDay : undefined,
        address: item?.location?.address || {},
        name: item?.location?.brand?.name || '',
        hostname: item?.location?.brand?.domains?.[0]?.hostname || '',
        onboarded: item?.onboarding?.status === 'done' || false,
      }
      return obj
    })

    let centerPoint = {}
    if (select.latitude && select.longitude) {
      centerPoint = {
        latitude: select.latitude,
        longitude: select.longitude,
      }
    } else {
      centerPoint = {
        latitude: centerRef.current?.latitude || address.latitude || '',
        longitude: centerRef.current?.longitude || address.longitude || '',
      }
    }
    // demo环境存在 点 的经纬度为null的情况
    if (centerPoint.latitude && centerPoint.longitude) {
      setCurItem({ brandId: '', ...centerPoint })
    }
    setBrandList(list)
    // 地图组件不需要太多数据，所以此处精简下数据结构
    setMapPointList(() =>
      list.map(item => ({
        brandId: item.brandId,
        latitude: item.address?.latitude,
        longitude: item.address?.longitude,
        onboarded: item?.onboarded, // true 绿色 false 蓝色
      })),
    )
  }

  // 点击左侧brand列表，右侧地图变化中心点、高亮点
  const handleClickLocation = index => {
    brandList.forEach(item => (item.isActive = false))
    brandList[index].isActive = true
    const item = brandList[index] || {}
    setCurItem({
      brandId: item.brandId,
      latitude: item.address?.latitude,
      longitude: item.address?.longitude,
    })
    setBrandList([...brandList])
  }
  // 点击searchArea
  const handleClickSearchArea = center => {
    centerRef.current = {
      latitude: center.lat,
      longitude: center.lng,
    }
    // console.log('center-->', centerRef.current)
    getAddrList()
  }

  const handleMapChange = item => {
    const brandId = item.brandId
    let curBrandIndex = 0
    brandList.forEach((v, index) => {
      if (v.brandId === brandId) {
        v.isActive = true
        curBrandIndex = index
      } else {
        v.isActive = false
      }
    })
    setCurItem(item)
    setBrandList([...brandList])
    setTimeout(() => {
      document.querySelector('.location-wrap').scrollTo({
        top: (curBrandIndex + 1) * 85,
        behavior: 'smooth',
      })
    }, 50)
  }

  // console.log('curItem--->', curItem)
  return (
    <div className='map-view-result'>
      <div className='list-wrap'>
        <div className='header-tips'>
          {`Total ${brandList.length || '0'} restaurants in the search area`}
        </div>
        <div className='location-wrap'>
          {brandList.map((item, index) => {
            return (
              <div
                key={item.brandId}
                className={classnames('location-item', {
                  isActive: item.isActive,
                })}
                onClick={() => handleClickLocation(index)}
              >
                <div
                  className={classnames('signup-mark', {
                    green: item.onboarded,
                  })}
                ></div>
                <div className='main-item'>
                  <div className='item-name'>
                    <span
                      className={classnames('name', {
                        haveDay: !!item.diffDay,
                      })}
                    >
                      {item.name}
                    </span>
                    {!!item.diffDay && (
                      <div className='diff-day'>
                        <div
                          className={classnames('tips', {
                            small: item.diffDay < 10,
                            middle: item.diffDay >= 10 && item.diffDay < 99,
                            big: item.diffDay > 99,
                          })}
                        >
                          {item.diffDay} Days
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='info-wrap'>
                    <div className='info'>
                      <i className='fas fa-info-square f-12'></i>
                      {item.onboarded
                        ? `${item.order_count} / $${item.order_amount}`
                        : 'Onboarding'}
                    </div>
                    <div className='price'>
                      <i className='fas fa-pig f-12'></i>
                      {item.onboarded
                        ? `$${(item.order_amount / 4)?.toFixed(0)}`
                        : '-'}
                    </div>
                    <div className='city'>
                      <i className='fas fa-map-marker-alt f-12'></i>
                      {item.address?.city || '-'}
                    </div>
                  </div>
                </div>
                {item.isActive && (
                  <div className='website'>
                    <i className='fas fa-external-link-square-alt f-12'></i>
                    <a href={item.hostname} target='_blank' rel='noreferrer'>
                      VIST WEBSITE
                    </a>
                  </div>
                )}
              </div>
            )
          })}
        </div>
      </div>
      <div className='map-wrap'>
        <Map
          mapRef={mapRef}
          loading={loading}
          onChange={handleMapChange}
          onClickSearchArea={handleClickSearchArea}
          mapPointList={mapPointList}
          center={{
            brandId: curItem?.brandId,
            latitude: curItem?.latitude || '',
            longitude: curItem?.longitude || '',
          }}
        ></Map>
      </div>
    </div>
  )
}
