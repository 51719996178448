import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'

function ClaimSuccessModal(props) {
  return (
    <Modal
      isOpen={props.open}
      toggle={props.toggle}
      className='alert-modal'
      centered={true}
    >
      <ModalHeader className='text-green'>Fund Claimed</ModalHeader>
      <ModalBody>
        <div className='warning-info text-center f-16 bold'>
          {
            'You have submitted your claim, please allow us few business days to process your fund'
          }
        </div>
      </ModalBody>
      <ModalFooter>
        {' '}
        <Button
          color='thridary'
          className='btn-thridary-upload w-100 mt-3'
          block
          onClick={props.toggle}
        >
          Done
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ClaimSuccessModal
