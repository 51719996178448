import React from 'react'
import { DAYS } from '../../utils/constant'
import { Button, Row, Col } from 'reactstrap'
import PropTypes from 'prop-types'
import moment from 'moment'
import TimePickerPrimary from '../Common/TimePickerPrimary'

const format = 'h:mm a'
class OpenHoursForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      autoFill: false,
      initAllEmpty: false,
    }
    this.isAllEmpty = this.isAllEmpty.bind(this)
    this.onAddHours = this.onAddHours.bind(this)
    this.onHourRowBlur = this.onHourRowBlur.bind(this)
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {}

  isAllEmpty() {
    let all_empty = true

    DAYS.map(day => {
      if (this.props.days[day.abbr].length !== 0) {
        all_empty = false
      }
    })
    return all_empty
  }

  onAddHours(day) {
    if (this.isAllEmpty()) {
      this.setState({
        autoFill: true,
      })
    }
    this.props.addHours(day.abbr, { count: this.props.count })
  }
  onHourRowBlur(editedDay) {
    if (!this.state.autoFill) return
    let allFilled = true
    let otersEmpty = true
    this.props.days[editedDay.abbr].map(val => {
      if (val.from === '' || val.to === '') {
        allFilled = false
      }
    })

    DAYS.map(day => {
      if (day !== editedDay && this.props.days[day.abbr].length !== 0) {
        otersEmpty = false
      }
    })

    if (allFilled && otersEmpty) {
      DAYS.map(day => {
        this.props.days[editedDay.abbr].map((item, key) => {
          this.props.onHourChange(
            key,
            day.abbr,
            'from',
            moment(this.props.days[editedDay.abbr][key]['from'], 'hh:mm'),
            {
              count: this.props.count,
            },
          )
          this.props.onHourChange(
            key,
            day.abbr,
            'to',
            moment(this.props.days[editedDay.abbr][key]['to'], 'hh:mm'),
            {
              count: this.props.count,
            },
          )
        })
      })
      this.setState({ autoFill: false })
    }
  }

  render() {
    let hiddenAlert = this.props.hiddenAlert ?? false

    if (!this.props.days || !this.props.days.sat) return null
    let all_empty = this.isAllEmpty()

    return DAYS.map(day => (
      <div
        key={`weekday-${day.abbr}`}
        id={`weekday-${day.abbr}`}
        className='open-hour-row  d-block'
        onBlur={e => this.onHourRowBlur(day)}
      >
        {this.props.days[day.abbr].length === 0 ? (
          <div className='justify-content-between w-100 d-flex py-5px'>
            <div className='d-inline-block day-label text-regular'>
              {day.label}
            </div>
            {this.props.disabled ? (
              <div className=' d-flex justify-content-center'>
                <div
                  className='m-auto  time-picker d-flex justify-content-center bold closed'
                  style={{ color: 'white' }}
                >
                  <span className='m-auto'>CLOSED</span>
                </div>
              </div>
            ) : (
              <div
                className=' d-flex justify-content-center'
                onClick={e => this.onAddHours(day)}
              >
                <div className='m-auto  time-picker d-flex justify-content-center bold closed'>
                  <span className='m-auto'>CLOSED</span>
                </div>
              </div>
            )}

            <Button
              className={
                'mt-0 d-inline-flex' + (this.props.disabled ? ' v-hidden' : '')
              }
              color='link'
              onClick={e => this.onAddHours(day)}
            >
              <span className='m-auto'> + hours</span>
            </Button>
            <Button className='v-hidden' color='link'>
              <span className='delete-icon'></span>
            </Button>
            {!all_empty || hiddenAlert ? null : (
              <i className='fas fa-exclamation exclamation time-picker-exclamation'></i>
            )}
          </div>
        ) : null}
        {this.props.days[day.abbr].map((item, key) => (
          <div
            className='justify-content-between w-100 d-flex py-5px'
            key={`day-${item.id}-timepicker`}
          >
            {(item.from && item.to && !all_empty) || hiddenAlert ? null : (
              <i className='fas fa-exclamation exclamation time-picker-exclamation'></i>
            )}
            <div className='d-inline-block day-label text-regular'>
              {key === 0 ? day.label : ''}
            </div>
            <div className='time-picker justify-content-between d-inline-flex'>
              <TimePickerPrimary
                className='from'
                // defaultValue={item.from ? moment(item.from, "hh:mm:ss") : null}
                onChange={value =>
                  this.props.onHourChange(key, day.abbr, 'from', value, {
                    count: this.props.count,
                  })
                }
                format={format}
                value={
                  item.from ? moment(item.from, 'hh:mm').format('h:mm A') : ''
                }
                removeHours={this.props.removeHours}
                count={this.props.count}
                abbr={day.abbr}
                key={key}
                disabled={this.props.disabled}
              />
              <span className='my-auto text-to'>to</span>
              <TimePickerPrimary
                disabled={this.props.disabled}
                className='to'
                onChange={value =>
                  this.props.onHourChange(key, day.abbr, 'to', value, {
                    count: this.props.count,
                  })
                }
                format={format}
                value={item.to ? moment(item.to, 'hh:mm').format('h:mm A') : ''}
              />
            </div>

            <Button
              className={
                'mt-0 d-inline-flex' + (this.props.disabled ? ' v-hidden' : '')
              }
              color='link'
              onClick={e => this.onAddHours(day)}
            >
              <span className='m-auto bold'> + hours</span>
            </Button>

            <Button
              className={
                'mt-0 d-inline-flex' + (this.props.disabled ? ' v-hidden' : '')
              }
              color='link'
              onClick={e =>
                this.props.removeHours(day.abbr, key, {
                  count: this.props.count,
                })
              }
            >
              {/* <span className='delete-icon m-auto'></span> */}
              <i className="far fa-trash-alt m-auto" style={{color: 'rgba(233, 233, 233, 0.5)'}}/>
            </Button>
          </div>
        ))}
      </div>
    ))
  }
}

OpenHoursForm.propTypes = {
  days: PropTypes.object,
  addHours: PropTypes.func,
  onHourChange: PropTypes.func,
  setClosed: PropTypes.func,
  removeHours: PropTypes.func,
}

export default OpenHoursForm
