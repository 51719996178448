import React from 'react'
import { FormGroup, Label, Row, Col } from 'reactstrap'
import FromWithIcon from './FromWithIcon'

function OrderSetting(props) {
  let { online_order_config } = props

  let order_in_house_config = online_order_config
    ? online_order_config.order_in_house_config ?? {}
    : {}
  let order_doordash_delivery_config = online_order_config
    ? online_order_config.order_doordash_delivery_config ?? {}
    : {}
  let order_pickup_config = online_order_config
    ? online_order_config.order_pickup_config ?? {}
    : {}

  let in_house = order_in_house_config
    ? order_in_house_config.enabled ?? false
    : false

  let doordash_delivery = order_doordash_delivery_config
    ? order_doordash_delivery_config.enabled ?? false
    : false
  let pickup = order_pickup_config
    ? order_pickup_config.enabled ?? false
    : false
  return (
    <div className='basic-info-section small'>
      <Label>ORDER SETTING</Label>

      <Row form>
        <Col>
          <FormGroup>
            <FromWithIcon
              text={'Pickup'}
              selected={pickup}
              icon={<i className='fas fa-shopping-bag'></i>}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col>
          <FormGroup>
            <FromWithIcon
              text={'On-demand Delivery'}
              selected={doordash_delivery && !in_house}
              icon={<i className='fas fa-car'></i>}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col>
          <FormGroup>
            <FromWithIcon
              text={'In-house Delivery'}
              selected={in_house}
              icon={<i className='fas fa-car'></i>}
            />
          </FormGroup>
        </Col>
      </Row>
    </div>
  )
}

export default OrderSetting
