import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

function useUserTeamType() {
  const [teamType, setTeamType] = useState('')
  const { crm_team } = useSelector(state => state.auth.profile ?? {})
  useEffect(() => {
    if (crm_team) {
      setTeamType(crm_team.type)
    }
  }, [crm_team])

  return teamType
}

export default useUserTeamType
