import axios from 'axios'
import { MAIN_API_URL } from '../../utils/constant'

export const SEARCH_BRANDS_REQUEST = 'SEARCH_BRANDS_REQUEST'
export const SEARCH_BRANDS_SUCCESS = 'SEARCH_BRANDS_SUCCESS'
export const SEARCH_BRANDS_FAIL = 'SEARCH_BRANDS_FAIL'
export const searchBrands =
  (name, refresh = true) =>
  async dispatch => {
    dispatch({ type: SEARCH_BRANDS_REQUEST })
    return axios
      .request({
        // url: MAIN_API_URL + "/cp/brand/exist",
        url: '/nc/sales/brands',
        method: 'get',
        params: { keyword: name },
        cache: { ignoreCache: refresh },
      })
      .then(({ data }) => {
        if (data.redirect) return

        if (data.success) {
          dispatch({
            type: SEARCH_BRANDS_SUCCESS,
            data: data.data,
            msg: data.message,
          })
        } else {
          dispatch({
            type: SEARCH_BRANDS_FAIL,
            msg: data.message,
            errors: data.errors,
          })
        }

        return data
      })
      .catch(err => {
        dispatch({ type: SEARCH_BRANDS_FAIL })
        return { success: false, data: [] }
      })
  }
