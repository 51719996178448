import React from 'react'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'
import { getOnboardingOptions } from '../../store/actions/onboarding'
import SelectComponent from '../../components/Select'
import LiveOrderButton from '../../components/Button'
import CustomDateRangePicker from '../../components/DateRangePicker'
import { capitalize } from '../../utils/tools'

const filterLists = [
  {
    id: 'all_brands',
    placeholder: 'Select Brand',
    width: 240,
    name: 'all-brands',
    type: 'brands',
  },
  {
    id: 'all_status',
    placeholder: 'Select Status',
    width: 240,
    name: 'all-status',
    type: 'status',
  },
  {
    id: 'all_types',
    placeholder: 'Select Type',
    width: 240,
    name: 'all-types',
    type: 'types',
  },
]

const FilterBar = ({ onChangeHandle, submitDateRange, filters }) => {
  const onboarding = useSelector(state => state.onboarding)
  const dispatch = useDispatch()
  const [showDatePicker, setShowDatePicker] = React.useState(false)
  const [startDate, setStartDate] = React.useState(filters.begin_time)
  const [endDate, setEndDate] = React.useState(filters.end_time)

  const fetchingOptionList = type => {
    if (!onboarding.onboardingOptions || !onboarding.onboardingOptions[type]) {
      dispatch(getOnboardingOptions({ type }))
    }
  }

  const { onboardingOptions } = onboarding

  const togglePicker = () => {
    setShowDatePicker(!showDatePicker)
  }

  const getOptions = item => {
    let temp_options = [{ label: `All ${capitalize(item.type)}`, value: '' }]
    temp_options = [
      ...temp_options,
      ...(onboardingOptions[item.type]
        ? onboardingOptions[item.type].map(option => {
            if (item.type === 'brands') return { label: option, value: option }
            else if (item.type === 'status')
              return {
                label: option.label,
                value: option.value,
              }
            else return option
          })
        : []),
    ]
    return temp_options
  }

  return (
    <div className='live-page-filter-group d-flex justify-content-between'>
      <div className='form-row'>
        {filterLists.map((item, index) => (
          <div key={index} className='mr-2'>
            <SelectComponent
              name={item.name}
              options={getOptions(item)}
              width={item.width}
              placeholder={item.placeholder}
              onMenuOpen={() => {
                fetchingOptionList(item.type)
              }}
              onChange={({ value, label }) => {
                onChangeHandle(item.type, value)
              }}
              value={filters[item.type]}
            />
          </div>
        ))}
        <CustomDateRangePicker
          showPicker={showDatePicker}
          submitDate={(startDate, endDate) => {
            setStartDate(startDate)
            setEndDate(endDate)
            togglePicker()
            submitDateRange(startDate, endDate)
          }}
        >
          <div className='date-form' onClick={togglePicker}>
            {startDate && endDate ? (
              <span>
                {moment(startDate).format('L')} - {moment(endDate).format('L')}
              </span>
            ) : (
              <span>Select the Date</span>
            )}{' '}
            <i className='fa fa-caret-down'></i>
          </div>
        </CustomDateRangePicker>
      </div>
      <div>
        <LiveOrderButton />
      </div>
    </div>
  )
}

export default FilterBar
