import React from 'react'

import BtnInForm from '../../Common/BtnInForm'

function SupportSidebar(props) {
  let { goToPath, activeLink, supportId, signupId } = props
  const onClick = route => {
    const pathname = route
    const search = signupId ? `?id=${signupId}` : null
    goToPath(pathname, search)
  }

  const disabled = supportId === 'create' && !signupId
  return (
    <div className='support-siderbar-list'>
      <BtnInForm
        text='Signup Form'
        onClick={() => onClick('signup-form')}
        select={activeLink.includes(`${supportId}/signup-form`)}
      />
      <BtnInForm
        text={'Account Info'}
        onClick={e => onClick('basic-info')}
        select={activeLink.includes(`${supportId}/basic-info`)}
        disabled={disabled}
      />
      <BtnInForm
        text='Menu Work'
        onClick={() => onClick('menu-work')}
        select={activeLink.includes(`${supportId}/menu-work`)}
        disabled={disabled}
      />
      <BtnInForm
        text='Onboarding'
        onClick={() => onClick('onboarding')}
        select={activeLink.includes(`${supportId}/onboarding`)}
        disabled={disabled}
      />
    </div>
  )
}
export default SupportSidebar
