import React from 'react'
import Table from '../../../components/Table'
import moment from 'moment'
import { checkFalseReturnChar } from '../../../utils/tools'
import UserAvatar from '../../../components/UserAvatar/UserAvatar'
import { currencyFormatter } from '../../../utils/digit'
const currencyOptions = {
  significantDigits: 0,
  thousandsSeparator: ',',
  decimalSeparator: '.',
  symbol: '$',
}
const columns = [
  {
    key: 'rank',
    name: 'RANK',
    className: 'text-center',
    custom: value => {
      let prefix = ''
      if (value === 1) prefix = <span className='f-20 text-blue'>🥇</span>
      else if (value === 2) prefix = <span className='f-20 text-blue'>🥈</span>
      else if (value === 3) prefix = <span className='f-20 text-blue'>🥉</span>
      return <div className='text-center columns-rank'>{prefix ? prefix : value}</div>
    },
  },
  {
    key: 'name',
    name: 'NAME',
    custom: param => {
      return (
        <div>
          <UserAvatar imgUrl={param.imgUrl} name={param.name} />
        </div>
      )
    },
  },
  {
    key: 'qty',
    name: 'QTY',
    className: 'text-right',
  },
  {
    key: 'tp',
    name: 'TPS',
    className: 'text-right',
  },
  {
    key: 'deal',
    name: 'SIGNUP $',
    className: 'text-right',
  },
  {
    key: 'tier',
    name: 'TIER $',
    className: 'text-right',
  },
  {
    key: 'adj',
    name: 'ADJ $',
    className: 'text-right',
  },
  {
    key: 'total',
    name: 'TOTAL $',
    className: 'text-right',
  },
]

const LeaderboardContent = ({ leaderboard, selectedRow, type, date }) => {
  const showPresentDateStartText = moment(date)
    .startOf(type)
    .format('MM/DD/YYYY')
  const showPresendDateEndText = moment(date).endOf(type).format('MM/DD/YYYY')

  const showPreviousDateStartText = moment(date)
    .subtract(1, type)
    .startOf(type)
    .format('MM/DD/YYYY')
  const showPreviousDateEndText = moment(date)
    .subtract(1, type)
    .endOf(type)
    .format('MM/DD/YYYY')

  const selected =
    leaderboard?.select_period?.data?.data?.map((item, index) => {
      return {
        id: item.closer_id,
        rank: index + 1,
        name: {
          imgUrl: item.crm_user.profile_photo,
          name: `${item.crm_user.first_name} ${item.crm_user.last_name}`,
        },
        qty: checkFalseReturnChar(item.quantity),
        tp: checkFalseReturnChar(item.touch_point),
        deal: item.signup_amount
          ? currencyFormatter(item.signup_amount, currencyOptions)
          : '-',
        tier: item.tier ? currencyFormatter(item.tier, currencyOptions) : '-',
        total: item.total
          ? `${Number(item.total) >= 0 ? currencyFormatter(item.total, currencyOptions) : `(${currencyFormatter(Math.abs(item.total), currencyOptions)})`}`
          : '-',
        adj: item.adjust && Number(item.adjust) !== 0 ? `(${currencyFormatter(Math.abs(item.adjust), currencyOptions)})` : '-',
        sales_goal: item.sales_goal,
      }
    }) || []

  const pervious =
    leaderboard?.previos_period?.data?.data?.map((item, index) => {
      return {
        id: item.closer_id,
        rank: index + 1,
        name: {
          imgUrl: item.crm_user.profile_photo,
          name: `${item.crm_user.first_name} ${item.crm_user.last_name}`,
        },
        qty: item.quantity || '-',
        tp: item.touch_point || '-',
        deal: item.signup_amount
          ? currencyFormatter(item.signup_amount, currencyOptions)
          : '-',
        tier: item.tier ? currencyFormatter(item.tier, currencyOptions) : '-',
        total: item.total
          ? `${Number(item.total) >= 0 ? currencyFormatter(item.total, currencyOptions) : `(${currencyFormatter(Math.abs(item.total), currencyOptions)})`}`
          : '-',
        adj: item.adjust && Number(item.adjust) !== 0 ? `(${currencyFormatter(Math.abs(item.adjust), currencyOptions)})` : '-',
        sales_goal: item.sales_goal,
      }
    }) || []

  const selectedPeriodFooter = (selected) => {
    let actualNum = 0
    let goalNum = 0
    selected.forEach(item => {
      const qty = Number(item.qty) ? Number(item.qty) : 0
      actualNum += qty
      const sales_goal = Number(item.sales_goal) ? Number(item.sales_goal) : 0
      goalNum += sales_goal
    })
    return (
      <div className='selected-period-footer'>
        <div className='spf-goal'>
          <div className='spf-goal-num'>{actualNum}</div>
          <div className='spf-goal-text'>Actual Signups</div>
        </div>
        <div className='spf-goal'>
          <div className='spf-goal-num'>{goalNum}</div>
          <div className='spf-goal-text'>Team Goal</div>
        </div>
        <div className='spf-goal'>
          <div className='spf-goal-num'>{goalNum === 0 ? 0 : parseInt(actualNum / goalNum * 100)}%</div>
          <div className='spf-goal-text'>Goal Achieving</div>
        </div>
      </div>
    )
  }

  return (
    <div className='row' style={{ flexWrap: 'initial'}}>
      <div className='col-sm'>
        <div className='mb-3 d-flex justify-content-between align-items-center'>
          <div className='text-24px font-weight-bold font-italic'>
            Selected Period
          </div>
          <div className='text-gray-70'>
            {showPresentDateStartText} to {showPresendDateEndText}
          </div>
        </div>
        <Table
          isBoarded
          columns={columns}
          data={selected}
          className="loaderboard-list-table"
          handleRowClicked={row => {
            selectedRow(row.id, true)
          }}
          tableFooter={selectedPeriodFooter(selected)}
        />
      </div>
      <div className='col-sm'>
        <div className='mb-3 d-flex justify-content-between align-items-center'>
          <div className='text-24px font-weight-bold font-italic'>
            Previous Period
          </div>
          {/* <div className='font-italic text-gray-70'>Jul 1, 2021 - Jul 31, 2021</div> */}
          <div className='text-gray-70'>
            {showPreviousDateStartText} to {showPreviousDateEndText}
          </div>
        </div>
        <Table
          isBoarded
          columns={columns}
          data={pervious}
          className="loaderboard-list-table"
          handleRowClicked={row => {
            selectedRow(row.id, false)
          }}
          tableFooter={selectedPeriodFooter(pervious)}
        />
      </div>
    </div>
  )
}

export default LeaderboardContent
