import React from 'react'
import { Label } from 'reactstrap'
import LogCard from './LogCard'
import moment from 'moment'
import LogHeader from './LogHeader'
import { getDaysFromSignupAt } from '../../../../utils/getDaysFromSignupAt'
import { getFullName } from '../../../../utils/getFullName'

function OnboardingLog(props) {
  let { signup_form, stripe_subscription_ends_at } = props
  let sigupPaid = '-'
  let signupPaidDays = '-'
  let sigupCompleted = '-'
  let sigupCompletedDay = '-'
  let onboarded = '-'
  let onboardedDay = '-'
  let managerName = '-'
  let isOnboarded = false
  let header = <LogHeader />

  if (signup_form) {
    let {
      onboarding,
      paid_at: signup_paid_at,
      done_at: signup_done_at,
    } = signup_form
    if (onboarding) {
      let { done_at: onboarding_done_at, project_lead, status, onboarding_cancellation } = onboarding
      isOnboarded = status === 'canceled'
      sigupPaid = signup_paid_at
        ? moment(signup_paid_at).format('MMM D, YYYY')
        : '-'
      signupPaidDays = getDaysFromSignupAt(signup_paid_at, signup_paid_at)
      sigupCompleted = signup_done_at
        ? moment(signup_done_at).format('MMM D, YYYY')
        : '-'
      sigupCompletedDay = getDaysFromSignupAt(signup_done_at, signup_paid_at)
      onboarded = onboarding_done_at
        ? moment(onboarding_done_at).format('MMM D, YYYY')
        : onboarding_cancellation && onboarding_cancellation.updated_at ? moment(onboarding_cancellation.updated_at).format('MMM D, YYYY') : '-'
      onboardedDay = getDaysFromSignupAt(onboarding_done_at, signup_paid_at)

      if (project_lead) {
        let { first_name, profile_photo, last_name } = project_lead
        managerName = getFullName(first_name, last_name)
        if (profile_photo) header = <LogHeader imgUrl={profile_photo} />
      }
    }
  }

  const onboardRender = () => {
    if (sigupCompleted !== '-' && onboarded === '-' && !isOnboarded) {
      return (
        <LogCard
          title={'Onboarding'}
          subTitle={'Today'}
          trailText={'DAY ' + onboardedDay}
          titleColor={'text-blue'}
        />
      )
    }
    if (stripe_subscription_ends_at) {
      if (moment().isBefore(stripe_subscription_ends_at)) {
        const days = moment(stripe_subscription_ends_at).diff(moment(), 'days')
        return (
          <LogCard
            title={'Canceling'}
            subTitle={`In ${days} days`}
            trailText={'DAY ' + onboardedDay}
            titleColor={'text-yellow'}
          />
        )
      }
      if (moment().isAfter(stripe_subscription_ends_at)) {
        return (
          <LogCard
            title={'Canceled'}
            subTitle={onboarded}
            trailText={'DAY ' + onboardedDay}
            titleColor={'text-red'}
          />
        )
      }
    }
    if (isOnboarded) {
      return (
        <LogCard
          title={'Canceled'}
          subTitle={onboarded}
          trailText={'DAY ' + onboardedDay}
          titleColor={'text-red'}
        />
      )
    }
    return (
      <LogCard
        title={'Onboarded'}
        subTitle={onboarded}
        trailText={'DAY ' + onboardedDay}
        titleColor={onboarded !== '-' ? 'text-green' : ''}
      />
    )
  }

  return (
    <>
      <Label>ONBOARDING LOG</Label>
      <div className='log-row'>
        <div className='center-back-line'></div>
        <LogCard
          title={'Signup Paid'}
          subTitle={sigupPaid}
          trailText={'DAY ' + signupPaidDays}
        />
        <LogCard
          title={'Signup Done'}
          subTitle={sigupCompleted}
          trailText={'DAY ' + sigupCompletedDay}
        />
        {onboardRender()}
        {/* <LogCard
          title={"Onboarded"}
          subTitle={onboarded}
          trailText={"DAY " + onboardedDay}
          titleColor={onboarded !== "-" ? "text-green" : ""}
        /> */}
        <LogCard
          title={'Success Manager'}
          subTitle={managerName}
          trailChild={header}
          large={true}
        />
      </div>
    </>
  )
}

export default OnboardingLog
