import React from 'react'
import { Card } from 'reactstrap'
import ReactSelect from 'react-select'
import { formatPhoneNumber } from '../../utils/digit'
import { BASIC_SELECT_STYLE, BASIC_SELECT_THEME } from '../../utils/constant'

function AffiliateTeam(props) {
  let { affiliates, usersOptions } = props

  const renderAccount = () => {
    if (!affiliates) return null
    let { accounts } = affiliates
    if (!accounts) return null

    const DropdownIndicator = () => {
      return (
        <i className='fas fa-caret-down multi-select-dropdown-indicator'></i>
      )
    }
    return affiliates.accounts.map((account, key) => {
      let { facade_closer, crm_role } = account

      let facade_closer_value = account.facade_closer
        ? {
            value: facade_closer.id,
            label: facade_closer.first_name + ' ' + facade_closer.last_name,
          }
        : { value: '', label: '' }
      return (
        <Card className='account-card' key={`account-${key}`}>
          <div className='w-100 h-100' key={`account-content-${key}`}>
            {' '}
            <div className='account-head'>
              {account.profile_photo ? (
                <span className='profile-photo f-28'>
                  {account.profile_photo}
                </span>
              ) : (
                <i className='fas fa-user-circle f-28'></i>
              )}
            </div>
            <div className=' account-info'>
              <div className=''>
                {(account.first_name ?? '-') + ' ' + (account.last_name ?? '')}
              </div>{' '}
              <div className='text-cream-50'>
                {formatPhoneNumber(account.phone) || ''}
              </div>{' '}
              <div className='text-cream-50'>{account.email || ''}</div>{' '}
              <div className='text-cream-50'>
                {' '}
                {crm_role ? crm_role.display_name || '-' : '-'}
              </div>
            </div>
          </div>
          <div className='account-user-select' key={`account-select-${key}`}>
            {' '}
            <ReactSelect
              value={facade_closer_value}
              options={usersOptions}
              // menuIsOpen={true}
              className='basic-single'
              classNamePrefix='select'
              onChange={value =>
                props.changeFacadeCloser(account.id, value.value)
              }
              onMenuOpen={e => props.getUsers('rushable')}
              components={{ DropdownIndicator }}
              isDisabled={props.changeFacadeCloserRequest}
              theme={BASIC_SELECT_THEME}
              styles={BASIC_SELECT_STYLE}
            ></ReactSelect>
            {props.changeFacadeCloserError ? (
              <div className='text-center text-red'>
                {props.changeFacadeCloserError}
              </div>
            ) : null}
          </div>
        </Card>
      )
    })
  }

  return (
    <>
      {' '}
      <label>ACCOUNT</label>
      {renderAccount()}
    </>
  )
}

export default AffiliateTeam
