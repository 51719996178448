/* eslint-disable eqeqeq */
/*
 * format phone to (xxx) xxx-xxxx when keyup
 */

import {
  RUSHABLE_FREE_PLAN_ID,
  RUSHABLE_MONTHLY_PLAN_ID,
  RUSHABLE_ANNUAL_PLAN_ID,
} from './constant'
export const onPhoneKeyUp = (e, phoneInput) => {
  var ph = phoneInput
  ph = ph.replace(/\D/g, '').substring(0, 10)
  // Backspace and Delete keys
  var deleteKey = e.keyCode == 8 || e.keyCode == 46
  var len = ph.length
  if (len == 0) {
  } else if (len < 3) {
    ph = '(' + ph
  } else if (len == 3) {
    ph = '(' + ph + (deleteKey ? '' : ') ')
  } else if (len < 6) {
    ph = '(' + ph.substring(0, 3) + ') ' + ph.substring(3, 6)
  } else if (len == 6) {
    ph =
      '(' +
      ph.substring(0, 3) +
      ') ' +
      ph.substring(3, 6) +
      (deleteKey ? '' : '-')
  } else {
    ph =
      '(' +
      ph.substring(0, 3) +
      ') ' +
      ph.substring(3, 6) +
      '-' +
      ph.substring(6, 10)
  }
  return ph
}

/*
 * allow 0-9 and delete key
 */
export const onPhoneKeyDown = e => {
  ;-1 !== [46, 8, 9, 27, 13, 110, 189].indexOf(e.keyCode) ||
    (/65|67|86|88/.test(e.keyCode) &&
      (e.ctrlKey === true || e.metaKey === true) &&
      (!0 === e.ctrlKey || !0 === e.metaKey)) ||
    (35 <= e.keyCode && 40 >= e.keyCode) ||
    ((e.shiftKey || 48 > e.keyCode || 57 < e.keyCode) &&
      (96 > e.keyCode || 105 < e.keyCode) &&
      e.preventDefault())
}

/*
 * remove all char to digit only 0-9
 */
export const formatDigitOnly = inputText => {
  return inputText.toString().replace(/\D/g, '')
}

export const formatPhoneNumber = (phoneNumberString, noParentheses) => {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    if (noParentheses) return match[1] + '-' + match[2] + '-' + match[3]
    return '(' + match[1] + ') ' + match[2] + '-' + match[3]
  }
  return null
}

/*
 * money input, allow: 0-9 . delete
 */
export const onNumericInputKeydown = e => {
  ;-1 !== [46, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) ||
    (/65|67|86|88/.test(e.keyCode) &&
      (e.ctrlKey === true || e.metaKey === true) &&
      (!0 === e.ctrlKey || !0 === e.metaKey)) ||
    (35 <= e.keyCode && 40 >= e.keyCode) ||
    ((e.shiftKey || 48 > e.keyCode || 57 < e.keyCode) &&
      (96 > e.keyCode || 105 < e.keyCode) &&
      e.preventDefault())
}

export const formatCurrency = (moneyString, decimal = 2) => {
  if (isNaN(moneyString)) {
    return 0
  }
  return parseFloat(Math.round(parseFloat(moneyString) * 100) / 100).toFixed(
    decimal,
  )
}

export const formatMoneyString = (
  moneyString,
  isZero = false,
  isNegative = false,
  negativeType = '',
  decimal = 2,
) => {
  let amountStr = ''
  if (isNaN(moneyString)) {
    return '-'
  }
  if (moneyString === '0.00') isZero = true
  if (moneyString && moneyString[0] === '-') isNegative = true
  if (isZero && isNegative) amountStr = '-'
  if (isZero && !isNegative) amountStr = '-'
  if (isNegative && !isZero) amountStr = '-$' + formatCurrency(moneyString)
  if (!isZero && !isNegative) amountStr = '$' + formatCurrency(moneyString)
  return amountStr
}

export const convertToCurrency = input => {
  let value = input
  // remove all characters that aren't digit or dot
  value = value.replace(/[^0-9.]/g, '')
  // replace multiple dots with a single dot
  value = value.replace(/\.+/g, '.')
  // only allow 2 digits after a dot
  value = value.replace(/(.*\.[0-9][0-9]?).*/g, '$1')
  // replace multiple zeros with a single one
  value = value.replace(/^0+(.*)$/, '0$1')
  // remove leading zero
  value = value.replace(/^0([^.].*)$/, '$1')
  return value
}

export const escapeRegExp = str => {
  str = str.replace(/[\\-\\[\]\\/\\{\\}\\(\\)\\*\\+\\?\\.\\\\^\\$\\|]/g, '\\$&')
  return str
}

export const onDobKeyUp = (e, dobInput) => {
  var dob = dobInput
  dob = dob.replace(/\D/g, '').substring(0, 8)
  // Backspace and Delete keys
  var deleteKey = e.keyCode == 8 || e.keyCode == 46
  var len = dob.length
  if (len == 0) {
  } else if (len < 2) {
    // dob = dob;
  } else if (len == 2) {
    dob = dob + (deleteKey ? '' : '-')
  } else if (len < 4) {
    dob = dob.substring(0, 2) + '-' + dob.substring(2, 4)
  } else if (len == 4) {
    dob =
      dob.substring(0, 2) + '-' + dob.substring(2, 4) + (deleteKey ? '' : '-')
  } else {
    dob =
      dob.substring(0, 2) +
      '-' +
      dob.substring(2, 4) +
      '-' +
      dob.substring(4, 8)
  }
  return dob
}

export const onEinKeyUp = (e, einInput) => {
  var ein = einInput
  ein = ein.replace(/\D/g, '').substring(0, 9)
  // Backspace and Delete keys
  var deleteKey = e.keyCode == 8 || e.keyCode == 46
  var len = ein.length
  if (len == 0) {
  } else if (len < 2) {
    // ein = ein;
  } else if (len == 2) {
    ein = ein + (deleteKey ? '' : '-')
  } else {
    ein = ein.substring(0, 2) + '-' + ein.substring(2, 9)
  }
  return ein
}

export const formatEin = einInput => {
  var ein = einInput
  ein = ein.replace(/\D/g, '').substring(0, 9)
  return ein.substring(0, 2) + '-' + ein.substring(2, 9)
}

export const getPlanStatus = (id, type, simple) => {
  var name = ''
  var color = ''

  switch (id) {
    case parseInt(RUSHABLE_FREE_PLAN_ID):
      color = 'orange'
      name = simple ? 'Free' : 'Free Plan'
      break
    case parseInt(RUSHABLE_MONTHLY_PLAN_ID):
      color = 'green'
      name = simple ? 'Monthly' : 'Monthly Plan'
      break
    case parseInt(RUSHABLE_ANNUAL_PLAN_ID):
      color = 'blue'
      name = simple ? 'Annual' : 'Annual Plan'
      break
    default:
      color = ''
      name = simple ? '-' : '-'
      break
  }

  switch (type) {
    case 'name':
      return name
    case 'color':
      return color
    default:
      return id
  }
}

export const getRoleName = role => {
  let role_name = '-'
  switch (role) {
    case 'brand_admin':
      role_name = 'Brand Admin'
      break
    case 'location_owner':
      role_name = 'Location Owner'
      break
    case 'manager':
      role_name = 'Manager'
      break
    case 'staff':
      role_name = 'Staff'
      break
    case 'channel_partner':
      role_name = 'Channel Partner'
      break
    case 'coordinator':
      role_name = 'Coordinator'
      break
    default:
      break
  }
  return role_name
}

export const currencyFormatter = (value, options) => {
  const defaultOptions = {
    significantDigits: 2,
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbol: '$',
  }
  if (typeof value !== 'number') value = parseFloat(value)
  options = { ...defaultOptions, ...options }
  value = value.toFixed(Math.max(0, ~~options.significantDigits))

  const [currency, decimal] = value.split('.')
  if (!decimal)
    return `${options.symbol}${currency.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      options.thousandsSeparator,
    )}`
  return `${options.symbol}${currency.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    options.thousandsSeparator,
  )}${options.decimalSeparator}${decimal}`
}
