import React, { useState } from 'react'
import { DOMAIN } from '../../../../utils/constant'

function LogHeader(props) {
  let { imgUrl } = props
  const [error, setError] = useState(false)
  const onError = () => {
    setError(true)
  }
  return (
    <span className='log-header'>
      {imgUrl && !error ? (
        <img alt='' src={DOMAIN + imgUrl} onError={onError} />
      ) : (
        <i className='fas fa-user-circle f-36'></i>
      )}
    </span>
  )
}

export default LogHeader
