import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import ReactSelect from 'react-select'
import { BASIC_SELECT_STYLE, BASIC_SELECT_THEME } from '../../utils/constant'
import { filterFromObjectArray } from '../../utils/tools'
import { getOnboardingOptions } from '../../store/actions/onboarding'

const ProjectLeadsFilter = ({ onChange, value = '' }) => {
  const dispatch = useDispatch()
  const onboarding = useSelector(state => state.onboarding)
  let all_option = { value: '', label: 'All Assignees' }
  const [options, setOptions] = useState([all_option])
  let { onboardingOptions, getOnboardingOptionsRequest } = onboarding
  const [selected, setSelected] = useState(all_option)

  useEffect(() => {
    const loadData = () => {
      dispatch(getOnboardingOptions({ type: 'project_leads' }))
    }
    loadData()
  }, [dispatch])

  useEffect(() => {
    setSelected(filterFromObjectArray(options, value, 'value'))
  }, [value, options])

  useEffect(() => {
    if (
      onboardingOptions &&
      onboardingOptions.project_leads &&
      !getOnboardingOptionsRequest
    ) {
      var arr = [{ value: '', label: 'All Assignees' }]
      onboardingOptions.project_leads.forEach(item => {
        arr.push({
          value: item.id?.toString(),
          label: `${item.first_name} ${item.last_name}`,
        })
      })
      setOptions(arr)
    }
  }, [onboardingOptions, getOnboardingOptionsRequest])

  const DropdownIndicator = () => {
    return <i className='fas fa-caret-down multi-select-dropdown-indicator'></i>
  }

  return (
    <ReactSelect
      value={selected}
      name='project_lead'
      options={options}
      className='basic-single'
      classNamePrefix='select'
      onChange={onChange}
      components={{ DropdownIndicator }}
      theme={BASIC_SELECT_THEME}
      styles={BASIC_SELECT_STYLE}
    />
  )
}

export default ProjectLeadsFilter

ProjectLeadsFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
}
