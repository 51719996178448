import React from 'react'
import cn from 'classnames'

const Card = ({ label, children, className }) => {
  return (
    <div
      className={cn(
        'd-flex align-items-center background-gray-12 py-2 px-15px rounded',
        className,
      )}
    >
      <div className='mr-2'>{children}</div>
      <div className='text-14px'>{label}</div>
    </div>
  )
}

export default Card
