import axios from 'axios'
import { toast } from 'react-toastify'
import { replace } from 'connected-react-router'
import { returnStatusesValue } from '../../utils/common'

export const ONBOARDING_CREATE_ONBOARDING_REQUEST =
  'ONBOARDING_CREATE_ONBOARDING_REQUEST'
export const ONBOARDING_CREATE_ONBOARDING_SUCCESS =
  'ONBOARDING_CREATE_ONBOARDING_SUCCESS'
export const ONBOARDING_CREATE_ONBOARDING_FAIL =
  'ONBOARDING_CREATE_ONBOARDING_FAIL'
export const createOnboarding = params => dispatch => {
  dispatch({ type: ONBOARDING_CREATE_ONBOARDING_REQUEST })

  axios
    .request({
      url: '/nc/onboarding/create',
      method: 'post',
      data: params,
      // headers: {
      //   "Content-Type": "multipart/form-data",
      // },
    })
    .then(({ data }) => {
      if (data.redirect) return

      if (data.success) {
        dispatch({
          type: ONBOARDING_CREATE_ONBOARDING_SUCCESS,
          data: data.data,
          msg: data.message,
          errors: data.errors,
        })
        if (data.data) {
          let onboarding_id = data.data.onboarding_id ?? ''
          window.location.href = `/restaurants/create/signup-form?id=${onboarding_id}`
        }
      } else {
        dispatch({
          type: ONBOARDING_CREATE_ONBOARDING_FAIL,
          msg: data.message,
          errors: data.errors,
        })
      }
    })
    .catch(err => {
      dispatch({ type: ONBOARDING_CREATE_ONBOARDING_FAIL })
    })
}

export const ONBOARDING_EDIT_ONBOARDING_REQUEST =
  'ONBOARDING_EDIT_ONBOARDING_REQUEST'
export const ONBOARDING_EDIT_ONBOARDING_SUCCESS =
  'ONBOARDING_EDIT_ONBOARDING_SUCCESS'
export const ONBOARDING_EDIT_ONBOARDING_FAIL = 'ONBOARDING_EDIT_ONBOARDING_FAIL'
export const editOnboarding = params => dispatch => {
  dispatch({ type: ONBOARDING_EDIT_ONBOARDING_REQUEST })

  axios
    .request({
      url: '/nc/onboarding/edit',
      method: 'post',
      data: params,
      // headers: {
      //   "Content-Type": "multipart/form-data",
      // },
    })
    .then(({ data }) => {
      if (data.redirect) return

      if (data.success) {
        dispatch({
          type: ONBOARDING_EDIT_ONBOARDING_SUCCESS,
          data: data.data,
          msg: data.message,
          errors: data.errors,
        })
        dispatch(getOnboardings({ params: {}, refresh: true }))
        dispatch(getOnboarding(params.onboarding_id, true))
      } else {
        dispatch({
          type: ONBOARDING_EDIT_ONBOARDING_FAIL,
          msg: data.message,
          errors: data.errors,
        })
      }
    })
    .catch(err => {
      dispatch({ type: ONBOARDING_EDIT_ONBOARDING_FAIL })
    })
}

export const ONBOARDING_GET_OPTIONS_REQUEST = 'ONBOARDING_GET_OPTIONS_REQUEST'
export const ONBOARDING_GET_OPTIONS_SUCCESS = 'ONBOARDING_GET_OPTIONS_SUCCESS'
export const ONBOARDING_GET_OPTIONS_FAIL = 'ONBOARDING_GET_OPTIONS_FAIL'
export const getOnboardingOptions =
  (params, refresh = true) =>
  dispatch => {
    dispatch({
      type: ONBOARDING_GET_OPTIONS_REQUEST,
      optionsType: params.type,
    })
    axios
      .request({
        url:
          params.type === 'brands_map_view'
            ? '/nc/sales/map_view/brands'
            : '/nc/onboarding/options',
        method: 'get',
        params: {
          type: params.type,
          team_id: params.team_id,
          team_type: params.team_type,
        },
        cache: { ignoreCache: refresh },
      })
      .then(({ data }) => {
        if (data.redirect) return

        if (data.success) {
          dispatch({
            type: ONBOARDING_GET_OPTIONS_SUCCESS,
            data: data.data,
            optionsType: params.type,
            msg: data.message,
            errors: data.errors,
          })
        } else {
          dispatch({
            type: ONBOARDING_GET_OPTIONS_FAIL,
            msg: data.message,
            errors: data.errors,
          })
        }
      })
      .catch(err => {
        dispatch({ type: ONBOARDING_GET_OPTIONS_FAIL })
      })
  }

export const ONBOARDING_GET_ONBOARDINGS_REQUEST =
  'ONBOARDING_GET_ONBOARDINGS_REQUEST'
export const ONBOARDING_GET_ONBOARDINGS_SUCCESS =
  'ONBOARDING_GET_ONBOARDINGS_SUCCESS'
export const ONBOARDING_GET_ONBOARDINGS_FAIL = 'ONBOARDING_GET_ONBOARDINGS_FAIL'
export const getOnboardings =
  ({ params, refresh = false, page, per_page = 15 }) =>
  (dispatch, getState) => {
    dispatch({ type: ONBOARDING_GET_ONBOARDINGS_REQUEST })
    axios
      .request({
        url: '/nc/sales/signups',
        method: 'get',
        params: {
          ...params,
          per_page: per_page,
          page: params.page ?? page,
        },
        cache: { ignoreCache: params ? params.refresh ?? refresh : refresh },
      })
      .then(({ data }) => {
        if (data.redirect) return

        if (data.success) {
          dispatch({
            type: ONBOARDING_GET_ONBOARDINGS_SUCCESS,
            data: data.data,
            msg: data.message,
            errors: data.errors,
          })
        } else {
          dispatch({
            type: ONBOARDING_GET_ONBOARDINGS_FAIL,
            msg: data.message,
            errors: data.errors,
          })
          toast.warn(data.message)
        }
      })
  }

export const ONBOARDING_GET_ONBOARDING_REQUEST =
  'ONBOARDING_GET_ONBOARDING_REQUEST'
export const ONBOARDING_GET_ONBOARDING_SUCCESS =
  'ONBOARDING_GET_ONBOARDING_SUCCESS'
export const ONBOARDING_GET_ONBOARDING_FAIL = 'ONBOARDING_GET_ONBOARDING_FAIL'
export const getOnboarding =
  (onboarding_id, refresh = false) =>
  (dispatch, getState) => {
    dispatch({ type: ONBOARDING_GET_ONBOARDING_REQUEST })
    axios
      .request({
        url: '/nc/sales/signup',
        method: 'get',
        params: {
          onboarding_id,
        },
        cache: { ignoreCache: refresh },
      })
      .then(({ data }) => {
        if (data.redirect) return

        if (data.success) {
          dispatch({
            type: ONBOARDING_GET_ONBOARDING_SUCCESS,
            data: data.data,
            msg: data.message,
            errors: data.errors,
          })
        } else {
          dispatch({
            type: ONBOARDING_GET_ONBOARDING_FAIL,
            msg: data.message,
            errors: data.errors,
          })
          toast.warn(data.message)
        }
      })
      .catch(err => {
        dispatch({
          type: ONBOARDING_GET_ONBOARDING_FAIL,
        })
      })
  }

export const CLEAR_ONBOARDING_ERROR = 'CLEAR_ONBOARDING_ERROR'

export const clearOnboardingError = () => dispatch => {
  dispatch({ type: CLEAR_ONBOARDING_ERROR })

  dispatch({
    type: CLEAR_ONBOARDING_ERROR,
  })
}

export const ONBOARDING_DELETE_DRAFT_REQUEST = 'ONBOARDING_DELETE_DRAFT_REQUEST'
export const ONBOARDING_DELETE_DRAFT_SUCCESS = 'ONBOARDING_DELETE_DRAFT_SUCCESS'
export const ONBOARDING_DELETE_DRAFT_FAIL = 'ONBOARDING_DELETE_DRAFT_FAIL'
export const deleteOnboardingDraft =
  ({ onboarding_id }) =>
  dispatch => {
    dispatch({ type: ONBOARDING_DELETE_DRAFT_REQUEST })
    axios
      .request({
        url: '/nc/onboarding/delete',
        method: 'POST',
        params: { onboarding_id },
      })
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: ONBOARDING_DELETE_DRAFT_SUCCESS,
            msg: data.message,
          })
          dispatch(getOnboardings({ params: {}, refresh: true }))
          dispatch(replace('/signups'))
        } else {
          dispatch({
            type: ONBOARDING_DELETE_DRAFT_FAIL,
            msg: data.message,
          })
        }
      })
  }

export const ONBOARDING_CHANGE_STATUS_REQUEST =
  'ONBOARDING_CHANGE_STATUS_REQUEST'
export const ONBOARDING_CHANGE_STATUS_SUCCESS =
  'ONBOARDING_CHANGE_STATUS_SUCCESS'
export const ONBOARDING_CHANGE_STATUS_FAIL = 'ONBOARDING_CHANGE_STATUS_FAIL'
export const changeOnboardingStatus = params => dispatch => {
  dispatch({ type: ONBOARDING_CHANGE_STATUS_REQUEST })

  axios
    .request({
      url: '/nc/onboarding/status/change',
      method: 'post',
      data: params,
    })
    .then(({ data }) => {
      if (data.redirect) return

      if (data.success) {
        dispatch({
          type: ONBOARDING_CHANGE_STATUS_SUCCESS,
          data: data.data,
          msg: data.message,
          errors: data.errors,
        })
        dispatch(getOnboardings({ params: {}, refresh: true }))
        dispatch(replace('/signups'))
      } else {
        dispatch({
          type: ONBOARDING_CHANGE_STATUS_FAIL,
          msg: data.message,
          errors: data.errors,
        })
      }
    })
    .catch(err => {
      dispatch({ type: ONBOARDING_CHANGE_STATUS_FAIL })
    })
}

export const ONBOARDING_CLEAR_SELECTED_ONBOARDING_REQUEST =
  'ONBOARDING_CLEAR_SELECTED_ONBOARDING_REQUEST'
export const clearSelectedOnboarding = () => dispatch => {
  dispatch({ type: ONBOARDING_CLEAR_SELECTED_ONBOARDING_REQUEST })
}

export const FROM_ONBOARDING_PAGE = 'FROM_ONBOARDING_PAGE'
export const fromOnboardingPage =
  (type = true) =>
  dispatch => {
    dispatch({ type: FROM_ONBOARDING_PAGE, msg: type })
  }

export const ONBOARDING_GET_SETUPFEE_REQUEST = 'ONBOARDING_GET_SETUPFEE_REQUEST'
export const ONBOARDING_GET_SETUPFEE_SUCCESS = 'ONBOARDING_GET_SETUPFEE_SUCCESS'
export const ONBOARDING_GET_SETUPFEE_FAIL = 'ONBOARDING_GET_SETUPFEE_FAIL'
export const getSetupfee =
  (params, refresh = false) =>
  (dispatch, getState) => {
    let coupon_code = params ? params.coupon_code ?? '' : ''
    if (coupon_code === null || coupon_code === '') {
      dispatch({
        type: ONBOARDING_GET_SETUPFEE_SUCCESS,
        msg: '',
        errors: '',
      })
      return
    }

    dispatch({ type: ONBOARDING_GET_SETUPFEE_REQUEST })

    axios
      .request({
        url: '/nc/stripe/coupon/validate',
        method: 'get',
        params: {
          coupon_code: coupon_code,
        },
        cache: { ignoreCache: params ? params.refresh ?? refresh : refresh },
      })
      .then(({ data }) => {
        if (data.redirect) return

        if (data.success) {
          dispatch({
            type: ONBOARDING_GET_SETUPFEE_SUCCESS,
            data: data.data,
            msg: data.message,
            errors: data.errors,
          })
        } else {
          dispatch({
            type: ONBOARDING_GET_SETUPFEE_FAIL,
            msg: data.message,
            errors: data.errors,
          })
        }
      })
  }
