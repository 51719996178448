/**
 * Get Full Name or return -
 * @param {string} first_name
 * @param {string} last_name
 * @returns
 */
export const getFullName = (first_name, last_name) => {
  let firstN = first_name ?? ''
  let lastN = last_name ?? ''
  if (!firstN && !lastN) return '-'
  return `${firstN} ${lastN}`
}
