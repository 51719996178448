import {
  GET_LEADERBOARD_LIST_FAILED,
  GET_LEADERBOARD_LIST,
  GET_LEADERBOARD_LIST_REQUEST,
  GET_LEADERBOARD_DETAIL_REQUEST,
  GET_LEADERBOARD_DETAIL,
  GET_LEADERBOARD_DETAIL_FAILED,
} from '../actions/leaderboard'

const initialState = {
  select_period: [],
  previos_period: [],
  get_leaderboards_list_request: false,
  get_leaderboard_detail_request: false,
  leaderboard_detail: {},
}

const leaderboard = (state = initialState, action) => {
  switch (action.type) {
    case GET_LEADERBOARD_LIST:
      return {
        ...state,
        get_leaderboards_list_request: false,
        select_period: action.data.select_period,
        previos_period: action.data.previous_period,
      }
    case GET_LEADERBOARD_LIST_REQUEST:
      return {
        ...state,
        get_leaderboards_list_request: true,
      }
    case GET_LEADERBOARD_LIST_FAILED:
      return {
        ...state,
        get_leaderboards_list_request: false,
      }
    case GET_LEADERBOARD_DETAIL_REQUEST:
      return {
        ...state,
        get_leaderboard_detail_request: true,
      }
    case GET_LEADERBOARD_DETAIL:
      return {
        ...state,
        leaderboard_detail: action.data,
        get_leaderboard_detail_request: false,
      }
    case GET_LEADERBOARD_DETAIL_FAILED:
      return {
        ...state,
        get_leaderboards_list_request: false,
        get_leaderboard_detail_request: false,
      }
    default:
      return state
  }
}

export default leaderboard
