import React from 'react'
import { Button } from 'reactstrap'
import PropTypes from 'prop-types'
function RequireAccessBtn({ requesting, redirectToStoreDashboard }) {
  return (
    <Button
      className='font-bold btn btn-menu f-12'
      onClick={redirectToStoreDashboard}
    >
      {requesting ? (
        <i className='fad fa-spinner-third fa-spin mr-2'></i>
      ) : null}
      DASHBOARD
    </Button>
  )
}
export default RequireAccessBtn

RequireAccessBtn.propTypes = {
  requesting: PropTypes.bool,
  redirectToStoreDashboard: PropTypes.func.isRequired,
}
