import {
  SEARCH_BRANDS_REQUEST,
  SEARCH_BRANDS_SUCCESS,
  SEARCH_BRANDS_FAIL,
} from '../actions/brands'

const initialState = {
  searchBrandsRequest: false,
  searchBrandsError: '',
  brands: null,
}

const brands = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_BRANDS_REQUEST:
      return {
        ...state,
        searchBrandsRequest: true,
      }
    case SEARCH_BRANDS_SUCCESS:
      return {
        ...state,
        searchBrandsRequest: false,
        brands: action.data,
      }
    case SEARCH_BRANDS_FAIL:
      return {
        ...state,
        searchBrandsRequest: false,
        searchBrandsError: action.msg,
      }

    default:
      return state
  }
}

export default brands
