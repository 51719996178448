import React, { useState } from 'react'
import { Input } from 'reactstrap'
import { useDispatch } from 'react-redux'
import ProgressReview from './ProgressReview'
import {
  noteCreate,
  noteEdit,
  noteDelete,
} from '../../../../store/actions/support'

export default function ProgressNotes({ comments, onboardingId, getData }) {
  const dispatch = useDispatch()
  const [value, setValue] = useState('')
  const [loading, setLoading] = useState(false)
  const handleNoteCreate = () => {
    if (value.length > 0) {
      setLoading(true)
      dispatch(noteCreate(onboardingId, value))
        .then(({ data }) => {
          if (data.success) {
            getData()
            setValue('')
          }
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }
  const handleNoteUpdate = ({ id, content, status, cb }) => {
    dispatch(noteEdit(id, content, status))
      .then(({ data }) => {
        if (data.success) getData()
      })
      .finally(() => {
        cb()
      })
  }
  const handleNoteDelete = ({ id, cb }) => {
    dispatch(noteDelete(id))
      .then(({ data }) => {
        if (data.success) getData()
      })
      .finally(() => {
        cb()
      })
  }
  return (
    <div className='progress-notes'>
      <div className='pn-title'>PROGRESS NOTES</div>
      <div className='pn-import'>
        <Input
          value={value}
          className='pn-import-input'
          type='textarea'
          placeholder='Input notes here'
          onChange={e => setValue(e.target.value)}
        />
        <div className='pn-import-submit' onClick={handleNoteCreate}>
          {loading ? (
            <i className='fad fa-spinner fa-spin' />
          ) : (
            <i className='fas fa-long-arrow-down'></i>
          )}
        </div>
      </div>
      <div className='pn-overflow'>
        {comments.map(comment => (
          <ProgressReview
            key={comment.id}
            comment={comment}
            noteUpdate={handleNoteUpdate}
            handleNoteDelete={handleNoteDelete}
          />
        ))}
      </div>
    </div>
  )
}
