import React from 'react'
import { Input } from 'reactstrap'

import { formatCurrency } from '../../../utils/digit'

export default function ModalItems({
	disabled,
	orderData,
	selectCreditData,
	orderTotal,
	handleSelect,
	handleSelectBlur,
	handleSelectFocus,
}) {
	const isDelivery = orderData.type === 'daas_delivery' || orderData.type === 'delivery'
	return (
		<>
			<div className='d-flex'>
				<div className='cmi-title cmi-title-left'>CREDIT ITEM</div>
				<div className='cmi-title'>CREDIT AMOUNT</div>
			</div>
			<div className='cmi-receipt'>
				<div className='cmi-receipt-title'>
					<span className='cmi-receipt-title-left'>Subtotal</span>
					<span className='cmi-receipt-title-right'>$ {formatCurrency(orderData.subtotal)}</span>
				</div>
				<Input
					value={selectCreditData.subtotal || ''}
					disabled={disabled}
					onChange={e => handleSelect('subtotal', e.target.value)}
					onBlur={e => handleSelectBlur(e.target.value)}
					onFocus={e => handleSelectFocus(e.target.value)}
					style={{
						width: 88,
						height: 36,
						padding: '0 16px',
						textAlign: 'right',
					}}
				/>
				<div
					className='cmi-receipt-check'
					onClick={() => handleSelect('subtotal')}
				>
					<i className={`fas fa-check ${selectCreditData.subtotal ? 'icon-action' : ''}`} />
				</div>
			</div>
			{orderData.custom_fee && Number(orderData.custom_fee) > 0 ? (
				<div className='cmi-receipt'>
					<div className='cmi-receipt-title'>
						<span className='cmi-receipt-title-left'>Custom Fee</span>
					</div>
					<div className='cmi-receipt-price'>
						<span>$ {formatCurrency(orderData.custom_fee)}</span>
					</div>
					<div
						className='cmi-receipt-check'
						onClick={() => handleSelect('custom_fee', orderData.custom_fee)}
					>
						<i className={`fas fa-check ${selectCreditData.custom_fee ? 'icon-action' : ''}`} />
					</div>
				</div>
			) : null}
			<div className='cmi-receipt'>
				<div className='cmi-receipt-title'>
					<span className='cmi-receipt-title-left'>Sales Tax</span>
				</div>
				<div className='cmi-receipt-price'>
					<span>$ {orderData.tax ? formatCurrency(orderData.tax) : '0.00'}</span>
				</div>
				<div
					className='cmi-receipt-check'
					onClick={() => handleSelect('tax', orderData.tax)}
				>
					<i className={`fas fa-check ${selectCreditData.tax ? 'icon-action' : ''}`} />
				</div>
			</div>
			<div className='cmi-receipt'>
				<div className='cmi-receipt-title'>
					<span className='cmi-receipt-title-left'>Convenience Fee</span>
				</div>
				<div className='cmi-receipt-price'>
					<span>$ {orderData.service_fee ? formatCurrency(orderData.service_fee) : '0.00'}</span>
				</div>
				<div
					className='cmi-receipt-check'
					onClick={() => handleSelect('service_fee', orderData.service_fee)}
				>
					<i className={`fas fa-check ${selectCreditData.service_fee ? 'icon-action' : ''}`} />
				</div>
			</div>

			{orderData.type === 'daas_delivery' ? (
				<>
					<div className='cmi-receipt'>
						<div className='cmi-receipt-title'>
							<span className='cmi-receipt-title-left'>Courier Fee <span className='f-12'>(Restaurant)</span></span>
						</div>
						<div className='cmi-receipt-price'>
							<span>$ {orderData.courier_fee_restaurant ? formatCurrency(orderData.courier_fee_restaurant) : '0.00'}</span>
						</div>
						<div
							className='cmi-receipt-check'
							onClick={() => handleSelect('courier_fee_restaurant', orderData.courier_fee_restaurant)}
						>
							<i className={`fas fa-check ${selectCreditData.courier_fee_restaurant ? 'icon-action' : ''}`} />
						</div>
					</div>
					<div className='cmi-receipt'>
						<div className='cmi-receipt-title'>
							<span className='cmi-receipt-title-left'>Courier Fee <span className='f-12'>(Customer)</span></span>
						</div>
						<div className='cmi-receipt-price'>
							<span>$ {orderData.courier_fee_customer ? formatCurrency(orderData.courier_fee_customer) : '0.00'}</span>
						</div>
						<div
							className='cmi-receipt-check'
							onClick={() => handleSelect('courier_fee_customer', orderData.courier_fee_customer)}
						>
							<i className={`fas fa-check ${selectCreditData.courier_fee_customer ? 'icon-action' : ''}`} />
						</div>
					</div>
				</>
			) : null}

			{isDelivery ? (
				<div className='cmi-receipt'>
					<div className='cmi-receipt-title'>
						<span className='cmi-receipt-title-left'>Courier Tip</span>
					</div>
					<div className='cmi-receipt-price'>
						<span>$ {orderData.courier_tip ? formatCurrency(orderData.courier_tip) : '0.00'}</span>
					</div>
					<div
						className='cmi-receipt-check'
						onClick={() => handleSelect('courier_tip', orderData.courier_tip)}
					>
						<i className={`fas fa-check ${selectCreditData.courier_tip ? 'icon-action' : ''}`} />
					</div>
				</div>
			) : null}

			{orderData.type === 'delivery' ? (
				<div className='cmi-receipt'>
					<div className='cmi-receipt-title'>
						<span className='cmi-receipt-title-left'>Delivery Fee</span>
					</div>
					<div className='cmi-receipt-price'>
						<span>$ {orderData.delivery_fee ? formatCurrency(orderData.delivery_fee) : '0.00'}</span>
					</div>
					<div
						className='cmi-receipt-check'
						onClick={() => handleSelect('delivery_fee', orderData.delivery_fee)}
					>
						<i className={`fas fa-check ${selectCreditData.delivery_fee ? 'icon-action' : ''}`} />
					</div>
				</div>
			) : null}

			{orderData.tip && Number(orderData.tip) > 0 ? (
				<div className='cmi-receipt'>
					<div className='cmi-receipt-title'>
						<span className='cmi-receipt-title-left'>Restaurant Tip</span>
					</div>
					<div className='cmi-receipt-price'>
						<span>$ {formatCurrency(orderData.tip)}</span>
					</div>
					<div
						className='cmi-receipt-check'
						onClick={() => handleSelect('tip', orderData.tip)}
					>
						<i className={`fas fa-check ${selectCreditData.tip ? 'icon-action' : ''}`} />
					</div>
				</div>
			) : null}
			<div className='cmi-receipt'>
				<div className='cmi-receipt-title'>
					<span className='cmi-receipt-title-left'>Dispatch Fee</span>
				</div>
				<div className='cmi-receipt-price'>
					<span>$ {orderData.dispatch_fee ? formatCurrency(orderData.dispatch_fee) : '0.00'}</span>
				</div>
				<div
					className='cmi-receipt-check'
					onClick={() => handleSelect('dispatch_fee', orderData.dispatch_fee)}
				>
					<i className={`fas fa-check ${selectCreditData.dispatch_fee ? 'icon-action' : ''}`} />
				</div>
			</div>
			<div className='cmi-total' onClick={() => console.log(selectCreditData, 'selectCreditData')}>TOTAL CREDIT: {orderTotal && Number(orderTotal) > 0 ? `$${formatCurrency(orderTotal)}` : '$ -'}</div>
		</>
	)
}