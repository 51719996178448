import React from 'react'
import cn from 'classnames'
import { UncontrolledPopover, PopoverBody } from "reactstrap";
import { formatCurrency } from '../../../utils/digit'

const OdDetail = ({ selectedOrder }) => {
  const {
    type,
    total,
    order_items,
    price_summary,
    sales_tax,
    confirmed_at,
    order_items_count,
    curbside_info,
    customer_note,
    order_custom_fees
  } = selectedOrder

  return (
    <div>
      {selectedOrder.status === 'canceled' && !selectedOrder.confirmed_at && (
        <div className='my-3'>
          <div className='f-16 bold text-red'>
            Order canceled,{' '}
            {confirmed_at
              ? `$${formatCurrency(
                price_summary.refunds_sum,
              )} has been refunded.`
              : 'no money is collected.'}
          </div>
          <p className='text-red'>Reason: {selectedOrder.reason_to_cancel}</p>
        </div>
      )}

      {selectedOrder.status !== 'canceled' &&
        selectedOrder.price_summary.refunds.length > 0 && (
          <div className='my-3'>
            <div className='f-16 bold text-red'>Refunds Issued</div>
            <ul className='nav flex-column text-red'>
              {selectedOrder.price_summary.refunds.map((refund, key) => (
                <li key={`refudn-${key}`}>
                  {`$${formatCurrency(refund.amount)}`} - {refund.note}
                </li>
              ))}
            </ul>
          </div>
        )}

      {order_items.map((item, i) => (
        <div
          key={i}
          className='d-flex justify-content-between text-cream-75 mt-28px text-16px'
        >
          <div className='d-flex'>
            <div
              className={cn('mr-15px', item.quantity > 1 && 'text-yellow-ef')}
            >
              {item.quantity} x
            </div>
            <div className='text-cream-75'>
              <div>{item.name}</div>
              <div className='text-gray-70 font-weight-bold text-12px'>
                {item.category_name}
              </div>
              {item.options_array
                ? item.options_array.map((option, index) => (
                  <div key={index}>
                    <div className='custom-border-left pl-2 mt-2'>
                      <div>{option.name}</div>
                      <div>
                        {option.value &&
                          option.value.map((val, key) => (
                            <div key={key}>{val}</div>
                          ))}
                      </div>
                    </div>
                  </div>
                ))
                : 'N/A'}
              {item.customer_note && (
                <div className='mt-2'>📝 {item.customer_note}</div>
              )}
            </div>
          </div>
          <div className='odi-footer'>
            <div className='item-price'>
              {item.total.original !== item.total.updated ? (
                <span className='mr-2'>${item.total.original ?? '0.00'}</span>
              ) : null}
              <span className='opp-updated'>
                ${item.total.updated ?? '0.00'}
              </span>
            </div>
          </div>
        </div>
      ))}

      {selectedOrder.location_coupon &&
        selectedOrder.location_coupon.coupon &&
        selectedOrder.location_coupon.coupon.type === 'free_item' && (
          <div className='d-flex text-cream-75 f-14'>
            <div className='odi-header mr-3'>
              <span className={cn(['odi-qty', { warning: false }])}>1</span>
            </div>
            <div className='odi-body mr-3'>
              <div className='name'>
                {selectedOrder.location_coupon.coupon.description}
              </div>
            </div>
            <div className='odi-footer'>
              <div className='price'>
                <span className='opp-updated'>$0.00</span>
              </div>
            </div>
          </div>
        )}

      <div className='custom-border-dotted-top-bottom py-15px mt-28px text-cream-75'>
        <div>
          <span className='mr-2'>📝</span>
          {customer_note ? customer_note : 'N/A'}
        </div>
      </div>

      <div className='text-cream-75 mt-28px text-16px d-flex justify-content-between'>
        <div>
          <div>{order_items_count ?? 0} Items in total</div>
          {curbside_info && (
            <div className='odsh-curbside'>
              <span className='make'>{curbside_info.vehicle_make}, </span>
              <span className='type'>{curbside_info.vehicle_type}, </span>
              <span className='color'>
                {curbside_info.vehicle_color}{' '}
                <i className={'fas fa-stop ' + curbside_info.vehicle_color}></i>
              </span>
            </div>
          )}
        </div>

        <div>
          <div className='d-flex min-w-210px justify-content-between'>
            <div>Subtotal</div>
            <div>${price_summary.subtotal}</div>
          </div>
          {price_summary.coupon && price_summary.coupon > 0 ? (
            <div className='d-flex min-w-210px justify-content-between mt-1'>
              <div>Coupon</div>
              <div>-${price_summary.coupon}</div>
            </div>
          ) : null}
          {price_summary.custom_fee && price_summary.custom_fee > 0 && (
            <div className='d-flex min-w-210px justify-content-between mt-1'>
              <div>
                Custom Fee
                <i
                  className="fas fa-question-circle"
                  id="custom-fee"
                  style={{
                    color: "#ccc",
                    paddingLeft: 5,
                  }}
                />
                <UncontrolledPopover
                  trigger="hover"
                  placement="top"
                  target="custom-fee"
                >
                  <PopoverBody>
                    {order_custom_fees &&
                      order_custom_fees.map((item) => (
                        <div key={item.id}>
                          {item.label_name} (
                          {`$${item.fixed_amount} + ${Number(
                            item.percentage
                          )}%`}
                          )
                        </div>
                      ))}
                  </PopoverBody>
                </UncontrolledPopover>
              </div>
              <div>${price_summary.custom_fee}</div>
            </div>
          )}

          <div className='d-flex min-w-210px justify-content-between mt-1'>
            <div>
              Tax(
              {sales_tax
                ? (Math.round(sales_tax * 100) / 100).toFixed(2) + '%'
                : null}
              )
            </div>
            <div>${price_summary.sales_tax ?? '0.00'}</div>
          </div>
          {type === 'delivery' ? (
            <div className='d-flex min-w-210px justify-content-between mt-1'>
              <div>Delivery Fee</div>
              <div>${price_summary.delivery_fee ?? '0.00'}</div>
            </div>
          ) : null}
          {type === 'daas_delivery' ? (
            <div className='d-flex min-w-210px justify-content-between mt-1'>
              <td>Courier Fee</td>
              <div>${price_summary.courier_fee_customer ?? '0.00'}</div>
            </div>
          ) : null}
          {price_summary.service_fee ? (
            <div className='d-flex min-w-210px justify-content-between mt-1'>
              <div>Convenience Fee</div>
              <div>${price_summary.service_fee ?? '0.00'}</div>
            </div>
          ) : null}
          <div className='d-flex min-w-210px justify-content-between mt-1'>
            <div>{type === 'daas_delivery' ? 'Courier Tip' : 'Tip'}</div>
            <div>
              $
              {type === 'daas_delivery'
                ? price_summary.courier_tip ?? '0.00'
                : price_summary.tip ?? '0.00'}
            </div>
          </div>
          {price_summary.refunds_sum ? (
            <div className='d-flex min-w-210px justify-content-between mt-1'>
              <div>Refund</div>
              <div>-${formatCurrency(price_summary.refunds_sum)}</div>
            </div>
          ) : null}
          <div className='d-flex min-w-210px text-cream-100 justify-content-between font-weight-bold mt-2'>
            <div>Total</div>
            <div>${total}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OdDetail
