import React from 'react'
import StatusTd from '../StatusTd/index'
import states from '../../utils/us-state-converter'
import ClientTablePlan from './ClientTablePlan'
import moment from 'moment'
import UserAvatar from '../UserAvatar/UserAvatar'
import { getOnboardedDays } from '../../utils/getOnboardedDays'
import Comments from './Comments'
import { allSources } from '../../utils/common'
import { currencyFormatter } from '../../utils/digit'

const ClientTableRow = ({ goToSupport, support, mode, progress }) => {
  let {
    id,
    brand,
    address,
    location_id,
    order_count,
    order_amount,
    name,
    rushable_plan,
    online_order_config,
    signup_form,
  } = support

  const signupForm = signup_form
    ? signup_form
    : { onboarding: { onboarding_comments: [] } }
  const { onboarding, source } = signupForm
  const {
    onboarding_comments: comments,
    crm_closer,
    gmb_status,
    google_listing_updated,
    need_help_regain_gmb,
  } = onboarding


  const onboarding_done_at = onboarding?.done_at

  const googleLogoShow =
  need_help_regain_gmb === 'rushable_claiming' &&
    gmb_status !== 'updated' &&
    google_listing_updated !== 1

  let brand_name = brand ? brand.name ?? '' : ''

  let signup_paid_at = signupForm ? signupForm.paid_at : null
  let onboard_days = getOnboardedDays(onboarding_done_at, signup_paid_at)
  const days_30 =
    order_count || order_amount
      ? `${order_count ?? '-'} / ${order_amount ? currencyFormatter(order_amount, { significantDigits: 0 }) : '-'
      }`
      : '-'
  let projectLead = onboarding ? onboarding.project_lead : null
  let assigneeName = projectLead ? projectLead.first_name : null
  let assigneeAvatar =
    projectLead && projectLead.crm_user
      ? projectLead.crm_user.profile_photo
      : null
  let pick_up_active = ''
  let delivery_active = ''
  if (online_order_config)
    if (online_order_config.order_pickup_config)
      if (online_order_config.order_pickup_config.enabled)
        pick_up_active = ' active'
  if (!online_order_config) {
    delivery_active = ''
  } else {
    let { order_doordash_delivery_config, order_in_house_config } =
      online_order_config
    let doordash_delivery = order_doordash_delivery_config
      ? order_doordash_delivery_config.enabled ?? false
      : false
    let in_house = order_in_house_config
      ? order_in_house_config.enabled ?? false
      : false
    delivery_active = doordash_delivery || in_house ? ' active' : ''
  }

  let team_name =
    crm_closer && crm_closer.crm_team ? crm_closer.crm_team.name : ''

  return (
    <tr onClick={e => goToSupport(id, signup_form?.onboarding?.status)}>
      <td className='fixed-width fixed-120'>{brand_name ?? ''}</td>
      <td className='fixed-width fixed-120'>{name ?? ''}</td>
      <td>{location_id ? '#' + location_id : ''}</td>

      <td>
        {address ? (address.state ? states.fullName(address.state) : '') : ''}
      </td>
      {progress === 'onboarding' ? (
        <td>{allSources.find(i => i.value === source)?.label ?? '-'}</td>
      ) : (
        <td className='table-plan-td'>
          <ClientTablePlan plan={rushable_plan} />
        </td>
      )}
      {progress !== 'onboarding' && (
        <td className='fixed-width fixed-120'>{team_name || '-'}</td>
      )}
      {progress !== 'onboarding' && (
        <td className='text-center essentials-td'>
          {' '}
          <span className={'essentials-td-icon' + pick_up_active}>
            {' '}
            <i className='fas fa-shopping-bag'></i>
          </span>{' '}
          <span className={'essentials-td-icon' + delivery_active}>
            {' '}
            <i className='fas fa-car'></i>
          </span>
        </td>
      )}
      {progress !== 'onboarding' ? (
        <td className='text-center'>{days_30}</td>
      ) : null}
      <td className='text-right text-nowrap'>
        {signup_paid_at ? moment(signup_paid_at).format('MMM DD, YYYY') : '-'}
      </td>
      <td className='text-right'>{onboard_days}</td>

      {progress === 'onboarding' ? (
        <td className='text-center text-nowrap'>
          <UserAvatar imgUrl={assigneeAvatar} name={assigneeName} />
        </td>
      ) : null}
      {progress === 'onboarding' ? (
        <td className='text-center'>
          <Comments comments={comments} showGoogle={googleLogoShow} />
        </td>
      ) : null}

      <td className='table-status-td'>
        <StatusTd
          signup_form={signupForm}
          onboarding={signupForm ? onboarding ?? {} : {}}
        />
      </td>
    </tr>
  )
}

export default ClientTableRow
