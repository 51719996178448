import {
  RUSHABLE_FREE_PLAN_ID,
  RUSHABLE_MONTHLY_PLAN_ID,
  RUSHABLE_ANNUAL_PLAN_ID,
} from './constant'

const statusOptions = [
  { value: '', label: 'Showing All', color: '#e9e9e9' },
  { value: 'no_progress', label: 'No Progress', color: '#E9E9E9' },
  { value: 'has_progress', label: 'Has Progress', color: '#F59762' },
  { value: 'onboarding', label: 'Onboarding', color: '#008DFF' },
  { value: 'launched', label: 'Launched', color: '#03C9A9' },
  { value: 'map_view', label: 'Map View', color: '#825CFF' },
]

const creditStatusOptions = [
  { value: '', label: 'Showing All', color: '#e9e9e9' },
  { value: 'pending', label: 'Requested', color: '#FFE200' },
  { value: 'approved', label: 'Approved', color: '#03C9A9' },
  { value: 'denied', label: 'Denied', color: '#ED5F65' },
]

const returnStatusesValue = tempStatus => {
  let result = []
  switch (tempStatus) {
    case 'no_progress':
      result = ['draft', 'withdrawn']
      break
    case 'has_progress':
      result = ['signup_ready']
      break
    case 'onboarding':
      result = ['signup_done']
      break
    case 'launched':
      result = ['done']
      break
    default:
      break
  }
  return result
}

const getPlanTypeFromPlanID = id => {
  let result = {
    label: '',
    color: '#ffffff',
  }
  if (!id) return result
  switch (id.toString()) {
    case RUSHABLE_FREE_PLAN_ID:
      result = {
        label: 'Free',
        color: '#F59762',
      }
      break
    case RUSHABLE_MONTHLY_PLAN_ID:
      result = {
        label: 'Monthly',
        color: '#03C9A9',
      }
      break
    case RUSHABLE_ANNUAL_PLAN_ID:
      result = {
        label: 'Annual',
        color: '#008DFF',
      }
      break
    default:
      break
  }
  return result
}

let allSources = [
  { value: 'self', label: 'By SELF' },
  { value: 'sdr', label: 'By SDR' },
  { value: 'referral', label: 'Referral' },
  { value: 'existing', label: 'Existing' },
  { value: 'inbound', label: 'Inbound' },
  { value: 'affiliate', label: 'Affiliate' },
]

export {
  statusOptions,
  returnStatusesValue,
  getPlanTypeFromPlanID,
  allSources,
  creditStatusOptions,
}
