import React from 'react'
import Table from '../../../components/Table'
import { checkFalseReturnChar, checkFalseReturnChar2 } from '../../../utils/tools'
import TouchPointTable from './TouchPointTable'
import { currencyFormatter } from '../../../utils/digit'
const currencyOptions = {
  significantDigits: 0,
  thousandsSeparator: ',',
  decimalSeparator: '.',
  symbol: '$',
}
const sucolumns = [
  {
    key: 'type',
    name: 'SOURCE',
  },
  {
    key: 'plan',
    name: 'PLAN',
    className: 'text-left',
  },
  {
    key: 'actl',
    name: 'ACTL',
    className: 'text-right',
  },
  // {
  //   key: 'adj',
  //   name: 'ADJ',
  //   className: 'text-center',
  // },
  // {
  //   key: 'net',
  //   name: 'NET',
  //   className: 'text-center',
  // },
  {
    key: 'bonus',
    name: 'BONUS',
    className: 'text-right',
  },
]

const correctionColumns = [
  {
    key: 'source',
    name: 'SOURCE',
  },
  {
    key: 'plan',
    name: 'PLAN',
    className: 'text-left',
  },
  {
    key: 'actl',
    name: 'ACTL',
    className: 'text-right',
  },
  {
    key: 'adj',
    name: 'ADJ $',
    className: 'text-right',
  },
]

const renderSignupBonusFooter = total => {
  return (
    <div className='custom-between table-footer-padding custom-border-top'>
      <div className='text-14px'>🎉 Total Signup Bonus</div>
      <div className='text-24px text-green font-weight-bold'>
        {total ? `+ ${currencyFormatter(total, currencyOptions)}` : '-'}
      </div>
    </div>
  )
}

const renderCorrectionFooter = (total, correction) => {
  return (
    <div>
      <div className='custom-between table-top-footer-padding custom-border-top'>
        <div className='text-12px text-cream-50 line-height-1'>Previous Tier Bonus Correction</div>
        <div className='text-14px text-cream-75 line-height-1'>
          {correction ? `(${currencyFormatter(Math.abs(correction), currencyOptions)})` : '-'}
        </div>
      </div>
      <div className='custom-between table-footer-padding custom-border-top'>
        <div className='text-14px'>📝 Total Correction</div>
        <div className='text-24px text-red font-weight-bold'>
          {total ? `- ${currencyFormatter(Math.abs(total), currencyOptions)}` : '-'}
        </div>
      </div>
    </div>

  )
}

const LeftSideBar = ({ canceledOnboardingShow, totalSignup, touchPoint, tierBonusCorrection }) => {
  const sudata =
    totalSignup?.detail?.map(item => {
      return {
        type: checkFalseReturnChar(item.source),
        actl: checkFalseReturnChar(item.actualy_quantity),
        plan: checkFalseReturnChar(item.plan),
        // adj: checkFalseReturnChar(item.adjust),
        // net: checkFalseReturnChar(item.net),
        bonus: item.bonus
          ? currencyFormatter(item.bonus, currencyOptions)
          : '-',
      }
    }) || []

  const codata = tierBonusCorrection?.correction_detail?.map(item => {
    return {
      source: checkFalseReturnChar(item.source),
      plan: checkFalseReturnChar(item.plan),
      actl: checkFalseReturnChar(item.canceled_quantity),
      adj: checkFalseReturnChar2(Math.abs(item.adjust)),
    }
  }) || []

  return (
    <div>
      {totalSignup && (
        <Table
          autoHeight
          isBoarded
          columns={sucolumns}
          handleRowClicked={row => { }}
          data={sudata}
          trClassName='py-39px'
          className='w-360px leaderboard-left-table'
          tableFooter={renderSignupBonusFooter(totalSignup?.total)}
        />
      )}
      {touchPoint && (
        <TouchPointTable touchPoint={touchPoint} className='leaderboard-touch-table' />
      )}
      {canceledOnboardingShow && (
        <div style={{ marginTop: 32 }}>
          <Table
            autoHeight
            isBoarded
            columns={correctionColumns}
            handleRowClicked={row => { }}
            data={codata}
            trClassName='py-39px'
            className='leaderboard-left-table'
            tableFooter={renderCorrectionFooter(tierBonusCorrection?.correction_total, tierBonusCorrection?.tier_bonus_correction)}
          />
        </div>
      )}
    </div>
  )
}

export default LeftSideBar
