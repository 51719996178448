import React from 'react'

const Document = ({
  size = 16,
  color = 'rgba(233,233,233,0.5)',
  className,
  handleClick,
}) => {
  return (
    <div className={className} onClick={handleClick}>
      <svg xmlns='http://www.w3.org/2000/svg' width={size} viewBox='0 0 16 16'>
        <g id='组_23363' data-name='组 23363' transform='translate(-673 -96)'>
          <path
            id='receipt-solid_2_'
            data-name='receipt-solid (2)'
            d='M11.2.074,10,1.474,8.3.074a.5.5,0,0,0-.6,0L6,1.474,4.3.074a.5.5,0,0,0-.6,0L2,1.474.8.074a.5.5,0,0,0-.8.4v15a.5.5,0,0,0,.8.4l1.2-1.4,1.7,1.4a.5.5,0,0,0,.6,0l1.7-1.4,1.7,1.4a.5.5,0,0,0,.6,0l1.7-1.4,1.2,1.4a.5.5,0,0,0,.8-.4v-15a.5.5,0,0,0-.8-.4ZM10,11.223a.251.251,0,0,1-.25.25H2.25a.251.251,0,0,1-.25-.25v-.5a.251.251,0,0,1,.25-.25h7.5a.251.251,0,0,1,.25.25Zm0-3a.251.251,0,0,1-.25.25H2.25A.251.251,0,0,1,2,8.223v-.5a.251.251,0,0,1,.25-.25h7.5a.251.251,0,0,1,.25.25Zm0-3a.251.251,0,0,1-.25.25H2.25A.251.251,0,0,1,2,5.223v-.5a.251.251,0,0,1,.25-.25h7.5a.251.251,0,0,1,.25.25Z'
            transform='translate(675.001 96.027)'
            fill={color}
          />
          <rect
            id='矩形_5207'
            data-name='矩形 5207'
            width='16'
            height='16'
            transform='translate(673 96)'
            fill='none'
          />
        </g>
      </svg>
    </div>
  )
}

export default Document
