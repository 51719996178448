import React from 'react'
import { Card, Table } from 'reactstrap'
import ReferralTableBody from './ReferralTableBody'
import ReferralTableHead from './ReferralTableHead'

const ReferralTable = ({
  isLoading,
  referralList,
  countOrder,
  onChangeFilter,
	editReward,
}) => {
  return (
    <Card className='primary-table support-table'>
      <Table responsive hover>
        <ReferralTableHead
          onChangeFilter={onChangeFilter}
          countOrder={countOrder}
        />
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan='12'>
                <i className='fad fa-spinner fa-spin mr-2'></i> Loading
              </td>
            </tr>
          ) : (
            <ReferralTableBody
							referralList={referralList}
							editReward={editReward}
            />
          )}
          <tr>
            <td colSpan='12' className='p-0 ponit-events-none'></td>
          </tr>
        </tbody>
      </Table>
    </Card>
  )
}

export default ReferralTable
