import React from 'react'
import { Card, Table } from 'reactstrap'
import CreditTableBody from './CreditTableBody'
import CreditTableHead from './CreditTableHead'

const CreditTable = ({
  isLoading,
  creditList,
  openCreditModal,
}) => {
  return (
    <Card className='primary-table support-table'>
      <Table responsive hover>
        <CreditTableHead />
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan='12'>
                <i className='fad fa-spinner fa-spin mr-2'></i> Loading
              </td>
            </tr>
          ) : (
            <CreditTableBody
              creditList={creditList}
              openCreditModal={openCreditModal}
            />
          )}
          <tr>
            <td colSpan='12' className='p-0 ponit-events-none'></td>
          </tr>
        </tbody>
      </Table>
    </Card>
  )
}

export default CreditTable
