import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useHistory } from 'react-router-dom'

import Modal from '../../components/Modal'
import DetailSideBar from './components/DetailSideBar'
import DetailLeftBar from './components/DetailLeftBar'
import { useSelector, useDispatch } from 'react-redux'
import { getSpecificOrder } from '../../store/actions/orders'
import { closeModal } from '../../store/actions/ui'
import Loading from '../../components/Loading'

const LiveOrderDetail = ({ }) => {
  const orders = useSelector(state => state.orders)
  const dispatch = useDispatch()
  const { orderId } = useParams()
  const history = useHistory()

  const locationState = history.location.state

  useEffect(() => {
    if (orderId) {
      dispatch(getSpecificOrder(orderId, null, true))
    }
  }, [orderId])

  const successChangeOrderStatus = id => {
    dispatch(getSpecificOrder(id, null, true))
  }

  const goBack = () => {
    dispatch(closeModal())
    const location = {
      pathname: `/liveorders`,
      state: {
        ...locationState,
      },
    }
    history.push(location)
  }

  return (
    <div>
      {Object.keys(orders?.selected_order).length > 0 &&
        !orders.get_specific_order_request ? (
        <Modal isOpen={true} handleClose={goBack}>
          {orders.get_specific_order_request ? (
            <Loading />
          ) : (
            <div>
              <h1>
                {orders?.selected_order?.id}
              </h1>
              <div className='d-flex justify-content-center mt-4'>
                <div className='d-flex justify-content-between'>
                  <DetailSideBar
                    successChangeOrderStatus={id => {
                      successChangeOrderStatus(id)
                    }}
                    selectedOrder={orders.selected_order}
                    className='mr-5'
                  />
                  <DetailLeftBar order={orders.selected_order} />
                </div>
              </div>
            </div>
          )}
        </Modal>
      ) : (
        <Loading />
      )}
    </div>
  )
}

export default LiveOrderDetail
