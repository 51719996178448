import React from 'react'
import NinjaPagination from '../../../../Common/NinjaPagination'

function Pagination(props) {
  let { orders } = props
  if (!orders) return null
  return (
    <div className='support-od-table-pagination'>
      <NinjaPagination
        currentPage={orders.current_page}
        totalPages={orders.last_page}
        pageNeighbours={1}
        goToPage={page => props.goToPage(page)}
      />
    </div>
  )
}
export default Pagination
