import {
  SUPPORT_GET_SUPPORTS_REQUEST,
  SUPPORT_GET_SUPPORTS_SUCCESS,
  SUPPORT_GET_SUPPORTS_FAIL,
  SUPPORT_GET_SUPPORT_REQUEST,
  SUPPORT_GET_SUPPORT_SUCCESS,
  SUPPORT_GET_SUPPORT_FAIL,
  SUPPORT_UPLOAD_MENU_REQUEST,
  SUPPORT_UPLOAD_MENU_SUCCESS,
  SUPPORT_UPLOAD_MENU_FAIL,
  SUPPORT_GET_EXPORT_REQUEST,
  SUPPORT_GET_EXPORT_SUCCESS,
  SUPPORT_GET_EXPORT_FAIL,
  SUPPORT_REMOVE_SUPPORT_DATA,
  SUPPORT_SET_SIGNUP_FORM_INFO,
} from '../actions/support'

const initialState = {
  getSupportsRequest: false,
  getSupportsError: null,
  uploadMenuRequest: false,
  uploadMenuSuccess: false,
  signupFormBrand: '',
}

const support = (state = initialState, action) => {
  switch (action.type) {
    case SUPPORT_GET_SUPPORTS_REQUEST:
      return {
        ...state,
        getSupportsRequest: true,
      }
    case SUPPORT_GET_SUPPORTS_SUCCESS:
      return {
        ...state,
        supports: action.data,
        getSupportsRequest: false,
        getSupportsError: null,
      }
    case SUPPORT_GET_SUPPORTS_FAIL:
      return {
        ...state,
        getSupportsRequest: false,
        getSupportsError: action.msg,
      }
    case SUPPORT_GET_SUPPORT_REQUEST:
      return {
        ...state,
        getSupportRequest: true,
      }
    case SUPPORT_GET_SUPPORT_SUCCESS:
      return {
        ...state,
        support: action.data,
        getSupportRequest: false,
        getSupportError: null,
      }
    case SUPPORT_GET_SUPPORT_FAIL:
      return {
        ...state,
        getSupportRequest: false,
        getSupportError: action.msg,
      }
    case SUPPORT_REMOVE_SUPPORT_DATA:
      return {
        ...state,
        support: null,
      }
    case SUPPORT_SET_SIGNUP_FORM_INFO:
      return {
        ...state,
        signupFormInfo: action.info,
      }
    case SUPPORT_UPLOAD_MENU_REQUEST:
      return {
        ...state,
        uploadMenuRequest: true,
        uploadMenuError: null,
        uploadMenuSuccess: false,
      }
    case SUPPORT_UPLOAD_MENU_SUCCESS:
      return {
        ...state,
        uploadMenuRequest: false,
        uploadMenuError: null,
        uploadMenuSuccess: true,
      }
    case SUPPORT_UPLOAD_MENU_FAIL:
      return {
        ...state,
        uploadMenuRequest: false,
        uploadMenuError: action.msg,
        uploadMenuSuccess: false,
      }
    case SUPPORT_GET_EXPORT_REQUEST:
      return {
        ...state,
        getExportRequest: true,
        getExportError: null,
        getExportSuccess: false,
      }
    case SUPPORT_GET_EXPORT_SUCCESS:
      return {
        ...state,
        getExportRequest: false,
        getExportError: null,
        getExportSuccess: true,
      }
    case SUPPORT_GET_EXPORT_FAIL:
      return {
        ...state,
        getExportRequest: false,
        getExportError: action.msg,
        getExportSuccess: false,
      }
    default:
      return state
  }
}

export default support
