import React from 'react'
import { Button } from 'reactstrap'
import classnames from 'classnames'

function Export(props) {
  let { getExportRequest, getExportError, getExportSuccess } = props
  return (
    <div>
      <div className='dashed-line'></div>
      <div className='support-upload px-0'>
        <div
          className={classnames(
            'form-group-content-primary text-center d-block',
            { ' text-red': getExportError, 'text-white': getExportSuccess },
          )}
          style={{ marginBottom: '10px' }}
        >
          {getExportError
            ? getExportError
            : getExportRequest
            ? 'To be generated…'
            : ''}
        </div>
        <Button
          color='white-block'
          type='submit'
          className='w-100'
          block
          onClick={e => props.getGsheetLink()}
        >
          Generate gsheet link
        </Button>
      </div>
      <div className='dashed-line'></div>
    </div>
  )
}
export default Export
