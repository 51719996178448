import React from 'react'
import { connect } from 'react-redux'
import AffiliateTeam from '../../../components/AffiliateTeam/index'
import {
  getAffiliates,
  changeFacadeCloser,
  clearClaim,
  claimCommission,
} from '../../../store/actions/affiliate'
import { getOnboardingOptions } from '../../../store/actions/onboarding'
import { getUsers } from '../../../store/actions/auth'

class AffiliateTeamIndex extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      filterData: {},
      usersOptions: [],
      claimModalOpen: false,
    }
    this.goBack = this.goBack.bind(this)
    this.goToPage = this.goToPage.bind(this)
    this.getOptions = this.getOptions.bind(this)
    this.onFilterChange = this.onFilterChange.bind(this)
    this.renderUsersOptions = this.renderUsersOptions.bind(this)
    this.getUsers = this.getUsers.bind(this)
    this.changeFacadeCloser = this.changeFacadeCloser.bind(this)
    this.claimCommission = this.claimCommission.bind(this)
    this.openProfile = this.openProfile.bind(this)
    this.toggleClaimModal = this.toggleClaimModal.bind(this)
    this.clearClaim = this.clearClaim.bind(this)
  }

  componentDidMount() {
    let team_id = this.props.match.params.teamId
    this.props.getAffiliates({ team_id: team_id, refresh: true })
    this.renderUsersOptions()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.users !== this.props.users) {
      this.renderUsersOptions()
    }
    let team_id = this.props.match.params.teamId
    if (prevProps.profile !== this.props.profile) {
      this.props.getAffiliates({ team_id: team_id })
    }
    if (prevProps.selectedTeamId !== this.props.selectedTeamId) {
      this.props.getAffiliates({ team_id: team_id })
    }
  }

  renderUsersOptions() {
    let { users } = this.props
    let options = []
    if (!users) return null
    if (!users.data) return null
    if (!users.data.length === 0) return null
    users.data.map(user => {
      let user_name = user.first_name + ' ' + user.last_name
      options.push({ value: user.id, label: user_name })
    })

    this.setState({
      usersOptions: options,
    })
  }

  goToPage(page) {
    let team_id = this.props.match.params.teamId
    this.setState({
      filterData: {
        ...this.state.filterData,
        page: page,
        team_id: team_id,
      },
    })

    this.props.getAffiliates({ params: this.state.filterData, team_id, page })
  }

  getOptions(type) {
    let team_id = this.props.match.params.teamId
    this.setState({
      options_type: type,
    })

    this.props.getOnboardingOptions({ type, team_id })
  }

  onFilterChange(name, val) {
    let team_id = this.props.match.params.teamId
    let filterData = {
      ...this.state.filterData,
      [name]: val,
      team_id: team_id,
      page: 1,
    }
    this.setState({
      filterData,
    })

    this.props.getAffiliates(filterData)
  }

  goBack() {
    this.props.history.push({
      pathname: '/affiliates',
      state: this.props.location.state,
    })
  }
  getUsers(type) {
    this.props.getUsers({ type: type })
  }

  changeFacadeCloser(user_id, closer_id) {
    let filterData = this.state.filterData
    let team_id = this.props.match.params.teamId
    this.props.changeFacadeCloser({ user_id, closer_id, filterData, team_id })
  }

  claimCommission(commission) {
    let params = {
      commission: commission,
      // type: "claim",
      // commission_type: "claim",
    }
    this.props.claimCommission(params)
  }

  openProfile() {
    this.props.history.push({
      pathname: `/profile`,
      state: { prevFormData: this.state.formData },
    })
  }

  toggleClaimModal() {
    this.setState({
      claimModalOpen: !this.state.claimModalOpen,
    })
  }

  clearClaim() {
    let team_id = this.props.match.params.teamId
    this.props.getAffiliates({ team_id: team_id, refresh: true })
    this.props.clearClaim()
  }

  render() {
    let team_name = this.props.match.params.teamName

    return (
      <>
        <AffiliateTeam
          goBack={this.goBack}
          goToPage={this.goToPage}
          affiliates={this.props.affiliates}
          filterData={this.state.filterData}
          getOptions={this.getOptions}
          onboardingOptions={this.props.onboardingOptions}
          onFilterChange={this.onFilterChange}
          usersOptions={this.state.usersOptions}
          getUsers={this.getUsers}
          changeFacadeCloser={this.changeFacadeCloser}
          claimCommission={this.claimCommission}
          profile={this.props.profile}
          openProfile={this.openProfile}
          location={this.props.location}
          team_name={team_name}
          toggleClaimModal={this.toggleClaimModal}
          claimModalOpen={this.state.claimModalOpen}
          getAffiliatesRequest={this.props.getAffiliatesRequest}
          getAffiliatesError={this.props.getAffiliatesError}
          changeFacadeCloserRequest={this.props.changeFacadeCloserRequest}
          changeFacadeCloserError={this.props.changeFacadeCloserError}
          claimCommissionRequest={this.props.claimCommissionRequest}
          claimCommissionError={this.props.claimCommissionError}
          claimCommissionSuccess={this.props.claimCommissionSuccess}
          clearClaim={this.clearClaim}
          getOnboardingOptionsRequestType={
            this.props.getOnboardingOptionsRequestType
          }
        ></AffiliateTeam>
      </>
    )
  }
}

const mapStateToProps = state => {
  let {
    affiliates,
    getAffiliatesRequest,
    getAffiliatesError,
    changeFacadeCloserRequest,
    changeFacadeCloserError,
    claimCommissionRequest,
    claimCommissionError,
    claimCommissionSuccess,
    selectedTeamId,
  } = state.affiliate
  let { profile, users } = state.auth
  return {
    auth: state.auth,
    profile,
    users,
    affiliates,
    selectedTeamId,
    getAffiliatesRequest,
    getAffiliatesError,
    changeFacadeCloserRequest,
    changeFacadeCloserError,
    claimCommissionRequest,
    claimCommissionError,
    claimCommissionSuccess,
    onboardingOptions: state.onboarding.onboardingOptions,
    getOnboardingOptionsRequestType:
      state.onboarding.getOnboardingOptionsRequestType,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getUsers: params => dispatch(getUsers(params)),
    getAffiliates: params => dispatch(getAffiliates(params)),
    claimCommission: params => dispatch(claimCommission(params)),
    getOnboardingOptions: params => dispatch(getOnboardingOptions(params)),
    changeFacadeCloser: params => dispatch(changeFacadeCloser(params)),
    clearClaim: params => dispatch(clearClaim(params)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AffiliateTeamIndex)
