import React from 'react'

const CircleClose = ({
  size = 44,
  color = '#292a33',
  className,
  handleClick,
}) => {
  return (
    <div className={className} onClick={handleClick}>
      <svg xmlns='http://www.w3.org/2000/svg' width={size} viewBox='0 0 44 44'>
        <g
          id='Group_22014'
          data-name='Group 22014'
          transform='translate(-0.043)'
        >
          <circle
            id='Ellipse_9'
            data-name='Ellipse 9'
            cx='22'
            cy='22'
            r='22'
            transform='translate(0.043)'
            fill={color}
          />
          <path
            id='times-light_1_'
            data-name='times-light (1)'
            d='M10.01,104.258,15.3,98.965,16.4,97.873a.413.413,0,0,0,0-.584l-1.168-1.168a.413.413,0,0,0-.584,0l-6.385,6.385L1.873,96.121a.413.413,0,0,0-.584,0L.121,97.289a.413.413,0,0,0,0,.584l6.386,6.386L.121,110.644a.413.413,0,0,0,0,.584L1.289,112.4a.413.413,0,0,0,.584,0l6.386-6.385,5.294,5.294,1.092,1.092a.413.413,0,0,0,.584,0l1.168-1.168a.413.413,0,0,0,0-.584Z'
            transform='translate(13.764 -82.236)'
            fill='rgba(233,233,233,0.5)'
          />
        </g>
      </svg>
    </div>
  )
}

export default CircleClose
