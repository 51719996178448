import React from 'react'
import { FormGroup, Label, Input, Row, Col } from 'reactstrap'
import ReactSelect from 'react-select'
import { BASIC_SELECT_STYLE, BASIC_SELECT_THEME } from '../../utils/constant'
import {
  filterFromObjectArrayIsMulti,
  filterFromObjectArray,
} from '../../utils/tools'
import BrandSearch from './BrandSearch'

const DropdownIndicator = () => {
  return <i className='fas fa-caret-down multi-select-dropdown-indicator'></i>
}

function AccountContent(props) {
  let { formData, profile, cuisineTypes } = props
  let {
    brand_name,
    rep_first_name,
    rep_last_name,
    rep_email,
    rep_phone,
    cuisine_type_ids,
    primary_language,
  } = formData
  let rushable_team = profile
    ? profile.crm_team
      ? profile.crm_team.type === 'rushable'
        ? true
        : false
      : false
    : false

  const handleChange = values => {
    const formatValue = values.map(value => {
      return value.value
    })
    props.onChange('cuisine_type_ids', formatValue)
  }

  const renderCuisineTypes = () => {
    return (
      <>
        <ReactSelect
          closeMenuOnSelect={false}
          placeholder='Cuisine type(s)'
          className={'basic-multi-select'}
          classNamePrefix='select'
          value={filterFromObjectArrayIsMulti(
            cuisineTypes,
            cuisine_type_ids,
            'value',
          )}
          isMulti
          options={cuisineTypes}
          onChange={value => handleChange(value)}
          components={{ DropdownIndicator }}
          isDisabled={!props.canUpdate}
          theme={BASIC_SELECT_THEME}
          styles={{
            ...BASIC_SELECT_STYLE,
            multiValue: (styles, { data }) => ({
              ...styles,
              height: 20,
              border: '1px solid rgba(233, 233, 233, 0.25)',
              borderRadius: 4,
              backgroundColor: 'transparent',
              alignItems: 'center'
            }),
            multiValueLabel: (styles, { data }) => ({
              ...styles,
              color: '#E9E9E9',
              fontSize: 10,
              padding: 0,
              paddingLeft: 4,
              transform: 'scale(calc(10/12))',
              transformOrigin: 'center',
            }),
            multiValueRemove: (styles, { data }) => ({
              ...styles,
              backgroundColor: 'transparent',
              paddingLeft: 0,
              color: 'rgba(233, 233, 233, 0.5)',
              ':hover': {
                backgroundColor: '#292a33',
                color: 'rgba(233, 233, 233, 0.5)',
              },
            }),
          }}
        />
      </>
    )
  }

  const renderPrimaryLanguage = () => {
    const options = [
      {
        value: 'english',
        label: 'English',
      },
      {
        value: 'chinese',
        label: 'Chinese',
      },
      {
        value: 'spanish',
        label: 'Spanish',
      },
    ]
    return (
      <ReactSelect
        placeholder='Primary language'
        className={'basic-single'}
        classNamePrefix='select'
        value={filterFromObjectArray(options, primary_language, 'value')}
        options={options}
        onChange={({ value }) => {
          props.onChange('primary_language', value)
        }}
        components={{ DropdownIndicator }}
        isDisabled={!props.canUpdate}
        theme={BASIC_SELECT_THEME}
        styles={BASIC_SELECT_STYLE}
      />
    )
  }

  return (
    <>
      <Row form>
        <Col>
          <Label>BRAND REGISTRATION</Label>
        </Col>
      </Row>
      <Row form>
        <Col>
          <FormGroup>
            <BrandSearch
              brandName={brand_name || ''}
              disabled={!props.canUpdate}
              onChange={({ type, value }) => props.onChange(type, value)}
            />
            {brand_name ? null : (
              <i className='fas fa-exclamation exclamation'></i>
            )}
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col>
          <FormGroup>
            {renderCuisineTypes()}
            {cuisine_type_ids.length > 0 ? null : (
              <i className='fas fa-exclamation exclamation'></i>
            )}
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col>
          <Label>ACCOUNT USER CREATION</Label>
        </Col>
      </Row>
      <Row form>
        <Col>
          <FormGroup>
            <Input
              type='text'
              name='rep_first_name'
              placeholder={'First name'}
              value={rep_first_name || ''}
              onChange={e => props.onChange('rep_first_name', e.target.value)}
              disabled={!props.canUpdate}
            />
            {rushable_team ? null : rep_first_name && rep_last_name ? null : (
              <i className='fas fa-exclamation exclamation'></i>
            )}
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Input
              type='text'
              name='rep_last_name'
              value={rep_last_name || ''}
              placeholder={'Last name'}
              onChange={e => props.onChange('rep_last_name', e.target.value)}
              disabled={!props.canUpdate}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col>
          <FormGroup>
            <Input
              type='email'
              name='rep_email'
              placeholder={'Email'}
              value={rep_email || ''}
              onChange={e => props.onChange('rep_email', e.target.value)}
              disabled={!props.canUpdate}
            />
            {rushable_team ? null : rep_email ? null : (
              <i className='fas fa-exclamation exclamation'></i>
            )}
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col>
          <FormGroup>
            <Input
              type='text'
              name='rep_phone'
              placeholder={'Cellphone'}
              onChange={e => props.onChange('rep_phone', e.target.value)}
              onKeyUp={props.onRepPhoneKeyUp}
              onKeyDown={props.onPhoneKeyDown}
              value={rep_phone || ''}
              disabled={!props.canUpdate}
            />
            {rep_phone ? null : (
              <i className='fas fa-exclamation exclamation'></i>
            )}
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col>
          <FormGroup>
            {renderPrimaryLanguage()}
            {primary_language ? null : (
              <i className='fas fa-exclamation exclamation'></i>
            )}
          </FormGroup>
        </Col>
      </Row>
    </>
  )
}

export default AccountContent
