import { OPEN_MODAL, CLOSE_MODAL } from '../actions/ui'

const initialState = {
  show_modal: false,
}

const ui = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_MODAL:
      return {
        ...state,
        show_modal: true,
      }
    case CLOSE_MODAL:
      return {
        ...state,
        show_modal: false,
      }
    default:
      return state
  }
}

export default ui
