import React from 'react'
import { Row, Col } from 'reactstrap'
import { DebounceInput } from 'react-debounce-input'

import { formatCurrency } from '../../../utils/digit'

export default function ModalOrderId({
	disabled,
	orderId,
	orderData,
	handleSetOrderId,
}) {
	const openOrder = () => {
		window.open(`${window.location.origin}/liveorders/${orderId}`)
	}
	return (
		<>
			<Row className='form-row'>
				<Col>
					<div className='cmi-title cmi-title-w156'>ASSO. ORDER ID</div>
				</Col>
				<Col>
					<div className='cmi-title cmi-title-w156 cmi-ml-5'>ORDER TOTAL</div>
				</Col>
			</Row>
			<Row className='form-row'>
				<Col>
					<DebounceInput
						value={orderId || ''}
						placeholder={'Input Order ID'}
						disabled={disabled}
						name='owner'
						className='cmi-title-w156 form-control'
						debounceTimeout={1000}
						onChange={e => handleSetOrderId(e.target.value)}
					/>
				</Col>
				<Col>
					<div className='cmi-title-w156 cmi-order-total cmi-ml-5' onClick={openOrder}>
						<i className="fas fa-receipt" />
						$ {orderData && orderData.order_total ? formatCurrency(orderData.order_total) : '-'}
					</div>
				</Col>
			</Row>
		</>
	)
}