import React from 'react'
import PropTypes from 'prop-types'
import LogHeader from '../Support/SupportContent/BasicInfo/LogHeader'

const UserAvatar = ({ imgUrl, name }) => {
  if (!name) return '-'
  return (
    <div className='userAvatar'>
      <LogHeader imgUrl={imgUrl} />
      <span className='userAvatar_name mw-100px'>{name}</span>
    </div>
  )
}

export default UserAvatar

UserAvatar.propTypes = {
  imgUrl: PropTypes.string,
  name: PropTypes.string,
}
