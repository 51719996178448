import React from 'react'

const DefaultAvatar = ({ size = 16, className, handleClick }) => {
  return (
    <div className={className} onClick={handleClick}>
      <svg xmlns='http://www.w3.org/2000/svg' width={size} viewBox='0 0 16 16'>
        <g id='组_23208' data-name='组 23208' transform='translate(-571 -54)'>
          <path
            id='user-tie-solid'
            d='M7,8A4,4,0,1,0,3,4,4,4,0,0,0,7,8ZM9.994,9.019,8.5,15l-1-4.25L8.5,9h-3l1,1.75L5.5,15,4.006,9.019A4.193,4.193,0,0,0,0,13.2v1.3A1.5,1.5,0,0,0,1.5,16h11A1.5,1.5,0,0,0,14,14.5V13.2A4.193,4.193,0,0,0,9.994,9.019Z'
            transform='translate(572 54)'
            fill='rgba(233,233,233,0.5)'
          />
          <rect
            id='矩形_5743'
            data-name='矩形 5743'
            width='16'
            height='16'
            transform='translate(571 54)'
            fill='none'
          />
        </g>
      </svg>
    </div>
  )
}

export default DefaultAvatar
