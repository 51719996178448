import React from 'react'
import { Row, Col, Card } from 'reactstrap'
import { Table } from 'reactstrap'
import ReactSelect from 'react-select'
import NinjaPagination from '../Common/NinjaPagination'
import { BASIC_SELECT_STYLE, BASIC_SELECT_THEME } from '../../utils/constant'
import moment from 'moment'
import { filterFromObjectArray } from '../../utils/tools'

function Affiliates(props) {
  let { teamAffiliates, onboardingOptions } = props
  let { team_ids, active } = props.filterData

  const renderTeamsFilter = () => {
    let all_options = [{ value: '', label: 'All Teams', id: '' }]

    if (onboardingOptions && onboardingOptions.teams)
      onboardingOptions.teams.forEach((team, key) => {
        all_options.push({
          value: team.name,
          label: team.name,
          id: team.id,
          name: team.name,
        })
        return all_options
      })

    const DropdownIndicator = () => {
      return (
        <i className='fas fa-caret-down multi-select-dropdown-indicator'></i>
      )
    }

    const changeTeamIds = id => {
      let team_ids = []
      team_ids.push(id)
      props.onFilterChange('team_ids', team_ids)
    }
    return (
      <ReactSelect
        value={filterFromObjectArray(all_options, team_ids || '', 'id')}
        name='team_name'
        options={all_options}
        className='basic-single'
        classNamePrefix='select'
        onChange={value => changeTeamIds(value.id)}
        onMenuOpen={e => props.getOptions('teams')}
        components={{ DropdownIndicator }}
        theme={BASIC_SELECT_THEME}
        styles={BASIC_SELECT_STYLE}
      />
    )
  }

  const renderStatusesFilter = () => {
    let all_options = [
      { value: '', label: 'All Statuses' },
      { value: 1, label: 'Active' },
      { value: 0, label: 'Disqualified' },
    ]
    const DropdownIndicator = () => {
      return (
        <i className='fas fa-caret-down multi-select-dropdown-indicator'></i>
      )
    }

    return (
      <ReactSelect
        value={filterFromObjectArray(all_options, active || '', 'value')}
        name='status'
        options={all_options}
        // menuIsOpen={true}
        className='basic-single'
        classNamePrefix='select'
        onChange={value => props.onFilterChange('active', value.value)}
        components={{ DropdownIndicator }}
        theme={BASIC_SELECT_THEME}
        styles={BASIC_SELECT_STYLE}
      />
    )
  }

  const renderAffiliates = () => {
    if (!teamAffiliates) return null
    if (!teamAffiliates.data) return null
    if (teamAffiliates.data.length === 0)
      return (
        <tr>
          <td colSpan='10' className='p-0 ponit-events-none'>
            <div className='text-white'>No data</div>
          </td>
        </tr>
      )
    const statusFunc = active => {
      return (
        <td className='table-status-td'>
          <div className={'table-status'}>
            <span
              className={
                'table-status-span ' + (!active ? 'disqualified' : 'active')
              }
            ></span>
            {!active ? 'Disqualified' : 'Active'}
          </div>
        </td>
      )
    }

    return teamAffiliates.data.map((affiliate, key) => {
      let month_text =
        affiliate.months === 0
          ? '0'
          : affiliate.months
          ? affiliate.months === 1
            ? affiliate.months + ' month'
            : affiliate.months + ' months'
          : '-'
      let training_text = affiliate.renew_training_days
        ? affiliate.renew_training_days > 1
          ? affiliate.renew_training_days + ' days'
          : affiliate.renew_training_days + ' day'
        : '-'

      let disqualified = affiliate.disqualified_at
        ? moment(affiliate.disqualified_at, 'hh:mm').format('MMM DD,YYYY')
        : '-'

      return (
        <tr
          key={key}
          onClick={e => props.seeAffiliateDetail(affiliate.id, affiliate.name)}
        >
          <td>{affiliate.name ?? ''}</td>
          <td className='text-right'>{affiliate.signup ?? ''}</td>
          <td className='text-right'>{affiliate.signup ?? ''}</td>
          <td className='text-right'>{month_text}</td>
          <td className='text-right'>
            {affiliate.average ? affiliate.average + '/mo' : '-'}
          </td>
          <td className='text-right'>{training_text}</td>
          <td className='text-right text-blue'>
            {'$' + (affiliate.pending_amount ?? '')}
          </td>
          <td className='text-right text-green'>
            {'$' + (affiliate.available_amount ?? '-')}
          </td>
          <td className='text-right'>{disqualified}</td>
          {statusFunc(affiliate.active)}
        </tr>
      )
    })
  }

  const renderPagination = () => {
    if (!teamAffiliates) return null

    return (
      <NinjaPagination
        currentPage={teamAffiliates.current_page}
        totalPages={teamAffiliates.last_page}
        pageNeighbours={1}
        goToPage={page => props.goToPage(page)}
      />
    )
  }

  return (
    <div className='onboardings-content'>
      <div className='onboardings-filter'>
        <Row className='form-row'>
          <Col style={{ flexGrow: 'initial' }}>
            <div style={{ width: '240px' }}>{renderTeamsFilter()}</div>
          </Col>
          <Col style={{ flexGrow: 'initial' }}>
            <div style={{ width: '240px' }}>{renderStatusesFilter()}</div>
          </Col>
        </Row>
      </div>
      <Card className='primary-table'>
        <Table responsive hover>
          <thead>
            <tr>
              <th>TEAM</th>
              <th className='text-right'>ACTIVE</th>
              <th className='text-right'>SIGNUPS</th>
              <th className='text-right'>MONTHS</th>
              <th className='text-right'>AVERAGE</th>
              <th className='text-right'>TRAINING</th>
              <th className='text-right'>PENDING</th>
              <th className='text-right'>AVAILABLE</th>
              <th className='text-right'>DISQUALIFY</th>
              <th className='text-left'>STATUS</th>
            </tr>
          </thead>
          <tbody>
            {props.getTeamAffiliatesRequest ? (
              <tr>
                <td colSpan='10'>
                  <i className='fad fa-spinner fa-spin'></i>
                </td>
              </tr>
            ) : null}
            {renderAffiliates()}
            <tr>
              <th colSpan='10' className='p-0 ponit-events-none'></th>
            </tr>
          </tbody>
        </Table>
      </Card>
      {renderPagination()}
    </div>
  )
}

export default Affiliates
