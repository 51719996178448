import React from 'react'
import { FormGroup, Label, Input, Row, Col } from 'reactstrap'
import ReactSelect from 'react-select'
import { BASIC_SELECT_STYLE, BASIC_SELECT_THEME } from '../../utils/constant'
import { filterFromObjectArray } from '../../utils/tools'

export default function WelcomeCall({ formData, onChange, canUpdate }) {
  const { need_welcome_call, welcome_note } = formData

  const handleChange = value => {
    onChange('need_welcome_call', value)
    setTimeout(() => {
      if (value === 0) {
        onChange(
          'welcome_note',
          'We have combined the Welcome Call for this location with your other locations, so our team can go over onboarding process for all locations with you in one call 😀',
        )
      } else {
        onChange('welcome_note', '')
      }
    })
  }

  const renderWelcomeCall = () => {
    let all_types = [
      { value: 1, label: 'Yes' },
      { value: 0, label: 'No' },
    ]

    const DropdownIndicator = () => {
      return (
        <i className='fas fa-caret-down multi-select-dropdown-indicator'></i>
      )
    }

    return (
      <ReactSelect
        name='business_type'
        options={all_types}
        placeholder={'Welcome Call Select'}
        value={filterFromObjectArray(
          all_types,
          need_welcome_call ?? 1,
          'value',
        )}
        // menuIsOpen={true}
        className={'basic-single'}
        classNamePrefix='select'
        onChange={({ value }) => handleChange(value)}
        components={{ DropdownIndicator }}
        isDisabled={!canUpdate}
        theme={BASIC_SELECT_THEME}
        styles={BASIC_SELECT_STYLE}
      />
    )
  }
  return (
    <>
      <Row form>
        <Col>
          <Label>WELCOME CALL</Label>
        </Col>
      </Row>
      <Row form>
        <Col>
          <FormGroup>{renderWelcomeCall()} </FormGroup>
        </Col>
      </Row>
      {typeof need_welcome_call === 'number' && need_welcome_call === 0 && (
        <Row form style={{ height: 105 }}>
          <Col>
            <FormGroup>
              <Input
                type='textarea'
                name='welcome_call_text'
                placeholder={'Welcome call text'}
                value={welcome_note || ''}
                onChange={e => onChange('welcome_note', e.target.value)}
                style={{
                  height: 95,
                  resize: 'none',
                  padding: '10px 16px',
                }}
              />
              {/* <i className="fas fa-exclamation exclamation"></i> */}
            </FormGroup>
          </Col>
        </Row>
      )}
    </>
  )
}
