import React from 'react'
import { connect } from 'react-redux'
import Affiliates from '../../../components/Affiliates/index'
import { getTeamAffiliates } from '../../../store/actions/affiliate'
import { getOnboardingOptions } from '../../../store/actions/onboarding'

class AffiliatesIndex extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      filterData: {
        team_ids: '',
        active: '',
      },
    }
    this.getOptions = this.getOptions.bind(this)
    this.onFilterChange = this.onFilterChange.bind(this)
    this.goToPage = this.goToPage.bind(this)
    this.seeAffiliateDetail = this.seeAffiliateDetail.bind(this)
  }

  componentDidMount() {
    //hold filter when going back from detail page
    if (this.props.location.state) {
      let { prevFilterData, prevOptionsType } = this.props.location.state
      let filterData = {
        ...prevFilterData,
      }
      this.setState({
        filterData,
        options_type: prevOptionsType,
      })
      let params = { ...filterData }
      this.props.getTeamAffiliates(params)
      this.props.getOnboardingOptions({
        type: prevOptionsType,
        team_type: 'affiliate',
      })
    } else {
      let params = { ...this.state.filterData }
      this.props.getTeamAffiliates(params)
    }
  }

  componentDidUpdate(prevProps) {
    let { onboardingOptions } = this.props
    if (prevProps.onboardingOptions !== this.props.onboardingOptions) {
      this.setState({
        onboardingOptions: onboardingOptions,
      })
    }
  }

  getOptions(type) {
    this.setState({
      options_type: type,
    })

    this.props.getOnboardingOptions({ type, team_type: 'affiliate' })
  }

  onFilterChange(name, val) {
    let filterData = { ...this.state.filterData, [name]: val, page: 1 }
    this.setState({
      filterData,
    })
    this.props.getTeamAffiliates(filterData)
  }

  goToPage(page) {
    this.setState({
      filterData: {
        ...this.state.filterData,
        page: page,
      },
    })
    this.props.getTeamAffiliates({ params: this.state.filterData, page })
  }

  seeAffiliateDetail(id, name) {
    this.props.history.push({
      pathname: `/affiliate-team/${id}/${name}`,
      state: {
        prevFilterData: this.state.filterData,

        prevOptionsType: this.state.options_type,
      },
    })
  }

  render() {
    return (
      <>
        <Affiliates
          filterData={this.state.filterData}
          teamAffiliates={this.props.teamAffiliates}
          getOptions={this.getOptions}
          onFilterChange={this.onFilterChange}
          onboardingOptions={this.state.onboardingOptions}
          goToPage={this.goToPage}
          seeAffiliateDetail={this.seeAffiliateDetail}
          getTeamAffiliatesRequest={this.props.getTeamAffiliatesRequest}
        ></Affiliates>
      </>
    )
  }
}

const mapStateToProps = state => {
  let { teamAffiliates, getTeamAffiliatesRequest, getTeamAffiliatesError } =
    state.affiliate
  return {
    auth: state.auth,
    teamAffiliates,
    getTeamAffiliatesRequest,
    getTeamAffiliatesError,
    onboardingOptions: state.onboarding.onboardingOptions,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getTeamAffiliates: params => dispatch(getTeamAffiliates(params)),
    getOnboardingOptions: params => dispatch(getOnboardingOptions(params)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AffiliatesIndex)
