import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import auth from "./auth";
import onboarding from "./onboarding";
import brands from "./brands";
import plans from "./plans";
import affiliate from "./affiliate";
import address from "./address";
import support from "./support";
import orders from "./orders";
import leaderboard from "./leaderboard";
import referrer from "./referrer";
import referral from "./referral";
import credit from "./credit"
import ui from "./ui";

const rootReducer = (history) =>
    combineReducers({
        router: connectRouter(history),
        auth,
        onboarding,
        brands,
        plans,
        affiliate,
        address,
        support,
        orders,
        leaderboard,
        ui,
        referrer,
        referral,
        credit,
    });

export default rootReducer
