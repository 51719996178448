import React, { useState } from 'react'

const ClientTableHead = ({ mode, progress, onFilterChange, count_order }) => {
  const isSupportMode = mode === 'support'
  const iconRender = () => {
    return (
      <div className='seven-day-icon'>
        <i
          className='fas fa-sort-up'
          style={{
            color:
              count_order === 'ASC'
                ? 'rgba(233, 233, 233, 0.75)'
                : 'rgba(233, 233, 233, 0.5)',
          }}
        />
        <i
          className='fas fa-sort-down'
          style={{
            color:
              count_order === 'DESC'
                ? 'rgba(233, 233, 233, 0.75)'
                : 'rgba(233, 233, 233, 0.5)',
          }}
        />
      </div>
    )
  }
  return (
    <thead>
      <tr>
        <th className='fixed-width fixed-120'>BRAND</th>
        <th className='fixed-width fixed-120'>LOCATION</th>
        <th>LOCATION ID</th>
        <th>STATE</th>
        {progress === 'onboarding' ? <th>SOURCE</th> : <th>PLAN</th>}
        {progress !== 'onboarding' && <th>TEAM</th>}
        {progress !== 'onboarding' && (
          <th className='text-center'>ESSENTIALS</th>
        )}
        {progress !== 'onboarding' ? (
          <th
            className='seven-day'
            onClick={() => onFilterChange('count_order')}
          >
            30 DAYS
            {iconRender()}
          </th>
        ) : null}
        <th className='text-right'>SIGNUP DONE</th>
        <th className='text-right'>ONBOARDING</th>
        {progress === 'onboarding' ? <th>ASSIGNEE</th> : null}
        {progress === 'onboarding' ? (
          <th className='text-center'>ATTENTION</th>
        ) : null}
        <th className='text-right'>STATUS</th>
      </tr>
    </thead>
  )
}

export default ClientTableHead
