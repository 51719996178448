import React, { useEffect, useRef } from 'react'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { Helmet } from 'react-helmet'
import MainNavbar from '../../components/Navbar/MainNavbar'
import { getProfile } from '../../store/actions/auth'
import { handleCreditModal } from '../../store/actions/credit'

import { useDispatch, useSelector } from 'react-redux'

import {
  removeSupportData,
  setSignupFormInfo,
} from '../../store/actions/support'

const MainLayout = ({ children, ...rest }) => {
  const history = useHistory()

  const dispatch = useDispatch()
  const profile = useSelector(state => state.auth.profile)
  const ui = useSelector(state => state.ui)

  useEffect(() => {
    if (!profile) dispatch(getProfile())
  }, [profile])

  const createOnboarding = () => {
    dispatch(removeSupportData())
    dispatch(setSignupFormInfo({}))
    history.push({
      pathname: '/restaurants/create/signup-form',
      state: {
        fromPath: '/signups',
      },
    })
  }

  const openCreditModal = () => {
    dispatch(handleCreditModal({
      open: true,
      creditType: 'create'
    }))
  }

  const openProfile = () => {
    history.push('/profile')
  }

  const mainLayoutEl = useRef(null)

  useEffect(() => {
    if (ui.show_modal) {
      mainLayoutEl.current.classList.add('fixed-height-overflow-hidden')
    } else {
      mainLayoutEl.current.classList.remove('fixed-height-overflow-hidden')
    }
  }, [ui.show_modal])

  return (
    <div className='main-layout' ref={mainLayoutEl}>
      <div className='container'>
        <Helmet>
          <title>CRM - Rushable</title>
        </Helmet>
        <ToastContainer
          autoClose={2 * 1000}
          position={toast.POSITION.TOP_CENTER}
        />
        <MainNavbar
          {...rest}
          profile={profile}
          createOnboarding={createOnboarding}
          openCreditModal={openCreditModal}
          openProfile={openProfile}
          affiliate_dashboard={false}
        />
        <div className='main-content'>{children}</div>
      </div>
    </div>
  )
}

export default MainLayout
