import React, { useState } from 'react'
import { Row, Col } from 'reactstrap'
import ReactSelect from 'react-select'
import ReactSelectAsync from 'react-select/async'
import { useDispatch, useSelector } from 'react-redux'
import { debounce } from 'lodash'
import NinjaPagination from '../Common/NinjaPagination'
import ReferralTable from './ReferralTable'
import { getReferrers } from '../../store/actions/referrer'
import { BASIC_SELECT_STYLE, BASIC_SELECT_THEME } from '../../utils/constant'
import { filterFromObjectArray } from '../../utils/tools'

const statusOptions = [
  { value: '', label: 'All Reward Status' },
  { value: 'qualified', label: 'Qualified' },
  { value: 'sent', label: 'Sent' },
]

export default function ReferralPage({
  filter,
  getReferralListRequest,
  referralList,
  onChangeFilter,
  editReward,
  goToPage,
}) {
  const {
    referrer_id,
    status,
    count_order,
  } = filter
  const [options, setOptions] = useState([
    { value: '', label: 'All Referrers', name: 'All Referrers' }
  ])
  const dispatch = useDispatch()
  const { getReferrersRequest } = useSelector(state => state.referrer)

  const loadOptions = async (inputValue, callback) => {
    let params = { keyword: inputValue }
    let res = await dispatch(getReferrers(params, true))
    if (res.success) {
      let referrerOptions = [
        { value: '', label: 'All Referrers', name: 'All Referrers' }
      ]

      res.data.forEach(referrer => {
        referrerOptions.push({
          value: referrer.id,
          label: referrer.name,
          name: referrer.name,
          email: referrer.email,
        })
      })
      setOptions(referrerOptions)
      callback(referrerOptions)
    }
  }

  const DropdownIndicator = () => {
    return <i className='fas fa-caret-down multi-select-dropdown-indicator'></i>
  }

  const CustomOption = ({ innerRef, innerProps, data }) => (
    <div ref={innerRef} {...innerProps} className='px-2 py-1 custom-option'>
      <div className='f-14'>{data.name}</div>
      <div className='f-12 text-cream-50'>{data.email}</div>
    </div>
  )
  return (
    <div className='onboardings-content'>
      <div className='onboardings-filter'>
        <Row className='form-row'>
          <Col style={{ flexGrow: 'initial' }}>
            <div style={{ width: '240px' }}>
              <ReactSelectAsync
                name='referrer_id'
                placeholder={'Referral Partner'}
                value={filterFromObjectArray(options, referrer_id ?? '', 'value')}
                className='basic-single'
                classNamePrefix='select'
                onChange={({ value }) => {
                  onChangeFilter('referrer_id', value)
                }}
                components={{ DropdownIndicator, Option: CustomOption }}
                theme={BASIC_SELECT_THEME}
                styles={BASIC_SELECT_STYLE}
                isLoading={getReferrersRequest}
                loadOptions={debounce(loadOptions, 500)}
              />
            </div>
          </Col>
          <Col style={{ flexGrow: 'initial' }}>
            <div style={{ width: '240px' }}>
              <ReactSelect
                value={filterFromObjectArray(statusOptions, status || "", 'value')}
                name='status'
                isSearchable={false}
                options={statusOptions}
                className='basic-single'
                classNamePrefix='select'
                onChange={({ value }) => {
                  onChangeFilter('status', value)
                }}
                components={{ DropdownIndicator }}
                theme={BASIC_SELECT_THEME}
                styles={BASIC_SELECT_STYLE}
              />
            </div>
          </Col>
        </Row>
      </div>
      <ReferralTable
        isLoading={getReferralListRequest}
        referralList={referralList}
        countOrder={count_order}
        onChangeFilter={onChangeFilter}
        editReward={editReward}
      />
      <div className='dashed-line margin-top-10 margin-bottom-20'></div>
      {referralList ? (
        <NinjaPagination
          currentPage={referralList.current_page || 1}
          totalPages={referralList.last_page}
          pageNeighbours={1}
          goToPage={page => goToPage(page)}
        />
      ) : null}
    </div>
  )
}
