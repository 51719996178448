import React, { useEffect, useState } from 'react'
import SelectComponent from '../../components/Select'
import { DatePicker } from 'antd'
import moment from 'moment'

import {
  customMonthFormat,
  customQuarterFormat,
  customYearFormat,
} from '../../utils/date'

const menuOptions = [
  { label: 'Monthly', value: 'month' },
  { label: 'Quarterly', value: 'quarter' },
  { label: 'Annually', value: 'year' },
]

const FilterBar = ({
  applyFilters,
  date,
  type,
  typeSelectWidth = 240,
  datePickerWidth = 240,
}) => {
  React.useEffect(() => {
    if (type) setPeriod(returnOptionObjectFromValue(type))
    if (date) setTime(moment(date))
  }, [date, type])

  const returnOptionObjectFromValue = p_value => {
    for (let option of menuOptions) {
      if (option.value === p_value) return option
    }
    return menuOptions[0]
  }

  const [period, setPeriod] = useState(menuOptions[0])
  const [time, setTime] = useState(moment())
  const onChange = (date, dateString) => {
    setTime(date)
    applyFilters(period.value, date)
  }
  const onPeriodChange = period => {
    const type = period.value
    let date = moment().startOf(type)
    setTime(date)
    setPeriod(period)
    applyFilters(type, date)
  }
  return (
    <div className='live-page-filter-group d-flex justify-content-between'>
      <div className='mr-2 d-flex'>
        <SelectComponent
          value={period.value}
          name='period'
          options={menuOptions}
          width={typeSelectWidth}
          placeholder='Select Period'
          onChange={value => {
            onPeriodChange(value)
          }}
        />
        <DatePicker
          style={{ width: datePickerWidth }}
          allowClear={false}
          className='ml-2'
          value={time}
          onChange={onChange}
          picker={period.value}
          format={
            period.value === 'month'
              ? customMonthFormat
              : period.value === 'quarter'
              ? customQuarterFormat
              : customYearFormat
          }
        />
      </div>
    </div>
  )
}

export default FilterBar
