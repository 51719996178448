import React from 'react'
import { Row, Col } from 'reactstrap'
import ReactSelect from 'react-select'

import DropdownIndicator from '../../Common/DropdownIndicator'
import { filterFromObjectArray } from '../../../utils/tools'
import { BASIC_SELECT_STYLE, BASIC_SELECT_THEME } from '../../../utils/constant'

export default function ModalLocation({
	disabled,
	locationOptions,
	locationId,
	setLocationId,
}) {
	return (
		<Row className='form-row'>
			<Col>
				<div>
					<ReactSelect
						value={filterFromObjectArray(locationOptions, locationId || '', 'value')}
						name='location_name'
						placeholder={'Select location account'}
						options={locationOptions}
						isDisabled={disabled}
						className='basic-single'
						classNamePrefix='select'
						onChange={({ value }) => {
							setLocationId(value);
						}}
						components={{ DropdownIndicator }}
						theme={BASIC_SELECT_THEME}
						styles={BASIC_SELECT_STYLE}
					/>
				</div>
			</Col>
		</Row>
	)
}