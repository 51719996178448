import React from 'react'
import { useSelector } from 'react-redux'
import { Input } from 'reactstrap'

export default function ModalSubmit({
	type,
	status,
	approveNote,
	setApproveNote,
	createCreditRequest,
	approveCreditRequest,
	submit,
	approve,
}) {
	const { profile } = useSelector(
		(state) => state.auth
	);
	const isMaster =
		profile &&
		profile.user &&
		(profile.user.role === 'master')
	const handleBlur = text => {
		if (text === '') {
			setApproveNote('N/A')
		}
	}
	const handleFocus = text => {
		if (text === 'N/A') {
			setApproveNote('')
		}
	}
	return (
		<div className='cmi-submit'>
			{type === 'create' && (
				<div className='cmi-submit-request cmi-submit-btn' onClick={submit}>
					{createCreditRequest && (
						<i class="fad fa-spinner-third fa-spin mr-2" />
					)}
					REQUEST
				</div>
			)}
			{status === 'pending' && isMaster ? (
				<>
					<div className='cmi-submit-note'>
						<div className='cmi-submit-note-title'>APPROVE/DENY NOTE</div>
						<Input 
							value={approveNote || ''}
							onChange={e => setApproveNote(e.target.value)}
							onBlur={e => handleBlur(e.target.value)}
							onFocus={e => handleFocus(e.target.value)}
						/>
					</div>
					<div
						className='cmi-submit-approve cmi-submit-btn' 
						onClick={() => approve('approved')}
					>
						{approveCreditRequest === 'approved' && (
							<i class="fad fa-spinner-third fa-spin mr-2" />
						)}
						APPROVE
					</div>
					<div
						className='cmi-submit-deny cmi-submit-btn'
						onClick={() => approve('denied')}
					>
						{approveCreditRequest === 'denied' && (
							<i class="fad fa-spinner-third fa-spin mr-2" />
						)}
						DENY
					</div>
				</>	
			): null}
			{status === 'approved' && (
				<div className='cmi-submited-approve'>
					<div className='cmi-submited-approve-title'>APPROVE</div>
					<div className='cmi-submited-text'>{approveNote ? approveNote : 'N/A'}</div>
				</div>
			)}
			{status === 'denied' && (
				<div className='cmi-submited-denied'>
					<div className='cmi-submited-denied-title'>DENIED</div>
					<div className='cmi-submited-text'>{approveNote ? approveNote : 'N/A'}</div>
				</div>
			)}
		</div>
	)
}