import {
  ADDR_AUTO_FILL_REQUEST,
  ADDR_AUTO_FILL_SUCCESS,
  ADDR_AUTO_FILL_FAIL,
} from '../actions/address'

const initialState = {
  addressSuggestions: [],
}

const address = (state = initialState, action) => {
  switch (action.type) {
    case ADDR_AUTO_FILL_REQUEST:
      return {
        ...state,
        autoFillRequest: true,
      }
    case ADDR_AUTO_FILL_SUCCESS:
      return {
        ...state,
        addressSuggestions: action.data,
        autoFillRequest: false,
      }
    case ADDR_AUTO_FILL_FAIL:
      return {
        ...state,
        autoFillRequest: false,
        addressSuggestions: [],
      }
    default:
      return state
  }
}

export default address
