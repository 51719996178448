import React, { useEffect, useState } from 'react'
import { FormGroup, Col } from 'reactstrap'
import { BASIC_SELECT_STYLE, BASIC_SELECT_THEME } from '../../../utils/constant'
import ReactSelect from 'react-select/async'
import { useDispatch, useSelector } from 'react-redux'
import { getReferrers } from '../../../store/actions/referrer'
import { debounce } from 'lodash'
import { filterFromObjectArray } from '../../../utils/tools'
function ReferralPartnerForm(props) {
  let { formData, onChange, canEditSource } = props
  let { source, referrer_id } = formData
  const dispatch = useDispatch()
  const { getReferrersRequest } = useSelector(state => state.referrer)
  const { selectedOnboarding } = useSelector(state => state.onboarding)
  const [defaultOption, setDefaultOption] = useState()
  const [options, setOptions] = useState([])
  useEffect(() => {
    const referrer = selectedOnboarding?.signup_form?.referrer
    if (referrer) {
      setDefaultOption({
        value: referrer.id,
        label: referrer.name,
        name: referrer.name,
        email: referrer.email,
      })
    } else {
      setDefaultOption(null)
    }
  }, [selectedOnboarding])

  const loadOptions = async (inputValue, callback) => {
    let params = { keyword: inputValue }
    let res = await dispatch(getReferrers(params, true))
    if (res.success) {
      let referrerOptions = []

      res.data.forEach(referrer => {
        referrerOptions.push({
          value: referrer.id,
          label: referrer.name,
          name: referrer.name,
          email: referrer.email,
        })
      })

      setOptions(referrerOptions)
      callback(referrerOptions)
    }
  }

  const DropdownIndicator = () => {
    return <i className='fas fa-caret-down multi-select-dropdown-indicator'></i>
  }

  const CustomOption = ({ innerRef, innerProps, data }) => (
    <div ref={innerRef} {...innerProps} className='px-2 py-1 custom-option'>
      <div className='f-14'>{data.name}</div>
      <div className='f-12 text-cream-50'>{data.email}</div>
    </div>
  )

  if (source !== 'referral') return null
  return (
    <Col>
      <FormGroup>
        <ReactSelect
          name='referrer_id'
          placeholder={'Referral Partner'}
          value={
            referrer_id !== defaultOption?.value
              ? filterFromObjectArray(options, referrer_id ?? '', 'value')
              : defaultOption
          }
          className={
            'basic-single menu-z2' + (!canEditSource ? ' disabled' : '')
          }
          classNamePrefix='select'
          onChange={value => {
            onChange('referrer_id', value.value)
          }}
          components={{ DropdownIndicator, Option: CustomOption }}
          theme={BASIC_SELECT_THEME}
          styles={BASIC_SELECT_STYLE}
          isLoading={getReferrersRequest}
          loadOptions={debounce(loadOptions, 500)}
          defaultOptions
        />
      </FormGroup>
    </Col>
  )
}

export default ReferralPartnerForm
