import React, { useState, useEffect } from 'react'
import { Button, Input } from 'reactstrap'
import { useDispatch } from 'react-redux'
import OnboardingSelectRow from './OnboardingSelectRow'
import { getLeaders } from '../../../../store/actions/support'
import { DOMAIN } from '../../../../utils/constant'
import { filterFromObjectArray } from '../../../../utils/tools'
import { getFullName } from '../../../../utils/getFullName'

export default function ProgressChecklist({
  profile,
  all_filled,
  onboardingFormData,
  onOnboardingFormChange,
}) {
  const dispatch = useDispatch()
  const {
    complete_welcome_call,
    menu_faq,
    menu_work_sent_for_approval,
    menu_work_approval,
    shipped_out,
    tablet_shipped_url,
    delivered,
    website_configuration,
    google_listing_updated,
    complete_launch_call,
    project_lead,
    project_lead_id,
  } = onboardingFormData
  const [url, setUrl] = useState('')
  const [getLeadersRequest, setGetLeadersRequest] = useState()
  const [leaders, setLeaders] = useState([])
  const [leaderImg, setLeaderImg] = useState([])
  const [leadersOptions, setLeadersOptions] = useState([])
  const [roleId, setRoleId] = useState()
  const [hasRole, setHasRole] = useState()
  useEffect(() => {
    const _getProjectLeaders = () => {
      setGetLeadersRequest(true)
      dispatch(getLeaders())
        .then(({ data }) => {
          if (data.success) {
            setGetLeadersRequest(false)
            leadersToOptions(data.data)
            setLeaders(data.data)
          } else {
            setGetLeadersRequest(false)
          }
        })
        .catch(error => {
          setGetLeadersRequest(false)
        })
    }
    _getProjectLeaders()
  }, [dispatch])

  useEffect(() => {
    leaders.forEach(leader => {
      if (leader.id === project_lead_id) setLeaderImg(leader.profile_photo)
    })
  }, [project_lead_id, leaders])

  useEffect(() => {
    if (!profile) return
    const { crm_role, user } = profile
    const { id: roleId } = crm_role
    setRoleId(roleId)
    setHasRole(
      user && (user.role === 'master' || user.role === 'lv2_support')
        ? true
        : false,
    )
  }, [profile])

  const getProjectLeaders = () => {
    setGetLeadersRequest(true)
    setLeadersOptions([{ value: '', label: 'loading...' }])
    dispatch(getLeaders())
      .then(({ data }) => {
        if (data.success) {
          setGetLeadersRequest(false)
          leadersToOptions(data.data)
        } else {
          setGetLeadersRequest(false)
        }
      })
      .catch(error => {
        setGetLeadersRequest(false)
      })
  }

  const leadersToOptions = data => {
    let options = [{ value: '', label: '-' }]
    if (data)
      if (data.length !== 0)
        data.forEach(leader => {
          options.push({
            value: leader.id,
            label: getFullName(leader.first_name, leader.last_name),
          })
        })
    setLeadersOptions(options)
  }
  let leader_value = getLeadersRequest
    ? { value: '', label: 'loading...' }
    : !project_lead_id
    ? ''
    : filterFromObjectArray(leadersOptions, project_lead_id, 'value')
  useEffect(() => {
    setUrl(tablet_shipped_url)
  }, [tablet_shipped_url])
  return (
    <div className='progress-check-list'>
      <div className='pcl-title'>PROGRESS CHECKLIST</div>
      <div className='pcl-select'>
        {roleId === 1 || project_lead_id === null ? (
          <OnboardingSelectRow
            title={'Onboarding Assignee'}
            onChange={onOnboardingFormChange}
            changeName={'project_lead_id'}
            value={leader_value || ''}
            onMenuOpen={getProjectLeaders}
            options={leadersOptions}
            withHeader={project_lead_id ? true : false}
            imgUrl={leaderImg ?? ''}
            leftCn='button-left'
          />
        ) : (
          <div className='pcl-row'>
            <div className='pcl-button mr-8'>Onboarding Assignee</div>
            <div
              className='pcl-button'
            >
              {project_lead ? (
                <>
                  <img
                    className='pcl-img'
                    src={`${DOMAIN}${project_lead.profile_photo}`}
                  />
                  {`${project_lead.first_name} ${project_lead.last_name}`}
                </>
              ) : null}
            </div>
          </div>
        )}

        <div className='pcl-row'>
          <div className='pcl-button mr-8'>Welcome call completed</div>
          {complete_welcome_call === 1 ? (
            <div
              className='pcl-button pcl-icon action'
              onClick={() => onOnboardingFormChange('complete_welcome_call', 0)}
            >
              <i className='fas fa-check'></i>
            </div>
          ) : (
            <div
              className='pcl-button pcl-icon'
              onClick={() => onOnboardingFormChange('complete_welcome_call', 1)}
            >
              <i className='fas fa-check'></i>
            </div>
          )}
        </div>
        <div className='pcl-row'>
          <div className='pcl-button mr-8'>Menu Q&A</div>
          {menu_faq === 1 ? (
            <div
              className='pcl-button pcl-icon action'
              onClick={() => onOnboardingFormChange('menu_faq', 0)}
            >
              <i className='fas fa-check'></i>
            </div>
          ) : (
            <div
              className='pcl-button pcl-icon'
              onClick={() => onOnboardingFormChange('menu_faq', 1)}
            >
              <i className='fas fa-check'></i>
            </div>
          )}
        </div>
        <div className='pcl-row'>
          <div className='pcl-button mr-8'>Menu build sent for approval</div>
          {menu_work_sent_for_approval === 1 ? (
            <div
              className='pcl-button pcl-icon action'
              onClick={() =>
                onOnboardingFormChange('menu_work_sent_for_approval', 0)
              }
            >
              <i className='fas fa-check'></i>
            </div>
          ) : (
            <div
              className='pcl-button pcl-icon'
              onClick={() =>
                onOnboardingFormChange('menu_work_sent_for_approval', 1)
              }
            >
              <i className='fas fa-check'></i>
            </div>
          )}
        </div>
        <div className='pcl-row'>
          <div className='pcl-button mr-8'>Menu build approved</div>
          {menu_work_approval === 1 ? (
            <div
              className='pcl-button pcl-icon action'
              onClick={() => onOnboardingFormChange('menu_work_approval', 0)}
            >
              <i className='fas fa-check'></i>
            </div>
          ) : (
            <div
              className='pcl-button pcl-icon'
              onClick={() => onOnboardingFormChange('menu_work_approval', 1)}
            >
              <i className='fas fa-check'></i>
            </div>
          )}
        </div>
        <div className='pcl-row'>
          <div className='pcl-button mr-8'>Tablet shipped</div>
          <Input
            value={url || ''}
            className='pcl-input'
            onChange={e => setUrl(e.target.value)}
            onBlur={() => onOnboardingFormChange('tablet_shipped_url', url)}
          />
          {shipped_out === 1 ? (
            <div
              className='pcl-button pcl-icon action'
              onClick={() => onOnboardingFormChange('shipped_out', 0)}
            >
              <i className='fas fa-check'></i>
            </div>
          ) : (
            <div
              className='pcl-button pcl-icon'
              onClick={() => onOnboardingFormChange('shipped_out', 1)}
            >
              <i className='fas fa-check'></i>
            </div>
          )}
        </div>
        <div className='pcl-row'>
          <div className='pcl-button mr-8'>Tablet delivered</div>
          {delivered === 1 ? (
            <div
              className='pcl-button pcl-icon action'
              onClick={() => onOnboardingFormChange('delivered', 0)}
            >
              <i className='fas fa-check'></i>
            </div>
          ) : (
            <div
              className='pcl-button pcl-icon'
              onClick={() => onOnboardingFormChange('delivered', 1)}
            >
              <i className='fas fa-check'></i>
            </div>
          )}
        </div>
        <div className='pcl-row'>
          <div className='pcl-button mr-8'>Website & domain configured</div>
          {website_configuration === 1 ? (
            <div
              className='pcl-button pcl-icon action'
              onClick={() => onOnboardingFormChange('website_configuration', 0)}
            >
              <i className='fas fa-check'></i>
            </div>
          ) : (
            <div
              className='pcl-button pcl-icon'
              onClick={() => onOnboardingFormChange('website_configuration', 1)}
            >
              <i className='fas fa-check'></i>
            </div>
          )}
        </div>
        <div className='pcl-row'>
          <div className='pcl-button mr-8'>Google listing updated</div>
          {google_listing_updated === 1 ? (
            <div
              className='pcl-button pcl-icon action'
              onClick={() =>
                onOnboardingFormChange('google_listing_updated', 0)
              }
            >
              <i className='fas fa-check'></i>
            </div>
          ) : (
            <div
              className='pcl-button pcl-icon'
              onClick={() =>
                onOnboardingFormChange('google_listing_updated', 1)
              }
            >
              <i className='fas fa-check'></i>
            </div>
          )}
        </div>
        <div className='pcl-row'>
          <div className='pcl-button mr-8'>Launch call completed</div>
          {complete_launch_call === 1 ? (
            <div
              className='pcl-button pcl-icon action'
              onClick={() => onOnboardingFormChange('complete_launch_call', 0)}
            >
              <i className='fas fa-check'></i>
            </div>
          ) : (
            <div
              className='pcl-button pcl-icon'
              onClick={() => onOnboardingFormChange('complete_launch_call', 1)}
            >
              <i className='fas fa-check'></i>
            </div>
          )}
        </div>
        <div className='pcl-submit'>
          <Button
            color='primary'
            className='pcl-submit-button'
            type='button'
            onClick={() => onOnboardingFormChange('status', 'done')}
            disabled={!all_filled || !hasRole}
          >
            ONBOARDED
          </Button>
        </div>
      </div>
    </div>
  )
}
