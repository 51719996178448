import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import Cropper from 'react-cropper'
import { Modal, Button } from 'reactstrap'
import { Spin } from '../Loading'
import 'cropperjs/dist/cropper.css'
import './Upload.scss'

const Upload = props => {
  const [isShowModal, setisShowModal] = useState(false)
  const [isShowLoading, setisShowLoading] = useState(false)
  const [image, setImage] = useState('')
  const [cropper, setCropper] = useState()
  const imageTypeRef = useRef('') // 保存图片类型
  const onChange = e => {
    e.preventDefault()
    const files = e.target.files
    imageTypeRef.current = e.target.files?.[0]?.type
    // 拖拽上传暂不考虑
    // files = e.dataTransfer.files
    const reader = new FileReader()
    reader.onload = () => {
      setImage(reader.result)
    }
    reader.readAsDataURL(files[0])
    setisShowModal(true)
    // 防止同一个文件重复上传不生效
    e.target.value = ''
  }

  const getCropData = () => {
    if (typeof cropper !== 'undefined') {
      setisShowLoading(true)
      const cropCanvas = cropper.getCroppedCanvas()
      cropCanvas.toBlob(blob => {
        const filedata = new FormData()
        filedata.append(props.field, blob)
        uploadApi(filedata)
      }, imageTypeRef.current || 'image/jpeg')
    }
  }

  const uploadApi = filedata => {
    axios
      .request({
        url: props.api,
        data: filedata,
        method: 'post',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(res => {
        props.onChange()
        setisShowModal(false)
      })
      .catch(err => console.log(err))
      .finally(() => setisShowLoading(false))
  }

  return (
    <div className='upload-component' style={{ width: '100%' }}>
      <div className='preview-wrap'>
        {props.uploadEl}
        <input className='upload-input' type='file' onChange={onChange} />
      </div>
      <Modal
        className='upload-comps-modal'
        centered={true}
        isOpen={isShowModal}
      >
        <div className='upload-header'>{props.headerTips}</div>
        <Spin spining={isShowLoading}>
          <>
            <div className='upload-content'>
              <Cropper
                style={{ height: '400px', width: '100%' }}
                aspectRatio={1} //固定图片裁剪比例 1为正方形
                initialAspectRatio={1}
                preview='.img-preview'
                zoomTo={0.1}
                src={image}
                viewMode={1}
                minCropBoxHeight={10}
                minCropBoxWidth={10}
                background={false}
                autoCropArea={1}
                guides={true}
                checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                onInitialized={instance => {
                  setCropper(instance)
                }}
              />
            </div>
            <div className='opt-btn'>
              <Button className='btn' onClick={() => setisShowModal(false)}>
                CANCEL
              </Button>
              <Button className='btn confirm' onClick={getCropData}>
                CONFIRM
              </Button>
            </div>
          </>
        </Spin>
      </Modal>
    </div>
  )
}

// 类型校验、参数说明
Upload.propTypes = {
  uploadEl: PropTypes.element.isRequired,
  api: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  headerTips: PropTypes.string,
}
Upload.defaultProps = {
  api: '/nc/profile/photo',
  field: 'photo',
  headerTips: 'UPLOAD AVATAR',
}
export default Upload
