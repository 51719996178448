import React from 'react'
import CreditTableRow from './CreditTableRow'

const CreditTableBody = ({
  creditList,
  openCreditModal,
}) => {
  if (!creditList || !creditList.data || creditList.data.length === 0)
    return (
      <tr>
        <td colSpan='7' className='p-0 ponit-events-none'>
          <div className='text-white'>No data</div>
        </td>
      </tr>
    )
  return creditList.data.map((item) => (
    <CreditTableRow
      key={item.id}
      item={item}
      openCreditModal={openCreditModal}
    />
  ))
}

export default CreditTableBody
