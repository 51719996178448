import {
	GET_CREDIT_LIST_REQUEST,
	GET_CREDIT_LIST_SUCCESS,
	GET_CREDIT_LIST_FAIL,
	GET_CREDIT_REQUEST,
	GET_CREDIT_SUCCESS,
	GET_CREDIT_FAIL,
	GET_CREDIT_BRANDS_REQUEST,
	GET_CREDIT_BRANDS_SUCCESS,
	GET_CREDIT_BRANDS_FAIL,
	GET_CREDIT_LOCATIONS_REQUEST,
	GET_CREDIT_LOCATIONS_SUCCESS,
	GET_CREDIT_LOCATIONS_FAIL,
	HANDLE_CREDIT_MODAL,
	CREATE_CREDIT_ORDER_REQUEST,
	CREATE_CREDIT_ORDER_SUCCESS,
	CREATE_CREDIT_ORDER_FAIL,
	APPROVE_CREDIT_ORDER_REQUEST,
	APPROVE_CREDIT_ORDER_SUCCESS,
	APPROVE_CREDIT_ORDER_FAIL,
	UPDATECREDITLIST,
} from "../actions/credit";

const initialState = {
	getCreditListRequest: false,
	getCreditRequest: false,
	creditList: [],
	getBrandsRequest: false,
	brands: [],
	creditOpen: false,
	creditType: null,
	creditData: null,
	getLocationsRequest: false,
	locations: false,
	createCreditRequest: false,
	approveCreditRequest: false,
	needUpdateList: false,
};

const referral = (state = initialState, action) => {
	switch (action.type) {
		case GET_CREDIT_LIST_REQUEST:
				return {
						...state,
						getCreditListRequest: true,
				};
		case GET_CREDIT_LIST_SUCCESS:
				return {
						...state,
						getCreditListRequest: false,
						creditList: action.data,
				};
		case GET_CREDIT_LIST_FAIL:
				return {
						...state,
						getCreditListRequest: false,
				};
		case GET_CREDIT_REQUEST:
			return {
					...state,
					getCreditRequest: true,
			};
		case GET_CREDIT_SUCCESS:
				return {
						...state,
						getCreditRequest: false,
						creditData: action.data,
				};
		case GET_CREDIT_FAIL:
				return {
						...state,
						getCreditRequest: false,
				};
		case GET_CREDIT_BRANDS_REQUEST:
				return {
						...state,
						getBrandsRequest: true,
						brands: []
				};
		case GET_CREDIT_BRANDS_SUCCESS:
				return {
						...state,
						getBrandsRequest: false,
						brands: action.data,
				};
		case GET_CREDIT_BRANDS_FAIL:
				return {
						...state,
						getBrandsRequest: false,
				};
		case GET_CREDIT_LOCATIONS_REQUEST:
			return {
				...state,
				getLocationsRequest: true,
				locations: []
			};
		case GET_CREDIT_LOCATIONS_SUCCESS:
				return {
						...state,
						getLocationsRequest: false,
						locations: action.data,
				};
		case GET_CREDIT_LOCATIONS_FAIL:
				return {
						...state,
						getLocationsRequest: false,
				};
		case CREATE_CREDIT_ORDER_REQUEST:
			return {
					...state,
					createCreditRequest: true,
			};
		case CREATE_CREDIT_ORDER_SUCCESS:
				return {
						...state,
						createCreditRequest: false,
				};
		case CREATE_CREDIT_ORDER_FAIL:
				return {
						...state,
						createCreditRequest: false,
				};
		case APPROVE_CREDIT_ORDER_REQUEST:
			return {
					...state,
					approveCreditRequest: action.status,
			};
		case APPROVE_CREDIT_ORDER_SUCCESS:
				return {
						...state,
						approveCreditRequest: '',
				};
		case APPROVE_CREDIT_ORDER_FAIL:
				return {
						...state,
						approveCreditRequest: '',
				};
		case HANDLE_CREDIT_MODAL:
			return {
				...state,
				creditOpen: action.open,
				creditType: action.creditType,
				creditData: null
			}
		case UPDATECREDITLIST: 
			return {
				...state,
				needUpdateList: action.need
			}
		default:
				return state;
	}
};

export default referral;
