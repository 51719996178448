import React, { useState } from 'react'
import moment from 'moment-timezone'
import { useDispatch } from 'react-redux'
import cn from 'classnames'
import OdRow from './OdRow'
import {
  getStatusName,
  renderStatusColor,
  getReference,
  getTypeName,
} from '../../../utils/order'
import { DB_TIMEZONE } from '../../../utils/constant'
import { formatPhoneNumber } from '../../../utils/digit'
import { changeOrderStatus } from '../../../store/actions/support'
import { Button } from 'reactstrap'

const timeDiff = (val1, val2) => {
  let diff = moment(val1).diff(moment(val2), 'minutes')
  return diff
}

const timeDiffStr = val => {
  let str = val + ' min'
  return str
}

const DetailSideBar = ({
  className,
  selectedOrder,
  successChangeOrderStatus,
}) => {
  const [prepTime, setPrepTime] = useState(null)
  const [changeStatusReuqest, setChangeStatusReuqest] = useState(null)
  const [changeStatusError, setChangeStatusError] = useState()

  const {
    customer,
    created_at,
    request_at,
    confirmed_at,
    ready_at,
    done_at,
    daas_delivery,
    location,
    receipt_link,
    express_order_confirmation_link
  } = selectedOrder

  const localTimezone =
    selectedOrder.location?.address?.timezone ?? DB_TIMEZONE

  const customerName = customer
    ? customer.first_name +
    ' ' +
    (customer.last_name
      ? customer.last_name.charAt(0).toUpperCase() + '.'
      : '')
    : '-'
  const customerPhone =
    customer && customer.phone ? formatPhoneNumber(customer.phone) : '-'
  const customerEmail = customer?.email || '-'
  const requestStr = request_at
    ? request_at === created_at
      ? 'ASAP'
      : moment.tz(request_at, DB_TIMEZONE).tz(localTimezone).format('MM/DD @ h:mm a')
    : '-'
  let quotedTime = '-'
  let actualTime = '-'
  let diffrenceTime = ''
  let diffrenceTimeStr = '-'
  let dasher = '-'
  let dasherAssignedStr = '-'
  let sinceAccept = ''
  let sinceAcceptStr = '-'

  let diffAcceptArrival = ''
  let diffAcceptArrivalStr = '-'
  let storeArrivalStr = '-'

  let diffArrivalPick = ''
  let diffArrivalPickStr = '-'
  let orderPickUpStr = '-'

  let diffPickDeliveryStr = '-'
  let orderDeliveryStr = '-'

  if (daas_delivery) {
    const {
      quoted_delivery_time,
      actual_delivery_time,
      driver_first_name,
      driver_last_name,
      key_statuses,
    } = daas_delivery
    quotedTime = quoted_delivery_time
      ? moment.tz(quoted_delivery_time, DB_TIMEZONE).tz(localTimezone).format('h:mm a')
      : '-'
    actualTime = actual_delivery_time
      ? moment.tz(actual_delivery_time, DB_TIMEZONE).tz(localTimezone).format('h:mm a')
      : '-'
    if (quoted_delivery_time && actual_delivery_time) {
      diffrenceTime = timeDiff(actual_delivery_time, quoted_delivery_time)
      diffrenceTimeStr = timeDiffStr(diffrenceTime)
    }

    dasher =
      driver_first_name != null
        ? driver_first_name + ' ' + driver_last_name[0] + '.'
        : '-'

    if (key_statuses) {
      let {
        dasher_confirmed,
        dasher_confirmed_store_arrival,
        dasher_picked_up,
      } = key_statuses
      if (dasher_confirmed) {
        dasherAssignedStr = dasher_confirmed
          ? moment.tz(dasher_confirmed, DB_TIMEZONE).tz(localTimezone).format('h:mm a')
          : '-'
        sinceAccept = timeDiff(dasher_confirmed, confirmed_at)
        sinceAcceptStr = timeDiffStr(sinceAccept)
      }

      //store arrival
      if (dasher_confirmed_store_arrival) {
        storeArrivalStr = dasher_confirmed_store_arrival
          ? moment.tz(dasher_confirmed_store_arrival, DB_TIMEZONE)
            .tz(localTimezone)
            .format('h:mm a')
          : '-'
        diffAcceptArrival = timeDiff(
          dasher_confirmed_store_arrival,
          dasher_confirmed,
        )
        diffAcceptArrivalStr = timeDiffStr(diffAcceptArrival)
      }

      //dasher_picked_up
      if (dasher_picked_up) {
        orderPickUpStr = dasher_picked_up
          ? moment.tz(dasher_picked_up, DB_TIMEZONE).tz(localTimezone).format('h:mm a')
          : '-'
        diffArrivalPick = timeDiff(
          dasher_picked_up,
          dasher_confirmed_store_arrival,
        )
        diffArrivalPickStr = timeDiffStr(diffArrivalPick)
      }
      //actual
    }
  }

  let showConfirm = selectedOrder.status === 'placed'

  return (
    <div className={cn('sidebar-container rounded', className)}>

      <div className='border-0 description-part'>
        {showConfirm && (
          <div className='mt-3'>
            <Button color='thirdary' className='font-bold w-100 justify-content-center d-flex flex-column' href={express_order_confirmation_link} target='_brank'>MANAGE ORDER</Button>
            <div className='custom-border-bottom-dashed-cream my-3'></div>
          </div>
        )}
        <OdRow
          label='Order ID'
          text={<div className='d-flex align-items-center'>{selectedOrder?.id} <a className='btn-link text-cream-75 ml-2 cursor-pointer' href={receipt_link} target='_brank'><i className='fas fa-receipt'></i></a></div>}
          className='text-uppercase text-capitalize mb-2'
        />
        <OdRow label='customer' text={customerName} />
        <OdRow label='Cellphone' text={customerPhone} />
        <OdRow label='Email' text={customerEmail} />
        <OdRow
          label='status'
          text={getStatusName(selectedOrder?.status)}
          valueClassName={`${renderStatusColor(
            getStatusName(selectedOrder?.status),
          )}`}
          className='text-uppercase text-capitalize mb-2'
        />
        <OdRow label='total' text={`$${selectedOrder?.total || '-'}`} />
        <OdRow label='type' text={getTypeName(selectedOrder?.type)} />
        <OdRow
          label='reference'
          text={getReference(selectedOrder?.type, selectedOrder?.daas_delivery)}
        />
      </div>

      <div className='description-part'>
        <OdRow
          label='Timezone'
          text={moment.tz(location?.address?.timezone).zoneAbbr()}
        />
        <OdRow
          label='submit'
          text={
            moment.tz(created_at, DB_TIMEZONE).tz(localTimezone).format('MM/DD @ h:mm a') || '-'
          }
        />
        <OdRow label='request' text={requestStr} />
        <OdRow
          label='accept'
          text={
            confirmed_at
              ? moment.tz(confirmed_at, DB_TIMEZONE).tz(localTimezone).format('MM/DD @ h:mm a')
              : '-'
          }
        />
        <OdRow
          label='ready'
          text={
            ready_at
              ? moment.tz(ready_at, DB_TIMEZONE).tz(localTimezone).format('MM/DD @ h:mm a')
              : '-'
          }
        />
        <OdRow
          label='complete'
          text={
            done_at
              ? moment.tz(done_at, DB_TIMEZONE).tz(localTimezone).format('MM/DD @ h:mm a')
              : '-'
          }
        />
      </div>

      <div className='description-part'>
        <OdRow label='quoted time' text={quotedTime} />
        <OdRow label='actual time' text={actualTime} />
        <OdRow label='difference' text={diffrenceTimeStr} />
      </div>

      <div className='description-part'>
        <OdRow label='dasher' text={dasher} />
        <OdRow label='since accept' text={sinceAcceptStr} />
        <OdRow label='dasher assigned' text={dasherAssignedStr} />
        <OdRow label='time spend' text={diffAcceptArrivalStr} />
        <OdRow label='store arrival' text={storeArrivalStr} />
        <OdRow label='time spend' text={diffArrivalPickStr} />
        <OdRow label='order pick up' text={orderPickUpStr} />
        <OdRow label='time spend' text={diffPickDeliveryStr} />
        <OdRow label='order delivered' text={orderDeliveryStr} />
      </div>
    </div>
  )
}

export default DetailSideBar
