import React from 'react'
import ClientTableRow from './ClientTableRow'

const ClientTableBody = ({
  mode = 'support',
  supports,
  goToSupport,
  progress,
}) => {
  if (!supports) return null
  if (!supports.data) return null
  if (supports.data.length === 0)
    return (
      <tr>
        <td colSpan='7' className='p-0 ponit-events-none'>
          <div className='text-white'>No data</div>
        </td>
      </tr>
    )
  return supports.data.map((item, key) => (
    <ClientTableRow
      key={key}
      support={item}
      mode={mode}
      goToSupport={goToSupport}
      progress={progress}
    />
  ))
}

export default ClientTableBody
