import { DebounceInput } from 'react-debounce-input'
import { Col } from 'reactstrap'

function PhoneControl(props) {
  let { onChange, value } = props
  return (
    <Col style={{ flexGrow: 'initial' }}>
      <div style={{ width: '240px' }}>
        <DebounceInput
          value={value || ''}
          placeholder={'Search by Phone'}
          name='owner'
          className='debounce-input'
          debounceTimeout={500}
          onChange={e => onChange(e.target.value)}
        />
      </div>
    </Col>
  )
}

export default PhoneControl
