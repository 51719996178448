import React, { useState } from 'react'
import { FormGroup, Label, Input, Button, Row, Col } from 'reactstrap'
import FromWithIcon from './FromWithIcon'

function FraudPrevention(props) {
  let { online_order_config } = props
  let check_card = online_order_config
    ? online_order_config.check_card ?? false
    : false

  let check_id = online_order_config
    ? online_order_config.check_id ?? false
    : false

  let fraud_check_threshold = online_order_config
    ? online_order_config.fraud_check_threshold ?? null
    : null

  return (
    <div className='basic-info-section small'>
      <Label>FRAUD PREVENTION</Label>

      <Row form>
        <Col>
          <FormGroup>
            <FromWithIcon
              text={'Require to show ID'}
              selected={check_id}
              icon={<i className='fas fa-address-card'></i>}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col>
          <FormGroup>
            <FromWithIcon
              text={'Require to show card'}
              selected={check_card}
              icon={<i className='fas fa-credit-card-front'></i>}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col>
          <FormGroup>
            <FromWithIcon
              text={
                'Trigger at' +
                (fraud_check_threshold
                  ? ' $' + fraud_check_threshold.toFixed(2)
                  : '')
              }
              selected={fraud_check_threshold}
              icon={<i className='fas fa-receipt'></i>}
            />
          </FormGroup>
        </Col>
      </Row>
    </div>
  )
}

export default FraudPrevention
