import React from 'react'
import { Label, Row, Col } from 'reactstrap'

import OpenHoursForm from '../OpenHours/OpenHoursForm'
// import OpenHoursInfo from "../OpenHours/OpenHoursForm";

function BusinessHoursContent(props) {
  return (
    <>
      <Row form>
        <Col>
          <Label>BUSINESS HOURS</Label>
        </Col>
      </Row>

      <OpenHoursForm
        days={props.days}
        addHours={props.addHours}
        onHourChange={props.onHourChange}
        setClosed={props.setClosed}
        removeHours={props.removeHours}
        disabled={!props.canUpdate}
      />
    </>
  )
}

export default BusinessHoursContent
