import React from 'react'

const StatusBar = props => {
  let { status_arr, nowStatus } = props

  let ratio = 0
  if (
    nowStatus === 'canceled' ||
    nowStatus === 'live' ||
    nowStatus === 'canceling'
  ) {
    ratio = 100
  }
  if (nowStatus === 'signup_up') {
    let count = 0
    status_arr.slice(0, 5).forEach(item => {
      if (item.top_filled) {
        count += 1
      }
      if (item.bottom_filled) {
        count += 1
      }
    })
    ratio = (count * 100) / 10
  }
  if (nowStatus === 'onboarding') {
    let count = 0
    status_arr.slice(6).forEach(item => {
      if (item.top_filled) {
        count += 1
      }
      if (item.bottom_filled) {
        count += 1
      }
    })
    ratio = (count * 100) / 9
  }

  return (
    <div className='status-bar'>
      {nowStatus === 'draft' && (
        <div className='status-bar-item status-bar-draft'>
          <span className='sbi-text'>Draft</span>
        </div>
      )}
      {nowStatus === 'signup_up' && (
        <div className='status-bar-item status-bar-signing-up'>
          <span className='sbi-text'>
            {ratio === 0 ? 'Ready' : 'Signing Up'}
          </span>
          <div
            className='sbi-border'
            style={{
              width: `${ratio}%`,
            }}
          />
        </div>
      )}
      {nowStatus === 'onboarding' && (
        <div className='status-bar-item status-bar-onboarding'>
          <span className='sbi-text'>Onboarding</span>
          <div
            className='sbi-border'
            style={{
              width: `${ratio}%`,
            }}
          />
        </div>
      )}
      {nowStatus === 'live' && (
        <div className='status-bar-item status-bar-live'>
          <span className='sbi-text'>Live</span>
          <div
            className='sbi-border'
            style={{
              width: `${ratio}%`,
            }}
          />
        </div>
      )}
      {nowStatus === 'canceling' && (
        <div className='status-bar-item status-bar-canceling'>
          <span className='sbi-text'>Cancelling</span>
          <div
            className='sbi-border'
            style={{
              width: `${ratio}%`,
            }}
          />
        </div>
      )}
      {nowStatus === 'canceled' && (
        <div className='status-bar-item status-bar-canceled'>
          <span className='sbi-text'>Cancelled</span>
          <div
            className='sbi-border'
            style={{
              width: `${ratio}%`,
            }}
          />
        </div>
      )}
    </div>
  )
}

export default StatusBar
