import React, { useEffect, useState } from 'react'
import { Button } from 'reactstrap'
import moment from 'moment-timezone'
import { DB_TIMEZONE } from '../../utils/constant'
const LiveOrderButton = () => {
  const [now, setNow] = useState(
    moment().tz(DB_TIMEZONE).format('hh:mm A z'),
  )
  const [showCircle, setShowCircle] = useState(true)
  useEffect(() => {
    const realTimeCounter = setInterval(() => {
      setNow(moment().tz(DB_TIMEZONE).format('hh:mm A z'))
    }, 1000)
    return () => {
      clearInterval(realTimeCounter)
    }
  }, [])

  useEffect(() => {
    let circletimeCounter = setTimeout(() => {
      setShowCircle(!showCircle)
    }, 500)
    return () => {
      clearTimeout(circletimeCounter)
    }
  }, [showCircle])

  return (
    <Button
      color='white'
      outline
      className='d-flex align-items-center min-w-178px'
    >
      <div className='w-8px h-8px mr-2'>
        {showCircle && <div className='red-circle mr-2'></div>}
      </div>
      <div className='f-12'>LIVE {now}</div>
    </Button>
  )
}

export default LiveOrderButton
