import React from 'react'

const SignupsTableHead = ({
  mode,
  isShowAttentionColumn,
  progress,
  onFilterChange,
  count_order,
}) => {
  const iconRender = () => {
    return (
      <div className='seven-day-icon'>
        <i
          className='fas fa-sort-up'
          style={{
            color:
              count_order === 'ASC'
                ? 'rgba(233, 233, 233, 0.75)'
                : 'rgba(233, 233, 233, 0.5)',
          }}
        />
        <i
          className='fas fa-sort-down'
          style={{
            color:
              count_order === 'DESC'
                ? 'rgba(233, 233, 233, 0.75)'
                : 'rgba(233, 233, 233, 0.5)',
          }}
        />
      </div>
    )
  }
  return (
    <thead>
      <tr>
        <th className='onboarding-th-brand'>BRAND</th>
        <th className='onboarding-th-location'>LOCATION</th>
        {(progress === 'no_progress' || progress === 'has_progress') && (
          <th>STATE</th>
        )}
        {progress !== 'onboarding' && <th>PLAN</th>}
        <th>TEAM</th>
        <th>SOURCE</th>
        <th>CLOSER</th>
        {progress === 'launched' && <th>ESSENTIALS</th>}
        {progress === 'launched' && (
          <th
            className='seven-day'
            onClick={() => onFilterChange('count_order')}
          >
            30 DAYS
            {iconRender()}
          </th>
        )}
        {(progress === 'no_progress' || progress === 'has_progress') && (
          <th>CONTACT</th>
        )}
        {(progress === '' ||
          progress === 'has_progress' ||
          progress === 'no_progress') && (
          <th className='text-right '>PAYMENT INPUT</th>
        )}
        {progress !== 'has_progress' && progress !== 'no_progress' && (
          <th className='text-right table-intiate-th'>SIGNUP DONE</th>
        )}

        {(progress === '' ||
          progress === 'launched' ||
          progress === 'onboarding') && (
          <th className='text-right table-onboard-th'>ONBOARDING</th>
        )}
        {progress === 'onboarding' && <th className='text-center'>ASSIGNEE</th>}
        {isShowAttentionColumn && <th className='text-center'>ATTENTION</th>}
        <th className='text-right'>STATUS</th>
      </tr>
    </thead>
  )
}

export default SignupsTableHead
