import React from 'react'
import { FormGroup, Label, Input, Button, Row, Col } from 'reactstrap'
import { formatPhoneNumber } from '../../../../utils/digit'

function RestaurantContact(props) {
  let { email, phone } = props
  return (
    <div className='basic-info-section'>
      <Label>RESTAURANT CONTACT</Label>

      <Row form>
        <Col>
          <FormGroup>
            <Input
              type='text'
              name='email'
              value={email ?? ''}
              disabled={true}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col>
          <FormGroup className='d-flex justify-content-between'>
            <Input
              type='text'
              name='email'
              className='phone-form'
              value={phone ? formatPhoneNumber(phone, true) : ''}
              disabled={true}
            />
            <Button
              className='m-0'
              color='white-block'
              style={{ width: '95px' }}
              type='button'
              onClick={e => window.open('tel:' + phone)}
            >
              CALL
            </Button>
          </FormGroup>
        </Col>
      </Row>
    </div>
  )
}

export default RestaurantContact
