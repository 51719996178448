import React from 'react'
import { FormGroup, Label, Input, Row, Col } from 'reactstrap'

function VerificationContent(props) {
  let { formData, profile } = props
  let { business_legal_name, business_ein } = formData

  let rushable_team = profile
    ? profile.crm_team
      ? profile.crm_team.type === 'rushable'
        ? true
        : false
      : false
    : false

  return (
    <>
      <Row form className='row-title'>
        <Col>
          <Label>BUSINESS VERIFICATION</Label>
        </Col>
      </Row>
      <Row form>
        <Col>
          <FormGroup>
            <div className='col-ver-tips'>
              👇 Must be accurate including letter case and symbols
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col>
          <FormGroup>
            <Input
              type='text'
              name='business_legal_name'
              placeholder={'Legal business name'}
              value={business_legal_name || ''}
              onChange={e =>
                props.onChange('business_legal_name', e.target.value)
              }
              disabled={!props.canUpdate}
            />
            {rushable_team ? null : business_legal_name ? null : (
              <i className='fas fa-exclamation exclamation'></i>
            )}
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col>
          <FormGroup>
            <Input
              type='text'
              name='business_ein'
              id='business_ein'
              value={business_ein || ''}
              placeholder={'EIN'}
              onKeyUp={props.onBusinessEinKeyUp}
              onKeyDown={props.onPhoneKeyDown}
              onChange={e => props.onChange('business_ein', e.target.value)}
              disabled={!props.canUpdate}
            />
            {rushable_team ? null : business_ein ? null : (
              <i className='fas fa-exclamation exclamation'></i>
            )}
          </FormGroup>
        </Col>
      </Row>
    </>
  )
}

export default VerificationContent
