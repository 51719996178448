import React from 'react'
import { capitalize } from '../../../../utils/tools'

export default function AccountUser({ users }) {
  const format = str => {
    if (typeof str !== 'string') return str
    return str
      .split('_')
      .map(s => capitalize(s))
      .join(' ')
  }
  return (
    <div className='account-user'>
      <div className='account-user-title'>ACCOUNT USER</div>
      <div className='account-user-row'>
        <div className='user-row-table table-1'>First name</div>
        <div className='user-row-table table-2'>Last name</div>
        <div className='user-row-table table-3'>Access</div>
        <div className='user-row-table table-4'>Email</div>
        <div className='user-row-table table-5'>Phone</div>
        <div className='user-row-table'>Language</div>
      </div>
      <div className='account-user-contaniar'>
        {users.map(user => (
          <div className='user-contaniar-col' key={user.id}>
            <div className='table-1'>
              <span className='col-table-text'>{user.first_name}</span>
            </div>
            <div className='table-2'>
              <span className='col-table-text'>{user.last_name}</span>
            </div>
            <div className='table-3'>
              <span className='col-table-text'>{format(user.role)}</span>
            </div>
            <div className='table-4 text-elliosis'>
              <span className='col-table-text'>{user.email}</span>
            </div>
            <div className='table-5'>
              <span className='col-table-text'>{user.phone}</span>
            </div>
            <div>
              <span className='col-table-text'>{user.language}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
