import React, { useState } from 'react'
import { FormGroup, Label, Input, Button, Row, Col } from 'reactstrap'
import { ONBOARDING_BASIC_URL } from '../../../utils/constant'

import SignupSourceFrom from './SignupSourceFrom'
import ReferralPartnerForm from './ReferralPartnerForm'

function OnboardingLinkForm(props) {
  let {
    formData,
    canUpdate,
    onChange,
    onboardingOptions,
    canEditSource,
    getOnboardingOptionsRequest,
  } = props
  let { src, requires_approval } = formData

  let full_url = ONBOARDING_BASIC_URL + src

  // add tooltip to Copy Url
  const [isCopied, setIsCopied] = useState(false)

  const onCopyText = () => {
    navigator.clipboard.writeText(full_url)
    setIsCopied(true)
    setTimeout(() => {
      setIsCopied(false)
    }, 1000)
  }

  return (
    <div className='board-col-primary'>
      <Row form>
        <Col>
          <Label>ONBOARDING LINK</Label>
        </Col>
      </Row>
      <Row form>
        <Col>
          <FormGroup>
            {requires_approval ? (
              <Input
              type='text'
              name='email'
              value={'Pending Approval'}
              disabled={true}
            />
            ) : (
              <Input
                type='text'
                name='email'
                value={
                  src && !requires_approval
                    ? full_url
                    : 'Complete form to generate'
                }
                disabled={true}
              />
            )}
          </FormGroup>
        </Col>
        {src && !requires_approval ? (
          <Button color='white-block' type='button' onClick={onCopyText}>
            Copy Link
            {isCopied && <div className='copy-success-alert'>Copied!</div>}
          </Button>
        ) : null}
      </Row>
      <Row form>
        <SignupSourceFrom
          formData={formData}
          canEditSource={canEditSource}
          onChange={onChange}
        />

        <ReferralPartnerForm
          formData={formData}
          canEditSource={canEditSource}
          onChange={onChange}
          onboardingOptions={onboardingOptions}
          getOnboardingOptionsRequest={getOnboardingOptionsRequest}
        />
      </Row>
    </div>
  )
}

export default OnboardingLinkForm
