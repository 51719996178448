import {
  AFFILIATE_GET_TEAM_AFFILIATES_REQUEST,
  AFFILIATE_GET_TEAM_AFFILIATES_SUCCESS,
  AFFILIATE_GET_TEAM_AFFILIATES_FAIL,
  AFFILIATE_GET_AFFILIATES_REQUEST,
  AFFILIATE_GET_AFFILIATES_SUCCESS,
  AFFILIATE_GET_AFFILIATES_FAIL,
  AFFILIATE_CHANGE_FACADE_CLOSER_REQUEST,
  AFFILIATE_CHANGE_FACADE_CLOSER_SUCCESS,
  AFFILIATE_CHANGE_FACADE_CLOSER_FAIL,
  AFFILIATE_CLEAR_CLAIM_REQUEST,
  AFFILIATE_CLAIM_COMMISSION_REQUEST,
  AFFILIATE_CLAIM_COMMISSION_SUCCESS,
  AFFILIATE_CLAIM_COMMISSION_FAIL,
} from '../actions/affiliate'

const initialState = {
  getTeamAffiliatesRequest: false,
  getTeamAffiliatesError: null,
  getAffiliatesRequest: false,
  getAffiliatesError: null,
  teamAffiliates: null,
  affiliates: null,
  changeFacadeCloserRequest: false,
  changeFacadeCloserError: null,
  claimCommissionRequest: false,
  claimCommissionError: null,
  selectedTeamId: '',
}

const affiliate = (state = initialState, action) => {
  switch (action.type) {
    case AFFILIATE_GET_TEAM_AFFILIATES_REQUEST:
      return {
        ...state,
        getTeamAffiliatesRequest: true,
      }
    case AFFILIATE_GET_TEAM_AFFILIATES_SUCCESS:
      return {
        ...state,
        teamAffiliates: action.data,
        getTeamAffiliatesRequest: false,
        getTeamAffiliatesError: null,
      }
    case AFFILIATE_GET_TEAM_AFFILIATES_FAIL:
      return {
        ...state,
        getTeamAffiliatesRequest: false,
        getTeamAffiliatesError: action.msg,
      }
    case AFFILIATE_GET_AFFILIATES_REQUEST:
      return {
        ...state,
        getAffiliatesRequest: true,
      }
    case AFFILIATE_GET_AFFILIATES_SUCCESS:
      return {
        ...state,
        affiliates: action.data,
        getAffiliatesRequest: false,
        getAffiliatesError: null,
        selectedTeamId: action.data.team ? action.data.team.id ?? '' : '',
      }
    case AFFILIATE_GET_AFFILIATES_FAIL:
      return {
        ...state,
        getAffiliatesRequest: false,
        getAffiliatesError: action.msg,
      }
    case AFFILIATE_CHANGE_FACADE_CLOSER_REQUEST:
      return {
        ...state,
        changeFacadeCloserRequest: true,
      }
    case AFFILIATE_CHANGE_FACADE_CLOSER_SUCCESS:
      return {
        ...state,
        changeFacadeCloserRequest: false,
        changeFacadeCloserError: null,
      }
    case AFFILIATE_CHANGE_FACADE_CLOSER_FAIL:
      return {
        ...state,
        changeFacadeCloserRequest: false,
        changeFacadeCloserError: action.msg,
      }
    case AFFILIATE_CLEAR_CLAIM_REQUEST:
      return {
        ...state,
        claimCommissionError: null,
        claimCommissionSuccess: null,
      }
    case AFFILIATE_CLAIM_COMMISSION_REQUEST:
      return {
        ...state,
        claimCommissionRequest: true,
        claimCommissionError: null,
        claimCommissionSuccess: null,
      }
    case AFFILIATE_CLAIM_COMMISSION_SUCCESS:
      return {
        ...state,
        claimCommissionRequest: false,
        claimCommissionError: null,
        claimCommissionSuccess: action.msg,
      }
    case AFFILIATE_CLAIM_COMMISSION_FAIL:
      return {
        ...state,
        claimCommissionRequest: false,
        claimCommissionError: action.msg,
        claimCommissionSuccess: null,
      }
    default:
      return state
  }
}

export default affiliate
