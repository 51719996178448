import React, { useState } from 'react'
import { FormGroup, Label, Input, Button, Row, Col } from 'reactstrap'

function BrandInfo(props) {
  let { brand } = props
  let default_domain = brand
    ? brand.domains
      ? brand.domains[0]
        ? brand.domains[0].hostname ?? ''
        : ''
      : ''
    : ''

  let cuisine_types = []
  return (
    <div className='basic-info-section'>
      <Label>BRAND INFO</Label>

      <Row form>
        <Col>
          <FormGroup>
            <div className='form-group-content-primary selected'>
              <div
                className='form-control-link'
                onClick={e => {
                  window.open('https://' + default_domain)
                  e.stopPropagation()
                }}
              >
                {default_domain.replace('https://', '')}
              </div>
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col>
          <FormGroup>
            <div className='form-group-content-primary selected'>
              {cuisine_types.map((type, key) => {
                return (
                  <span className='cuisine-type' key={key}>
                    {type}
                  </span>
                )
              })}
            </div>
          </FormGroup>
        </Col>
      </Row>
    </div>
  )
}

export default BrandInfo
