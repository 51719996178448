import React, { useState, useEffect, useMemo } from 'react'
import { Modal, Input } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import cn from 'classnames'
import { toast } from 'react-toastify'
import { postCancelAccount, getSupport } from '../../../store/actions/support'
import { getFullName } from '../../../utils/getFullName'
import { DOMAIN } from '../../../utils/constant'

export default function SupportCancel({
	onboarding
}) {
	const onboardingId = onboarding?.id ?? ''
	const status = onboarding?.status ?? ''
	const account = onboarding?.onboarding_cancellation ?? null
	const dispatch = useDispatch()
	const auth = useSelector(state => state.auth)
	const { profile } = auth
	const [show, setShow] = useState(false)
	const [submitShow, setSubmitShow] = useState(false)
	const [comment, setComment] = useState('')
	const [reason, setReason] = useState('')
	const [canceled, setCanceled] = useState(false)
	const [loading, setLoading] = useState(false)

	const disabled = account || canceled

	const { supportId } = useParams()

	const isMaster =
		profile &&
		profile.user &&
		(profile.user.role === 'master')

	const approvedBy = useMemo(() => {
		if (account && account.approved_by) {
			return {
				url: account?.approved_by?.profile_photo ?? '',
				name: getFullName(account?.approved_by?.first_name, account?.approved_by?.last_name)
			}
		}
		return {
			url: profile?.profile_photo || '',
			name: getFullName(profile?.first_name, profile?.last_name)
		}
	}, [account, profile])

	const toggleShow = () => {
		setShow(!show)
	}
	const openSubmit = () => {
		if (disabled) return
		if (reason.length > 0) {
			if (isMaster) {
				submit()
			} else {
				setShow(false)
				setSubmitShow(true)
			}
		}
	}
	const select = type => {
		if (disabled) return
		setReason(type)
	}
	const submit = async () => {
		if (status !== 'signup_done') return
		setLoading(true)

		const params = {
			reason,
			comment,
			onboarding_id: onboardingId
		}
		try {
			const { data } = await dispatch(postCancelAccount(params))
			setLoading(false)
			if (data.success) {
				setCanceled(true)
				setShow(false)

				dispatch(getSupport({ location_id: supportId, refresh: true }))
			} else {
				toast.warn(data.message)
			}
		} catch (error) {
			let resData = error.response
				? error.response.data
				: { message: error.message }
			toast.warn(resData.message)
		}
	}
	useEffect(() => {
		if (account) {
			setComment(account.comment)
			setReason(account.reason)
		}
	}, [])
	return (
		<div className='support-cancel'>
			<div className={cn(disabled ? 'support-canceled-btn' : 'support-cancel-btn')} onClick={toggleShow}>
				{disabled ? 'DETAIL OF CANCELLATION' : 'CANCEL ACCOUNT'}
			</div>
			<Modal
				isOpen={show}
				toggle={toggleShow}
				className='support-cancel-modal'
				centered={true}
			>
				<div className='scm-container'>
					<div className='scm-title'>ACCOUNT CANCELLATION</div>
					<div className='scm-reason'>
						<div className='scm-reason-title'>REASON</div>
						<div className='scm-reason-row'>
							<div
								className={cn(
									'reason-row-select',
									reason === 'many_stystem_bugs' ? 'reason-row-action' : ''
								)}
								onClick={() => select('many_stystem_bugs')}
							>Many system bugs</div>
							<div
								className={cn(
									'reason-row-select',
									reason === 'too_hard_to_use' ? 'reason-row-action' : ''
								)}
								onClick={() => select('too_hard_to_use')}
							>Too hard to use</div>
						</div>
						<div className='scm-reason-row'>
							<div
								className={cn(
									'reason-row-select',
									reason === 'too_expensive' ? 'reason-row-action' : ''
								)}
								onClick={() => select('too_expensive')}
							>Too expensive</div>
							<div
								className={cn(
									'reason-row-select',
									reason === 'found_better_solution' ? 'reason-row-action' : ''
								)}
								onClick={() => select('found_better_solution')}
							>Found better solution</div>
						</div>
						<div className='scm-reason-row'>
							<div
								className={cn(
									'reason-row-select',
									reason === 'closing_down' ? 'reason-row-action' : ''
								)}
								onClick={() => select('closing_down')}
							>Selling / Closing down</div>
							<div
								className={cn(
									'reason-row-select',
									reason === 'other' ? 'reason-row-action' : ''
								)}
								onClick={() => select('other')}
							>Other reason</div>
						</div>
					</div>
					<div className='scm-comment'>
						<div className='scm-comment-title'>COMMENT</div>
						<Input
							type="textarea"
							className='scm-comment-input'
							value={comment}
							disabled={disabled}
							maxLength={200}
							onChange={e => setComment(e.target.value)}
						/>
					</div>
					{disabled ? (
						<div className='scm-people'>
							<div className='scm-people-appoved'>
								<div className='scm-people-title'>APPROVED BY</div>
								<div className='scm-people-name'>
									{approvedBy && approvedBy.url ? (
										<img className='people-name-img' src={`${DOMAIN + approvedBy.url}`} alt="header-log" />
									) : (
										<i className='fas fa-user-circle' />
									)}
									<span className='text-cream-100 f-14 text-elliosis'>{approvedBy.name}</span>
								</div>
							</div>
						</div>
					) : (
						<div className='scm-btn'>
							<div className='scm-btn-back' onClick={toggleShow}>GO BACK</div>
							<div className='scm-btn-confirm' onClick={openSubmit}>
								{loading && (
									<i className='fad fa-spinner fa-spin mr-2' />
								)}
								{disabled ? 'CANCELED' : 'CONFIRM TO CANCEL'}

							</div>
						</div>
					)}

				</div>
			</Modal>
			<Modal
				isOpen={submitShow}
				toggle={() => setSubmitShow(!submitShow)}
				className='support-cancel-submit'
				centered={true}
			>
				<div className="scm-submit">
					<div className="scm-submit-title f-14">ERROR</div>
					<div className="scm-submit-text">You are not authorized to perform this action, please contact lv2 support.</div>
					<div className="scm-submit-btn" onClick={() => setSubmitShow(false)}>
						CLOSE
					</div>
				</div>
			</Modal>
		</div>
	)
}
