import React from 'react'
import { Button, Card, Input } from 'reactstrap'
import { escapeRegExp } from '../../utils/digit'
import moment from 'moment'
import OutsideAlerter from '../OutsideAlerter/OutsideAlerter'

class TimePickerPrimary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      optionsOpen: false,
      inputValue: null,
    }
    this.inputValue = this.inputValue.bind(this)
    this.optionsCard = this.optionsCard.bind(this)
    this.toggleOptionsOpen = this.toggleOptionsOpen.bind(this)
    this.onClickOutside = this.onClickOutside.bind(this)
  }

  componentDidMount() { }

  componentDidUpdate(prevProps) { }
  optionsCard() {
    let options = [{ value: moment('3:00:00', 'hh:mm:ss'), label: '3:00 AM' }]

    for (var i = 1; i < 48; i++) {
      options.push({
        value: moment('3:00:00', 'hh:mm:ss').add(30 * i, 'minutes'),
        label: moment('3:00:00', 'hh:mm:ss')
          .add(30 * i, 'minutes')
          .format('h:mm A'),
      })
    }

    let optionsSearchList = []

    if (!this.state.inputValue || this.state.inputValue === 'Invalid date') {
      optionsSearchList = options
    } else {
      options.forEach(option => {
        var reg1 = new RegExp(escapeRegExp(this.state.inputValue))

        var str = option.label
        if (reg1.exec(str)) {
          optionsSearchList.push(option)
        }
      })
    }

    let optionsHasValue =
      !optionsSearchList || optionsSearchList.length < 2 ? false : true

    let optionsList = optionsSearchList.map((option, key) => (
      <Button
        key={`time-picker-${key}`}
        color='timepicker'
        className='time-picker-option'
        onClick={e => {
          this.props.onChange(option.value)
          this.toggleOptionsOpen(false)
        }}
      >
        <div>{option.label}</div>
      </Button>
    ))

    let optionsCard = (
      <Card
        className={
          this.state.optionsOpen && optionsHasValue
            ? 'time-picker-options'
            : 'time-picker-options d-none'
        }
        tabIndex='2'
      >
        <div className='options-list'>{optionsList}</div>
      </Card>
    )

    return optionsCard
  }

  inputValue(value) {
    this.setState({
      inputValue: value,
    })
  }

  toggleOptionsOpen(status) {
    if (this.props.value === 'Invalid date') {
      this.inputValue('')
    } else {
      this.inputValue(this.props.value)
    }

    this.setState({
      optionsOpen: status,
    })
  }

  onClickOutside() {
    this.toggleOptionsOpen(false)
  }

  render() {
    return (
      <OutsideAlerter className='time-picker-primary' onClickOutside={this.onClickOutside} >
        <>
          <Input
            disabled={this.props.disabled}
            className='from'
            type='text'
            value={
              this.state.optionsOpen ? this.state.inputValue : this.props.value
            }
            onChange={e => {
              this.inputValue(e.target.value)
            }}
            onFocus={() => {
              this.toggleOptionsOpen(true)
            }}
          ></Input>

          {this.optionsCard()}
        </>
      </OutsideAlerter>

    )
  }
}

export default TimePickerPrimary
