import { DAYS } from './constant'
import moment from 'moment'
export const readableBytes = bytes => {
  var i = Math.floor(Math.log(bytes) / Math.log(1024)),
    sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  return (bytes / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + sizes[i]
}

export const filterFromObjectArray = (options, val, valueKey) => {
  let selected = options.filter(item => {
    // eslint-disable-next-line eqeqeq
    return item[valueKey] === val
  })
  if (!selected.length) return null
  return selected[0]
}

export const filterFromObjectArrayIsMulti = (
  options = [],
  vals = [],
  valueKey,
) => {
  let selected = []
  options.forEach(option => {
    vals.forEach(val => {
      if (option[valueKey] === val) selected.push(option)
    })
  })
  if (!selected.length) return null
  return selected
}

export const filterDayHours = (openHours, day) => {
  let dayHours = openHours.filter(item => {
    return item.day === day
  })
  dayHours.sort((a, b) => {
    return moment(a.from, 'HH:mm:ss') - moment(b.from, 'HH:mm:ss')
  })
  return dayHours
}

export const returnHoursInDays = openHours => {
  let hoursInDays = {}
  if (!openHours) return
  DAYS.forEach(day => {
    let dayHours = filterDayHours(openHours, day.abbr)
    hoursInDays = {
      ...hoursInDays,
      [day.abbr]: dayHours,
    }
  })
  return hoursInDays
}

export const returnOpenHours = days => {
  let openHours = []
  if (!days) return
  DAYS.forEach(day => {
    openHours = [...openHours, ...days[day.abbr]]
  })
  return openHours
}

export const validateOpenHours = hours => {
  let isValid = true
  hours.forEach((hour, key) => {
    let from = moment(hour.from, 'HH:mm:ss')
    let to = moment(hour.to, 'HH:mm:ss')
    if (from > to) {
      isValid = false
    }
  })
  return isValid
}

export const debounce = (func, wait, immediate) => {
  var timeout
  return function () {
    var context = this,
      args = arguments
    var later = function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    var callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

export const capitalize = text => {
  return text.charAt(0).toUpperCase() + text.slice(1)
}

export const titleCase = str => {
  str = str.toLowerCase().split(' ')
  for (var i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1)
  }
  return str.join(' ')
}

export const prorationCost = (
  periodEnd,
  periodStart,
  requestTime,
  quantity,
  planPrice,
) => {
  return (
    ((periodEnd - requestTime) / (periodEnd - periodStart)) *
    quantity *
    planPrice
  )
}

export const roleLevel = role => {
  let role_level = [
    {
      value: 'master',
      level: 1,
    },
    {
      value: 'lv2_support',
      level: 2,
    },
    {
      value: 'lv1_support',
      level: 3,
    },
    {
      value: 'menu_entry',
      level: 4,
    },
    {
      value: 'brand_admin',
      level: 11,
    },
    {
      value: 'location_owner',
      level: 12,
    },
    {
      value: 'manager',
      level: 13,
    },
    {
      value: 'location_staff',
      level: 14,
    },
    {
      value: 'channel_partner',
      level: 15,
    },
    {
      value: 'coordinator',
      level: 16,
    },
  ]
  let item = role_level.filter(item => {
    // eslint-disable-next-line eqeqeq
    return item['value'] == role
  })
  if (!item.length) return null
  return item[0].level
}

export const ccCardIcon = cardBrand => {
  let cardIcon = null
  switch (cardBrand) {
    case 'visa':
      cardIcon = 'fab fa-cc-visa'
      break
    case 'amex':
      cardIcon = 'fab fa-cc-amex'
      break
    case 'diners':
      cardIcon = 'fab fa-cc-diners-club'
      break
    case 'discover':
      cardIcon = 'fab fa-cc-discover'
      break
    case 'jcb':
      cardIcon = 'fab fa-cc-jcb'
      break
    case 'master':
    case 'mastercard':
      cardIcon = 'fab fa-cc-mastercard'
      break
    case 'unionpay':
      cardIcon = 'fad fa-credit-card'
      break
    default:
      cardIcon = 'fad fa-credit-card'
      break
  }
  return cardIcon
}

export const proAddonIcon = name => {
  let cardIcon = null
  switch (name) {
    case 'Multilingual Menu':
      cardIcon = 'multilingual_menu'
      break
    case 'Curbside Order':
      cardIcon = 'curbside_order'
      break
    case 'Order Reminder':
      cardIcon = 'order_reminder'
      break
    case 'Orders Hub':
      cardIcon = 'orders_hub'
      break
    default:
      cardIcon = null
      break
  }
  return cardIcon
}

export const deepClone = item => {
  if (!item) {
    return item
  } // null, undefined values check

  var types = [Number, String, Boolean],
    result

  // normalizing primitives if someone did new String('aaa'), or new Number('444');
  types.forEach(function (type) {
    if (item instanceof type) {
      result = type(item)
    }
  })

  if (typeof result == 'undefined') {
    if (Object.prototype.toString.call(item) === '[object Array]') {
      result = []
      item.forEach(function (child, index, array) {
        result[index] = deepClone(child)
      })
    } else if (typeof item == 'object') {
      // testing that this is DOM
      if (item.nodeType && typeof item.cloneNode == 'function') {
        result = item.cloneNode(true)
      } else if (!item.prototype) {
        // check that this is a literal
        if (item instanceof Date) {
          result = new Date(item)
        } else {
          // it is an object literal
          result = {}
          for (var i in item) {
            result[i] = deepClone(item[i])
          }
        }
      } else {
        // depending what you would like here,
        // just keep the reference, or create new object
        if (false && item.constructor) {
          // would not advice to do that, reason? Read below
          result = new item.constructor()
        } else {
          result = item
        }
      }
    } else {
      result = item
    }
  }

  return result
}

export const onboardingStatus = status => {
  let text = ''
  switch (status) {
    case 'draft':
      text = 'Signup Draft'
      break
    case 'signup_ready':
      text = 'Ready to sign up'
      break
    case 'withdrawn':
      text = 'Withdrawn'
      break
    case 'signup_done':
      text = 'Signed Up'
      break
    case 'done':
      text = 'Onboarded'
      break
    case 'canceled':
      text = 'Unsubscribed'
      break
    default:
      break
  }
  return (
    <div className={'table-status'}>
      <span className={'table-status-span ' + status}></span>
      {text}
    </div>
  )
}

export const isRushableSales = profile => {
  if (!profile) return false
  let { crm_role_id } = profile
  return [11, 12, 13, 14].indexOf(crm_role_id) > -1
}

export const routeAccess = (profile, route) => {
  if (!profile) return false
  let { crm_role_id } = profile
  switch (route) {
    case 'orders':
      return [1, 21, 22].indexOf(crm_role_id) > -1
    case 'signups':
      return [1, 11, 12, 13, 14, 101, 102].indexOf(crm_role_id) > -1
    case 'leaderboard':
      return [1, 11, 12, 13, 14].indexOf(crm_role_id) > -1
    case 'restaurants':
      return [1, 21, 22].indexOf(crm_role_id) > -1
    case 'referral':
      return [1, 21, 22].indexOf(crm_role_id) > -1
    case 'affiliates':
      return [1].indexOf(crm_role_id) > -1
    case 'credit':
      return [1, 21, 22].indexOf(crm_role_id) > -1
    case 'affiliate_dashboard':
      return [101].indexOf(crm_role_id) > -1
    default:
      return false
  }
}

export const checkUserAccess = (profile, type) => {
  let has_access = false
  if (!profile) return false
  let { crm_team, crm_role } = profile
  let rushable_access = crm_team
    ? crm_team.type === 'rushable'
      ? true
      : false
    : false
  let is_rushable_master = crm_team
    ? crm_team.id
      ? crm_team.id === 1
      : false
    : false
  let supports = crm_role
    ? is_rushable_master && [1, 21, 22].indexOf(crm_role.id) !== -1
    : false
  switch (type) {
    case 'rushable_access':
      has_access = rushable_access
      break
    case 'is_rushable_master':
      has_access = is_rushable_master
      break
    case 'supports':
      has_access = supports
      break
    default:
      break
  }
  return has_access
}

export const isAsapOrder = order => {
  if (!order) return true
  let { created_at, request_at } = order

  if (!created_at || !request_at) return true

  return created_at === request_at ? true : false
}

export const checkFalseReturnChar = text => {
  if (text && text !== '0') return text
  else return '-'
}

export const checkFalseReturnChar2 = text => {
  if (text && text !== '0') return `($${text})`
  else return '-'
}

export const copyText = (text, success, fail) => {
  if (typeof text !== 'string') return false
  if (typeof text === 'string' && text.length === 0) return false
  const input = document.createElement('input')
  document.body.appendChild(input)
  input.setAttribute('value', text)
  input.select()
  try {
    document.execCommand('copy')
    document.body.removeChild(input)
    success && success()
  } catch (e) {
    document.body.removeChild(input)
    fail && fail()
  }
}
