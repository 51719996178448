import React, { useState, useEffect } from 'react'
import FilterBar from './Filterbar'
import LeaderboardContent from './components/LeaderboardContent'
import { useHistory } from 'react-router-dom'

import { useSelector, useDispatch } from 'react-redux'
import { getLeaderboardList } from '../../store/actions/leaderboard'
import Loading from '../../components/Loading'

import moment from 'moment'

const Leaderboard = () => {
  const dispatch = useDispatch()
  const leaderboard = useSelector(state => state.leaderboard)
  const [type, setType] = useState('month')
  const [date, setDate] = useState(moment())
  const history = useHistory()

  const locationState = history.location.state

  useEffect(() => {
    fetchLeaderBoardsList({
      type: 'monthly',
      option: moment().format('YYYY-MM'),
    })
  }, [])

  const fetchLeaderBoardsList = params => {
    dispatch(getLeaderboardList(params))
  }

  const getOptionParam = (type_p, date_p) => {
    let option =
      type_p === 'month'
        ? moment(date_p).format('YYYY-MM')
        : type_p === 'year'
        ? moment(date_p).format('YYYY')
        : `${moment(date_p).year()} Q${moment(date_p).quarter()}`
    return {
      type: `${type_p}ly`,
      option,
    }
  }

  const applyFilters = (type_p, date_p) => {
    setType(type_p)
    setDate(date_p)
    fetchLeaderBoardsList(getOptionParam(type_p, date_p))
  }

  const gotoDetailPage = (id, isCurrentPeriod = true) => {
    const currentPath = '/leaderboard'
    let selectedDate = moment(date).format()
    if (!isCurrentPeriod) selectedDate = moment(date).add(-1, type).format()
    const location = {
      pathname: `/leaderboard/${id}`,
      state: {
        fromPath: currentPath,
        type,
        date: selectedDate,
        filtedDate: moment(date).format(),
      },
    }
    history.push(location)
  }

  return (
    <div className='leaderboard-page'>
      <FilterBar applyFilters={applyFilters} date={date} type={type} />
      {leaderboard.get_leaderboards_list_request ? (
        <Loading />
      ) : (
        <LeaderboardContent
          type={type}
          date={date}
          selectedRow={gotoDetailPage}
          leaderboard={leaderboard}
        />
      )}
    </div>
  )
}

export default Leaderboard
