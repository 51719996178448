import React from 'react'
import RushableRightSide from './RushableRightSide'
import AffiliateRightSide from './AffiliateRightSide'
import AffiliateContent from './AffiliateContent'
import RushableHead from './RushableHead'
import ClaimSuccessModal from './ClaimSuccessModal'
import MainNavbar from '../Navbar/MainNavbar'

function AffiliateTeam(props) {
  let { affiliates, onboardingOptions, filterData, usersOptions, profile } =
    props

  if (!profile) return null
  if (!profile.crm_team) return null

  let is_rushable_team = profile
    ? profile.crm_team.type === 'rushable'
      ? true
      : false
    : false

  return (
    <div className='h-100 w-100 detail-layout'>
      <div className='detail-leftside '>
        <div className='container-with-rightside'>
          {is_rushable_team ? (
            <RushableHead
              goBack={props.goBack}
              team_name={props.team_name}
              affiliates={affiliates}
            />
          ) : (
            <MainNavbar
              profile={profile}
              openProfile={props.openProfile}
              affiliate_dashboard={true}
              location={props.location}
            />
          )}
          <AffiliateContent
            affiliates={affiliates}
            filterData={filterData}
            getAffiliatesRequest={props.getAffiliatesRequest}
            getAffiliatesError={props.getAffiliatesError}
            onboardingOptions={onboardingOptions}
            usersOptions={usersOptions}
            onFilterChange={props.onFilterChange}
            getOptions={props.getOptions}
            goToPage={props.goToPage}
            getOnboardingOptionsRequest={props.getOnboardingOptionsRequest}
            getOnboardingOptionsRequestType={
              props.getOnboardingOptionsRequestType
            }
          />
        </div>
      </div>

      <div className='detail-rightside'>
        {is_rushable_team ? (
          <RushableRightSide
            affiliates={affiliates}
            usersOptions={usersOptions}
            getUsers={props.getUsers}
            changeFacadeCloser={props.changeFacadeCloser}
            changeFacadeCloserRequest={props.changeFacadeCloserRequest}
            changeFacadeCloserError={props.changeFacadeCloserError}
          />
        ) : (
          <AffiliateRightSide
            affiliates={affiliates}
            claimCommission={props.claimCommission}
            claimCommissionRequest={props.claimCommissionRequest}
            claimCommissionError={props.claimCommissionError}
          />
        )}
        <ClaimSuccessModal
          open={props.claimCommissionSuccess}
          toggle={props.clearClaim}
          message={props.claimCommissionSuccess}
        />
      </div>
    </div>
  )
}

export default AffiliateTeam
