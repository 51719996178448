import {
  PLANS_GET_PLANS_REQUEST,
  PLANS_GET_PLANS_SUCCESS,
  PLANS_GET_PLANS_FAIL,
} from '../actions/plans'

const initialState = {}

const plans = (state = initialState, action) => {
  switch (action.type) {
    // plans
    case PLANS_GET_PLANS_REQUEST:
      return {
        ...state,
        getPlansRequest: true,
      }
    case PLANS_GET_PLANS_SUCCESS:
      return {
        ...state,
        getPlansRequest: false,
        plans: action.data,
      }
    case PLANS_GET_PLANS_FAIL:
      return {
        ...state,
        getPlansRequest: false,
      }

    default:
      return state
  }
}

export default plans
