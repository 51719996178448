import React from 'react'
import Support from '../../components/Support'
import { getSupport } from '../../store/actions/support'

import { connect } from 'react-redux'
import { checkUserAccess, isRushableSales } from '../../utils/tools'

class SupportLayout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.goBack = this.goBack.bind(this)
    this.goToPath = this.goToPath.bind(this)
  }

  componentDidMount() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
    if (this.props.location.state) {
      this.setState({ backState: this.props.location.state })
    }
  }

  componentDidUpdate(prevProps) {}

  goBack() {
    const rolePath = isRushableSales(this.props.profile)
      ? '/signups'
      : '/restaurants'
    let pathname = this.state.backState?.fromPath ?? rolePath
    this.props.history.push({
      pathname,
      state: this.state.backState,
    })
  }

  goToPath(pathname, search = null) {
    this.props.history.push({
      pathname,
      state: this.state.backState,
      search,
    })
  }

  render() {
    let activeLink = this.props.location.pathname
    let { access, getSupportRequest, children, supportId, supportData } =
      this.props
    return (
      <>
        <Support
          location={this.props.location}
          goBack={this.goBack}
          goToPath={this.goToPath}
          supportData={supportData}
          supportId={supportId}
          getSupportRequest={getSupportRequest}
          access={access}
          activeLink={activeLink}
          children={children}
        />
      </>
    )
  }
}

const mapStateToProps = state => {
  let { getSupportRequest, getSupportError, access } = state.support
  return {
    auth: state.auth,
    access,
    profile: state.auth.profile,
    support: state.support.support,
    uploadMenuRequest: state.support.uploadMenuRequest,
    uploadMenuSuccess: state.support.uploadMenuSuccess,
    uploadMenuError: state.support.uploadMenuError,
    getSupportRequest,
    getSupportError,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getSupport: params => dispatch(getSupport(params)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(SupportLayout)
