import React from 'react'
import { Row, Col } from 'reactstrap'
import ReactSelect, { components } from 'react-select'
import { DebounceInput } from 'react-debounce-input'
import { useDispatch, useSelector } from 'react-redux'
import NinjaPagination from '../Common/NinjaPagination'
import DropdownIndicator from '../Common/DropdownIndicator'
import CreditTable from './CreditTable'
import CreditModal from './CreditModal'
import { getCreditBrands } from '../../store/actions/credit'
import { BASIC_SELECT_STYLE, BASIC_SELECT_THEME } from '../../utils/constant'
import { filterFromObjectArray } from '../../utils/tools'
import { creditStatusOptions } from '../../utils/common'


export default function CreditPage({
  filter,
  getCreditListRequest,
  creditList,
  onChangeFilter,
  openCreditModal,
  goToPage,
}) {
  const {
    brand_id,
    status,
    order_id,
  } = filter
  const dispatch = useDispatch()
  const {
    brands,
    getBrandsRequest,
  } = useSelector(
    (state) => state.credit
  );

  const renderStatusFilter = () => {
    return (
      <ReactSelect
        value={filterFromObjectArray(creditStatusOptions, status || "", 'value')}
        name='progress'
        options={creditStatusOptions}
        className='basic-single'
        classNamePrefix='select'
        isSearchable={false}
        onChange={value => onChangeFilter('status', value.value)}
        components={{
          Control: ({ children, ...rest }) => (
            <components.Control {...rest}>
              <div
                className='rectangle-banner ml-4'
                style={{
                  backgroundColor:
                    filterFromObjectArray(creditStatusOptions, status || "", 'value')
                      ?.color ?? 'white',
                }}
              ></div>
              {children}
            </components.Control>
          ),
          DropdownIndicator,
        }}
        theme={BASIC_SELECT_THEME}
        styles={{
          ...BASIC_SELECT_STYLE,
          control: (styles) => ({
            ...styles,
            boxShadow: 'none',
            minHeight: '36px',
            height: '36px',
            borderWidth: '2px',
            borderStyle: 'solid',
            borderColor: '#E9E9E9 !important'
          }),
          menu: (styles) => {
            return {
              ...styles,
              backgroundColor: '#000016',
              marginTop: -1,
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              borderLeft: '2px solid #E9E9E9',
              borderRight: '2px solid #E9E9E9',
              borderBottom: '2px solid #E9E9E9',
            }
          },
          valueContainer: (styles) => ({
            ...styles,
            padding: '2px 16px',
            fontWeight: 700,
          }),
        }}
      />
    )
  }

  const renderBrandsFilter = () => {
    let all_options = [{ value: '', label: 'All Brands' }]
    if (getBrandsRequest) {
      all_options = [{ value: '', label: 'Loading...' }]
    }
    if (brands) {
      brands.map((brand) => {
        all_options.push({
          value: brand.id,
          label: brand.name,
        })
        return all_options
      })
    }

    return (
      <ReactSelect
        value={filterFromObjectArray(all_options, brand_id || '', 'value')}
        name='brand_name'
        options={all_options}
        className='basic-single'
        classNamePrefix='select'
        onChange={({ value }) => {
          onChangeFilter('brand_id', value);
        }}
        onMenuOpen={e => {
          dispatch(getCreditBrands({ name: '', type: 'list' }))
        }}
        components={{ DropdownIndicator }}
        theme={BASIC_SELECT_THEME}
        styles={{
          ...BASIC_SELECT_STYLE,
          singleValue: (provided, state) => ({
            color: '#e9e9e9 !important',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            maxWidth: 192,
          })
        }}
      />
    )
  }

  return (
    <div className='onboardings-content'>
      <div className='onboardings-filter'>
        <Row className='form-row'>
          <Col style={{ flexGrow: 'initial' }}>
            <div style={{ width: '196px' }}>
              {renderStatusFilter()}
            </div>
          </Col>
          <Col style={{ flexGrow: 'initial' }}>
            <div style={{ width: '240px' }}>
              {renderBrandsFilter()}
            </div>
          </Col>
          <Col style={{ flexGrow: 'initial' }}>
            <div style={{ width: '240px' }}>
              <DebounceInput
                value={order_id || ''}
                placeholder={'Search Associated Order ID'}
                name='owner'
                className='debounce-input'
                debounceTimeout={500}
                onChange={e => onChangeFilter('order_id', e.target.value)}
              />
            </div>
          </Col>
        </Row>
      </div>
      <CreditTable
        isLoading={getCreditListRequest}
        creditList={creditList}
        openCreditModal={openCreditModal}
      />
      <div className='dashed-line margin-top-10 margin-bottom-20'></div>
      {creditList ? (
        <NinjaPagination
          currentPage={creditList.current_page || 1}
          totalPages={creditList.last_page}
          pageNeighbours={1}
          goToPage={page => goToPage(page)}
        />
      ) : null}
      <CreditModal />
    </div>
  )
}
