// MainLayoutRoute is restricted
// only auth user can access
// redirect to /login page when:
// a. no token
// b. no profile
import React from 'react'
import Cookies from 'js-cookie'
import { Route, Redirect } from 'react-router-dom'
import MainLayout from './MainLayout'
import { TOKEN_KEY } from '../../utils/constant'

class MainLayoutRoute extends React.Component {
  render() {
    const { component: Component, location, ...rest } = this.props
    let hasToken = Cookies.get(TOKEN_KEY)
    return (
      <Route
        {...rest}
        render={props =>
          hasToken ? (
            <MainLayout {...props}>
              <Component {...props} />
            </MainLayout>
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: location },
              }}
            />
          )
        }
      />
    )
  }
}

export default MainLayoutRoute
