import React from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Input,
  Row,
  Col,
  FormGroup,
  Form,
} from 'reactstrap'

function DomainInformationModal(props) {
  let {
    open,
    toggle,
    onDomainFormChange,
    domainFormData,
    confirmPassword,
    confirmPswRequest,
    confirmPswError,
    confirmPswSuccess,
    saved_information_notes,
    saved_information_notes_edit,
    onFormChange,
    cn,
  } = props
  return (
    <Modal
      isOpen={open}
      toggle={toggle}
      className='domain-information-modal'
      centered={true}
    >
      <ModalHeader className={cn}>Other Information</ModalHeader>
      <ModalBody>
        {confirmPswSuccess ? (
          <div className='suopport-onboarding-notes in-modal'>
            <Row form>
              <Col className='p-0'>
                <FormGroup>
                  <Input
                    type='textarea'
                    placeholder={'Empty'}
                    value={saved_information_notes || ''}
                    onChange={e =>
                      onFormChange('saved_information_notes', e.target.value)
                    }
                    disabled={!saved_information_notes_edit}
                  />
                </FormGroup>
              </Col>

              <Button
                color='thirdary'
                className='write-btn'
                onClick={e =>
                  onFormChange(
                    'saved_information_notes_edit',
                    !saved_information_notes_edit,
                  )
                }
              >
                {saved_information_notes_edit ? (
                  <i className='fas fa-check'></i>
                ) : (
                  <i className='fas fa-pen'></i>
                )}
              </Button>
            </Row>
          </div>
        ) : (
          <Form
            onSubmit={function (e) {
              e.preventDefault()
            }}
          >
            <Input
              type='password'
              className='text-center mb-3'
              value={domainFormData.password ?? ''}
              onChange={e => onDomainFormChange('password', e.target.value)}
              placeholder={'Input your CRM account password'}
              disabled={false}
              autoComplete='off'
            />

            <Button
              color='thirdary'
              type='submit'
              className='btn-inform'
              onClick={e => confirmPassword()}
            >
              {confirmPswRequest ? (
                <i className='fad fa-spinner fa-spin mr-2'></i>
              ) : null}
              {'Reveal'}
            </Button>
            {confirmPswError ? (
              <div className='my-2 text-center text-red bold'>
                {confirmPswError}
              </div>
            ) : null}
          </Form>
        )}
      </ModalBody>
    </Modal>
  )
}
export default DomainInformationModal
