import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import CreditPage from "../../components/CreditPage";
import { getCreditList, handleCreditModal, getCredit } from '../../store/actions/credit'

const initialFilterOptions = {
	brand_id: "",
	status: "",
	order_id: "",
};

export default function Credit() {
	const dispatch = useDispatch();
	const { getCreditListRequest, creditList, needUpdateList } = useSelector(
		(state) => state.credit
	);
	const [filter, setFilter] = useState(initialFilterOptions);

	const getList = ({params, page = 1}) => {
		dispatch(getCreditList({params, page}))
	};

	const onChangeFilter = (type, value) => {
		setFilter({ ...filter, [type]: value });
		getList({
			params: { ...filter, [type]: value }, 
			page: 1,
		})
	};

	const openCreditModal = id => {
		dispatch(handleCreditModal({
      open: true,
      creditType: 'edit'
    }))
		dispatch(getCredit(id))
	}

	const goToPage = (page) => {
		getList({params: filter, page})
	}

	useEffect(() => {
		getList({params: filter})
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (needUpdateList) getList({params: filter})
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [needUpdateList])

	return (
		<CreditPage 
			filter={filter}
			getCreditListRequest={getCreditListRequest}
			creditList={creditList}
			onChangeFilter={onChangeFilter}
			openCreditModal={openCreditModal}
			goToPage={goToPage}
		/>
	);
}
