import React, { useState, useEffect } from 'react'
import { Modal } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'

import ModalBrand from './ModalComponents/ModalBrand'
import ModalLocation from './ModalComponents/ModalLocation'
import ModalReason from './ModalComponents/ModalReason'
import ModalOrderId from './ModalComponents/ModalOrderId'
import ModalComment from './ModalComponents/ModalComment'
import ModalItems from './ModalComponents/ModalItems'
import ModalConfirmation from './ModalComponents/ModalConfirmation'
import ModalSubmit from './ModalComponents/ModalSubmit'
import ModalAmount from './ModalComponents/ModalAmount'

import {
	handleCreditModal,
	getCreditLocations,
	getCreditOrder,
	createCreditOrder,
	approveCreditOrder,
} from '../../store/actions/credit'

import { formatCurrency } from '../../utils/digit'
import { toast } from 'react-toastify'

export default function CreditModal() {
	const dispatch = useDispatch()
	const {
		creditOpen,
		creditType,
		creditData,
		createCreditRequest,
		approveCreditRequest,
	} = useSelector(
		(state) => state.credit
	);
	const [brandName, setBrandName] = useState('')
	const [brandId, setBrandId] = useState(null)
	const [locationId, setLocationId] = useState(null)
	const [locationOptions, setLocationOptions] = useState([])
	const [reason, setReason] = useState('')
	const [assoOrder, setAssoOrder] = useState('')
	const [orderId, setOrderId] = useState(null)
	const [orderData, setOrderData] = useState(null)
	const [selectCreditData, setSelectCreditData] = useState({})
	const [orderTotal, setOrderTotal] = useState(0)
	const [creditComment, setCreditComment] = useState('')
	const [creditConfirmation, setCreditConfirmation] = useState('')
	const [approveNote, setApproveNote] = useState('N/A')
	const [creditAmount, setCreditAmount] = useState('')
	const [status, setStatus] = useState('')

	const disabled = creditType === 'edit'

	const getLocations = async id => {
		const res = await dispatch(getCreditLocations({ id }))
		if (res.success) {
			resetLevel(1)
			setLocationOptions(res.data.map(item => {
				return {
					value: item.id,
					label: item.name
				}
			}))
		}
	}

	const handleSetOrderId = async id => {
		setOrderId(id)
		if (id.length >= 8) {
			const res = await dispatch(getCreditOrder({ orderId: id, locationId, type: reason }))
			if (res.success) {
				setOrderData(res.data)
			}
		}
	}

	const handleSelect = (type, price) => {
		if (disabled) return
		if (type === 'subtotal') {
			if (!price) {
				if (selectCreditData.subtotal) {
					const data = JSON.parse(JSON.stringify(selectCreditData))
					delete data[type]
					console.log(data, 'data')
					setSelectCreditData(data)
					return
				}
			}
			setSelectCreditData({
				...selectCreditData,
				[type]: price ? price : '$0.00'
			})
			return
		}
		if (selectCreditData[type]) {
			const data = JSON.parse(JSON.stringify(selectCreditData))
			delete data[type]
			setSelectCreditData(data)
		} else {
			setSelectCreditData({
				...selectCreditData,
				[type]: price
			})
		}
	}

	const handleSelectBlur = (price) => {
		if (price && Number(price) > 0 && Number(price) <= Number(orderData.subtotal)) {
			setSelectCreditData({
				...selectCreditData,
				subtotal: `$${formatCurrency(price)}`
			})
			return
		}
		setSelectCreditData({
			...selectCreditData,
			subtotal: ''
		})
	}

	const handleSelectFocus = (price) => {
		if (price[0] === '$' && Number(price.substr(1)) > 0) {
			setSelectCreditData({
				...selectCreditData,
				subtotal: Number(price.substr(1))
			})
			return
		}
		setSelectCreditData({
			...selectCreditData,
			subtotal: ''
		})
	}

	const resetLevel = lv => {
		if (lv === 0) {
			setBrandId(null)
			setBrandName('')
		}
		if (lv <= 1) {
			setLocationOptions([])
			setLocationId(null)
		}
		if (lv <= 2) {
			setReason('')
		}
		if (lv <= 3) {
			setAssoOrder('')
		}
		setOrderId(null)
		setOrderData(null)
		setSelectCreditData({})
		setCreditComment('')
		setCreditConfirmation('')
		setCreditAmount('')
		setStatus('')
		setApproveNote('N/A')
	}

	const submit = () => {
		if (!creditComment) {
			toast.warn('Please Input Credit Comment');
			return
		}
		const params = {
			location_id: locationId,
			order_id: orderId,
			reason,
			comment: creditComment,
			confirmation: creditConfirmation,
			credit_amount: reason === 'odd' ? orderTotal : creditAmount.substr(2),
			credit_items: Object.keys(selectCreditData).map(key => {
				if (key === 'subtotal') {
					return {
						name: 'subtotal',
						amount: Number(selectCreditData[key].substr(1))
					}
				}
				return {
					name: key,
					amount: Number(selectCreditData[key])
				}
			})
		}
		dispatch(createCreditOrder(params))
	}

	const approve = status => {
		const params = {
			credit_id: creditData.id,
			status,
			approver_note: approveNote === 'N/A' ? '' : approveNote
		}
		dispatch(approveCreditOrder(params))
	}

	useEffect(() => {
		let result = 0
		Object.values(selectCreditData).forEach(price => {
			if (price[0] === '$' && Number(price.substr(1)) > 0) {
				result += Number(price.substr(1))
			} else {
				result += Number(price)
			}
		})
		setOrderTotal(result)
	}, [selectCreditData])

	useEffect(() => {
		if (creditData) {
			const {
				location,
				reason,
				order_id,
				order,
				comment,
				confirmation,
				credit_items,
				status,
				approver_note,
				amount,
			} = creditData
			const { brand } = location
			setBrandId(brand.id)
			setBrandName(brand.name)
			setLocationOptions([{
				label: location.name,
				value: location.id
			}])
			setLocationId(location.id)
			setReason(reason)
			setOrderId(order_id)
			if (reason === 'other') {
				if (order_id) {
					setAssoOrder('yes')
				} else {
					setAssoOrder('no')
				}
			}
			setOrderData(order)
			setCreditComment(comment)
			setCreditConfirmation(confirmation)
			const CreditObj = {}
			credit_items.forEach(item => {
				if (item.name === 'subtotal') {
					CreditObj[item.name] = `$${item.amount}`
				} else {
					CreditObj[item.name] = item.amount
				}
			})
			setSelectCreditData(CreditObj)
			setStatus(status)
			setApproveNote(approver_note)
			setCreditAmount(`$ ${amount}`)
		} else {
			resetLevel(0)
		}
	}, [creditData, creditOpen]) // creditOpen 改变时，也要再初始化一下 modal 的数据

	return (
		<Modal
			isOpen={creditOpen}
			toggle={() =>
				dispatch(handleCreditModal({
					open: !creditOpen,
				}))
			}
			className='credit-modal'
			modalTransition={{ baseClass: 'fade-right' }}
		>
			<div className='credit-modal-item'>
				<ModalBrand
					disabled={disabled}
					brandName={brandName}
					setBrandName={setBrandName}
					getLocations={getLocations}
					setBrandId={setBrandId}
				/>
				{brandId && (
					<ModalLocation
						disabled={disabled}
						locationOptions={locationOptions}
						locationId={locationId}
						setLocationId={newId => {
							setLocationId(newId)
							resetLevel(2)
						}}
					/>
				)}
			</div>
			{locationId && (
				<div className='credit-modal-item'>
					<ModalReason
						disabled={disabled}
						reason={reason}
						assoOrder={assoOrder}
						setReason={newReason => {
							setReason(newReason)
							resetLevel(3)
						}}
						setAssoOrder={newAsso => {
							setAssoOrder(newAsso)
							resetLevel(4)
						}}
					/>
				</div>
			)}
			{reason === 'odd' || (reason === 'other' && assoOrder === 'yes') ? (
				<div className='credit-modal-item'>
					<ModalOrderId
						disabled={disabled}
						orderId={orderId}
						orderData={orderData}
						handleSetOrderId={handleSetOrderId}
					/>
				</div>
			) : null}
			{orderData || (reason === 'other' && assoOrder === 'no') ? (
				<div className='credit-modal-item'>
					<ModalComment
						disabled={disabled}
						creditComment={creditComment}
						setCreditComment={setCreditComment}
					/>
				</div>
			) : null}
			{orderData && reason === 'odd' ? (
				<>
					<div className='credit-modal-item'>
						<ModalItems
							disabled={disabled}
							orderData={orderData}
							selectCreditData={selectCreditData}
							orderTotal={orderTotal}
							handleSelect={handleSelect}
							handleSelectBlur={handleSelectBlur}
							handleSelectFocus={handleSelectFocus}
						/>
					</div>
					<div className='credit-modal-item'>
						<ModalConfirmation
							disabled={disabled}
							creditConfirmation={creditConfirmation}
							setCreditConfirmation={setCreditConfirmation}
						/>
					</div>
					<div className='credit-modal-item'>
						<ModalSubmit
							type={creditType}
							status={status}
							approveNote={approveNote}
							setApproveNote={setApproveNote}
							createCreditRequest={createCreditRequest}
							approveCreditRequest={approveCreditRequest}
							submit={submit}
							approve={approve}
						/>
					</div>
				</>
			) : null}
			{(reason === 'other' && assoOrder === 'no') || (reason === 'other' && assoOrder === 'yes' && orderData) ? (
				<>
					<div className='credit-modal-item'>
						<ModalAmount
							disabled={disabled}
							creditAmount={creditAmount}
							setCreditAmount={setCreditAmount}
						/>
					</div>
					<div className='credit-modal-item'>
						<ModalSubmit
							type={creditType}
							status={status}
							approveNote={approveNote}
							setApproveNote={setApproveNote}
							createCreditRequest={createCreditRequest}
							approveCreditRequest={approveCreditRequest}
							submit={submit}
							approve={approve}
						/>
					</div>
				</>
			) : null}
		</Modal>
	)
}