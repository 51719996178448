import React from 'react'

export default function DialogBtn({ isBlank, value, handleClick, styles }) {
  return (
    <div className='dialog-btn' onClick={handleClick} style={styles}>
      <div className='dialog-btn-text'>{value}</div>
      {isBlank ? (
        <div className='dialog-btn-icon is-blank'></div>
      ) : (
        <div className='dialog-btn-icon'></div>
      )}
    </div>
  )
}
