import axios from 'axios'
import { toast } from 'react-toastify'
export const GET_LEADERBOARD_LIST = 'GET_LEADERBOARD_LIST'
export const GET_LEADERBOARD_LIST_FAILED = 'GET_LEADERBOARD_LIST_FAILED'
export const GET_LEADERBOARD_LIST_REQUEST = 'GET_LEADERBOARD_LIST_REQUEST'

export const GET_LEADERBOARD_DETAIL_REQUEST = 'GET_LEADERBOARD_DETAIL_REQUEST'
export const GET_LEADERBOARD_DETAIL = 'GET_LEADERBOARD_DETAIL'
export const GET_LEADERBOARD_DETAIL_FAILED = 'GET_LEADERBOARD_DETAIL_FAILED'

export const getLeaderboardList = params => dispatch => {
  dispatch({ type: GET_LEADERBOARD_LIST_REQUEST })
  axios
    .request({
      url: 'nc/leaderboard/list',
      method: 'GET',
      params: params,
      cache: { ignoreCache: true },
    })
    .then(({ data }) => {
      if (data.success) {
        dispatch({
          type: GET_LEADERBOARD_LIST,
          data: data.data,
          msg: data.message,
        })
      } else {
        dispatch({
          type: GET_LEADERBOARD_LIST_FAILED,
          msg: data.message,
          errors: data.errors,
        })
        toast.warn(data.message)
      }
    })
    .catch(err => {
      toast.warn(err.message)
      dispatch({ type: GET_LEADERBOARD_LIST_FAILED })
    })
}

export const getLeaderDetail = (order_id, params) => dispatch => {
  dispatch({ type: GET_LEADERBOARD_DETAIL_REQUEST })
  axios
    .request({
      url: `nc/leaderboard/${order_id}`,
      method: 'get',
      params,
      cache: { ignoreCache: true },
    })
    .then(({ data }) => {
      if (data.redirect) return
      if (data.success) {
        dispatch({
          type: GET_LEADERBOARD_DETAIL,
          data: data.data,
          msg: data.message,
        })
      } else {
        dispatch({
          type: GET_LEADERBOARD_DETAIL_FAILED,
          msg: data.message,
          errors: data.errors,
        })
        toast.warn(data.message)
      }
    })
    .catch(err => {
      toast.warn(err.message)
      console.error(err)
      dispatch({ type: GET_LEADERBOARD_DETAIL_FAILED })
    })
}
