import React from 'react'
import {
  Card,
  Form,
  FormGroup,
  Input,
  Button,
  Row,
  Col,
  Label,
  Alert,
} from 'reactstrap'
import { DOMAIN } from '../../utils/constant'
import { formatPhoneNumber } from '../../utils/digit'
import MailCopy from './MailCopy'
import Upload from '../Upload/Upload'
import './Profile.scss'

function Profile(props) {
  let { profile, state } = props
  let { formData, isFormChanged, editProfileRequest, editProfileError } = state
  if (!profile || !formData) return null

  let closer_phone = ''

  if (profile?.phone) {
    closer_phone = formatPhoneNumber(profile.phone)
  }

  // 是否是rushable账户
  const isRushable = profile.crm_team?.type === 'rushable'
  let uploadEl = ''
  if (profile.profile_photo) {
    const avatar = `${DOMAIN}${profile.profile_photo}`
    uploadEl = (
      <div className='upload-preview'>
        <img src={avatar} alt='' />
      </div>
    )
  } else {
    uploadEl = <i className='fas fa-user-circle f-72 head-profile'></i>
  }

  return (
    <Card className='board-card'>
      <button
        className='btn-board-close go-back-btn'
        onClick={e => props.goBack()}
      >
        GO BACK
      </button>
      <div className='user-name'>
        {formData.first_name || ''} {formData.last_name}
      </div>
      <div className='board-content profile-wrap'>
        <>
          <div className='w-100 head'>
            <Upload
              uploadEl={uploadEl}
              onChange={e => {
                props?.getProfileRefresh()
              }}
            ></Upload>
          </div>
          <div className='w-100 content'>
            <Form onSubmit={props.onSubmit}>
              <Row form>
                <Col md='6'>
                  <FormGroup>
                    <Label>FIRST NAME</Label>
                    <Input
                      type='text'
                      name='first_name'
                      value={formData.first_name || ''}
                      onChange={e =>
                        props.onFormChange('first_name', e.target.value)
                      }
                    />
                  </FormGroup>
                </Col>

                <Col md='6'>
                  <FormGroup>
                    <Label>LAST NAME</Label>
                    <Input
                      type='text'
                      name='last_name'
                      value={formData.last_name || ''}
                      onChange={e =>
                        props.onFormChange('last_name', e.target.value)
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col md='6'>
                  <FormGroup>
                    <Label>WORK PHONE</Label>
                    <Input
                      mask='(999)999-9999'
                      type='phone'
                      name='phone'
                      defaultValue={closer_phone || ''}
                      disabled={true}
                    />
                  </FormGroup>
                </Col>

                <Col md='6'>
                  <FormGroup>
                    <Label>WORK EMAIL</Label>
                    <Input
                      type='email'
                      name='email'
                      defaultValue={profile.email || ''}
                      disabled={true}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col md='6'>
                  <FormGroup>
                    <Label>TEAM</Label>
                    <Input
                      type='text'
                      name='team_name'
                      disabled={true}
                      defaultValue={profile.crm_team.name || ''}
                    />
                  </FormGroup>
                </Col>

                <Col md='6'>
                  <FormGroup>
                    <Label>POSITION</Label>
                    <Input
                      type='text'
                      name='positon'
                      disabled={!isRushable}
                      value={formData.position}
                      onChange={e => {
                        props.onFormChange('position', e.target.value)
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>

              {isRushable && (
                <>
                  <Row form>
                    <Col>
                      <FormGroup>
                        <Label>SCHEDULE A CALL LINK [OPTIONAL]</Label>
                        <Input
                          name='call_link'
                          value={formData.call_link || ''}
                          onChange={e =>
                            props.onFormChange('call_link', e.target.value)
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <MailCopy
                    formData={formData}
                    phone={closer_phone}
                    email={profile.email}
                  />
                </>
              )}
              <Row form>
                <Col>
                  <FormGroup>
                    <Label>CURRENT PASSWORD</Label>
                    <Input
                      type='password'
                      name='old_password'
                      value={formData.old_password || ''}
                      onChange={e =>
                        props.onFormChange('old_password', e.target.value)
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col>
                  <FormGroup>
                    <Label>NEW PASSWORD</Label>
                    <Input
                      type='password'
                      name='new_password'
                      value={formData.new_password || ''}
                      onChange={e =>
                        props.onFormChange('new_password', e.target.value)
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col>
                  <FormGroup>
                    <Label>REPEAT NEW PASSWORD</Label>
                    <Input
                      type='password'
                      name='new_password_confirmation'
                      value={formData.new_password_confirmation || ''}
                      onChange={e =>
                        props.onFormChange(
                          'new_password_confirmation',
                          e.target.value,
                        )
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </div>
        </>

        {editProfileError && (
          <Alert color='danger' className='mt-3'>
            {editProfileError}
          </Alert>
        )}

        {isFormChanged && (
          <div className='update-profile'>
            <Button
              color='primary'
              onClick={props.editProfile}
              disabled={editProfileRequest}
            >
              {editProfileRequest && (
                <i className='fad fa-spinner fa-spin mr-2' />
              )}
              UPDATE PROFILE
            </Button>
          </div>
        )}
        <Button
          color='link'
          type='button'
          className='text-red btn-board-red-link'
          style={{ marginTop: '27px' }}
          block
          onClick={e => props.logout()}
        >
          LOGOUT
        </Button>
      </div>
    </Card>
  )
}

export default Profile
