import React from 'react'
import PropTypes from 'prop-types'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'

const LEFT_PAGE = 'LEFT'
const RIGHT_PAGE = 'RIGHT'

/**
 * Helper method for creating a range of numbers
 * range(1, 5) => [1, 2, 3, 4, 5]
 */
const range = (from, to, step = 1) => {
  let i = from
  const range = []

  while (i <= to) {
    range.push(i)
    i += step
  }

  return range
}

class NinjaPagination extends React.Component {
  handleClick(e, pageNum) {
    e.preventDefault()
    this.props.goToPage(pageNum)
  }

  /**
   * Let's say we have 10 pages and we set pageNeighbours to 2
   * Given that the current page is 6
   * The NinjaPagination control will look like the following:
   *
   * (1) < {4 5} [6] {7 8} > (10)
   *
   * (x) => terminal pages: first and last page(always visible)
   * [x] => represents current page
   * {...x} => represents page neighbours
   */
  fetchPageNumbers = () => {
    const { totalPages, currentPage, pageNeighbours } = this.props
    /**
     * totalNumbers: the total page numbers to show on the control
     * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
     */
    const totalNumbers = pageNeighbours * 2 + 3
    const totalBlocks = totalNumbers + 2

    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, currentPage - pageNeighbours)
      const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours)

      let pages = range(startPage, endPage)

      /**
       * hasLeftSpill: has hidden pages to the left
       * hasRightSpill: has hidden pages to the right
       * spillOffset: number of hidden pages either to the left or to the right
       */
      const hasLeftSpill = startPage > 2
      const hasRightSpill = totalPages - endPage > 1
      const spillOffset = totalNumbers - (pages.length + 1)

      switch (true) {
        // handle: (1) < {5 6} [7] {8 9} (10)
        case hasLeftSpill && !hasRightSpill: {
          const extraPages = range(startPage - spillOffset, startPage - 1)
          pages = [LEFT_PAGE, ...extraPages, ...pages]
          break
        }

        // handle: (1) {2 3} [4] {5 6} > (10)
        case !hasLeftSpill && hasRightSpill: {
          const extraPages = range(endPage + 1, endPage + spillOffset)
          pages = [...pages, ...extraPages, RIGHT_PAGE]
          break
        }

        // handle: (1) < {4 5} [6] {7 8} > (10)
        case hasLeftSpill && hasRightSpill:
        default: {
          pages = [LEFT_PAGE, ...pages, RIGHT_PAGE]
          break
        }
      }

      return [1, ...pages, totalPages]
    }

    return range(1, totalPages)
  }

  render() {
    const { totalPages, currentPage } = this.props
    if (totalPages === 1) return null

    const pages = this.fetchPageNumbers()

    return (
      <Pagination
        size='sm'
        className=''
        aria-label='Page navigation example'
        listClassName='justify-content-center'
      >
        {pages.map((page, index) => {
          if (page === LEFT_PAGE)
            return (
              <PaginationItem key={`page-${index}`}>
                <PaginationLink
                  // href="#"
                  onClick={e => this.handleClick(e, currentPage - 1)}
                >
                  <i className='fal fa-angle-left'></i>
                </PaginationLink>
              </PaginationItem>
            )

          if (page === RIGHT_PAGE)
            return (
              <PaginationItem key={`page-${index}`}>
                <PaginationLink
                  // href="#"
                  onClick={e => this.handleClick(e, currentPage + 1)}
                >
                  <i className='fal fa-angle-right'></i>
                </PaginationLink>
              </PaginationItem>
            )

          return (
            <PaginationItem active={currentPage === page} key={`page-${index}`}>
              <PaginationLink
                // href="#"
                onClick={e => this.handleClick(e, page)}
              >
                {page}
              </PaginationLink>
            </PaginationItem>
          )
        })}
      </Pagination>
    )
  }
}
NinjaPagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number,
  pageNeighbours: PropTypes.number,
  goToPage: PropTypes.func,
}
export default NinjaPagination
