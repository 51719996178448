import {
  AUTH_LOGIN_REQUEST,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_FAIL,
  AUTH_PROFILE_REQUEST,
  AUTH_PROFILE_SUCCESS,
  AUTH_PROFILE_FAIL,
  AUTH_GET_USERS_REQUEST,
  AUTH_GET_USERS_SUCCESS,
  AUTH_GET_USERS_FAIL,
  AUTH_LOGOUT,
  AUTH_EDIT_PROFILE_REQUEST,
  AUTH_EDIT_PROFILE_SUCCESS,
  AUTH_EDIT_PROFILE_FAIL,
} from '../actions/auth'

const initialState = {
  loginRequest: false,
  loginError: '',
  profile: null,
  logoutRequest: false,
  selectedBrandId: null,
  resetRequest: null,
  getProfileRequest: false,
  users: null,
  getUsersError: null,
  getUsersRequest: false,
}

const auth = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_LOGIN_REQUEST:
      return {
        ...state,
        loginRequest: true,
        loginError: '',
        profile: null,
      }
    case AUTH_LOGIN_SUCCESS:
      return {
        ...state,
        loginRequest: false,
      }
    case AUTH_LOGIN_FAIL:
      return {
        ...state,
        loginRequest: false,
        loginError: action.msg,
      }

    case AUTH_GET_USERS_REQUEST:
      return {
        ...state,
        getUsersRequest: true,
      }
    case AUTH_GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.data,
        getUsersRequest: false,
      }
    case AUTH_GET_USERS_FAIL:
      return {
        ...state,
        users: null,
        getUsersError: action.msg,
        getUsersRequest: false,
      }
    case AUTH_PROFILE_REQUEST:
      return {
        ...state,
        getProfileRequest: true,
      }
    case AUTH_PROFILE_SUCCESS:
      return {
        ...state,
        profile: action.profile,
        getProfileRequest: false,
      }
    case AUTH_PROFILE_FAIL:
      return {
        ...state,
        profile: null,
        getProfileError: action.msg,
        getProfileRequest: false,
      }
    case AUTH_LOGOUT:
      return {}
    case AUTH_EDIT_PROFILE_REQUEST:
      return {
        ...state,
        editProfileRequest: true,
        editProfileSuccess: null,
        editProfileError: null,
      }
    case AUTH_EDIT_PROFILE_SUCCESS:
      return {
        ...state,
        editProfileRequest: false,
        editProfileSuccess: action.msg,
        editProfileError: null,
      }
    case AUTH_EDIT_PROFILE_FAIL:
      return {
        ...state,
        editProfileRequest: false,
        editProfileSuccess: null,
        editProfileError: action.msg,
      }
    default:
      return state
  }
}

export default auth
