import React from 'react'

const BadgeText = ({ label }) => {
  const color =
    label === 'ready'
      ? '#008DFF'
      : label === 'new'
      ? '#FFE200'
      : label === 'accepted'
      ? '#F59762'
      : label === 'completed'
      ? '#03C9A9'
      : label === 'placed'
      ? '#efc853'
      : label === 'canceled'
      ? '#ed5f65'
      : '#000000'
  return (
    <div className='badge-status d-flex align-items-center'>
      <div
        className='rounded-circle mr-3'
        style={{ backgroundColor: color, width: 6, height: 6 }}
      ></div>
      <div className='text-capitalize'>{label}</div>
    </div>
  )
}

export default BadgeText
