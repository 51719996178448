import React from "react";
import { Provider } from "react-redux";
import { Switch, Route } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import store, { history } from "./store/store";
import httpService from "./utils/api.interceptors";
import Login from "./pages/auth/Login";
import OnboardingsIndex from "./pages/onboardings/index";
import AffiliatesIndex from "./pages/affiliate/affiliates/index";
import SupportsIndex from "./pages/supports/index";
import LiveOrder from "./pages/liveorder";
import LiveOrderDetail from "./pages/liveorder/LiveOrderDetail";
import Leaderboard, { LeaderboardDetail } from "./pages/leaderboard";
import SupportIndex from "./pages/support/index";
import AffiliateTeamIndex from "./pages/affiliate/affiliateTeam/index";
import OnboardingIndex from "./pages/onboarding/index";
import MainLayoutRoute from "./pages/layouts/MainLayoutRoute";
import DetailLayoutRoute from "./pages/layouts/DetailLayoutRoute";
import ProfileIndex from "./pages/profile/index";
import PageNotFound from "./pages/PageNotFound";
import Referral from "./pages/referral";
import Credit from "./pages/credit";
import InitRoute from "./InitRoute";

httpService.setupInterceptors(store, history)
function App() {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Switch>
          <MainLayoutRoute path='/profile' component={ProfileIndex} />
          <MainLayoutRoute exact path='/signups' component={OnboardingsIndex} />
          <MainLayoutRoute
            exact
            path='/affiliates'
            component={AffiliatesIndex}
          />

          <MainLayoutRoute
            exact
            path='/restaurants'
            component={SupportsIndex}
          />
          <MainLayoutRoute exact path='/liveorders' component={LiveOrder} />
          <MainLayoutRoute
            exact
            path='/liveorders/:orderId'
            component={LiveOrderDetail}
          />
          <MainLayoutRoute exact path="/leaderboard" component={Leaderboard} />
          <MainLayoutRoute exact path="/referral" component={Referral} />
          <MainLayoutRoute exact path="/credit" component={Credit} />
          <DetailLayoutRoute
            exact
            path='/leaderboard/:leaderId'
            component={LeaderboardDetail}
          />
          <DetailLayoutRoute
            path='/restaurants/:supportId'
            component={SupportIndex}
          />
          <MainLayoutRoute
            path='/signups/:signupId'
            component={OnboardingIndex}
          />
          <DetailLayoutRoute
            path='/affiliate-team/:teamId'
            component={AffiliateTeamIndex}
          />

          <Route path='/login' component={Login} />
          <MainLayoutRoute exact path='/' component={InitRoute} />
          <MainLayoutRoute path='*' component={PageNotFound} />
        </Switch>
      </ConnectedRouter>
    </Provider>
  )
}

export default App
