import React, { useState, useEffect } from 'react'
import ReactSelect from 'react-select'
import { useDispatch } from 'react-redux'
import { Modal, Input } from 'reactstrap'
import multiDownload from 'multi-download'
import {
  BASIC_SELECT_STYLE,
  BASIC_SELECT_THEME,
} from '../../../../utils/constant'
import DialogBtn from './DialogBtn'
import DomainInformationModal from './DomainInformationModal'
import {
  filterFromObjectArray,
  deepClone,
  copyText,
} from '../../../../utils/tools'
import { passwordVerify } from '../../../../store/actions/support'
import DropdownIndicator from '../../../Common/DropdownIndicator'
import { toast } from 'react-toastify'

export default function Preference({
  onboardingId,
  uploadFile,
  deleteFile,
  onboardingFormData,
  downloadAllLoading,
  onOnboardingFormChange,
  downloadFile,
}) {
  const {
    website_preference,
    has_existing_domain,
    self_domain,
    delegate_dns_or_website,
    website_and_domain_status,
    has_gmb_ownership,
    need_help_regain_gmb,
    delegate_gmb_update,
    gmb_status,
    menu_link,
    menu_work_notes,
    onboarding_menus,
    saved_information_notes,
    saved_information_notes_edit,
    tablet_shipping_address,
    has_information,
  } = onboardingFormData
  const dispatch = useDispatch()
  const [domain, setDomain] = useState('')
  const [menuSourceDialog, setMenuSourceDialog] = useState({
    show: false,
    linkOpen: false,
    linkText: '',
    noteOpen: false,
    noteText: '',
  })
  const [otherInfo, setOtherInfo] = useState({
    show: false,
    text: '',
    open: false,
  })
  const [modalOpen, setModalOpen] = useState(false)
  const [confirmPswRequest, setConfirmPswRequest] = useState(false)
  const [confirmPswError, setConfirmPswError] = useState()
  const [confirmPswSuccess, setConfirmSuccess] = useState(false)
  const [domainFormData, setDomainFormData] = useState({
    password: '',
  })
  const [loadingFile, setLoadingFile] = useState(false)
  const [loadingDeleteId, setLoadingDeleteId] = useState(null)
  // const [downloadAllLoading, setDownloadAllLoading] = useState(false);
  const allOptions = {
    website_preference: [
      { value: '', label: '-' },
      { value: 'full_website', label: 'Full website' },
      { value: 'ordering_page_only', label: 'Ordering page' },
    ],
    has_existing_domain: [
      { value: '', label: '-' },
      { value: 0, label: 'New domain' },
      { value: 1, label: 'Existing domain' },
    ],
    delegate_dns_or_website: [
      { value: '', label: '-' },
      { value: 0, label: 'Self update' },
      { value: 1, label: 'Update by Rushable' },
    ],
    website_and_domain_status: [
      { value: '', label: '-' },
      { value: 'instructed', label: 'Instructed' },
      { value: 'access_granted', label: 'Access granted' },
      { value: 'updated', label: 'Update completed' },
    ],
    has_gmb_ownership: [
      { value: '', label: '-' },
      { value: 1, label: 'Has ownership' },
      { value: 0, label: 'Need to claim' },
    ],
    need_help_regain_gmb: [
      { value: '', label: '-' },
      { value: 'self_claim', label: 'Self claim' },
      { value: 'rushable_claiming', label: 'Claiming by Rushable' },
      { value: 'rushable_claimed', label: 'Claimed by Rushable' },
    ],
    delegate_gmb_update: [
      { value: '', label: '-' },
      { value: 0, label: 'Self update' },
      { value: 1, label: 'Update by Rushable' },
    ],
    gmb_status: [
      { value: '', label: '-' },
      { value: 'instructed', label: 'Instructed' },
      { value: 'access_granted', label: 'Access granted' },
      { value: 'updated', label: 'Update completed' },
    ],
  }
  const confirmPassword = psw => {
    let { password } = domainFormData
    let params = { password }
    setConfirmPswRequest(true)
    dispatch(passwordVerify(params, onboardingId))
      .then(({ data }) => {
        if (data.success) {
          setConfirmPswRequest(false)
          setConfirmPswError('')
          setModalOpen(false)
          setOtherInfo({
            ...otherInfo,
            show: true,
            text: data.data.saved_information_notes,
          })
        } else {
          setConfirmPswRequest(false)
          setConfirmPswError(data.message)
        }
      })
      .catch(error => {
        let resData = error.response
          ? error.response.data
          : { message: error.message }
        setConfirmPswRequest(false)
        setConfirmPswError(resData.message)
      })
  }

  const handleUploadFile = async e => {
    if (e.target.files.length === 0) {
      return
    }
    setLoadingFile(true)
    let file = e.target.files[0]
    uploadFile(file, () => setLoadingFile(false))
  }
  const handleDeleteFile = id => {
    setLoadingDeleteId(id)
    deleteFile(id, () => {
      setLoadingDeleteId(null)
    })
  }
  const downloadAll = async () => {
    onboarding_menus.forEach(async fileData => {
      await downloadFile(fileData.id, fileData.file_name)
    })
  }
  const menuSourceDialogRender = () => {
    return (
      <Modal
        isOpen={menuSourceDialog.show}
        toggle={() =>
          setMenuSourceDialog({
            ...menuSourceDialog,
            show: !menuSourceDialog.show,
          })
        }
        className='msd'
        centered={true}
      >
        <div className='msd-container'>
          <div className='msd-title'>MENU SOURCE</div>
          <div className='msd-link'>
            <div className='msd-link-left'>
              {menuSourceDialog.linkOpen ? (
                <Input
                  className='msd-link-input'
                  value={menuSourceDialog.linkText || ''}
                  onChange={e =>
                    setMenuSourceDialog({
                      ...menuSourceDialog,
                      linkText: e.target.value,
                    })
                  }
                />
              ) : (
                <div className='msd-link-text'>{menu_link}</div>
              )}
            </div>
            {menuSourceDialog.linkOpen ? (
              <div
                className='msd-link-icon action'
                onClick={() => {
                  onOnboardingFormChange('menu_link', menuSourceDialog.linkText)
                  setMenuSourceDialog({
                    ...menuSourceDialog,
                    linkOpen: false,
                    linkText: menu_link,
                  })
                }}
              >
                <i className='fas fa-check' />
              </div>
            ) : (
              <div
                className='msd-link-icon'
                onClick={() => {
                  setMenuSourceDialog({
                    ...menuSourceDialog,
                    linkOpen: true,
                    linkText: menu_link,
                  })
                }}
              >
                <i className='far fa-pen' />
              </div>
            )}
          </div>
          <div className='msd-upload'>
            <div className='msd-upload-menus'>
              {onboarding_menus &&
                onboarding_menus.map(menu => (
                  <div className='msd-upload-menus-row' key={menu.id}>
                    <Input
                      value={menu.download_link || ''}
                      type='text'
                      className='msd-upload-menus-text'
                    />
                    <div
                      className='msd-upload-menus-icon'
                      onClick={() => handleDeleteFile(menu.id)}
                    >
                      {menu.id === loadingDeleteId ? (
                        <i className='far fa-spinner fa-spin' />
                      ) : (
                        <i className='far fa-trash-alt' />
                      )}
                    </div>
                  </div>
                ))}
              {loadingFile && (
                <div className='msd-upload-menus-row'>
                  <Input type='text' className='msd-upload-menus-text' />
                  <div className='msd-upload-menus-icon'>
                    <i className='far fa-spinner fa-spin' />
                  </div>
                </div>
              )}
            </div>
            <div className='msd-upload-button'>
              <div className='msd-upload-add'>
                <span
                  className='msd-upload-add-text'
                  style={{
                    color:
                      onboarding_menus && onboarding_menus.length > 0
                        ? '#03C9A9'
                        : 'rgba(233, 233, 233, 0.5)',
                  }}
                >
                  + UPLOAD FILE
                </span>
                <Input
                  type='file'
                  className='msd-upload-add-input'
                  accept='application/pdf,application/vnd.ms-excel,image/*'
                  multiple={false}
                  onChange={handleUploadFile}
                />
              </div>
              <div className='msd-upload-all' onClick={downloadAll}>
                {downloadAllLoading && <i className='fad fa-spinner fa-spin' />}
                DOWNLOAD ALL
              </div>
            </div>
          </div>
          <div className='msd-note-title'>MENU Q&A</div>
          <div className='msd-note'>
            <div className='msd-note-left'>
              {menuSourceDialog.noteOpen ? (
                <Input
                  type='textarea'
                  className='msd-note-input'
                  value={menuSourceDialog.noteText || ''}
                  onChange={e =>
                    setMenuSourceDialog({
                      ...menuSourceDialog,
                      noteText: e.target.value,
                    })
                  }
                />
              ) : (
                <div className='msd-note-text'>{menu_work_notes}</div>
              )}
            </div>
            {menuSourceDialog.noteOpen ? (
              <div
                className='msd-note-icon action'
                onClick={() => {
                  onOnboardingFormChange(
                    'menu_work_notes',
                    menuSourceDialog.noteText,
                  )
                  setMenuSourceDialog({
                    ...menuSourceDialog,
                    noteOpen: false,
                    noteText: menu_work_notes,
                  })
                }}
              >
                <i className='fas fa-check' />
              </div>
            ) : (
              <div
                className='msd-note-icon'
                onClick={() => {
                  setMenuSourceDialog({
                    ...menuSourceDialog,
                    noteOpen: true,
                    noteText: menu_work_notes,
                  })
                }}
              >
                <i className='far fa-pen' />
              </div>
            )}
          </div>
        </div>
      </Modal>
    )
  }
  const otherInfoDialogRender = () => {
    return (
      <Modal
        isOpen={otherInfo.show}
        toggle={() => setOtherInfo({ ...otherInfo, show: !otherInfo })}
        className='msd'
        centered={true}
      >
        <div className='msd-container'>
          <div className='msd-title'>Other Information</div>
          <div className='msd-info'>
            <div className='msd-info-left'>
              {otherInfo.open ? (
                <Input
                  type='textarea'
                  className='msd-info-input'
                  value={otherInfo.text || ''}
                  onChange={e =>
                    setOtherInfo({
                      ...otherInfo,
                      text: e.target.value,
                    })
                  }
                />
              ) : (
                <div className='msd-info-text'>{otherInfo.text}</div>
              )}
            </div>
            {otherInfo.open ? (
              <div
                className='msd-info-icon action'
                onClick={() => {
                  onOnboardingFormChange(
                    'saved_information_notes',
                    otherInfo.text,
                  )
                  setOtherInfo({
                    ...otherInfo,
                    open: false,
                  })
                }}
              >
                <i className='fas fa-check' />
              </div>
            ) : (
              <div
                className='msd-info-icon'
                onClick={() => {
                  setOtherInfo({
                    ...otherInfo,
                    open: true,
                  })
                }}
              >
                <i className='far fa-pen' />
              </div>
            )}
          </div>
        </div>
      </Modal>
    )
  }
  const onDomainFormChange = (name, val) => {
    setDomainFormData({ ...domainFormData, [name]: val })
  }
  const toggleModalOpen = val => {
    setModalOpen(val ?? !modalOpen)
    setConfirmSuccess(false)
    onDomainFormChange('password', '')
  }
  const address =
    tablet_shipping_address && tablet_shipping_address.line_1
      ? `${
          tablet_shipping_address.line_1
            ? tablet_shipping_address.line_1 + ' '
            : ''
        }${
          tablet_shipping_address.line_2
            ? tablet_shipping_address.line_2 + ' '
            : ''
        }${
          tablet_shipping_address.city ? tablet_shipping_address.city + ' ' : ''
        }${
          tablet_shipping_address.state
            ? tablet_shipping_address.state + ' '
            : ''
        }${
          tablet_shipping_address.zipcode
            ? tablet_shipping_address.zipcode + ' '
            : ''
        }${
          tablet_shipping_address.country
            ? tablet_shipping_address.country + ' '
            : ''
        }`
      : 'Ship to restaurant'
  const copyAddress = () => {
    copyText(
      address,
      () => {
        toast.success('Copy Success!')
      },
      () => {
        toast.warning('Copy Fail!')
      },
    )
  }
  useEffect(() => {
    setDomain(self_domain)
  }, [self_domain])
  return (
    <div className='onboarding-preference'>
      <div className='op-title'>PREFERENCE</div>
      <div className='op-row'>
        <div className='op-icon'>
          <i className='fas fa-file'></i>
        </div>
        <div className='op-option mr-8'>
          <Input
            value={domain || ''}
            type='text'
            onChange={e => setDomain(e.target.value)}
            onBlur={e => onOnboardingFormChange('self_domain', e.target.value)}
            className='op-option-input'
          />
        </div>
        <div className='op-option mr-8' onClick={copyAddress}>
          <div className='op-option-button'>{address}</div>
        </div>
        <div className='op-option mr-8'>
          <DialogBtn
            isBlank={
              menu_link ||
              menu_work_notes ||
              (onboarding_menus &&
                onboarding_menus.length &&
                onboarding_menus.length > 0)
            }
            value='Menu Source'
            styles={{
              height: 36,
            }}
            handleClick={() =>
              setMenuSourceDialog({ ...menuSourceDialog, show: true })
            }
          />
        </div>
        <div className='op-option'>
          <DialogBtn
            isBlank={has_information}
            value='Other Information'
            styles={{
              height: 36,
            }}
            handleClick={() => setModalOpen(true)}
          />
        </div>
      </div>
      <div className='op-row'>
        <div className='op-icon'>
          <i className='fas fa-browser'></i>
        </div>
        <div className='op-option mr-8'>
          <ReactSelect
            value={filterFromObjectArray(
              allOptions['website_preference'],
              website_preference || '',
              'value',
            )}
            options={allOptions['website_preference']}
            className='basic-single'
            classNamePrefix='select'
            onChange={({ value }) =>
              onOnboardingFormChange('website_preference', value)
            }
            components={{ DropdownIndicator }}
            theme={BASIC_SELECT_THEME}
            styles={BASIC_SELECT_STYLE}
          />
        </div>
        <div className='op-option mr-8'>
          <ReactSelect
            value={filterFromObjectArray(
              allOptions['has_existing_domain'],
              typeof has_existing_domain === 'number'
                ? has_existing_domain
                : '',
              'value',
            )}
            options={allOptions['has_existing_domain']}
            className='basic-single'
            classNamePrefix='select'
            onChange={({ value }) =>
              onOnboardingFormChange('has_existing_domain', value)
            }
            components={{ DropdownIndicator }}
            theme={BASIC_SELECT_THEME}
            styles={BASIC_SELECT_STYLE}
          />
        </div>
        <div className='op-option mr-8'>
          <ReactSelect
            value={filterFromObjectArray(
              allOptions['delegate_dns_or_website'],
              typeof delegate_dns_or_website === 'number'
                ? delegate_dns_or_website
                : '',
              'value',
            )}
            options={allOptions['delegate_dns_or_website']}
            className='basic-single'
            classNamePrefix='select'
            onChange={({ value }) =>
              onOnboardingFormChange('delegate_dns_or_website', value)
            }
            components={{ DropdownIndicator }}
            theme={BASIC_SELECT_THEME}
            styles={BASIC_SELECT_STYLE}
          />
        </div>
        <div className='op-option'>
          <ReactSelect
            value={filterFromObjectArray(
              allOptions['website_and_domain_status'],
              website_and_domain_status || '',
              'value',
            )}
            options={allOptions['website_and_domain_status']}
            className='basic-single'
            classNamePrefix='select'
            onChange={({ value }) =>
              onOnboardingFormChange('website_and_domain_status', value)
            }
            components={{ DropdownIndicator }}
            theme={BASIC_SELECT_THEME}
            styles={Object.assign(deepClone(BASIC_SELECT_STYLE), {
              option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                return {
                  ...styles,
                  backgroundColor: '#000016',
                  color: data.value === 'updated' ? '#03C9A9' : '#fff',
                }
              },
              singleValue: (styles, { data }) => ({
                ...styles,
                color: data.value === 'updated' ? '#03C9A9!important' : '#fff',
              }),
            })}
          />
        </div>
      </div>
      <div className='op-row'>
        <div className='op-icon'>
          <i className='fab fa-google'></i>
        </div>
        <div className='op-option mr-8'>
          <ReactSelect
            value={filterFromObjectArray(
              allOptions['has_gmb_ownership'],
              typeof has_gmb_ownership === 'number' ? has_gmb_ownership : '',
              'value',
            )}
            options={allOptions['has_gmb_ownership']}
            className='basic-single'
            classNamePrefix='select'
            onChange={({ value }) =>
              onOnboardingFormChange('has_gmb_ownership', value)
            }
            components={{ DropdownIndicator }}
            theme={BASIC_SELECT_THEME}
            styles={BASIC_SELECT_STYLE}
          />
        </div>
        <div className='op-option mr-8'>
          <ReactSelect
            value={filterFromObjectArray(
              allOptions['need_help_regain_gmb'],
              need_help_regain_gmb || '',
              'value',
            )}
            options={allOptions['need_help_regain_gmb']}
            className='basic-single'
            classNamePrefix='select'
            onChange={({ value }) =>
              onOnboardingFormChange('need_help_regain_gmb', value)
            }
            components={{ DropdownIndicator }}
            theme={BASIC_SELECT_THEME}
            styles={Object.assign(deepClone(BASIC_SELECT_STYLE), {
              option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                return {
                  ...styles,
                  backgroundColor: '#000016',
                  color:
                    data.value === 'rushable_claiming' ? '#008DFF' : '#fff',
                }
              },
              singleValue: (styles, { data }) => ({
                ...styles,
                color:
                  data.value === 'rushable_claiming'
                    ? '#008DFF!important'
                    : '#fff',
                marginLeft: 0
              }),
            })}
          />
        </div>
        <div className='op-option mr-8'>
          <ReactSelect
            value={filterFromObjectArray(
              allOptions['delegate_gmb_update'],
              typeof delegate_gmb_update === 'number'
                ? delegate_gmb_update
                : '',
              'value',
            )}
            options={allOptions['delegate_gmb_update']}
            className='basic-single'
            classNamePrefix='select'
            onChange={({ value }) =>
              onOnboardingFormChange('delegate_gmb_update', value)
            }
            components={{ DropdownIndicator }}
            theme={BASIC_SELECT_THEME}
            styles={BASIC_SELECT_STYLE}
          />
        </div>
        <div className='op-option'>
          <ReactSelect
            key={'gmb_status'}
            value={filterFromObjectArray(
              allOptions['gmb_status'],
              gmb_status || '',
              'value',
            )}
            options={allOptions['gmb_status']}
            className='basic-single'
            classNamePrefix='select'
            onChange={({ value }) =>
              onOnboardingFormChange('gmb_status', value)
            }
            components={{ DropdownIndicator }}
            theme={BASIC_SELECT_THEME}
            styles={Object.assign(deepClone(BASIC_SELECT_STYLE), {
              option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                return {
                  ...styles,
                  backgroundColor: '#000016',
                  color: data.value === 'updated' ? '#03C9A9' : '#fff',
                }
              },
              singleValue: (styles, { data }) => ({
                ...styles,
                color: data.value === 'updated' ? '#03C9A9!important' : '#fff',
                marginLeft: 0
              }),
            })}
          />
        </div>
      </div>
      {menuSourceDialog.show && menuSourceDialogRender()}
      {otherInfo.show && otherInfoDialogRender()}
      <DomainInformationModal
        open={modalOpen}
        toggle={e => toggleModalOpen()}
        onDomainFormChange={onDomainFormChange}
        domainFormData={domainFormData}
        saved_information_notes={saved_information_notes}
        confirmPassword={confirmPassword}
        confirmPswRequest={confirmPswRequest}
        confirmPswError={confirmPswError}
        confirmPswSuccess={confirmPswSuccess}
        saved_information_notes_edit={saved_information_notes_edit}
        onFormChange={onOnboardingFormChange}
        cn='msd-model-header'
      />
    </div>
  )
}
