import React from 'react'
import { Link } from 'react-router-dom'
import { Navbar, Nav, NavItem, Button } from 'reactstrap'
import { DOMAIN } from '../../utils/constant'
import { routeAccess } from '../../utils/tools'

function MainNavbar(props) {
  let activeLink = props.location.pathname
  let { profile, affiliate_dashboard } = props
  if (!profile) {
    return (
      <div className='main-nav'>
        <Navbar expand='xs'>
          <button className='btn-profile d-inline-flex' onClick={() => null}>
            <span className='head-sculpture'>
              <i className='fas fa-user-circle f-32'></i>
            </span>
            <span className='name-profile'></span>
          </button>
        </Navbar>
      </div>
    )
  }

  let { crm_team } = profile
  let crm_team_id = crm_team?.id ?? ''
  let first_name = profile.first_name ?? ''
  let last_name = profile.last_name ?? ''
  let profile_photo = profile.profile_photo ?? ''
  let show_initiate_signup = activeLink.includes('/signups')
  const showIssueCredit = activeLink.includes('/credit')

  return (
    <div className='main-nav'>
      <Navbar expand='xs'>
        <button
          className='btn-profile d-inline-flex'
          onClick={e => props.openProfile()}
        >
          {profile_photo ? (
            <img
              alt=''
              src={DOMAIN + profile_photo}
              className='head-sculpture'
            />
          ) : (
            <span className='head-sculpture'>
              <i className='fas fa-user-circle f-32'></i>
            </span>
          )}
          <span className='name-profile mw-100px'>
            {first_name} {last_name}
          </span>
        </button>
        <Nav navbar>
          {routeAccess(profile, 'orders') ? (
            <NavItem>
              <Link to={`/liveorders`} id={'mainnav-live-order'}>
                <span
                  className={
                    activeLink.includes('/liveorders')
                      ? 'text-cream-50 text-regular selected'
                      : 'text-cream-50 text-regular'
                  }
                >
                  Orders
                </span>
              </Link>
            </NavItem>
          ) : null}
          {routeAccess(profile, 'leaderboard') ? (
            <NavItem>
              <Link to={`/leaderboard`} id={'mainnav-leaderboard'}>
                <span
                  className={
                    activeLink.includes('/leaderboard')
                      ? 'text-cream-50 text-regular selected'
                      : 'text-cream-50 text-regular'
                  }
                >
                  Leaderboard
                </span>
              </Link>
            </NavItem>
          ) : null}
          {routeAccess(profile, 'signups') ? (
            <NavItem>
              <Link to={`/signups`} id={'mainnav-signups'}>
                <span
                  className={
                    activeLink.includes('/signups')
                      ? 'text-cream-50 text-regular selected'
                      : 'text-cream-50 text-regular'
                  }
                >
                  Signups
                </span>
              </Link>
            </NavItem>
          ) : null}
          {routeAccess(profile, 'restaurants') ? (
            <NavItem>
              <Link to={`/restaurants`} id={'mainnav-support'}>
                <span
                  className={
                    activeLink.includes('/restaurants')
                      ? 'text-cream-50 text-regular selected'
                      : 'text-cream-50 text-regular'
                  }
                >
                  Restaurants
                </span>
              </Link>
            </NavItem>
          ) : null}
          {routeAccess(profile, 'referral') ? (
            <NavItem>
              <Link to={`/referral`} id={'mainnav-referral'}>
                <span
                  className={
                    activeLink.includes('/referral')
                      ? 'text-cream-50 text-regular selected'
                      : 'text-cream-50 text-regular'
                  }
                >
                  Referral
                </span>
              </Link>
            </NavItem>
          ) : null}
          {routeAccess(profile, 'affiliates') ? (
            <NavItem>
              <Link to={`/affiliates`} id={'affiliates'}>
                <span
                  className={
                    activeLink.includes('/affiliates')
                      ? 'text-cream-50 text-regular selected'
                      : 'text-cream-50 text-regular'
                  }
                >
                  Affiliate
                </span>
              </Link>
            </NavItem>
          ) : null}
          {routeAccess(profile, 'credit') ? (
            <NavItem>
              <Link to={`/credit`} id={'mainnav-credit'}>
                <span
                  className={
                    activeLink.includes('/credit')
                      ? 'text-cream-50 text-regular selected'
                      : 'text-cream-50 text-regular'
                  }
                >
                  Credit
                </span>
              </Link>
            </NavItem>
          ) : null}
          {routeAccess(profile, 'affiliate_dashboard') ? (
            <NavItem>
              <Link
                to={`/affiliate-team/` + crm_team_id}
                id={'mainnav-affiliate-team'}
              >
                <span
                  className={
                    activeLink.includes('/affiliate-team')
                      ? 'text-cream-50 text-regular selected'
                      : 'text-cream-50 text-regular'
                  }
                >
                  Dashboard
                </span>
              </Link>
            </NavItem>
          ) : null}
        </Nav>
      </Navbar>
      {affiliate_dashboard ? null : show_initiate_signup ? (
        <Button
          className='font-bold btn btn-menu f-12'
          onClick={e => props.createOnboarding()}
        >
          INITIATE SIGNUP
        </Button>
      ) : null}
      {showIssueCredit ? (
        <Button
          className='font-bold btn btn-menu f-12'
          onClick={e => props.openCreditModal()}
        >
          ISSUE CREDIT
        </Button>
      ) : null}
    </div>
  )
}

export default MainNavbar
