import React, { useRef } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { ToastContainer } from 'react-toastify'
import { Helmet } from 'react-helmet'
import { getProfile } from '../../store/actions/auth'
class DetailLayout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isMobile: false,
    }
  }
  resize() {
    let isMobile = window.innerWidth <= 1000
    this.setState({
      isMobile,
    })
  }
  componentDidMount() {
    if (!this.props.profile) this.props.getProfile()
  }

  render() {
    let { children, profile } = this.props

    return (
      <div className='main-layout'>
        <Helmet>
          <title>CRM - Rushable</title>
        </Helmet>
        <ToastContainer
          autoClose={2 * 1000}
          position={toast.POSITION.TOP_CENTER}
        />
        {children}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    profile: state.auth.profile,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getProfile: () => dispatch(getProfile({})),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailLayout)
