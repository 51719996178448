import React from 'react'
import { Button } from 'reactstrap'

function GoBackBtn(props) {
  return (
    <Button
      outline
      className='go-back-btn'
      onClick={e => props.goBack()}
    >
      GO BACK
    </Button>
  )
}
export default GoBackBtn
