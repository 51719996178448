import React, { useState } from 'react'
import AddressInput from '../../Onboarding/AddressInput'

import './index.scss'
export default function MapViewSearch(props) {
  const [location, setLocation] = useState('')

  return (
    <div className='map-view-search primary-table'>
      <div className='input-wrap'>
        <p className='tips'>Search an address, city, or zipcode to start</p>
        <AddressInput
          name='loc_add_query'
          classes='loc_add_query'
          noprefixIcon={true}
          value={location}
          placeholder='Search address here'
          disabled={false}
          onFormChange={value => {
            if (value !== location) {
              setLocation(value)
              props.onChange(value)
            }
          }}
        />
      </div>
    </div>
  )
}
