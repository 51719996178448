import React from 'react'
import Profile from '../../components/Profile/Profile'
import { connect } from 'react-redux'
import { logout, getProfile, editProfile } from '../../store/actions/auth'
import { deepEqual } from '../../utils/deepEqual'

class ProfileIndex extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.goBack = this.goBack.bind(this)
    this.extractFormData = this.extractFormData.bind(this)
    this.editProfile = this.editProfile.bind(this)
    this.onFormChange = this.onFormChange.bind(this)
  }

  componentDidMount() {
    this.extractFormData()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.profile !== this.props.profile) {
      this.extractFormData()
    }

    if (prevState.formData !== this.state.formData) {
      if (this.state.formData && this.state.originalFormData) {
        this.setState({
          isFormChanged: !deepEqual(
            this.state.originalFormData,
            this.state.formData,
          ),
        })
      }
    }
  }

  onFormChange(name, value) {
    this.setState({
      formData: {
        ...this.state.formData,
        [name]: value,
      },
    })
  }

  extractFormData() {
    if (this.props.profile) {
      let { first_name, last_name, call_link, position, crm_role } =
        this.props.profile
      let formData = {
        first_name,
        last_name,
        call_link,
        position: position || crm_role?.display_name || '',
        new_password: '',
        new_password_confirmation: '',
        old_password: '',
      }
      this.setState({
        formData,
        originalFormData: formData,
        editProfileSuccess: null,
        editProfileError: null,
        editProfileRequest: false,
        editProfileErrors: null,
      })
    }
  }

  goBack() {
    this.props.history.push({ pathname: '/signups' })
  }

  async editProfile() {
    this.setState({
      editProfileRequest: true,
    })
    let {
      first_name,
      last_name,
      position,
      call_link,
      old_password,
      new_password,
      new_password_confirmation,
    } = this.state.formData
    let res = await this.props.editProfile({
      first_name,
      last_name,
      position,
      call_link,
      old_password,
      new_password,
      new_password_confirmation,
    })
    if (res.success) {
      this.setState({
        editProfileSuccess: res.message,
        editProfileError: null,
        editProfileRequest: false,
        editProfileErrors: null,
      })
      this.props.getProfile({ refresh: true })
    } else {
      this.setState({
        editProfileSuccess: null,
        editProfileError: res.message,
        editProfileRequest: false,
        editProfileErrors: res.errors,
      })
    }
  }

  render() {
    return (
      <Profile
        state={this.state}
        goBack={this.goBack}
        type={this.props.type}
        profile={this.props.profile}
        isFormChanged={this.state.isFormChanged}
        logout={() => this.props.logout('/login')}
        loading={this.props.getProfileRequest}
        errorMsg={this.props.getProfileError}
        editProfile={this.editProfile}
        onFormChange={this.onFormChange}
        getProfileRefresh={() => this.props.getProfile({ refresh: true })}
      ></Profile>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    profile: state.auth.profile,
    getProfileRequest: state.auth.getProfileRequest,
    getProfileError: state.auth.getProfileError,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(logout()),
    getProfile: params => dispatch(getProfile(params)),
    editProfile: params => dispatch(editProfile(params)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileIndex)
