import React from 'react'
import OdDetail from './OdDetail'

const DetailLeftBar = ({ order }) => {
  let { type, delivery_note } = order
  let deliveryAddress = order.address
  let fullAddress = ''
  if (deliveryAddress) {
    const line1 = deliveryAddress.line_1 ?? ''
    const line2 = deliveryAddress.line_2 ?? ''
    const city = deliveryAddress.city ?? ''
    const state = deliveryAddress.state ?? ''
    const country = deliveryAddress.country ?? ''
    const zipcode =
      deliveryAddress.zipcode != null ? deliveryAddress.zipcode : ''
    fullAddress =
      line1 +
      (line2 === '' ? '' : ' ' + line2) +
      ' ' +
      city +
      ', ' +
      state +
      ' ' +
      zipcode +
      ', ' +
      country
  } else {
    fullAddress = 'None'
  }

  let instruction = 'None'
  if (!delivery_note) instruction = delivery_note ?? 'None'

  return (
    <div className='min-w-600px'>
      <div>
        {order.check_id || order.check_card ? (
          <div className='custom-border py-15px px-30px d-flex justify-content-between mb-15px'>
            <div className='text-uppercase text-cream-50 font-weight-bold'>
              Fraud protection
            </div>
            <div className='text-cream-75'>
              {order.contact} /{' '}
              <span style={{ textTransform: 'capitalize' }}>
                {order.payment_card_brand}
              </span>{' '}
              {order.payment_card_last_4}
            </div>
          </div>
        ) : null}
        {(type === 'delivery' || type === 'daas_delivery') && (
          <div className='custom-border py-15px px-30px'>
            <div className='custom-between'>
              <div className='text-uppercase text-cream-50 font-weight-bold'>
                Address
              </div>
              <div className='text-cream-75'>{fullAddress}</div>
            </div>
            <div className='custom-between'>
              <div className='text-uppercase text-cream-50 font-weight-bold'>
                Instruction
              </div>
              <div className='text-cream-75'>{instruction}</div>
            </div>
          </div>
        )}
        <OdDetail selectedOrder={order} />
      </div>
    </div>
  )
}

export default DetailLeftBar
