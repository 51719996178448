import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReferralPage from "../../components/ReferralPage";
import { getReferralList, referralListChange, editReferral } from '../../store/actions/referral'

const initialFilterOptions = {
	referrer_id: "",
	status: "qualified",
	count_order: "",
};

export default function Referral() {
	const dispatch = useDispatch();
	const { getReferralListRequest, referralList } = useSelector(
		(state) => state.referral
	);
	const [filter, setFilter] = useState(initialFilterOptions);
	const [nowPage, setNowPage] = useState(1)

	const getList = ({params, page = 1}) => {
		dispatch(getReferralList({params, page}))
	};

	const onChangeFilter = (type, value) => {
		let result = value
		if (type === 'count_order') {
			result = filter.count_order && filter.count_order === 'ASC'? 'DESC'
			: filter.count_order &&
				filter.count_order === 'DESC'
			? ''
			: 'ASC'
		}
		setFilter({ ...filter, [type]: result });
		getList({
			params: { ...filter, [type]: result }, 
			page: type === 'count_order' ? nowPage : 1
		})
	};

	const editReward = async (signup_form, status) => {
		const list = {
			...referralList,
			data: referralList.data.map(item => {
				if (item.id === signup_form) {
					item.rewardLoading = true
				}
				return item
			})
		};
		dispatch(referralListChange(list))
		try {
			const res = await dispatch(editReferral(signup_form, status))
			if (res.success) {
				const list = {
					...referralList,
					data: referralList.data.map(item => {
						if (item.id === signup_form) {
							item.referral_info.status = status
						}
						return item
					})
				};
				dispatch(referralListChange(list))
			}
		} finally {
			const list = {
				...referralList,
				data: referralList.data.map(item => {
					if (item.id === signup_form) {
						item.rewardLoading = false
					}
					return item
				})
			};
			dispatch(referralListChange(list))
		}
	}

	const goToPage = (page) => {
		setNowPage(page)
		getList({params: filter, page})
	}

	useEffect(() => {
		getList({params: filter})
	}, []);

	return (
		<ReferralPage 
			filter={filter}
			getReferralListRequest={getReferralListRequest}
			referralList={referralList}
			onChangeFilter={onChangeFilter}
			editReward={editReward}
			goToPage={goToPage}
		/>
	);
}
