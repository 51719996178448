import React from 'react'
import { Button } from 'reactstrap'
import classnames from 'classnames'

class SingleDocUploader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      file: null,
    }
    this.onFileChange = this.onFileChange.bind(this)
    this.onRemoveFile = this.onRemoveFile.bind(this)
  }
  componentDidUpdate(prevProps) {}

  onFileChange(e) {
    if (e.target.files.length === 0) {
      return
    }
    this.setState({
      file: e.target.files[0],
    })
    this.props.onChange('csv', e.target.files[0], this.props.count)
  }

  onRemoveFile() {
    this.setState({
      file: null,
    })
    this.props.onChange(null)
  }

  render() {
    return (
      <div className='upload-doc'>
        <div
          className={classnames('upload-zone', {
            disabled: this.props.disabled,
            inactive: this.props.disabled,
          })}
        >
          <input
            className='d-none'
            type='file'
            id={`file`}
            onChange={this.onFileChange}
          />
          <label htmlFor={`file`} className='upload-area'>
            {this.props.fileName
              ? this.props.fileName
              : this.props.placeHolderText ?? ''}
          </label>
        </div>
      </div>
    )
  }
}

export default SingleDocUploader
