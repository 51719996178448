import React from 'react'
import { Row, Col, Input } from 'reactstrap'

import { formatCurrency } from '../../../utils/digit'

export default function ModalAmount ({
  disabled,
  creditAmount,
  setCreditAmount,
}) {
  const handleSelectBlur = (price) => {
		if (price && Number(price) > 0 && Number(price)) {
      setCreditAmount(`$ ${formatCurrency(price)}`)
			return
		}
		setCreditAmount('')
	}

	const handleSelectFocus = (price) => {
		if (price[0] === '$' && Number(price.substr(1)) > 0) {
      setCreditAmount(Number(price.substr(1)))
			return
		}
    setCreditAmount('')
	}
  return (
    <>
      <Row className='form-row'>
        <Col>
          <div className='cmi-title'>CREDIT AMOUNT</div>
        </Col>
      </Row>
      <Row className='form-row'>
				<Col>
					<Input 
						placeholder={''}
						disabled={disabled}
						value={creditAmount}
						onChange={e => setCreditAmount(e.target.value)}
            onBlur={e => handleSelectBlur(e.target.value)}
            onFocus={e => handleSelectFocus(e.target.value)}
					/>
				</Col>
			</Row>
    </>
  )
}