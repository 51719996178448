import React from 'react'
import { Row, Col } from 'reactstrap'

import BrandSearch from './BrandSearch'

export default function ModalBrand({
	disabled,
	brandName,
	setBrandName,
	getLocations,
	setBrandId,
}) {
	return (
		<>
			<Row className='form-row'>
					<Col>
						<div className='cmi-title'>RESTAURANT</div>
					</Col>
				</Row>
				<Row className='form-row'>
					<Col>
						<div>
							<BrandSearch
								brandName={brandName || ''}
								disabled={disabled}
								onChange={({ type, value }) => {
									if (type === 'brand_name') {
										setBrandName(value)
									}
									if (type === 'brand_id') {
										getLocations(value)
										setBrandId(value)
									}
								}}
							/>
						</div>
					</Col>
				</Row>
		</>
	)
}