import React from 'react'
import { getPlanStatus } from '../../../utils/digit'
import { Document as DocumentIcon } from '../../Icons'
import classnames from 'classnames'
import { useSelector } from 'react-redux'

function HeaderCenter(props) {
  let { supportData } = props

  const signupFormInfo =
    useSelector(state => state.support.signupFormInfo) ?? {}

  let { brand, name, rushable_plan, id, orders_count, access_code } = supportData
  let plan_id = rushable_plan ? rushable_plan.id ?? '' : ''
  let plan_color = getPlanStatus(plan_id, 'color')
  let brandName = id ? brand.name : signupFormInfo.brand_name
  brandName = brandName ?? 'Your Brand'
  let locationName = id ? name : signupFormInfo.location_name
  locationName = locationName ?? 'Your Location'
  return (
    <>
      <div className='support-title'>
        <h1 className='location-name'>{brandName}</h1>
        <div className='location-info-row'>
          <div className='location-info-tab'>
            <div className='location-info-content'>
              {' '}
              <i className='fas fa-map-marker-alt map-marker'></i>
              <span> {locationName}</span>
            </div>
          </div>
          <div className='location-info-tab'>
            <div className='location-info-content'>
              {' '}
              <i className='fas fa-keyboard'></i>
              <span> {id ?? '-'}</span>
            </div>
          </div>
          <div className='location-info-tab'>
            <div className='location-info-content'>
              {' '}
              <DocumentIcon className='mr-10px' />
              <span>7 Days: {orders_count ?? '-'}</span>
            </div>
          </div>
          <div className='location-info-tab'>
            <div className='location-info-content'>
              {' '}
              <span
                className={classnames('select-form-primary-dot', plan_color)}
              ></span>
              <span className='name'>
                {getPlanStatus(plan_id, 'name', true)}
              </span>
            </div>
          </div>
          <div className='location-info-tab'>
            <div className='location-info-content'>
              <i className='fas fa-fingerprint'></i>
              <span>{access_code?.code ?? '-'}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default HeaderCenter
