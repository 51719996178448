import React from 'react'
import {
  getSupport,
  removeSupportData,
  uploadMenu,
} from '../../store/actions/support'
import { fromOnboardingPage } from '../../store/actions/onboarding'
import { returnOpenHours, returnHoursInDays } from '../../utils/tools'
import newid from '../../utils/newid'
import { connect } from 'react-redux'
import { checkUserAccess } from '../../utils/tools'
import { Switch, Route } from 'react-router-dom'
import SupportLayout from './SupportLayout'
import MenuWork from '../../components/Support/SupportContent/MenuWork'
import BasicInfo from '../../components/Support/SupportContent/BasicInfo'
import Onboarding from '../../components/Support/SupportContent/Onboarding'
import SignupForm from '../../components/Support/SupportContent/SignupForm'

const format = 'HH:mm:00'

class SupportIndex extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      fileCount: 0,
      supportData: {},
      formData: {},
      menuData: [],
      onMenuUpload: true,
      openErrorDialog: false,
      backState: null,
    }
    this.getSupport = this.getSupport.bind(this)
    this.renderSupportData = this.renderSupportData.bind(this)
    this.onFormChange = this.onFormChange.bind(this)
    this.uploadMenu = this.uploadMenu.bind(this)
    this.toggleOnUpload = this.toggleOnUpload.bind(this)
    this.addHours = this.addHours.bind(this)
    this.removeHours = this.removeHours.bind(this)
    this.setClosed = this.setClosed.bind(this)
    this.onHourChange = this.onHourChange.bind(this)
    this.toggleAvailability = this.toggleAvailability.bind(this)
    this.toggleErrorDialog = this.toggleErrorDialog.bind(this)
    this.goToPath = this.goToPath.bind(this)
    this.redirectToTab = this.redirectToTab.bind(this)
  }

  componentDidMount() {
    this.getSupport()
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
    if (this.props.location.state) {
      this.setState({ backState: this.props.location.state })
    }
    this.props.fromOnboardingPage()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.support !== this.props.support) {
      this.renderSupportData()
    }
    if (prevProps.uploadMenuSuccess !== this.props.uploadMenuSuccess) {
      if (this.props.uploadMenuSuccess) {
        let { fileCount, menuData } = this.state
        let onMenuUpload = true
        fileCount++
        menuData[fileCount] = {
          count: fileCount,
          menu_collection_name: '',
          availability: 'same_as_store',
          days: returnHoursInDays([]),
        }

        if (fileCount > 1) {
          onMenuUpload = false
        }
        this.setState({
          fileCount,
          onMenuUpload,
        })
      }
    }

    if (prevProps.uploadMenuError !== this.props.uploadMenuError) {
      if (this.props.uploadMenuError) {
        this.toggleErrorDialog(true)
      }
    }
  }

  redirectToTab(support) {
    const activeLink = this.props.location.pathname
    const tabs = ['signup-form', 'onboarding', 'basic-info', 'menu-work']
    const tabNameIndex = tabs.indexOf(activeLink)
    let tabName = null
    if (tabNameIndex > -1) tabName = tabs[tabNameIndex]
    if (tabName) return '' // do not redirect
    const { signup_form } = support
    return !signup_form || signup_form?.onboarding?.done_at
      ? 'basic-info'
      : signup_form.paid_at
      ? 'onboarding'
      : 'signup-form'
  }

  getSupport() {
    let { supportId } = this.props.match.params
    if (supportId === 'create') {
      this.props.removeSupportData()
      const pathname = `/restaurants/${supportId}/signup-form`
      this.props.history.push({
        pathname,
        search: this.props.location?.search,
        state: this.props.location?.state,
      })
      return
    }
    let params = { location_id: supportId, refresh: true }
    this.props.getSupport(params)
  }

  renderSupportData() {
    if (this.props.support) {
      this.setState({
        supportData: this.props.support,
      })
      const tabName = this.redirectToTab(this.props.support)
      if (tabName) {
        let { supportId } = this.props.match.params
        const pathname = `/restaurants/${supportId}/${tabName}`
        this.props.history.push({
          pathname,
          search: this.props.location?.search,
          state: this.props.location?.state,
        })
      }
    }
  }

  toggleErrorDialog(type) {
    this.setState({
      openErrorDialog: type ?? !this.state.openErrorDialog,
    })
  }

  onFormChange(name, val, num) {
    let { menuData } = this.state
    let number = num ?? 0
    let modifier_file_name = ''
    menuData[number] = { ...menuData[number], [name]: val }
    if (name === 'csv' && num === 0) {
      if (val) {
        modifier_file_name = val.name ?? ''
        this.setState({
          ...this.state,
          modifier_file_name,
        })
      }
    }
    this.setState({
      menuData,
    })
  }

  uploadMenu() {
    let { menuData, fileCount } = this.state

    let type = fileCount > 0 ? 'item' : 'modifier'
    if (!menuData[fileCount]) return
    let csv = menuData[fileCount].csv ?? null
    let menu_collection_name = menuData[fileCount].menu_collection_name ?? null
    let days = menuData[fileCount].days ?? null
    let availability = menuData[fileCount].availability ?? null
    let { location_id } = this.props.support
    location_id = location_id ?? ''

    let open_hours = returnOpenHours(days)
    let params = {
      csv: csv ?? null,
      location_id: location_id,
      type,
      menu_collection_name,
      availability,
      open_hours,
    }
    this.props.uploadMenu(params)
  }

  toggleOnUpload(type) {
    this.setState({
      onMenuUpload: type ?? !this.state.onMenuUpload,
    })
  }

  setClosed(panel, day, { count }) {
    panel.close()
    let { menuData } = this.state
    menuData[count] = {
      ...menuData[count],
      days: { ...menuData[count].days, [day]: [] },
    }
    this.setState({
      menuData,
    })
  }

  addHours(day, { count }) {
    let { menuData } = this.state
    let days = menuData[count].days
    let item = { id: newid('null'), day: day, from: '', to: '' }
    let _day = days[day]
    days = {
      ...days,
      [day]: [..._day, item],
    }
    menuData[count] = {
      ...menuData[count],
      days,
    }
    this.setState({
      menuData,
    })
  }

  removeHours(day, index, { count }) {
    let { menuData } = this.state
    let days = menuData[count].days

    let _day = days[day]

    days = {
      ...days,
      [day]: _day.filter((_, i) => i !== index),
    }
    menuData[count] = {
      ...menuData[count],
      days,
    }
    this.setState({
      menuData,
    })
  }

  onHourChange(index, day, name, value, { count }) {
    let { menuData } = this.state
    let days = menuData[count].days
    let dayHours = days[day]
    if (dayHours[index]) {
      dayHours[index][name] = value && value.format(format)
    } else {
      dayHours[index] = { id: newid('null'), day: day, from: '', to: '' }
      dayHours[index][name] = value && value.format(format)
    }
    menuData[count] = {
      ...menuData[count],
      days: {
        ...days,
        [day]: dayHours,
      },
    }

    this.setState({
      menuData,
    })
  }

  toggleAvailability(type, count) {
    let { menuData } = this.state
    menuData[count] = {
      ...menuData[count],
      availability: type,
    }
    this.setState({
      menuData,
    })
  }

  goToPath(path) {
    let { supportId } = this.props.match.params
    let pathname = `/restaurants/${supportId}/${path}`

    this.props.history.push({
      pathname,
      state: {
        prevFilterData: this.state.backState?.prevFilterData,
        prevOnboardingFilterData:
          this.state.backState?.prevOnboardingFilterData,
      },
    })
  }

  render() {
    let { supportData, onMenuUpload, fileCount, menuData } = this.state
    let { supportId } = this.props.match.params

    let profile = this.props.profile
    let { access, getSupportRequest } = this.props
    return (
      <>
        <SupportLayout
          {...this.props}
          supportId={supportId}
          supportData={supportData}
          access={access}
          getSupportRequest={getSupportRequest}
        >
          <Switch>
            <Route
              path='/restaurants/:supportId/signup-form'
              render={props => (
                <SignupForm {...props} supportData={supportData} />
              )}
            />
            <Route
              path='/restaurants/:supportId/basic-info'
              render={props => (
                <BasicInfo {...props} supportData={supportData} />
              )}
            />
            <Route
              path='/restaurants/:supportId/menu-work'
              render={props => (
                <MenuWork
                  {...props}
                  supportData={supportData}
                  onMenuUpload={onMenuUpload}
                  menuData={menuData}
                  supportId={supportId}
                  uploadMenu={this.uploadMenu}
                  fileCount={fileCount}
                  uploadMenuRequest={this.props.uploadMenuRequest}
                  onFormChange={this.onFormChange}
                  toggleAvailability={this.toggleAvailability}
                  toggleErrorDialog={this.toggleErrorDialog}
                  openErrorDialog={this.state.openErrorDialog}
                  toggleOnUpload={this.toggleOnUpload}
                  setClosed={this.setClosed}
                  removeHours={this.removeHours}
                  addHours={this.addHours}
                  onHourChange={this.onHourChange}
                  modifier_file_name={this.state.modifier_file_name}
                  errorMsg={this.props.uploadMenuError}
                />
              )}
            />
            <Route
              path='/restaurants/:supportId/onboarding'
              render={props => (
                <Onboarding
                  {...props}
                  supportData={supportData}
                  profile={profile}
                />
              )}
            />
          </Switch>
        </SupportLayout>
      </>
    )
  }
}

const mapStateToProps = state => {
  let {
    getExportRequest,
    getExportError,
    getExportSuccess,
    getAccessRequest,
    getAccessError,
    getAccessSuccess,
    getSupportRequest,
    getSupportError,
    access,
  } = state.support
  return {
    auth: state.auth,
    access,
    profile: state.auth.profile,
    support: state.support.support,
    getExportRequest,
    getExportError,
    getExportSuccess,
    getAccessRequest,
    getAccessError,
    getAccessSuccess,
    uploadMenuRequest: state.support.uploadMenuRequest,
    uploadMenuSuccess: state.support.uploadMenuSuccess,
    uploadMenuError: state.support.uploadMenuError,
    getSupportRequest,
    getSupportError,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getSupport: params => dispatch(getSupport(params)),
    uploadMenu: params => dispatch(uploadMenu(params)),
    removeSupportData: () => dispatch(removeSupportData()),
    fromOnboardingPage: params => dispatch(fromOnboardingPage(params)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(SupportIndex)
