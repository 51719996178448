import React from 'react'

import OnboardingLinkForm from './OnboardingLinkForm'
import SignupSettingForm from './SignupSettingForm'

function HeadContent(props) {
  let {
    formData,
    isMaster,
    canUpdate,
    canEditSource,
    onChange,
    canEditSignupfee,
    onboardingOptions,
    getOnboardingOptionsRequest,
    status,
  } = props

  return (
    <div className='board-equal-row'>
      <OnboardingLinkForm
        formData={formData}
        canUpdate={canUpdate}
        canEditSource={canEditSource}
        onChange={onChange}
        onboardingOptions={onboardingOptions}
        getOnboardingOptionsRequest={getOnboardingOptionsRequest}
      />
      <SignupSettingForm
        formData={formData}
        canUpdate={canUpdate}
        onChange={onChange}
        isMaster={isMaster}
        status={status}
        canEditSignupfee={canEditSignupfee}
      />
    </div>
  )
}

export default HeadContent
