import React from 'react'
import SignupsTableRow from './SignupsTableRow'

const SignupsTableBody = ({
  mode = 'support',
  signups,
  seeDetail,
  isShowAttentionColumn,
  progress,
}) => {
  if (!signups) return null
  if (!signups.data) return null
  if (signups.data.length === 0)
    return (
      <tr>
        <td colSpan='7' className='p-0 ponit-events-none'>
          <div className='text-white'>No data</div>
        </td>
      </tr>
    )
  return signups.data.map((item, key) => (
    <SignupsTableRow
      key={key}
      signup={item}
      mode={mode}
      seeDetail={seeDetail}
      isShowAttentionColumn={isShowAttentionColumn}
      progress={progress}
    />
  ))
}

export default SignupsTableBody
