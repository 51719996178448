import React from 'react'
import { getPlanStatus } from '../../utils/digit'

const ReferralTablePlan = ({ plan }) => {
  if (!plan) return '-'
  let text = getPlanStatus(plan.id, 'name', true)
  let plan_color = getPlanStatus(plan.id, 'color')
  return (
    <div className={'table-plan'}>
      <span className={'table-plan-span ' + plan_color}></span>
      {text}
    </div>
  )
}

export default ReferralTablePlan
