import React from 'react'
import Cookies from 'js-cookie'
import { connect } from 'react-redux'
import AuthLayout from '../../pages/layouts/AuthLayout'
import LoginForm from '../../components/Auth/LoginForm'
import { login } from '../../store/actions/auth'

class Login extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: '',
    }

    this.onSubmit = this.onSubmit.bind(this)
    this.onInputChange = this.onInputChange.bind(this)
  }

  componentDidMount() {
    // let hasToken = Cookies.get("access_token");
    // if (hasToken) {
    //   let { from } = this.props.location.state || { from: { pathname: "/" } };
    //   this.props.history.replace(from);
    // }
  }

  componentDidUpdate(prevProps) {}

  // static getDerivedStateFromProps(props, state) {
  //   if (props.auth.profile) {
  //     let { from } = props.location.state || { from: { pathname: "/" } };
  //     props.history.replace(from);
  //   }
  //   return state;
  // }

  onSubmit(e) {
    e.preventDefault()
    let { email, password } = this.state
    let { from } = this.props.location.state || { from: { pathname: '/' } }
    this.props.login({ email, password, from })
  }

  onInputChange(name, val) {
    this.setState({
      [name]: val,
    })
  }

  render() {
    const renderForm = () => {
      return (
        <LoginForm
          data={this.state}
          onInputChange={this.onInputChange}
          onSubmit={this.onSubmit}
        />
      )
    }

    return (
      <AuthLayout history={this.props.history}>
        <div className='container'>
          <div className='my-auto mx-auto'>{renderForm()}</div>
        </div>
      </AuthLayout>
    )
  }
}

const mapStateToProps = state => {
  return { auth: state.auth }
}

const mapDispatchToProps = dispatch => {
  return { login: params => dispatch(login(params)) }
}
export default connect(mapStateToProps, mapDispatchToProps)(Login)
