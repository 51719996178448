import React from 'react'
import StatusTd from '../StatusTd/index'
import moment from 'moment'
import UserAvatar from '../UserAvatar/UserAvatar'
import { getOnboardedDays } from '../../utils/getOnboardedDays'
import { getFullName } from '../../utils/getFullName'
import cn from 'classnames'
import Comments from '../ClientTable/Comments'
import { getPlanTypeFromPlanID } from '../../utils/common'
import { allSources } from '../../utils/common'
import states from '../../utils/us-state-converter'

const CircleLabel = ({ param }) => {
  return (
    <div className='d-flex align-items-center'>
      <div
        className='w-6px h-6px rounded mr-2'
        style={{ backgroundColor: `${param.color}` }}
      ></div>
      <div>{param.label}</div>
    </div>
  )
}

const SignupsTableRow = ({
  seeDetail,
  signup,
  isShowAttentionColumn,
  progress,
}) => {
  const haveMaxWidth = true

  let {
    id,
    signup_form,
    crm_closer,
    project_lead,
    order_count,
    order_amount,
    onboarding_comments,
    need_help_regain_gmb,
    google_listing_updated,
    gmb_status,
    status,
  } = signup
  let {
    crm_representative,
    online_order_config,
    brand_name,
    location_name,
    location_address,
    paid_at: signup_paid_at,
    done_at: signup_done_at,
    rushable_plan_id,
    source,
    location_id,
    requires_approval,
    onboarding,
  } = signup_form
  const googleLogoShow =
    need_help_regain_gmb === 'rushable_claiming' &&
    gmb_status !== 'updated' &&
    google_listing_updated !== 1

  const onboarding_done_at = onboarding?.done_at

  let repName = crm_representative
    ? getFullName(crm_representative.first_name, crm_representative.last_name)
    : '-'

  let closerName = crm_closer
    ? getFullName(crm_closer.first_name, crm_closer.last_name)
    : '-'

  let closerAvatar = crm_closer ? crm_closer.profile_photo : null
  let team_name =
    crm_closer && crm_closer.crm_team ? crm_closer.crm_team.name : ''

  let onboard_days = getOnboardedDays(onboarding_done_at, signup_paid_at)

  let pick_up_active = ''
  let delivery_active = ''
  if (online_order_config?.order_pickup_config?.enabled)
    pick_up_active = ' active'

  if (!online_order_config) {
    delivery_active = ''
  } else {
    let { order_doordash_delivery_config, order_in_house_config } =
      online_order_config
    let doordash_delivery = order_doordash_delivery_config
      ? order_doordash_delivery_config.enabled ?? false
      : false
    let in_house = order_in_house_config
      ? order_in_house_config.enabled ?? false
      : false
    delivery_active = doordash_delivery || in_house ? ' active' : ''
  }

  return (
    <tr onClick={e => seeDetail(id, location_id, status)}>
      <td className={cn('table-brand-td', haveMaxWidth && 'mw-180px')}>
        {brand_name ?? '-'}
      </td>
      <td className='table-location-td mw-100px'>{location_name ?? '-'}</td>
      {(progress === 'no_progress' || progress === 'has_progress') && (
        <td>
          {location_address?.state
            ? states.fullName(location_address?.state)
            : '-'}
        </td>
      )}
      {/* Add Rushable Plan to the column. */}
      {progress !== 'onboarding' && (
        <td>
          <CircleLabel param={getPlanTypeFromPlanID(rushable_plan_id)} />
        </td>
      )}

      <td className='table-team-td mw-100px'>{team_name}</td>
      <td>{allSources.find(i => i.value === source)?.label ?? '-'}</td>
      <td className='table-closer-td'>
        <UserAvatar imgUrl={closerAvatar} name={closerName} />
      </td>
      {progress === 'launched' && (
        <td className='text-center essentials-td'>
          <span className={'essentials-td-icon' + pick_up_active}>
            <i className='fas fa-shopping-bag'></i>
          </span>
          <span className={'essentials-td-icon' + delivery_active}>
            <i className='fas fa-car'></i>
          </span>
        </td>
      )}

      {progress === 'launched' && (
        <td>
          {order_count && order_amount
            ? `${order_count ? order_count : '-'} / ${
                order_amount ? '$' + Math.round(order_amount) : '-'
              }`
            : '-'}
        </td>
      )}

      {(progress === 'no_progress' || progress === 'has_progress') && (
        <td>{repName}</td>
      )}

      {(progress === '' ||
        progress === 'has_progress' ||
        progress === 'no_progress') && (
        <td className='text-right text-nowrap'>
          {signup_paid_at ? moment(signup_paid_at).format('MMM DD, YYYY') : '-'}
        </td>
      )}
      {progress !== 'has_progress' && progress !== 'no_progress' && (
        <td className='text-right table-intiate-td'>
          {signup_done_at ? moment(signup_done_at).format('MMM DD, YYYY') : '-'}
        </td>
      )}

      {(progress === '' ||
        progress === 'launched' ||
        progress === 'onboarding') && (
        <td className='text-right table-onboard-td'>{onboard_days}</td>
      )}
      {progress === 'onboarding' && (
        <td className='text-center'>
          <UserAvatar
            imgUrl={project_lead?.profile_photo ?? null}
            name={`${project_lead?.first_name ?? ''} ${
              project_lead?.last_name ?? '-'
            }`}
          />
        </td>
      )}
      {isShowAttentionColumn && (
        <td className='text-center'>
          <Comments
            comments={onboarding_comments ?? []}
            showGoogle={googleLogoShow}
          />
        </td>
      )}
      <td className='table-status-td'>
        {requires_approval ? (
          <div className='requires_approval'>
            <span>Need Approval</span>
            <div className='requires_approval-border' />
          </div>
        ) : (
          <StatusTd signup_form={signup_form} onboarding={signup} />
        )}
      </td>
    </tr>
  )
}

export default SignupsTableRow
