import React from 'react'
import classnames from 'classnames'

function LogCard(props) {
  let { title, subTitle, trailText, trailChild, large, titleColor } = props
  return (
    <>
      <div
        className={classnames('log-span', {
          large: large,
        })}
      >
        <span className='log-leader'>
          <div className={'log-title ' + titleColor}>{title}</div>
          <div className='log-info text-elliosis'>{subTitle ?? '-'}</div>
        </span>
        {trailChild ?? <span className='log-trail'>{trailText}</span>}
      </div>
    </>
  )
}

export default LogCard
