import React, { useState } from 'react'
import Table from '../../../components/Table'
import moment from 'moment'
import StatusTd from '../../../components/StatusTd'
import Pagination from '../../../components/Support/SupportContent/Orders/OrdersTable/Pagination'
import Comments from '../../../components/ClientTable/Comments'
import { getOnboardedDays } from '../../../utils/getOnboardedDays'

import {
  RUSHABLE_FREE_PLAN_ID,
  RUSHABLE_MONTHLY_PLAN_ID,
  RUSHABLE_ANNUAL_PLAN_ID,
} from '../../../utils/constant'

const columns = [
  {
    key: 'brand',
    name: 'BRAND',
    className: 'mw-180px'
  },
  {
    key: 'location',
    name: 'LOCATION',
    className: 'mw-180px'
  },
  {
    key: 'signed_plan',
    name: 'PLAN',
    custom: value => {
      let result = ''
      let color = '#000000'
      if (value.toString() === RUSHABLE_FREE_PLAN_ID) {
        color = '#F59762'
        result = 'Free'
      } else if (value.toString() === RUSHABLE_MONTHLY_PLAN_ID) {
        color = '#03C9A9'
        result = 'Monthly'
      } else if (value.toString() === RUSHABLE_ANNUAL_PLAN_ID) {
        color = '#008DFF'
        result = 'Annual'
      } else result = '-'
      return (
        <div className='d-flex align-items-center'>
          <div
            className='w-6px h-6px rounded mr-2'
            style={{ backgroundColor: color }}
          ></div>
          <div>{result}</div>
        </div>
      )
    },
  },
  {
    key: 'type',
    name: 'SOURCE',
    className: 'text-center',
  },
  {
    key: 'onboard',
    name: 'ONBOARDING',
    className: 'text-right',
  },
  {
    key: 'attention',
    name: 'ATTENTION',
    className: 'text-center',
    custom: param => {
      const { onboarding_comments, need_help_regain_gmb, gmb_status, google_listing_updated } = param?.onboarding
      const googleLogoShow = need_help_regain_gmb === 'rushable_claiming' &&
      gmb_status !== 'updated' &&
      google_listing_updated !== 1
      return (
        <Comments
          comments={onboarding_comments ?? []}
          showGoogle={googleLogoShow}
        />
      )
    },
  },
  {
    key: 'status',
    name: 'STATUS',
    className: 'text-right w-120',
    custom: param => {
      return (
        <div className='table-status-td'>
          <StatusTd
            signup_form={param}
            onboarding={param ? param.onboarding ?? {} : {}}
          />
        </div>
      )
    },
  },
]
const canceledColumns = [
  {
    key: 'brand',
    name: 'BRAND',
    className: 'mw-180px'
  },
  {
    key: 'location',
    name: 'LOCATION',
    className: 'mw-180px'
  },
  {
    key: 'signed_plan',
    name: 'PLAN',
    custom: value => {
      let result = ''
      let color = '#000000'
      if (value.toString() === RUSHABLE_FREE_PLAN_ID) {
        color = '#F59762'
        result = 'Free'
      } else if (value.toString() === RUSHABLE_MONTHLY_PLAN_ID) {
        color = '#03C9A9'
        result = 'Monthly'
      } else if (value.toString() === RUSHABLE_ANNUAL_PLAN_ID) {
        color = '#008DFF'
        result = 'Annual'
      } else result = '-'
      return (
        <div className='d-flex align-items-center'>
          <div
            className='w-6px h-6px rounded mr-2'
            style={{ backgroundColor: color }}
          ></div>
          <div>{result}</div>
        </div>
      )
    },
  },
  {
    key: 'type',
    name: 'SOURCE',
    className: 'text-center',
  },
  {
    key: 'signup_done',
    name: 'SIGNUP DONE',
    className: 'text-right',
  },
  {
    key: 'onboard',
    name: 'ONBOARDING',
    className: 'text-right',
  },
  {
    key: 'status',
    name: 'STATUS',
    className: 'text-right w-120',
    custom: param => {
      return (
        <div className='table-status-td'>
          <StatusTd
            signup_form={param}
            onboarding={param ? param.onboarding ?? {} : {}}
          />
        </div>
      )
    },
  },
]

const RightSideBar = ({ canceledOnboardingShow, onboarding, canceledOnboarding, pageHandler, seeSignupDetail }) => {

  const data = onboarding?.data.map(item => {
    let onboard_days = getOnboardedDays(
      item?.onboarding?.done_at,
      item?.paid_at,
    )
    return {
      id: item.id,
      brand: item.brand_name,
      location: item.location_name,
      type:
        item.source === 'self'
          ? 'By SELF'
          : item.source === 'sdr'
          ? 'By SDR'
          : item.source === 'referral'
          ? 'Referral'
          : item.source === 'inbound'
          ? 'Inbound'
          : item.source === 'existing'
          ? 'Existing'
          : 'N/A',

      signed_plan: item.rushable_plan_id,
      onboard: onboard_days,
      attention: item,
      status: item,
    }
  })

  const canceledData = canceledOnboarding?.data.map(item => {
    let onboard_days = getOnboardedDays(
      item?.onboarding?.done_at,
      item?.paid_at,
    )
    return {
      id: item.id,
      brand: item.brand_name,
      location: item.location_name,
      type:
        item.source === 'self'
          ? 'By SELF'
          : item.source === 'sdr'
          ? 'By SDR'
          : item.source === 'referral'
          ? 'Referral'
          : item.source === 'inbound'
          ? 'Inbound'
          : item.source === 'existing'
          ? 'Existing'
          : 'N/A',

      signed_plan: item.rushable_plan_id,
      signup_done: moment(item.paid_at).format('ll'),
      onboard: onboard_days,
      attention: item,
      status: item,
    }
  })

  const renderPagination = (currentPage, lastPage, goToPage) => {
    if (lastPage === 1) return null 
    return (
      <Pagination
        orders={{ current_page: currentPage, last_page: lastPage }}
        goToPage={goToPage}
      />
    )
  }

  return (
    <div>
      <Table
        isBoarded
        columns={columns}
        data={data}
        trClassName='py-39px'
        className="leaderboard-detail-table"
        handleRowClicked={row => seeSignupDetail(row)}
        tableFooter={renderPagination(onboarding?.current_page, onboarding?.last_page, (no) => pageHandler(no, 'onboarding'))}
      />
      {canceledOnboardingShow && (
        <Table
          isBoarded
          columns={canceledColumns}
          data={canceledData}
          trClassName='py-39px'
          className="leaderboard-canceled-detail-table"
          availableShow={false}
          handleRowClicked={row => seeSignupDetail(row)}
          tableFooter={renderPagination(canceledOnboarding?.current_page, canceledOnboarding?.last_page, (no) => pageHandler(no, 'canceled_onboarding'))}
        />
      )}
    </div>
  )
}

export default RightSideBar
