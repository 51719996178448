import axios from 'axios'

export const PLANS_GET_PLANS_REQUEST = 'PLANS_GET_PLANS_REQUEST'
export const PLANS_GET_PLANS_SUCCESS = 'PLANS_GET_PLANS_SUCCESS'
export const PLANS_GET_PLANS_FAIL = 'PLANS_GET_PLANS_FAIL'
export const getPlans =
  (refresh = true) =>
  dispatch => {
    dispatch({ type: PLANS_GET_PLANS_REQUEST })
    axios
      .request({
        url: 'http://192.168.2.200:8888/api/cp/location/payment/plans',
        method: 'get',
        cache: { ignoreCache: refresh },
      })
      .then(({ data }) => {
        if (data.redirect) return

        if (data.success) {
          dispatch({
            type: PLANS_GET_PLANS_SUCCESS,
            data: data.data,
          })
        } else {
          dispatch({ type: PLANS_GET_PLANS_FAIL })
        }
      })
  }
