import React from 'react'

const Calendar = ({ size = 16, className, handleClick }) => {
  return (
    <div className={className} onClick={handleClick}>
      <svg xmlns='http://www.w3.org/2000/svg' width={size} viewBox='0 0 16 16'>
        <g id='组_23304' data-name='组 23304' transform='translate(-1135 -10)'>
          <path
            id='calendar-alt-solid'
            d='M0,14.5A1.5,1.5,0,0,0,1.5,16h11A1.5,1.5,0,0,0,14,14.5V6H0ZM10,8.375A.376.376,0,0,1,10.375,8h1.25A.376.376,0,0,1,12,8.375v1.25a.376.376,0,0,1-.375.375h-1.25A.376.376,0,0,1,10,9.625Zm0,4A.376.376,0,0,1,10.375,12h1.25a.376.376,0,0,1,.375.375v1.25a.376.376,0,0,1-.375.375h-1.25A.376.376,0,0,1,10,13.625Zm-4-4A.376.376,0,0,1,6.375,8h1.25A.376.376,0,0,1,8,8.375v1.25A.376.376,0,0,1,7.625,10H6.375A.376.376,0,0,1,6,9.625Zm0,4A.376.376,0,0,1,6.375,12h1.25A.376.376,0,0,1,8,12.375v1.25A.376.376,0,0,1,7.625,14H6.375A.376.376,0,0,1,6,13.625Zm-4-4A.376.376,0,0,1,2.375,8h1.25A.376.376,0,0,1,4,8.375v1.25A.376.376,0,0,1,3.625,10H2.375A.376.376,0,0,1,2,9.625Zm0,4A.376.376,0,0,1,2.375,12h1.25A.376.376,0,0,1,4,12.375v1.25A.376.376,0,0,1,3.625,14H2.375A.376.376,0,0,1,2,13.625ZM12.5,2H11V.5a.5.5,0,0,0-.5-.5h-1A.5.5,0,0,0,9,.5V2H5V.5A.5.5,0,0,0,4.5,0h-1A.5.5,0,0,0,3,.5V2H1.5A1.5,1.5,0,0,0,0,3.5V5H14V3.5A1.5,1.5,0,0,0,12.5,2Z'
            transform='translate(1136 10)'
            fill='rgba(233,233,233,0.5)'
          />
          <rect
            id='矩形_5827'
            data-name='矩形 5827'
            width='16'
            height='16'
            transform='translate(1135 10)'
            fill='none'
          />
        </g>
      </svg>
    </div>
  )
}

export default Calendar
