import ReactSelect from 'react-select'

import { BASIC_SELECT_STYLE, BASIC_SELECT_THEME } from '../../utils/constant'

const DropdownIndicator = () => {
  return <i className='fas fa-caret-down multi-select-dropdown-indicator'></i>
}

const Select = ({
  placeholder = 'Select',
  options,
  name,
  width,
  onMenuOpen,
  onChange,
  value,
}) => {
  const p_value = options.filter(option => option.value === value)
  return (
    <div
      className='select-component'
      id='select-component'
      style={{ width: `${width}px` }}
    >
      <ReactSelect
        placeholder={placeholder}
        options={options}
        value={p_value}
        className='basic-single'
        classNamePrefix='select'
        components={{ DropdownIndicator }}
        theme={BASIC_SELECT_THEME}
        styles={BASIC_SELECT_STYLE}
        onMenuOpen={onMenuOpen}
        onChange={onChange}
      />
    </div>
  )
}

export default Select
