import React from 'react'
import moment from 'moment'
import UserAvatar from '../UserAvatar/UserAvatar'

import { formatCurrency } from '../../utils/digit'

const CreditTableRow = ({ item, openCreditModal }) => {
	const { status, order, amount, requested_by, approved_by, reason, location, id } = item
	const { order_amount = '' } = order || {}
	const { brand } = location

	const renderText = () => {
		if (status === 'denied') return 'Denied'
		if (status === 'pending') return 'Requested'
		if (status === 'approved') return 'Approved'
	}

	const renderColor = () => {
		if (status === 'denied') return '#ED5F65'
		if (status === 'pending') return '#FFE200'
		if (status === 'approved') return '#03C9A9'
	}

	return (
		<tr onClick={() => openCreditModal(id)}>
			<td
				className='fixed-width fixed-120'
			>
				<div className='d-flex align-items-center'>
					<div
						className='mr-2'
						style={{
							width: 4,
							height: 16,
							backgroundColor: renderColor(),
							borderRadius: 2
						}}
					></div>
					<div>{renderText()}</div>
				</div>
			</td>
			<td className='text-right'>{`$${formatCurrency(amount)}`}</td>
			<td className='text-right'>{order_amount ? `$${formatCurrency(order_amount)}`: '-'}</td>
			<td className='table-closer-td fixed-width fixed-200'>
				<UserAvatar imgUrl={requested_by.profile_photo} name={`${requested_by.first_name} ${requested_by.last_name}`} />
			</td>
			<td>
				{reason === 'other' ? 'Other' : ''}
				{reason === 'odd' ? 'ODD Error' : ''}
			</td>
			<td className='table-closer-td fixed-width fixed-200'>
				{approved_by ? (
					<UserAvatar 
						imgUrl={approved_by.profile_photo} 
						name={`${approved_by.first_name} ${approved_by.last_name}`} 
					/>
				): '-'}
			</td>
			<td className='fixed-width fixed-200'>{brand.name}</td>
			<td>{location.name}</td>
			<td className='text-right text-nowrap'>{order && order.id ? `#${order.id}` : '-'}</td>
			<td className='text-right text-nowrap'>
				{order && order.created_at ? moment(order.created_at).format('MMM DD, YYYY') : '-'}
			</td>
		</tr>
	)
}

export default CreditTableRow
