import PropTypes from 'prop-types'

const Loading = ({
  className = 'w-100 text-center mt-25-percent text-28px',
}) => {
  return (
    <div className={className}>
      <i className='fad fa-spinner fa-spin mr-2'></i>
    </div>
  )
}

export default Loading

export function Spin(props) {
  const { className, children, spining } = props
  return (
    <div className={className} style={{ position: 'relative' }}>
      {children}
      <div
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          left: 0,
          top: 0,
          background: 'rgba(0,0,0,0.5)',
          display: spining ? 'flex' : 'none',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <i className='fad fa-spinner fa-spin text-28px'></i>
      </div>
    </div>
  )
}

// 类型校验、参数说明
Spin.propTypes = {
  children: PropTypes.element,
  spining: PropTypes.bool.isRequired,
  className: PropTypes.string,
}
