export const DOMAIN = process.env.REACT_APP_API_DOMAIN

export const API_URL = DOMAIN + '/api/'

export const MAIN_API_URL = process.env.REACT_APP_MAIN_API

export const ONBOARDING_BASIC_URL = process.env.REACT_APP_ONBOARDING_BASIC_URL

export const DASHBOARD_ADMIN_URL = process.env.REACT_APP_DASHBOARD_ADMIN_URL

export const MAPBOX_ACCESSTOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN

export const TOKEN_KEY = 'access_token'

export const CRM_ROLE_ID = 'crm_role_id'

export const DB_TIMEZONE = 'America/Chicago'

export const CRM_SUPPORT_IDS = [21, 22]

export const RUSHABLE_FREE_PLAN_ID = process.env.REACT_APP_RUSHABLE_FREE_PLAN_ID
export const RUSHABLE_MONTHLY_PLAN_ID =
  process.env.REACT_APP_RUSHABLE_MONTHLY_PLAN_ID
export const RUSHABLE_ANNUAL_PLAN_ID =
  process.env.REACT_APP_RUSHABLE_ANNUAL_PLAN_ID

export const BASIC_SELECT_THEME = theme => ({
  ...theme,
  borderRadius: 8,
  colors: {
    ...theme.colors,
    primary25: '#292A33',
    primary: '#292A33',
  },
})

export const BASIC_SELECT_STYLE = {
  container: (styles, state) => {
    return {
      ...styles,
      borderRadius: 8,
    }
  },
  placeholder: defaultStyles => {
    return {
      ...defaultStyles,
      color: ' rgba(233, 233, 233, 0.25)',
    }
  },
  control: (styles, state) => ({
    // none of react-select's styles are passed to <Control />
    ...styles,
    boxShadow: 'none',
    minHeight: '36px',
    height: '36px',
  }),

  indicatorSeparator: styles => {
    return {
      ...styles,
      display: 'none',
    }
  },
  menu: (styles, state) => {
    return {
      ...styles,
      backgroundColor: '#000016',
      marginTop: -1,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      borderLeft: '1px solid #E9E9E9',
      borderRight: '1px solid #E9E9E9',
      borderBottom: '1px solid #E9E9E9',
    }
  },
  menuList: base => ({
    ...base,
    '::-webkit-scrollbar': {
      width: '9px',
    },
    '::-webkit-scrollbar-track': {
      background: '#000016',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#008dff',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: '#000016',
    }
  },
  input: base => ({
    ...base,
    padding: 0,
    margin: 0,
  }),
  dropdownIndicator: styles => {
    return {
      ...styles,
      color: 'rgba(233, 233, 233, 0.25)',
    }
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: 'white',
    backgroundColor: '#292A33',
    borderRadius: 0,
    fontSize: '100%',
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    display: 'none',
  }),
  clearIndicator: (styles, { data }) => ({
    ...styles,
    display: 'none',
  }),
  valueContainer: (styles, { data }) => ({
    ...styles,
    padding: '2px 16px',
  }),
  singleValue: (provided, state) => ({
    color: '#e9e9e9 !important',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '90%',
  }),
}

export const DAYS = [
  {
    label: 'Sunday',
    abbr: 'sun',
  },
  {
    label: 'Monday',
    abbr: 'mon',
  },
  {
    label: 'Tuesday',
    abbr: 'tue',
  },
  {
    label: 'Wednesday',
    abbr: 'wed',
  },
  {
    label: 'Thursday',
    abbr: 'thu',
  },
  {
    label: 'Friday',
    abbr: 'fri',
  },
  {
    label: 'Saturday',
    abbr: 'sat',
  },
]

// new
export const currentStepMap2 = {
  confirm_representative: 0,
  confirm_location: 1,
  confirm_work_with_stripe: 2,
  confirm_policy: 3,
  confirm_questions: 4,
  confirm_payment: 5,
  confirm_payout: 6,
  confirm_business: 7,
  business_pending_verification: 8,
  business_failed_verification: 9,
  confirm_welcome_call: 10,
  completed: 11,
}

// old
export const currentStepMap1 = {
  confirm_representative: 0,
  confirm_location: 1,
  confirm_work_with_stripe: 2,
  confirm_policy: 3,
  confirm_questions: 4,
  confirm_payment: 5,
  confirm_welcome_call: 6,
  confirm_payout: 7,
  confirm_business: 8,
  business_pending_verification: 9,
  business_failed_verification: 10,
  completed: 11,
}
