import Cookies from 'js-cookie'
import moment from 'moment'
import axios from 'axios'
import { toast } from 'react-toastify'
import { push, replace } from 'connected-react-router'
import { TOKEN_KEY, CRM_ROLE_ID } from '../../utils/constant'

export const AUTH_LOGIN_REQUEST = 'AUTH_LOGIN_REQUEST'
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS'
export const AUTH_LOGIN_FAIL = 'AUTH_LOGIN_FAIL'
export const login =
  ({ email = null, password = null, from, refresh = true }) =>
  dispatch => {
    dispatch({ type: AUTH_LOGIN_REQUEST })
    axios
      .request({
        url: 'nc/login',
        data: { email, password },
        method: 'post',
        // headers: {
        //   "Content-Type": "application/json",
        // },
        cache: { ignoreCache: refresh },
      })
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: AUTH_LOGIN_SUCCESS,
            msg: data.message,
          })
          Cookies.set(TOKEN_KEY, data.data.access_token, {
            expires: moment(data.data.expires_at).diff(moment(), 'days'),
          })
          // document.cookie = `${TOKEN_KEY}=${data.data.access_token};expires=Tue, 16 Aug 2022 00:00:00 UTC;path=/`
          dispatch(push({ pathname: '/signups' }))
        } else {
          dispatch({
            type: AUTH_LOGIN_FAIL,
            msg: data.message,
            errors: data.errors,
          })
          toast.warn(data.message)
        }
      })
      .catch(error => {
        dispatch({
          type: AUTH_LOGIN_FAIL,
          msg: null,
          errors: {},
        })
      })
  }

export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const logout =
  (redirect = '/login') =>
  dispatch => {
    dispatch({ type: AUTH_LOGOUT })

    let access_token = Cookies.get(TOKEN_KEY)
    if (!access_token) return dispatch(push('/login'))

    axios
      .request({
        url: 'nc/logout',
        method: 'post',
        cache: { ignoreCache: true },
      })
      .then(({ data }) => {
        if (data.success) {
          Cookies.remove(TOKEN_KEY)
          Cookies.remove(CRM_ROLE_ID)
          dispatch(replace(redirect))
        }
      })
  }

export const AUTH_PROFILE_REQUEST = 'AUTH_PROFILE_REQUEST'
export const AUTH_PROFILE_SUCCESS = 'AUTH_PROFILE_SUCCESS'
export const AUTH_PROFILE_FAIL = 'AUTH_PROFILE_FAIL'
export const getProfile =
  (refresh = false) =>
  dispatch => {
    dispatch({ type: AUTH_PROFILE_REQUEST })

    axios
      .request({
        url: 'nc/profile',
        method: 'get',
        cache: { ignoreCache: refresh },
      })
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: AUTH_PROFILE_SUCCESS,
            profile: data.data,

            msg: data.message,
            errors: data.errors,
          })
          Cookies.set(CRM_ROLE_ID, data.data.crm_role_id)
        } else {
          dispatch({
            type: AUTH_PROFILE_FAIL,
            msg: data.message,
            errors: data.errors,
          })
        }
      })
  }

export const AUTH_GET_USERS_REQUEST = 'AUTH_GET_USERS_REQUEST'
export const AUTH_GET_USERS_SUCCESS = 'AUTH_GET_USERS_SUCCESS'
export const AUTH_GET_USERS_FAIL = 'AUTH_GET_USERS_FAIL'
export const getUsers =
  (params, refresh = false) =>
  dispatch => {
    dispatch({ type: AUTH_GET_USERS_REQUEST })

    axios
      .request({
        url: 'nc/users',
        method: 'get',
        params: params,
        cache: { ignoreCache: refresh },
      })
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: AUTH_GET_USERS_SUCCESS,
            data: data.data,
            msg: data.message,
            errors: data.errors,
          })
        } else {
          dispatch({
            type: AUTH_GET_USERS_FAIL,
            msg: data.message,
            errors: data.errors,
          })
        }
      })
  }

export const AUTH_EDIT_PROFILE_REQUEST = 'AUTH_EDIT_PROFILE_REQUEST'
export const AUTH_EDIT_PROFILE_SUCCESS = 'AUTH_EDIT_PROFILE_SUCCESS'
export const AUTH_EDIT_PROFILE_FAIL = 'AUTH_EDIT_PROFILE_FAIL'
export const editProfile = params => async dispatch => {
  dispatch({ type: AUTH_EDIT_PROFILE_REQUEST })
  return axios
    .request({
      url: '/nc/profile/edit',
      method: 'POST',
      data: params,
    })
    .then(({ data }) => {
      if (data.success) {
        dispatch({ type: AUTH_EDIT_PROFILE_SUCCESS, msg: data.message })
      } else {
        dispatch({
          type: AUTH_EDIT_PROFILE_FAIL,
          msg: data.msg,
          errors: data.errors,
        })
      }
      return data
    })
    .catch(error => {
      return { success: false, message: error.message }
    })
}
