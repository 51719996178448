import React from 'react'
import { Button } from 'reactstrap'

function RushableHead(props) {
  let { affiliates } = props
  if (!affiliates) return null
  let { team } = affiliates
  if (!team) return null
  return (
    <div className='detail-header'>
      <Button
        color='thirdary'
        className='my-auto goback'
        onClick={e => props.goBack()}
      >
        GO BACK
      </Button>
      <h1 className='m-auto'>{team.name || 'Team'}</h1>
    </div>
  )
}

export default RushableHead
