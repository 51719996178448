import {
    GET_REFERRAL_LIST_REQUEST,
    GET_REFERRAL_LIST_SUCCESS,
    GET_REFERRAL_LIST_FAIL,
    EDIT_REFERRAL_REQUEST,
    EDIT_REFERRAL_SUCCESS,
    EDIT_REFERRAL_FAIL,
    REFERRAL_LIST_CHANGE,
} from "../actions/referral";

const initialState = {
    getReferralListRequest: false,
    referralList: [],
    editReferralRequest: false,
};

const referral = (state = initialState, action) => {
    switch (action.type) {
        case GET_REFERRAL_LIST_REQUEST:
            return {
                ...state,
                getReferralListRequest: true,
            };
        case GET_REFERRAL_LIST_SUCCESS:
            return {
                ...state,
                getReferralListRequest: false,
                referralList: action.data,
            };
        case GET_REFERRAL_LIST_FAIL:
            return {
                ...state,
                getReferralListRequest: false,
            };
        case REFERRAL_LIST_CHANGE:
            return {
                ...state,
                referralList: action.list,
            }
        case EDIT_REFERRAL_REQUEST:
            return {
                ...state,
                editReferralRequest: true,
            };
        case EDIT_REFERRAL_SUCCESS:
            return {
                ...state,
                editReferralRequest: false,
            };
        case EDIT_REFERRAL_FAIL:
            return {
                ...state,
                editReferralRequest: false,
            };
        default:
            return state;
    }
};

export default referral;
