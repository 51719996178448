import React from 'react'
import BrandInfo from './BrandInfo'
import RestaurantContact from './RestaurantContact'
import LocationInfo from './LocationInfo'
import OrderSetting from './OrderSetting'
import FraudPrevention from './FraudPrevention'
import OnboardingLog from './OnboardingLog'
import DealClosingLog from './DealClosingLog'
import AccountUser from './AccountUser'

function BasicInfo(props) {
  let { supportData } = props
  let {
    email,
    phone,
    brand,
    name,
    address,
    online_order_config,
    users,
    signup_form,
    stripe_subscription_ends_at,
  } = supportData

  return (
    <div className='basic-info'>
      <>
        <div className='basic-info-row'>
          <BrandInfo brand={brand} />
          <RestaurantContact email={email} phone={phone} />
        </div>
        <div className='basic-info-row'>
          <LocationInfo name={name} address={address} />
          <OrderSetting online_order_config={online_order_config} />
          <FraudPrevention online_order_config={online_order_config} />
        </div>
        <div className='basic-info-row d-block'>
          <OnboardingLog
            signup_form={signup_form}
            stripe_subscription_ends_at={stripe_subscription_ends_at}
          />
        </div>
        <div className='basic-info-row d-block'>
          <DealClosingLog signup_form={signup_form} />
        </div>
        {users ? (
          <div className='basic-info-row d-block'>
            <AccountUser users={users} />
          </div>
        ) : null}
      </>
    </div>
  )
}
export default BasicInfo
