import React from 'react'
import { Switch, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { CRM_SUPPORT_IDS } from './utils/constant'
import Cookies from 'js-cookie'
import { TOKEN_KEY, CRM_ROLE_ID } from './utils/constant'

class InitRoute extends React.Component {
  render() {
    let access_token = Cookies.get(TOKEN_KEY)
    if (!this.props.profile && !access_token)
      return (
        <Switch>
          <Redirect exact from='/' to='/login' />
        </Switch>
      )
    let jumpToRoute = '/signups'
    if (this.props.profile || access_token) {
      let crm_role_id = Cookies.get(CRM_ROLE_ID)

      jumpToRoute = CRM_SUPPORT_IDS.includes(crm_role_id)
        ? '/support'
        : '/signups'
    }

    return (
      <Switch>
        <Redirect exact from='/' to={jumpToRoute} />
      </Switch>
    )
  }
}

const mapStateToProps = state => {
  return {
    profile: state.auth.profile,
  }
}

const mapDispatchToProps = dispatch => {
  return {}
}
export default connect(mapStateToProps, mapDispatchToProps)(InitRoute)
