import cn from 'classnames'
import React, { useState, useRef, useEffect } from 'react'
import { DebounceInput } from 'react-debounce-input'
import { useDispatch } from 'react-redux'
import useOnClickOutside from '../../hooks/useOnClickOutside'
import { searchBrands } from '../../store/actions/brands'
const BrandSearch = ({ brandName, brandId, onChange, disabled = false }) => {
  const ref = useRef()
  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState(null)
  const [inputValue, setInputValue] = useState('')
  const dispatch = useDispatch()
  const [brands, setBrands] = useState([])

  const [loading, setLoading] = useState(false)

  useOnClickOutside(ref, () => {
    setInputValue(brandName || '')
    setOpen(false)
  })

  useEffect(() => {
    setInputValue(brandName)
  }, [brandName])

  const onInputChange = async e => {
    if (e.target.value === selected) return
    setInputValue(e.target.value)
    setLoading(true)
    setBrands([])
    let res = await dispatch(searchBrands(e.target.value))
    if (res.success) {
      setBrands(res.data)
    } else {
      setBrands([])
    }
    setLoading(false)
    setOpen(true)
  }

  const onSelectBrand = ({ id, name }) => {
    onChange({ type: 'brand_name', value: name })
    setTimeout(() => {
      onChange({ type: 'brand_id', value: id })
    })
    setSelected(name)
    setOpen(false)
  }

  const onCreateBrand = () => {
    onChange({ type: 'brand_name', value: inputValue })
    setTimeout(() => {
      onChange({ type: 'brand_id', value: null })
    })
    setOpen(false)
    setSelected(inputValue)
    setInputValue()
  }

  const onFocus = () => {
    if (inputValue && brands?.length) {
      setOpen(true)
    }
  }

  const needCreate = () => {
    if (loading || inputValue.length === 0) return false;
    let need = true
    brands.forEach(brand => {
      if (inputValue.trim().toLowerCase() === brand.name.trim().toLowerCase()) need = false
    })
    return need
  }

  return (
    <div className={cn(['brandSearchSelect', { isOpen: open }])} ref={ref} style={{
      borderColor: disabled ? '#121217' : null
    }}>
      <DebounceInput
        placeholder='Search '
        value={inputValue}
        onChange={onInputChange}
        debounceTimeout={2000}
        className={disabled ? 'form-control onboarded' : 'form-control'}
        name='brand'
        autoComplete='off'
        onFocus={onFocus}
        disabled={disabled}
      />

      {open && (
        <div className='brandSearchDropdown'>
          <div className='brandSearchDropdownItem divider'></div>
          {loading ? (
            <div className='spanture px-4 py-1'>
              <i className='fad fa-spinner fa-spin mr-2'></i>
            </div>
          ) : null}
          {brands &&
            brands.map((value, index) => (
              <div
                role='button'
                className='brandSearchDropdownItem'
                key={index}
              >
                <div
                  className='d-flex justify-content-between align-items-center'
                  href='#'
                  onClick={() => onSelectBrand(value)}
                >
                  <div className='d-flex align-items-center'>
                    <i className='fas fa-store f-16 mr-2'></i>
                    {value.name}
                  </div>
                  <div className='d-flex align-items-center'>
                    {value.locations && value.locations.length > 0
                      ? value.locations.slice(0, 4).map((location, index) => {
                          return (
                            <div
                              className='brandSearchDropdownLocation'
                              key={index}
                            >
                              <span>
                                {`${location.state} ${location.location_count}`}
                              </span>
                            </div>
                          )
                        })
                      : null}
                    {value.locations && value.locations.length > 4 ? (
                      <div className='brandSearchDropdownLocation'>
                        <span>more</span>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            ))}
          {needCreate() && (
            <div className='brandSearchDropdownItem creatable'>
              <div
                role='button'
                className='d-block'
                href='#'
                onClick={() => onCreateBrand()}
              >{`Create “${inputValue}”`}</div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default BrandSearch
