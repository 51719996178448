import React from 'react'

const ReferralTableHead = ({ onChangeFilter, countOrder }) => {
  const iconRender = () => {
    return (
      <div className='seven-day-icon'>
        <i
          className='fas fa-sort-up'
          style={{
            color:
              countOrder === 'ASC'
                ? 'rgba(233, 233, 233, 0.75)'
                : 'rgba(233, 233, 233, 0.5)',
          }}
        />
        <i
          className='fas fa-sort-down'
          style={{
            color:
              countOrder === 'DESC'
                ? 'rgba(233, 233, 233, 0.75)'
                : 'rgba(233, 233, 233, 0.5)',
          }}
        />
      </div>
    )
  }
  return (
    <thead>
      <tr>
        <th className='fixed-width fixed-120'>REWARD</th>
        <th className='fixed-width fixed-120'>AMOUNT</th>
        <th>REFERRER</th>
        <th>REFERRER EMAIL</th>
        <th>BRAND</th>
        <th>LOCATION</th>
        <th>PLAN</th>
        <th>CLOSER</th>
        <th
					className='seven-day'
					onClick={() => onChangeFilter('count_order')}
        >
					30 DAYS
					{iconRender()}
        </th>
				<th className='text-right'>ONBOARDING</th>
      </tr>
    </thead>
  )
}

export default ReferralTableHead
