import moment from 'moment'

export const weekFormat = 'MM/DD'

export const monthFormat = 'YYYY/MM'

export const customWeekStartEndFormat = value =>
  `${moment(value).startOf('week').format(weekFormat)} ~ ${moment(value)
    .endOf('week')
    .format(weekFormat)}`

export const customMonthFormat = value => `${moment(value).format('MMM, YYYY')}`

export const customQuarterFormat = value =>
  `Q${moment(value).quarter()} ${moment(value).year()}`

export const customYearFormat = value => `${moment(value).format('YYYY')}`
