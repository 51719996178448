import React from 'react'
import { Row, Col, Input } from 'reactstrap'

export default function ModalComment({
	disabled,
	creditComment,
	setCreditComment,
}) {
	return (
		<>
			<Row className='form-row'>
				<Col>
					<div className='cmi-title'>CREDIT COMMENT</div>
				</Col>
			</Row>
			<Row className='form-row'>
				<Col>
					<Input
						type='textarea'
						name='credit comment'
						placeholder={''}
						disabled={disabled}
						value={creditComment || ''}
						onChange={e => setCreditComment(e.target.value)}
						style={{
							height: 72,
							resize: 'none',
							padding: '10px 16px',
						}}
					/>
				</Col>
			</Row>
		</>
	)
}